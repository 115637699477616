import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
let Templates = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true)
   let [templates, setTemplates] = useState([])
   useEffect(() => {
      getTemplate()
   }, [])
   let getTemplate = () => {
      try {
         AppApi.getTemplate().then(res => {
            if (res.status == 200) {
               setTemplates(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }


   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               <div className="container-fluid mt-3">
                  <div class="row">
                     <div class="col-sm-4">
                        <Link class="btn btn-info rounded-0 text-white" to={Url.AddTemplates}>Add Template <i class="fa fa-angle-double-right"></i></Link>
                     </div>
                  </div>
                  <div className="row mt-3">
                     <div class="col-sm-12">
                        <div class="panel border panel-default">

                           <div class="panel-heading">
                              <strong>Templates</strong><div class="badge badge-info ng-binding"></div>
                           </div>
                           <div class="table-responsive p-1">
                              <table class="table table-condensed table-hover table-striped">
                                 <thead>
                                    <tr>
                                       <th> Name</th>
                                       <th>Subject</th>
                                       <th>Content</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {apiLoading ? (
                                       <tr>
                                          <td className='text-center' colSpan="3">Loading...</td>
                                       </tr>
                                    ) : templates.map((data, index) => {
                                       return (<tr key={index}>
                                          <td><Link to={Url.EditTemplates(data.id)}>{data.title}</Link></td>
                                          <td>{data.subject}</td>
                                          <td>{data?.content?.substring(0, 20)}
                                             <ToolTipContainer
                                                view={""}
                                                index={1}
                                                placement="left"
                                                icon="text-info"
                                                title={""}
                                                des={data.content}
                                             />

                                          </td>
                                       </tr>)
                                    })}
                                    {!apiLoading && templates.length == 0 && (
                                       <tr>
                                          <td colSpan={3} className="text-center text-info">No Record found.</td>
                                       </tr>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>)}

      </>
   )
}

export default withNavigation(Templates)