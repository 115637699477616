import axios from "../Utils/axios";

let ItemsApi = {
   topAvailable: async () => axios({
      url: "/items/top_available.json?limit=300",
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   autoMatch: async (data) => axios({
      url: `/items/${data}/auto_match.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   WideAutoMatch: async (data) => axios({
      url: `/items/${data}/auto_match_wide.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   advanceSearch: async (data) => axios({
      url: `/items/advanced_search.json?category_id=${data.category_id}&item_container_name=${data.item_container_name}&item_found_date_end=${data.item_found_date_end}&item_found_date_start=${data.item_found_date_start}&item_status=${data.item_status}&keyword=${data.keyword}&venue_id=${data.venue_id}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   revertRelease: async (data) => axios({
      url: `/items/${data}/remove_release.json`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   todayItems: async () => axios({
      url: "/items/found_today.json",
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   topAvailableLimit: async (data) => axios({
      url: `/items/top_available.json?limit=${data}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   lastReleasedItems: async (data) => axios({
      url: `/items/last_released?limit=${data}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   searchItemsWithKeyWords: async (data, status) => axios({
      url: `/items/keyword_search?keyword=${data}&released=${status}`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addItems: async (data) => axios({
      url: "/items.json",
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addItemsPhoto: async (data) => axios({
      url: `/items/${data.id}/add_photo.json`,
      method: "patch",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteApi: async (data) => axios({
      url: `/items/${data}/inactivate.json`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteItemsPhoto: async (data) => axios({
      url: `/items/${data}/delete_photo.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   claimItems: async (data) => axios({
      url: `/items/${data.id}/release.json`,
      method: "patch",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateItems: async (data) => axios({
      url: `/items/${data.id}.json`,
      method: "patch",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsDetails: async (data) => axios({
      url: `/items/${data}.json`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getItemParcelInformation: async (data) => axios({
      url: `/items/${data}/get_item_parcel_information`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateItemParcelInformation: async (data) => axios({
      url: `/items/${data.id}/item_parcel_information`,
      method: "put",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsHistory: async (data) => axios({
      url: `/item_histories/${data}.json`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),

}
export default ItemsApi

