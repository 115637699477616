import React, { useEffect, useState, useRef } from 'react';

import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ClaimItemsApi from '../../Api/ClaimItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ClaimMatch from '../../Components/MatchingComponents/ClaimMaches';
import { useSelector } from 'react-redux';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import DateHelper from '../../Utils/DateHelper';
import moment from 'moment';
import EmailHelper from '../../Utils/EmailHelper';
import SelectEmailTemplateDropdown from "../../Components/dropdowns/SelectEmailTemplateDropdown";

const FormValidation = Yup.object().shape({
   claim_first_name: Yup.string().required('First name is required.'),
   claim_last_name: Yup.string().required('Last name is required.'),
   category_id: Yup.string().required('Category  is required.'),
   claim_lost_date: Yup.string().required('Lost Date  is required.'),
   claim_description: Yup.string().required('Description is required.'),
   claim_expire_after: Yup.string().required('Expire after  is required.'),
});
let Index = (props) => {
   const componentRef = useRef();
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [CategoriesData, setDataCategories] = useState([]);
   let [printModal, setPrintModal] = useState(false);
   let [venuesData, setDataVenues] = useState([]);
   let [data, setData] = useState({});
   let [apiLoading, setApiLoading] = useState(true);
   let [claimId, setClaimId] = useState(-1);
   let [claimDescription, setClaimDescription] = useState("");

   let { id } = props.params;
   useEffect(() => {
      getApi(id);
   }, [])

   let getApi = (id) => {
      Promise.all([getCategories(),
      getVenues(),
      getData(id)

      ]).then((values) => {
         setDataCategories(values[0])
         setDataVenues(values[1])
         setData(values[2])
         setApiLoading(false)
      });
   }

   let getData = async (id) => {
      try {
         let result = await ClaimItemsApi.getClaim(id).then(res => {
            setClaimId(id);
            if (res.status == 200) {
               setClaimDescription(res.data.claim_description);
               res.data.claim_lost_date = DateHelper.convertUTCToLocalDate(res.data?.claim_lost_date);
               return res.data
            }
         }).catch(error => {
            return []
         })
         return result
      } catch (error) {
         setClaimId(id);
         return []
      }
   }
   let getCategories = async () => {
      try {
         let result = await AppApi.getCategories().then(res => {
            if (res.status == 200) {
               return res.data
            }
         }).catch(error => {
            return []
         })
         return result
      } catch (error) {
         return []
      }

   }

   let deleteClaim = async (id) => {
      try {
         let result = await AppApi.deleteClaims(id).then(res => {
            if (res.status == 200) {
               toast.success('Record deleted successfully.', {
                  autoClose: 5000,
               });
               props.navigate(Url.Claims)
            }
         }).catch(error => {
            toast.error('Record deleting failed.', {
               autoClose: 5000,
            });
         });
         return result
      } catch (error) {
         return []
      }
   }
   let getVenues = async () => {
      try {
         let result = await AppApi.getVenues().then(res => {
            if (res.status == 200) {
               return res.data
            }
         }).catch(error => {
            return []
         });
         return result
      } catch (error) {
         return []
      }
   }


   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   });

   return (
      <>

         <UserHeader />
         {!UserPermissions?.permissions?.pages?.claims?.edit_claim ? <UnAuthorized /> : (
            <>
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <>
                     <div className="container-fluid mt-3">
                        {data.id ? (
                           <div className="row">
                              <div className="col-md-8 co-12">
                                 <Formik
                                    initialValues={{
                                       claim_first_name: data.claim_first_name,
                                       claim_last_name: data.claim_last_name,
                                       claim_phone: data.claim_phone,
                                       claim_email: data.claim_email,
                                       venue_id: data.venue_id,
                                       claim_lost_location: data.claim_lost_location,
                                       category_id: data.category_id,
                                       claim_lost_date: data.claim_lost_date,
                                       claim_description: data.claim_description,
                                       claim_notes: data.claim_notes,
                                       claim_expire_after: data.claim_expire_after,
                                       claim_follow_up: data.claim_follow_up,
                                       claim_source_web: data.claim_source_web
                                    }}
                                    validationSchema={FormValidation}
                                    onSubmit={(values, { setSubmitting }) => {
                                       setSubmitting(true);
                                       try {
                                          let valuesToAdd = JSON.parse(JSON.stringify(values));
                                          valuesToAdd.claim_lost_date = DateHelper.convertLocalToUTCDate(valuesToAdd?.claim_lost_date);
                                          let object = {
                                             data: valuesToAdd,
                                             id
                                          }
                                          ClaimItemsApi.updateClaim(object).then(result => {
                                             if (result && result.status == 200) {
                                                setData({...data, ...object.data})
                                                setClaimDescription(object.data.claim_description);
                                                toast.success('Updated successfully.', {
                                                   autoClose: 5000,
                                                });
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false);
                                          }).catch(error => {
                                             setSubmitting(false);
                                             try {
                                                let message = error.response.data.errors[0]
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          console.log("🚀 ~ file: Index.js ~ line 133 ~ Index ~ error", error)
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form >
                                          <div className="panel border panel-default">
                                             <div className="panel-heading d-flex justify-content-between">
                                                <h3 className="panel-title">Claim Details
                                                   <ToolTipContainer
                                                      index={1}
                                                      view={""}
                                                      placement="right"
                                                      icon="text-info"
                                                      title="About Claims"
                                                      des={"Use this form to capture lost item(s) reports by the public. Refrain from entering language that identifies reporter as a 'patient' if you're in a Healthcare setting or 'student' if you're in a University setting."}
                                                   />

                                                   - ID: {id}  </h3>
                                                <div className='d-flex align-items-center'>
                                                   <SelectEmailTemplateDropdown
                                                       setApiLoading={setApiLoading}
                                                       sendToEmail={data.claim_email}
                                                       contentPopulator={(content)=> EmailHelper.createClaimEmailBody(decodeURIComponent(content), claimId, claimDescription)}
                                                   />
                                                   {/* <a href={`mailto:${values.claim_email}?subject=Your Lost Claim Report ( ${id})&body=${values.claim_first_name},%0ABelow is a summary of your lost item report.%0A%0AClaim ID: ${values.id} %0APhone: ${values.claim_phone}%0ALost Date: ${data.claim_lost_date}%0ADescription: ${data.claim_description}%0A`} className='px-2'><i className="fa fa-envelope" title="Launch default mail program with claim info pre-populated" style={{ color: "#666" }}></i></a> */}
                                                   <span className='d-flex align-items-center pointer' onClick={() => setPrintModal(true)}>   <i className="fa fa-print me-1"></i> <span>Print</span></span>
                                                </div>
                                             </div>

                                             <div className="panel-body p-2 px-4">
                                                <div className="row">
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold required">First Name</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_first_name" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_first_name" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold required">Last Name</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_last_name" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_last_name" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  ">Phone</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_phone" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_phone" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  ">Email</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_email" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_email" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold">Venue</label>
                                                         <select className='form-control rounded-0'
                                                            onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                            value={values.venue_id}
                                                         >
                                                            <option value="">-Pick Venue -</option>
                                                            {venuesData.map((data, index) => {
                                                               return (
                                                                  <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                               )
                                                            })}
                                                         </select>
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  ">Lost Location</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_lost_location" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_lost_location" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold required">Category</label>
                                                         <select className='form-control rounded-0' name=""
                                                            onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                            value={values.category_id}
                                                         >
                                                            <option value="">-Pick Category -</option>
                                                            {CategoriesData.map((data, index) => {
                                                               return (
                                                                  <option key={data.id} value={data.id}>{data.category_description}</option>
                                                               )
                                                            })}
                                                         </select>
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold required ">Lost Date</label>
                                                         <DatePickerInput
                                                            onChange={setFieldValue}
                                                            name="claim_lost_date"
                                                            value={values.claim_lost_date}
                                                         />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_lost_date" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  required">Lost Items</label>
                                                         <textarea name="" className='form-control' rows="2"
                                                            value={values.claim_description}
                                                            onChange={(event) => setFieldValue("claim_description", event.target.value)}
                                                         ></textarea>
                                                         <ErrorMessage component="small" className='text-danger' name="claim_description" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  ">Notes</label>
                                                         <textarea name="" className='form-control' rows="2"
                                                            onChange={(event) => setFieldValue("claim_notes", event.target.value)}
                                                            value={values.claim_notes}
                                                         ></textarea>
                                                         <ErrorMessage component="small" className='text-danger' name="claim_notes" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold  required">Expire After
                                                            <ToolTipContainer
                                                               index={1}
                                                               view={""}
                                                               placement="right"
                                                               icon="text-info"
                                                               title="About Expire After"
                                                               des={"Select the number of days for which this Claim will be considered 'current' and resolvable. Claims beyond this date are still accessible but considered expired and won't appear by default in the recent list of claims. You can search, view, and edit expired Claims. Also note that under Settings section you may set the default Expire After value."}
                                                            />
                                                         </label>
                                                         <select className="form-control "
                                                            onChange={(event) => setFieldValue("claim_expire_after", event.target.value)}
                                                            value={values.claim_expire_after}
                                                         >
                                                            <option value="" selected="selected">-- Pick Expire After --</option>
                                                            <option value="30">30 days</option>
                                                            <option value="60">60 days</option>
                                                            <option value="90">90 days</option>
                                                         </select>
                                                         <ErrorMessage component="small" className='text-danger' name="claim_expire_after" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold required ">days or OTHER::</label>
                                                         <Field type="text" className='form-control rounded-0' name="claim_expire_after" />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_expire_after" />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                      <div className="form-group">
                                                         <label className="font-weight-bold d-block ">Follow-Up
                                                            <ToolTipContainer
                                                               index={1}
                                                               view={""}
                                                               placement="right"
                                                               icon="text-info"
                                                               title="About Follow-up"
                                                               des={"Check to signify Claim requires follow-up action(s) by your organization. For Web Claims, un-check to signify acknowledging receipt and review of the Claim. Use Notes to capture the follow-up action(s)."}
                                                            />
                                                         </label>
                                                         <Field type="checkbox" name="claim_follow_up" checked={values.claim_follow_up} />
                                                         <ErrorMessage component="small" className='text-danger' name="claim_follow_up" />
                                                      </div>
                                                   </div>
                                                </div>
                                                    {(data.claim_source_web == false)  && 
                                                         <>
                                                            <div className="row">
                                                                  <div class="col-sm-12">
                                                                     <div class="alert-warning " >
                                                                        <strong>Created By: </strong> <i class="fa fa-user"></i> <span >{data?.create_user?.email}</span> <i class="fa fa-calendar"></i>
                                                                        <span> {moment(data?.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>
                                                                        <br />
                                                                        <strong>Last Updated By: </strong> <i class="fa fa-user"></i> {data?.update_user?.email} <i class="fa fa-calendar"></i>
                                                                        <span> {moment(data?.updated_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>

                                                                     </div>
                                                                  </div>
                                                            </div>
                                                         </>
                                                   }
                                                   {
                                                    (data.claim_source_web == true)  && (
                                                         <> 
                                                            <div className="row">
                                                               <div class="col-sm-12">
                                                                  <div class="alert-warning " >
                                                                     <strong>Created By: </strong> <i class="fa fa-user"></i> <span >{data?.claim_first_name+" "}({data?.claim_email}) </span> <i class="fa fa-calendar"></i>
                                                                     <span> {moment(data?.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>
                                                                     <br />

                                                                     {(data?.update_user) && (
                                                                        <>
                                                                           <strong>Last Updated By: </strong> <i class="fa fa-user"></i> {data?.update_user?.email} <i class="fa fa-calendar"></i>
                                                                           <span> {moment(data?.updated_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>
                                                                        </>
                                                                     )}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>
                                                      )

                                                   }  
                                               
                                             </div>
                                             <div className="panel-footer">
                                                <div>
                                                   {isSubmitting ? ("Loading..") : (
                                                      <>
                                                         <button className="btn btn-success me-1" disabled={isSubmitting} type="submit "   > {isSubmitting ? "Loading..." : (<><i className="fa fa-check"></i> Update</>)} </button>
                                                         <button className="btn btn-danger me-1"
                                                            type='button'
                                                            onClick={async () => {
                                                               setSubmitting(true);
                                                               await deleteClaim(id)
                                                               setSubmitting(false);
                                                            }}
                                                         > <i className="fa fa-trash-o"></i> Delete</button>
                                                         <Link to={Url.Claims} className="btn btn-default"  >Cancel</Link>
                                                      </>
                                                   )}
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik>
                              </div>
                              <div className="col-md-4">
                                 <ClaimMatch id={id} />
                              </div>
                           </div>
                        ) : (
                           <div className="row">
                              <div className="col-12">
                                 <div className="alert alert-warning">
                                    Record not found, incorrect ID or record may have been deleted.
                                 </div>
                              </div>
                           </div>
                        )}

                     </div>
                     <Modal
                        show={printModal}
                        onHide={() => {
                           setPrintModal(false)
                        }}
                        keyboard={true}
                     >
                        <Modal.Body>
                           {/* style={{ width: "350px", height: "115" }} */}
                           <div ref={componentRef} >
                              <div className='d-flex justify-content-between w-100 align-items-center'   >
                                 <div> Claim ID: {data?.id}<br />
                                    {data?.claim_description},
                                    {data?.claim_original_description}</div>
                                 <div>
                                    <button className='no-print'
                                       onClick={handlePrint}
                                    >Print</button>
                                 </div>
                              </div>
                              <table className="print-table"  >
                                 <tbody>
                                    <tr><td>First Name</td><td>{data.claim_first_name} </td></tr>
                                    <tr><td>Last Name</td><td> {data.claim_last_name} </td></tr>
                                    <tr><td>Phone</td><td> {data.claim_phone} </td></tr>
                                    <tr><td>Email</td><td> {data.claim_email} </td></tr>
                                    <tr><td>Lost Date</td><td> {DateHelper.systemFormattedDate(data.claim_lost_date, true)} </td></tr>
                                    <tr><td>Venue</td><td>{data.venue_id} </td></tr>
                                    <tr><td>Lost Location</td><td> {data.claim_lost_location}</td></tr>
                                    <tr><td>Expire After</td><td> {data.claim_expire_after} </td></tr>
                                    <tr><td>Category</td><td>{data?.category?.category_description}
                                    </td></tr>
                                 </tbody></table>
                           </div>

                        </Modal.Body>
                     </Modal>

                  </>
               )}

            </>)}

         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withParams(withNavigation(Index))