import React, {useEffect, useState, useRef, useMemo} from 'react';

import { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import Modal from 'react-bootstrap/Modal';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import Swal from 'sweetalert2'
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ItmesAppNavigater from '../../Components/AppComponents/ItmesAppNavigater';
import ItemsMatches from '../../Components/MatchingComponents/ItemsMatches';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import QrCode from '../../Components/AppComponents/QrCode';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import moment from 'moment';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import DateHelper from '../../Utils/DateHelper';
import SelectEmailTemplateDropdown from "../../Components/dropdowns/SelectEmailTemplateDropdown";
import EmailHelper from '../../Utils/EmailHelper';
import UpdateStatusForm from 'Components/Forms/Items/UpdateStatusForm';

const FormValidation = (data) => {
   let checks = {}
   if (!data?.storage_date_enabled) {
      checks.item_storage_date = Yup.string().required('Item storage date is required.');
   }
   if (data?.require_asset_id) {
      checks.item_asset_id = Yup.string().required('Assets id is required.')
   }
   if (data?.require_venue) {
      checks.venue_id = Yup.string().required('Venue is required.')
   }
   if (data?.require_category) {
      checks.category_id = Yup.string().required('Category name is required.')
   }

   checks.category_id = Yup.string().required('Category name is required.')
   checks.item_container_name = Yup.string().required('Container name is required.')
   checks.item_description = Yup.string().required('Description is required.')
   checks.item_expire_after = Yup.string().required('Expire date is required.')
   checks.item_found_date = Yup.string().required('Item found date is required.')

   return Yup.object().shape(checks)
};
let Index = (props) => {

   const UserPermissions = useSelector((state) => state.UserPermissions)
   let { id } = props.params;
   let [printOption, setPrintOption] = useState("");
   let [CategoriesData, setDataCategories] = useState([]);
   let [data, setData] = useState({});
   let [itemsData, setItemsData] = useState({});
   let [ContainersData, setDataContainers] = useState([]);
   let [printModal, setPrintModal] = useState(false);
   let [venuesData, setDataVenues] = useState([]);
   let [apiLoading, setApiLoading] = useState(true);
   let [disabled, setDisable] = useState(false);
   let [itemID, setItemID] = useState(-1);
   let [itemDescription, setItemDescription] = useState("");
   let [showUpdateStatusForm, setShowUpdateStatusForm] = useState(false);

   useEffect(() => {
      getData();
   }, [])

   let getData = async () => {
      try {
         AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   useEffect(() => {
      getCategories();
      getContainers();
      getVenues();
      getItemsData();
   }, [])
   let getItemsData = async () => {
      try {
         console.log("props", props)

         ItemsApi.itemsDetails(id).then(res => {
            setItemID(id);
            
            if (res.status == 200) {
               setItemDescription(res?.data?.item_description);
               if (res?.data?.item_status?.item_status_code == "R") {
                  setDisable(true);
               }
               res.data.item_found_date = DateHelper.convertUTCToLocalDate(res.data?.item_found_date);
               res.data.item_storage_date = DateHelper.convertUTCToLocalDate(res.data?.item_storage_date);
               setItemsData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setItemID(id);
            setApiLoading(false)
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getCategories = async () => {
      try {
         AppApi.getCategories().then(res => {
            if (res.status == 200) {
               setDataCategories(res.data)
            }
         }).catch(error => {
            console.log("server error", error)
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getContainers = async () => {
      try {
         AppApi.getContainers().then(res => {
            if (res.status == 200) {
               setDataContainers(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getVenues = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setDataVenues(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let deleteApi = async () => {
      try {
         ItemsApi.deleteApi(id).then(res => {
            if (res.status == 200) {
               props.navigate(Url.RepoAppDashboard);
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   const getSendToEmail = () => {
      let email = itemsData.item_suspected_owner_email

      if (email?.length <= 0 && itemsData?.item_claim_email != null){
         email = itemsData.item_claim_email;
      }

      return email;
   }

   const componentRef = useRef();
   const handlePrintFullDetails = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
         size: landscape  !important;
         marginTop: 25px !important;
         margin: 25px !important;
       }
       
       @media print {
         @page {

            size: landscape  !important;
            marginTop: 25px !important;
            margin: 25px !important;
         }
       }`
   });
   const handleAddressLabelPrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
         size: landscape  !important;
         marginTop: 25px !important;
         margin: 25px !important;
       }
       
       @media print {
         @page {

            size: landscape  !important;
            marginTop: 25px !important;
            margin: 25px !important;
         }
       }`
   });
   const handleAddressLabel2Print = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
         
       }
       
       @media print {
         @page {
            size: landscape  !important;
           marginTop: 25px !important;
           margin: 25px !important;
         }
       }`
   });
   const handleShippingPrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
         size: landscape  !important;
       }
       
       @media print {
         @page {
           marginTop: 25px !important;
           margin: 25px !important;
         }
       }`
   });
   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.items?.edit_item ? <UnAuthorized /> : (
            <>
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <>
                     <div className="container-fluid mt-3">
                        {itemsData.id ? (
                           <>
                              <ItmesAppNavigater />
                              <div className="row">
                                 <div className="col-md-8 co-12">
                                    {itemsData?.item_status?.item_status_code == "R" && (
                                       <div class="alert alert-warning ng-binding">
                                          <p class="lead">Item Released <Link to={Url.ItemsRelease(itemsData.id)} className="btn btn-default btn-sm">View Details </Link></p>
                                          <i class="fa fa-user"></i> {itemsData?.release_user?.email}<br />
                                          <i class="fa fa-calendar"></i> <span id="item_release_datetime" class="ng-binding">{moment(itemsData?.item_release_datetime).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span> <br />
                                       </div>
                                    )}
                                    <Formik
                                       initialValues={{
                                          category_id: itemsData.category_id,
                                          item_asset_id: itemsData.item_asset_id,
                                          item_container_name: itemsData.item_container_name,
                                          item_description: itemsData.item_description,
                                          item_expire_after: itemsData.item_expire_after,
                                          item_found_by: itemsData.item_found_by,
                                          item_found_date: itemsData.item_found_date,
                                          item_found_location: itemsData.item_found_location,
                                          item_notes: itemsData.item_notes,
                                          item_storage_date: itemsData.item_storage_date,
                                          item_suspected_owner: itemsData.item_suspected_owner,
                                          item_suspected_owner_country_code: itemsData.item_suspected_owner_country_code,
                                          item_suspected_owner_email: itemsData.item_suspected_owner_email,
                                          item_suspected_owner_initial_country: itemsData.item_suspected_owner_initial_country,
                                          item_suspected_owner_phone_number: itemsData.item_suspected_owner_phone_number,
                                          item_claim_email : itemsData.item_claim_email,
                                          item_title: itemsData.item_title,
                                          item_web_description: itemsData.item_web_description,
                                          venue_id: itemsData.venue_id
                                       }}
                                       validationSchema={FormValidation(data)}
                                       onSubmit={(values, { setSubmitting }) => {
                                          setSubmitting(true);
                                          try {
                                             let valuesToAdd = JSON.parse(JSON.stringify(values));
                                             valuesToAdd.item_storage_date = DateHelper.convertLocalToUTCDate(values?.item_storage_date);
                                             valuesToAdd.item_found_date = DateHelper.convertLocalToUTCDate(valuesToAdd?.item_found_date);
                                             let apiObjects = {
                                                id: id,
                                                data: valuesToAdd
                                             }
                                             ItemsApi.updateItems(apiObjects).then(result => {
                                                if (result && result.status == 200) {
                                                   setItemsData({...itemsData, ...result.data});
                                                   setItemDescription(result.data.item_description);
                                                   toast.success('Updated successfully.', {
                                                      autoClose: 5000,
                                                   });
                                                   // props.navigate(Url.RepoAppDashboard);
                                                } else {
                                                   toast.error('Server error.', {
                                                      autoClose: 5000,
                                                   });
                                                }
                                                setSubmitting(false)
                                             }).catch(error => {
                                                setSubmitting(false)
                                                try {
                                                   let message = error.response.data.errors[0]
                                                   toast.error(message ? message : 'Server Error!', {
                                                      autoClose: 5000,
                                                   });
                                                } catch (error) {

                                                }
                                             })
                                          } catch (error) {
                                             setSubmitting(false)
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                             console.log("server error", error.message)
                                          }
                                       }}
                                    >
                                       {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                          <Form >
                                             <div class="panel border panel-default">
                                                <div class="panel-heading d-flex justify-content-between">
                                                   <div>
                                                      <h3 class="panel-title">Item Details - ID: {id} </h3>
                                                   </div>
                                                   <div className='d-flex align-items-center'>
                                                      {itemsData?.item_status?.item_status_code !== "R" && <Link className={`btn btn-default`} onClick={() => setShowUpdateStatusForm(true)}>Update Status</Link>}
                                                       <SelectEmailTemplateDropdown
                                                           sendToEmail={getSendToEmail()}
                                                           setApiLoading={setApiLoading}
                                                           contentPopulator={(content) => EmailHelper.createItemEmailBody(decodeURIComponent(content),itemID,itemDescription)}
                                                       />
                                                      {itemsData?.item_status?.item_status_code == "R" ? (
                                                         <span class="label label-danger" >Status: {itemsData.item_status?.item_status_description}</span>
                                                      ) : itemsData?.item_expired ? (
                                                         <span class="label label-warning" >Status: {itemsData.item_status?.item_status_description}</span>
                                                      ) : itemsData?.item_status?.item_status_code ? (
                                                         <span class="label label-success" >Status: {itemsData.item_status?.item_status_description}</span>
                                                      ) : ""}
                                                      <Dropdown className='dashboardDropDown '>
                                                         <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                                                            <i class="fa fa-print"></i> Print
                                                         </Dropdown.Toggle>

                                                         <Dropdown.Menu>
                                                            <Link to="#" className='dropdown-item'
                                                               onClick={() => { setPrintOption(1); setPrintModal(true) }}
                                                            >Full Details</Link>
                                                            <Dropdown.Divider />
                                                            <small className='text-muted px-3'>Dymo LabelWriter Printer</small>
                                                            <Link to="#" className='dropdown-item'
                                                               onClick={() => { setPrintOption(2); setPrintModal(true) }}
                                                            >Address Label (28.85 x 88.5mm)</Link>
                                                            <Link to="#" className='dropdown-item'
                                                               onClick={() => { setPrintOption(3); setPrintModal(true) }}
                                                            >Address Label 2(28.85 x 88.5mm)</Link>
                                                            <Link to="#" className='dropdown-item'
                                                               onClick={() => { setPrintOption(4); setPrintModal(true) }}
                                                            >Shipping Label (54 x 101mm)</Link>

                                                         </Dropdown.Menu>
                                                      </Dropdown>
                                                   </div>
                                                </div>
                                                <div class="panel-body p-2 px-4">
                                                   <div className="row">
                                                      <div className="col-12">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold">Item Title</label>
                                                            <Field type="text" className='form-control rounded-0' disabled={disabled} name="item_title" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_title" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold required">Description</label>
                                                            <textarea name="" className='form-control' rows="2"
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("item_description", event.target.value)}
                                                               value={values.item_description}
                                                            ></textarea>
                                                            <ErrorMessage component="small" className='text-danger' name="item_description" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Web Description
                                                               <ToolTipContainer
                                                                  index={1}
                                                                  view={""}
                                                                  placement="right"
                                                                  icon="text-info"
                                                                  title="Web Description"
                                                                  des={"For accounts that currently use the Item Web Integration feature for displaying Items on public accessible page. Add less descriptive version of the Item. The Web Description will appear on the public Item search page. If no description is provided, the record will not be listed. Found Date and Found Location is also shown on the public Item search page. "}
                                                               />
                                                            </label>
                                                            <textarea name="" className='form-control' rows="2"
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("item_web_description", event.target.value)}
                                                               value={values.item_web_description}
                                                            ></textarea>
                                                            <ErrorMessage component="small" className='text-danger' name="item_web_description" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold">Venue</label>
                                                            <select className='form-control rounded-0'
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                               value={values.venue_id}
                                                            >
                                                               <option value="">-Pick Venue -</option>
                                                               {venuesData.map((data, index) => {
                                                                  return (
                                                                     <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                                  )
                                                               })}
                                                            </select>
                                                            <ErrorMessage component="small" className='text-danger' name="venue_id" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold required">Category</label>
                                                            <select className='form-control rounded-0' name=""
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                               value={values.category_id}
                                                            >
                                                               <option value="">-Pick Category -</option>
                                                               {CategoriesData.map((data, index) => {
                                                                  return (
                                                                     <option key={data.id} value={data.id}>{data.category_description}</option>
                                                                  )
                                                               })}
                                                            </select>
                                                            <ErrorMessage component="small" className='text-danger' name="category_id" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Container
                                                               <ToolTipContainer
                                                                  index={1}
                                                                  view={""}
                                                                  placement="right"
                                                                  icon="text-info"
                                                                  title="Container"
                                                                  des={"Pick a container where the item will be stored. You may add new container dropdown values under the Container section."}
                                                               />
                                                            </label>
                                                            <select className='form-control rounded-0'
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("item_container_name", event.target.value)}
                                                               value={values.item_container_name}
                                                            >
                                                               <option value="">-Pick Container -</option>
                                                               {ContainersData.map((data, index) => {
                                                                  return (
                                                                     <option key={data.container_name} value={data.container_name}>{data.container_name}</option>
                                                                  )
                                                               })}
                                                            </select>
                                                            <ErrorMessage component="small" className='text-danger' name="item_container_name" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Expire After
                                                               <ToolTipContainer
                                                                  index={1}
                                                                  view={""}
                                                                  placement="right"
                                                                  icon="text-info"
                                                                  title="Expire After"
                                                                  des={"Select the number of days this Item will be kept in storage per your rentention policy. Expiration is based on Storage Date + Expire After days. Items beyond this date will still be 'Available' but will show up as 'Expired' (such as under the Bulk Release Expired Items function)."}
                                                               />
                                                            </label>
                                                            <select className='form-control rounded-0'
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("item_expire_after", event.target.value)}
                                                               value={values.item_expire_after}
                                                            >
                                                               <option value="" >- Pick Expire After -</option>
                                                               <option value="30">30 days</option>
                                                               <option value="60">60 days</option>
                                                               <option value="90">90 days</option>
                                                               <option value="120">120 days</option>
                                                            </select>
                                                         </div>
                                                      </div>

                                                      <div className="col-md-3">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold required">or other # of days</label>
                                                            <Field type="text" className='form-control rounded-0' disabled={disabled} name="item_expire_after" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_expire_after" />
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className="row">
                                                      <div className="col-md-3">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold required">Found Date</label>
                                                            <DatePickerInput
                                                               disabled={disabled}
                                                               onChange={setFieldValue}
                                                               name="item_found_date"
                                                               value={values.item_found_date}
                                                            />
                                                            <ErrorMessage component="small" className='text-danger' name="item_found_date" />
                                                         </div>
                                                      </div>
                                                      {(!data?.storage_date_enabled || data?.storage_date_enabled == null) && (
                                                         <div className="col-md-3">
                                                            <div className="form-group">
                                                               <label className="font-weight-bold required">Storage Date
                                                                  <ToolTipContainer
                                                                     index={1}
                                                                     view={""}
                                                                     placement="right"
                                                                     icon="text-info"
                                                                     title="Storage Date"
                                                                     des={"Select the date when this item was added to storage. Usually, this should match the Found Date unless you have a different internal process. The expiration of this item is based on the Storage Date and Expire After value."}
                                                                  />
                                                               </label>
                                                               <DatePickerInput
                                                                  onChange={setFieldValue}
                                                                  disabled={disabled}
                                                                  name="item_storage_date"
                                                                  value={values.item_storage_date}
                                                               />
                                                               <ErrorMessage component="small" className='text-danger' name="item_storage_date" />
                                                            </div>
                                                         </div>
                                                      )}
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Asset / Badge ID</label>
                                                            <Field type="text" disabled={disabled} className='form-control rounded-0' name="item_asset_id" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_asset_id" />
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className="row">
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Suspected Owner</label>
                                                            <Field disabled={disabled} type="text" className='form-control rounded-0' name="item_suspected_owner" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_suspected_owner" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Suspected Owner Email</label>
                                                            <Field disabled={disabled} type="email" className='form-control rounded-0' name="item_suspected_owner_email" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_suspected_owner_email" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Suspected Owner Phone Number</label>
                                                            <Field disabled={disabled} type="text" className='form-control rounded-0' name="item_suspected_owner_phone_number" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_suspected_owner_phone_number" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Found Location</label>
                                                            <Field disabled={disabled} type="text" className='form-control rounded-0' name="item_found_location" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_found_location" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Found By</label>
                                                            <Field disabled={disabled} type="text" className='form-control rounded-0' name="item_found_by" />
                                                            <ErrorMessage component="small" className='text-danger' name="item_found_by" />
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className="row">
                                                      <div className="col-12">
                                                         <div className="form-group">
                                                            <label className="font-weight-bold  ">Notes</label>
                                                            <textarea name="" className='form-control' rows="2"
                                                               disabled={disabled}
                                                               onChange={(event) => setFieldValue("item_notes", event.target.value)}
                                                               value={values.item_notes}>
                                                            </textarea>
                                                         </div>

                                                      </div>
                                                   </div>
                                                   <div className="row">
                                                      <div class="col-sm-12">
                                                         <div class="alert alert-warning  " >
                                                            <strong>Created By:  <span  >{itemsData?.create_user?.first_name}</span></strong> <i class="fa fa-user"></i> <span >{itemsData?.create_user?.email}</span> <i class="fa fa-calendar"></i>
                                                            <span>{moment(itemsData.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>
                                                            <br />
                                                            <strong>Last Updated By: <span>{itemsData?.update_user?.first_name}</span></strong> <i class="fa fa-user"></i> {itemsData?.update_user?.email} <i class="fa fa-calendar"></i>

                                                            <span>{moment(itemsData.updated_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span>
                                                            <div>Item considered expired on/after: <span  >{moment(itemsData.item_expiration_date).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span></div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="panel-footer">
                                                   <div>
                                                      <span >
                                                         <button class="btn btn-success me-1" disabled={isSubmitting ? true : false} type="submit "     > {isSubmitting ? "Loading..." : (<><i class="fa fa-check"></i> Save</>)} </button>

                                                         {itemsData.item_status?.item_status_code == "A" && (
                                                            <>
                                                               {!isSubmitting && (
                                                                  <>
                                                                     <button class="btn btn-danger me-1" type='button'
                                                                        onClick={() => {
                                                                           Swal.fire({
                                                                              // title: '',
                                                                              text: 'Are you sure you want to delete this item?',
                                                                              showDenyButton: false,
                                                                              showCancelButton: true,
                                                                              confirmButtonText: 'Yes',
                                                                           }).then((result) => {
                                                                              /* Read more about isConfirmed, isDenied below */
                                                                              if (result.isConfirmed) {
                                                                                 setSubmitting(true);
                                                                                 deleteApi()
                                                                              } else if (result.isDenied) {
                                                                                 setSubmitting(false);
                                                                              }
                                                                           })
                                                                        }}
                                                                     > <i class="fa fa-trash"></i> Delete</button>
                                                                  </>
                                                               )}
                                                            </>
                                                         )}
                                                         <Link to={Url.RepoAppDashboard} class="btn btn-default"  >Cancel</Link>


                                                      </span>

                                                   </div>
                                                </div>
                                             </div>
                                          </Form>
                                       )}
                                    </Formik>
                                 </div>
                                 <div className="col-md-4">
                                    {itemsData.item_status?.item_status_code != "R" && (
                                       <ItemsMatches id={id} />
                                    )}

                                 </div>
                              </div>
                           </>
                        ) : (
                           <div className="row">
                              <div className="col-12">
                                 <div className="alert alert-warning">
                                    Record not found, incorrect ID or record may have been deleted.
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>
                     {showUpdateStatusForm && (
                        <UpdateStatusForm show={showUpdateStatusForm} handleClose={() => setShowUpdateStatusForm(false)} handleSuccess={(itemData) => setItemsData(itemData)} itemData={itemsData} />  
                     )}
                     <Modal
                        show={printModal}
                        size={printOption == 1 ? "lg" : ""}
                        onHide={() => {
                           setPrintModal(false)
                           setPrintOption("false")
                        }}
                        keyboard={true}
                     >
                        <Modal.Body>

                           <div ref={componentRef} >
                              <>
                                 <button className='no-print float-right'
                                    onClick={printOption === 1 ? handlePrintFullDetails : printOption === 2 ? handleAddressLabelPrint : printOption === 3 ? handleAddressLabel2Print : handleShippingPrint}
                                 >Print</button>
                                 {printOption == 1 && (
                                    <>
                                       <div className='d-flex justify-content-between w-100 align-items-center'   >
                                          <div> Item ID:: {itemsData?.id}<br />
                                             {itemsData?.item_title},
                                             {itemsData?.item_description}
                                          </div>
                                          <div>

                                          </div>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                          <div className="w-100  ">
                                             <table className="print-table">
                                                <tbody>
                                                   <tr>
                                                      <td>Category</td>
                                                      <td >{itemsData?.category?.category_description}</td>
                                                   </tr>
                                                   <tr><td>Container</td><td > {itemsData.item_container_name} </td></tr>
                                                   <tr><td>Venue</td><td >  {itemsData?.venue?.venue_name} </td></tr>
                                                   <tr><td>Asset / Badge ID</td><td > {itemsData?.item_asset_id} </td></tr>
                                                   <tr><td>Expire After</td><td >{itemsData?.item_expire_after} </td></tr>
                                                   <tr><td>Found Date</td><td >{DateHelper.systemFormattedDate(itemsData?.item_found_date, true)} </td></tr>
                                                   <tr><td>Found Location</td><td > {itemsData?.item_found_location} </td></tr>
                                                   <tr><td>Found By</td><td > {itemsData?.item_found_by} </td></tr>
                                                   <tr><td>Storage Date</td><td >{DateHelper.systemFormattedDate(itemsData?.item_storage_date, true)} </td></tr>
                                                   <tr><td>Status</td><td > {itemsData?.item_status_code == "A" ? "Available" : "Release"} </td></tr>
                                                </tbody>
                                             </table>
                                          </div>
                                          <div className="w-100">
                                             <table className="print-table w-100">
                                                <tbody><tr><td>Claim</td><td > {itemsData?.claim_id} </td></tr>
                                                   <tr><td>First Name</td><td >{itemsData?.item_claim_first_name}  </td></tr>
                                                   <tr><td>Last Name</td><td > {itemsData?.item_claim_last_name} </td></tr>
                                                   <tr><td>Email</td><td >{itemsData?.item_claim_email}  </td></tr>
                                                   <tr><td>Phone</td><td >  {itemsData?.item_claim_phone}</td></tr>
                                                   <tr><td>Address</td><td > {itemsData?.item_release_address} </td></tr>
                                                   <tr><td>Release Date</td><td class="ng-binding"> {itemsData?.item_release_datetime} </td></tr>
                                                   <tr><td>Notes</td><td class="ng-binding">{itemsData?.item_notes}  </td></tr>
                                                   <tr><td> </td></tr>
                                                </tbody></table>
                                          </div>
                                       </div>
                                       <div className="col-12">
                                          <table className="table">
                                             <tr >
                                                <td className='pt-3'>
                                                   <br />
                                                   <br />
                                                   X________________________________________________</td>
                                                <td> <QrCode id={itemsData.id} /></td>
                                             </tr>
                                          </table>
                                       </div>


                                    </>
                                 )}

                                 {printOption == 2 && (
                                    <div className="d-flex  " >
                                       <div style={{ width: "270px" }} >
                                          ID: {itemsData.id} S-DATE: {DateHelper.systemFormattedDate(itemsData?.item_found_date, true)} <br />
                                          CONT: {itemsData.item_container_name} <br />
                                          {itemsData.item_description}
                                       </div>
                                       <div className=' mx-2'>
                                          <QrCode id={itemsData.id} />
                                       </div>
                                    </div>

                                 )}

                                 {printOption == 3 && (
                                    <div className="d-flex">
                                       <table>
                                          <tbody><tr>
                                             <td><strong>ID:</strong> {itemsData.id}</td><td  ><strong>CONTAINER ID:</strong> {itemsData.item_container_name}</td>
                                          </tr>
                                             <tr>
                                                <td><strong>S. DATE:</strong> {DateHelper.systemFormattedDate(itemsData?.item_found_date, true)}</td><td ><strong>CATEGORY:</strong> {itemsData?.category?.category_description}
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><strong>VENUE:</strong>  {itemsData?.venue?.venue_name}</td><td ><strong>CREATED BY:</strong> {itemsData?.create_user?.first_name} {itemsData?.create_user?.last_name}</td>
                                             </tr>
                                             <tr>
                                                <td colspan="2"   ><strong>EXPIRATION DATE:</strong> {itemsData.item_expiration_date}</td>
                                             </tr>
                                             <tr>
                                             </tr>
                                             <tr>
                                                <td colspan="2"   ><strong>ITEM DESCRIPTION:</strong> {itemsData.item_description}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                       <div className='px-2'>
                                          <QrCode id={itemsData.id} />
                                       </div>
                                    </div>

                                    // <PrintLabels2 item_id={itemsData.id} item_storage_date={itemsData.item_storage_date}
                                    // item_venue_name = {itemsData.venue?.venue_name} item_container_name ={itemsData.item_container_name} 
                                    // item_category_name = {itemsData?.category?.category_description} 
                                    // item_created_by = {itemsData?.create_user?.first_name}
                                    // item_expiration_date = {itemsData.item_expiration_date}
                                    // item_description = {itemsData.item_description}
                                    // >
                                    // </PrintLabels2>
                                 )}
                                 {printOption == 4 && (
                                    <div className="d-flex ">
                                       <div >
                                          ID: {itemsData.id} &nbsp;&nbsp;&nbsp; CONT.: {itemsData.item_container_name} <br />
                                          S-DATE: {DateHelper.systemFormattedDate(itemsData?.item_storage_date, true)} &nbsp;&nbsp; EXP.:{itemsData.item_expiration_date}<br />
                                          F-DATE: {DateHelper.systemFormattedDate(itemsData?.item_found_date, true)} &nbsp;&nbsp; FOUND BY: {itemsData.item_found_by} <br />
                                          VENUE:  {itemsData?.venue?.venue_name}<br />
                                          Asset / Badge ID: {itemsData.item_asset_id} <br />
                                          CREATED BY: {itemsData?.create_user?.email} <br />
                                          {itemsData.item_original_description}
                                       </div>
                                       <div className='px-2'>
                                          <QrCode id={itemsData.id} />
                                       </div>
                                    </div>
                                 )}

                              </>
                           </div>

                        </Modal.Body>
                     </Modal>
                  </>)}
            </>
         )
         }


         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withParams(withNavigation(Index))