import React, { useEffect, useState } from 'react';
import { json, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthApi from '../../Api/AuthApi'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withNavigation, withParams } from '../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import Swal from 'sweetalert2';
const FormValidation = Yup.object().shape({
   company: Yup.string().required('Company name is required.'),
   street1: Yup.string().required('Address is required.'),
   // street2: Yup.string().required('Address is required.'),
   city: Yup.string().required('City is required.'),
   state: Yup.string().required('State is required.').min(2, "State code is not valid.").max(2, "State code is not vail"),
   zip: Yup.string().required('Zip is required.'),
   phone: Yup.string().required('Phone is required.'),
   country: Yup.string().required('Country is required.'),
});
let ItemShipment = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [countries, setCountries] = useState([]);
   let [data, setData] = useState({});
   let [form, setForm] = useState(1);
   let [submitting, setSubmitting] = useState(false)
   let id = props.id
   useEffect(() => {
      Promise.all([
         getData(id),
         countriesFun(),]).then((values) => {
            setData(values[0])
            setCountries(values[1])
            setApiLoading(false)
         });
   }, [props.shipmentStatus])
   let getData = async (id) => {
      try {
         let data = await AppApi.shipmentDetails(id).then(res => {
            if (res.status == 200) {
               return res.data.data
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
      }
   }
   let cancelShipment = async (id) => {
      try {
         let data = await AppApi.shipmentCancel(id).then(res => {
            if (res.status == 200) {
               toast.success(res.data.message, {
                  autoClose: 5000,
               });
               props.setShipmentStatus(true)
               getData(id)
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
      }
   }
   let countriesFun = async () => {
      try {
         let data = await AuthApi.getCountries().then(res => {
            if (res.status == 200) {
               return res.data.data
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
      }
   }

   let getCountryName = (countries, id) => {
      let list = [...countries];
      console.log(list)
      let filterData = list.filter(data => data.id == id);
      if (filterData[0]) {
         return filterData[0].country_name
      } else {
         return id
      }
   }
   const handleCancelShipment = () => {
      Swal.fire({
         // title: '',
         text: 'Are you sure you want to cancel shipment?',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonText: 'Yes',
      }).then((result) => {
         if (result.isConfirmed) {
            setSubmitting(true);
            cancelShipment(data.shipment.id);
         } else if (result.isDenied) {
            setSubmitting(false);
         }
      })
   }


   return (
      <>

         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <>
               {data?.item?.id ? (
                  <>
                     <div className="panel border panel-default panel-info">
                        <div className="panel-heading d-flex justify-content-between">
                           <div className="panel-title">Shipment Details</div>
                           <div>
                              {props.stripeStatus ? (
                                 <span className={`label label-success`}>Status: {data.shipment_status}</span>
                              ) : (
                                 <>
                                    {data.shipment_status && (
                                       <span className={`label ${data.shipment_status == "draft" && "label-warning"} ${data.shipment_status == "unpaid" && "label-danger"} ${data.shipment_status == "paid" && "label-success"} ${data.shipment_status == "cancelled" && "label-danger"}`}>Status: {data.shipment_status}</span>
                                    )}
                                 </>
                              )}

                           </div>
                        </div>
                        <div className="panel-body p-2 ">
                           <table className='print-table'>
                              <tbody>
                                 <tr>
                                    <td>Shipment ID</td>
                                    <td>{data?.parcel?.shipment_id}</td>
                                 </tr>
                                 <tr>
                                    <td>Weight (lbs)</td>
                                    <td>{data?.parcel?.weight}</td>
                                 </tr>
                                 <tr>
                                    <td>Tracking URL</td>
                                    <td>{data?.shipment?.tracking_url ?
                                       <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.shipment?.tracking_url}>
                                          {data?.shipment?.tracking_url}
                                       </a>
                                       : "N/A"}
                                    </td>
                                 </tr>
                                 {/* <tr>
                                       <td>Height (inches) </td>
                                       <td>{data?.parcel?.height}</td>
                                    </tr>
                                    <tr>
                                       <td>Width (inches) </td>
                                       <td>{data?.parcel?.width}</td>
                                    </tr>
                                    <tr>
                                       <td>Length (inches)  </td>
                                       <td>{data?.parcel?.length} </td>
                                    </tr> */}
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div className="panel border panel-default panel-info">
                        <div className="panel-heading d-flex justify-content-between">
                           <div className="panel-title">Item Details</div>

                        </div>
                        <div className="panel-body p-2 ">
                           <table className='  print-table'>
                              <tbody>
                                 <tr>
                                    <td>Item ID</td>
                                    <td>{data?.item?.id}</td>
                                 </tr>
                                 <tr>
                                    <td>Title</td>
                                    <td>{data?.item?.item_title}</td>
                                 </tr>
                                 <tr>
                                    <td>Description</td>
                                    <td>{data?.item?.item_description}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div className="panel border panel-default panel-info">
                        <div className="panel-heading d-flex justify-content-between">
                           <div className="panel-title">Shipper Information</div>

                        </div>
                        <div className="panel-body p-2 ">
                           <table className='  print-table'>
                              <tbody>
                                 <tr>
                                    <td>Name</td>
                                    <td>{data?.from_address?.company}</td>
                                 </tr>
                                 {/* <tr>
                                          <td>Name</td>
                                          <td>{data?.from_address?.name}</td>
                                       </tr> */}
                                 {/* <tr>
                                          <td>Phone</td>
                                          <td>{data?.from_address?.phone}</td>
                                       </tr> */}
                                 <tr>
                                    <td>Address</td>
                                    <td>
                                       {data?.from_address?.street1}&nbsp;
                                       {data?.from_address?.street2},&nbsp;
                                       {data?.from_address?.city},&nbsp;
                                       {data?.from_address?.state},&nbsp;
                                       {data?.from_address?.country}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>Zip</td>
                                    <td>{data?.from_address?.zip}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div>
                        {/* {data.shipment_status} */}
                        {data.shipment_status == "paid" || data.shipment_status == "cancelled" || data?.shipment?.shipment_rate_id !== null ? null : (<button class="btn btn-danger" disabled={submitting} onClick={handleCancelShipment} >I've changed my mind</button>)}
                     </div>
                  </>
               ) : (
                  <div className="alert alert-danger mt-5" style={{ marginBottom: "500px" }}>
                     No shipment found with this record.
                  </div>
               )}
            </>
         )}
      </>
   )
}

export default withParams(withNavigation(ItemShipment))