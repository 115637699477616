import moment from "moment";

const convertUTCToLocalDate = (date) => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date
  }
  const convertLocalToUTCDate = (date) => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date
  }

  const systemFormattedDate =(date, utc = false) => {
    if (!date) {
      return date
    }
    if (utc)
      return new moment(date).format(localStorage.getItem("date_format").toString().toUpperCase())
    else
      return new moment.utc(date).format(localStorage.getItem("date_format").toString().toUpperCase())
  }

  export default {convertLocalToUTCDate, convertUTCToLocalDate,systemFormattedDate }