import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AuthApi from '../../Api/AuthApi'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import ShipmentAddress from '../../Components/AppComponents/ShipmentAddress';
import EditAddress from '../../Components/AppComponents/EditAddress';
import Swal from 'sweetalert2';

const FormValidation2 = Yup.object().shape({
   carrier_accounts: Yup.string().required('Carrier accounts is required.'),
});
const FormValidation3 = Yup.object().shape({
   company: Yup.string().required('Company is required.'),
   name: Yup.string().required('Name is required.'),
   phone: Yup.string().required('Phone is required.').min(10, 'Phone must be at least 10 characters.'),
   email: Yup.string().email('Invalid email').required('Email is required'),
   street1: Yup.string().required('Address is required.'),
   city: Yup.string().required('City is required.'),
   state: Yup.string().required('State is required.').min(1, "State code is not valid.").max(3, "State code is not valid"),
   zip: Yup.string().required('Postal Code is required.'),
   country: Yup.string().required('Country is required.'),
});
let Index = () => {
   let [apiLoading, setApiLoading] = useState(true);
   let [carrier_accounts, setCarrierAccounts] = useState([]);
   let [countries, setCountries] = useState([]);
   let [from_address, setFromAddress] = useState({});
   let [dropdown_address, setDropdownAddress] = useState([]);
   let [showAddress, setShowAddress] = useState(false);
   let [showEdit, setShowEdit] = useState(true);
   let [selected_carrier_account, setSelected_carrier_account] = useState("");
   let [SelectedCarrierAccountName, setSelectedCarrierAccountName] = useState("");
   let [isDeleteDisable, setIsDeleteDisable] = useState(false)
   let [isSetDefaultDisable, setIsSetDefaultDisable] = useState(false)
   let [dataId, setDataId] = useState(0);
   let [renderDelete, setRenderDelete] = useState(true);
   let [editRender, setEditRender] = useState(false);
   let [data, setData] = useState(from_address)
   let [obj, setObj] = useState({});
   let defaultOnSave = 0;
   let [refreshSate, setRefreshState] = useState(false)
   let [sharedState, setSharedState] = useState({ company: "", name: "", email: "", phone: "" });
   const [shouldBypassAddressValidation, setShouldBypassAddressValidation] = useState(false);

   useEffect(() => {
      // getData()
      // countriesFun()
      // getShipmentFromAddress();
      Promise.all([
         getShipmentFromAddress(),
         getData(),
         countriesFun()]).then((values) => {
            setFromAddress(values[0])
            setData(values[0])
            setIsSetDefaultDisable(true)
            setSharedState(values[0])
            setCarrierAccounts(values[1])
            setCountries(values[2])
            setApiLoading(false)
         });
   }, [refreshSate])
   let getShipmentFromAddress = async () => {
      try {
         let data = await AppApi.getShipmentFromAddress().then(res => {
            if (res.status == 200 && res?.data?.from_address[0]?.id) {
               if (res?.data?.from_address.length == 1 && res?.data?.from_address[0]?.is_default == false) {
                  let someData = res?.data?.from_address[0];
                  const { company, street1, street2, city, state, zip, phone, country, name, email } = someData;
                  obj = { company, street1, street2, city, state, zip, phone, country, name, email }
                  setDataId(Number(res?.data?.from_address[0]?.id))
                  setDefaultAddress(res?.data?.from_address?.length, Number(res?.data?.from_address[0]?.id))
               }
               const filtered_address = res?.data?.from_address.filter((data) => data.is_default);
               setDataId(Number(filtered_address[0]?.id))
               setIsDeleteDisable(false)
               editRender === false ? renderDelete ? setData(filtered_address[0]) : setData(from_address) : setEditRender(false);
               setDropdownAddress(res?.data?.from_address)
               return filtered_address[0]
            } else {
               setIsDeleteDisable(true)
               setShowEdit(false)
               setSharedState({ company: "", name: "", email: '', phone: "" })
               setDropdownAddress([])
               return {}
            }
         }).catch(error => {
            ;
            return {}
         })
         return data
      } catch (error) {
         
         return {}
      }
   }
   let getData = async () => {
      try {
         let data = await AppApi.CarrierAccounts().then(res => {
            if (res.status == 200) {
               setSelected_carrier_account(res.data.selected_carrier_account)
               setSelectedCarrierAccountName(res.data.data.filter((data) => data.id == res.data.selected_carrier_account)[0]?.description)
               console.log(res.data.data.filter((data) => data.id == res.data.selected_carrier_account)[0]?.description)
               return res.data.data
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.message
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
            return []
         })
         return data
      } catch (error) {
         
         return []
      }
   }
   let countriesFun = async () => {
      try {
         let data = await AuthApi.getCountries().then(res => {
            if (res.status == 200) {
               return res.data.data
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
         return []
      }
   }
   if (from_address && data) {
      const { company, phone, name, email } = from_address;
      const { street1, street2, city, state, country, zip } = data;
      obj = { company, street1, street2, city, state, zip, phone, country, name, email }
   }
   const filtered_address = dropdown_address.filter((data) => data.is_default);
   let setDefaultAddress = async (length, updatedDataId) => {
      try {
         let valueToPass = length == 1 ? updatedDataId : dataId;
         await AppApi.setDefault(obj, valueToPass).then(res => {
            if (res.status == 200) {
               if (length != 1) { getShipmentFromAddress() }
               setIsSetDefaultDisable(true)
               toast.success("Address set as default successfully.", {
                  autoClose: 5000,
               });
               if (length == 1) {
                  setRefreshState(true)
                  setIsDeleteDisable(false)
               }
            }
         }).catch(error => {
            console.log("error:", error)
         })
         return data
      } catch (error) {
         
      }
   }
   const handleSelectedAddress = (address, values) => {
      handleEdit();
      const parsedObject = JSON.parse(address);
      parsedObject.is_default ? setIsSetDefaultDisable(true) : setIsSetDefaultDisable(false)
      setFromAddress({ ...from_address, address });
      setData(parsedObject)
   }
   const handleAdd = () => {
      setShowEdit(false);
      setShowAddress(true);
   }
   const handleEdit = () => {
      setShowAddress(false);
      setShowEdit(true);
      setRenderDelete(true)
      setEditRender(true)
   }
   const handleAddButton = (e) => {
      e.preventDefault();
      handleAdd();
   }
   const handleSetDefault = (e) => {
      e.preventDefault();
      setIsSetDefaultDisable(true)
      setRenderDelete(true)
      setDefaultAddress()
   }
   const getShipmentFromAddressRef = useRef(getShipmentFromAddress);
   const handleDeleteButton = (e) => {
      e.preventDefault()
      Swal.fire({
         text: 'Are you sure you want to delete this address?',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonText: 'Yes',
      }).then(async (result) => {
         if (result.isConfirmed) {
            setEditRender(false)
            try {
               await AppApi.deleteAddress(dataId).then(res => {
                  const message = res.data.message;
                  if (res.data.success == true) {
                     getShipmentFromAddressRef.current();
                     setRefreshState(false)
                     setIsSetDefaultDisable(true)
                     toast.success(message, {
                        autoClose: 5000,
                     });
                  }
               }).catch(error => {
                  console.log("error:", error)
               })
               return data
            } catch (error) {
               
            }
         } else if (result.isDenied) {
         }
      })
   }

   return (
      <>
         <UserHeader />

         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <>
               <div className="container-fluid mt-3">
                  <div className="row">
                     <div className="col-md-4">
                        <Formik>
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form>
                                 <div class="panel border panel-default panel-info">
                                    <div class="panel-heading">
                                       <div class="panel-title">Contact Information</div>
                                    </div>
                                    <div class="panel-body p-2 ">
                                       <div className="row">
                                          <div className="col-12">
                                             <div className="alert alert-info">
                                                Please make sure the address information is correct - this will be the  shipping return address.
                                             </div>
                                          </div>
                                       </div>

                                       <div className="row">
                                          <div className="col-12">
                                             <div class="form-group">
                                                <label class="control-label" >Address</label>
                                                <select name="" id="select-address" className='form-control'
                                                   onChange={(e) => {
                                                      setShouldBypassAddressValidation(false);
                                                      handleSelectedAddress(e.target.value, values)
                                                   }}
                                                >
                                                   <option value={filtered_address[0] ? JSON.stringify(filtered_address[0]) : ''}>
                                                      {filtered_address[0] ? `${filtered_address[0].name} - ${filtered_address[0].street1}` : 'Select default address'}
                                                      {filtered_address[0]?.is_default == true ? (<span>&nbsp;&nbsp;&nbsp;(Default)</span>) : null}
                                                   </option>
                                                   {dropdown_address.map((data) => {
                                                      if (!data.is_default) {
                                                         return (
                                                            <option key={data.id} value={JSON.stringify(data)}>{`${data?.name} - ${data?.street1}`}</option>
                                                         )
                                                      } else {
                                                         return null;
                                                      }

                                                   })}
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-12 d-flex justify-content-end">
                                             <button class="btn btn-success" disabled={isSetDefaultDisable} onClick={(e) => handleSetDefault(e)}>Set as default address</button>
                                          </div>
                                       </div>
                                    </div>

                                 </div>

                                 <div class=" d-flex justify-content-end address-bottom-margin">
                                    <button class="btn btn-primary button-margin" onClick={(e) => handleAddButton(e)}><i class="fa fa-plus"></i> Add Address</button>
                                    <button class="btn btn-danger" disabled={isDeleteDisable} onClick={(e) => { handleDeleteButton(e) }} ><i class="fa fa-trash"></i> Delete</button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                     <div className="col-md-4">
                        <Formik
                           initialValues={{
                              carrier_accounts: selected_carrier_account ? selected_carrier_account : "",
                              carrier_account_name: SelectedCarrierAccountName || "",
                           }}
                           validationSchema={FormValidation2}
                           onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(true);
                              let carrier_accounts = values.carrier_accounts
                              let carrier_account_name = values.carrier_account_name
                              // *************************************************************
                              try {
                                 let webOptionApiData = {
                                    client_account_option: {
                                       default_carrier_account: carrier_accounts,
                                       default_carrier_account_name: carrier_account_name
                                    }
                                 }
                                 AppApi.UpdateClientAccountOptions(webOptionApiData).then(result => {
                                    if (result && result.status == 200) {
                                       toast.success("Updated successfully.", {
                                          autoClose: 5000,
                                       });
                                    } else {
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                    }
                                    setSubmitting(false)
                                 }).catch(error => {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                    setSubmitting(false)
                                 })
                              } catch (error) {

                                 console.log("server error", error.message)
                              }
                              // *************************************************************
                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form>
                                 <div class="panel border panel-default panel-info pb-0">
                                    <div class="panel-heading">
                                       <div class="panel-title">Shipment Options</div>
                                    </div>
                                    <div class="panel-body pt-3">
                                       <div className="col-12">
                                          <div class="form-group">
                                             <label class="control-label" >Carrier Options </label>
                                             <select name="" id="" className='form-control'
                                                onChange={(event) => {
                                                   setFieldValue("carrier_account_name", event.target.value.split("-")[1])
                                                   setFieldValue("carrier_accounts", event.target.value.split("-")[0])
                                                }}
                                                value={`${values.carrier_accounts}-${values.carrier_account_name}`}
                                             >
                                                <option value="">Choose carrier </option>
                                                {carrier_accounts.map((data, index) => {
                                                   return (
                                                      <option value={`${data.id}-${data.description}`}>{data.description}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger d-block' name="carrier_accounts" />
                                          </div>
                                       </div>
                                       <div class="panel-footer d-flex justify-content-end ">
                                          <button class="btn btn-success" disabled={isSubmitting}><i class="fa fa-check"></i> {isSubmitting ? "Loading..." : "Save"}</button>
                                       </div>
                                    </div>
                                 </div>
                              </Form>
                           )}
                        </Formik>

                        {showAddress ? (<Formik
                           initialValues={{
                              company: "",
                              street1: "",
                              name: "",
                              email: "",
                              street2: "",
                              city: "",
                              state: "",
                              zip: "",
                              phone: "",
                              country: "",
                              skip_validation: false,
                           }}
                           validationSchema={FormValidation3}
                           onSubmit={(values, { setSubmitting, resetForm }) => {
                              setSubmitting(true);
                              try {
                                 let apiData = { from_address: values }
                                 AppApi.updateShipmentFromAddress(apiData).then(result => {
                                    if (result.status == 200 && result?.data?.from_address?.id) {
                                       getShipmentFromAddress(defaultOnSave);
                                       setShouldBypassAddressValidation(false);
                                       toast.success("Added successfully.", {
                                          autoClose: 5000,
                                       });
                                       resetForm()
                                    } else {
                                       toast.error('Shipping Address not found', {
                                          autoClose: 5000,
                                       });
                                    }
                                    setSubmitting(false)
                                 }).catch(error => {
                                    setSubmitting(false)
                                    setShouldBypassAddressValidation(true);
                                    try {
                                       let message = error.response.data.errors[0]
                                       toast.error(message ? message : 'Server Error!', {
                                          autoClose: 5000,
                                       });
                                    } catch (error) {
                                       
                                    }
                                 })
                              } catch (error) {
                                 setSubmitting(false);
                                 toast.error('Server error.', {
                                    autoClose: 5000,
                                 });
                                 console.log("server error", error.message);
                              }
                              // *************************************************************
                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form>
                                 <div class="panel border panel-default panel-info">
                                    <div class="panel-heading">
                                       <div class="panel-title">Address Information</div>
                                    </div>
                                    <div class="panel-body pt-3">
                                       <ShipmentAddress setFieldValue={setFieldValue} values={values} shouldBypassAddressValidation={shouldBypassAddressValidation} />
                                    </div>
                                    <div class="panel-footer d-flex justify-content-end ">
                                       <button class="btn btn-success" disabled={isSubmitting}><i class="fa fa-check"></i> {isSubmitting ? "Loading..." : "Save"}</button>
                                    </div>
                                 </div>

                              </Form>
                           )}
                        </Formik>) : null}
                        {showEdit ? (<Formik

                           initialValues={{
                              company: data?.company ? data?.company : "",
                              street1: data?.street1 ? data?.street1 : "",
                              name: data?.name ? data?.name : "",
                              email: data?.email ? data?.email : "",
                              street2: data?.street2 ? data?.street2 : "",
                              city: data?.city ? data?.city : "",
                              state: data?.state ? data?.state : "",
                              zip: data?.zip ? data?.zip : "",
                              phone: data?.phone ? data?.phone : "",
                              country: data?.country ? data?.country : "",
                              skip_validation: false,
                           }}
                           validationSchema={FormValidation3}
                           onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(true);
                              try {
                                 let apiData = { from_address: values }
                                 AppApi.updateShipmentFromAddressById(apiData, dataId).then(async result => {
                                    if (result && result.status == 200) {
                                       await getShipmentFromAddress()
                                       setShouldBypassAddressValidation(false);
                                       toast.success("Updated successfully.", {
                                          autoClose: 5000,
                                       });
                                    } else {
                                       toast.error('Server error.', { autoClose: 5000, });
                                    }
                                    setSubmitting(false)
                                 }).catch(error => {
                                    setSubmitting(false);
                                    setShouldBypassAddressValidation(true);
                                    try {
                                       let message = error.response.data.errors[0]
                                       toast.error(message ? message : 'Server Error!', { autoClose: 5000, });
                                    } catch (error) {
                                       
                                    }
                                 })
                              } catch (error) {
                                 setSubmitting(false);
                                 toast.error('Server error.', { autoClose: 5000, });
                                 console.log("server error", error.message);
                              }
                              // *************************************************************
                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form>
                                 <div class="panel border panel-default panel-info address-top-margin">
                                    <div class="panel-heading">
                                       <div class="panel-title">Address Information</div>
                                    </div>
                                    <div class="panel-body pt-3">
                                       <EditAddress setFieldValue={setFieldValue} values={values} data={data} setDataId={setDataId} shouldBypassAddressValidation={shouldBypassAddressValidation} />
                                    </div>
                                    <div class="panel-footer d-flex justify-content-end ">
                                       <button type='submit' class="btn btn-success" disabled={isSubmitting}> {isSubmitting ? "Loading..." : "Update"}</button>
                                    </div>
                                 </div>
                              </Form>
                           )}
                        </Formik>) : null}

                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   )
}

export default withNavigation(Index)