import axios from "../Utils/axios";
let AppApi = {
   clientList: async (data) => axios.get("/repoadmin/clients.json",
      {
         params: data,
         headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
         }
      }
   ),
   clientDetails: async (data) => axios({
      url: `/repoadmin/clients/${data}/edit.json`,
      data,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   clientDetail: async () => axios({
      url: `/clients/details`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   CarrierAccounts: async () => axios({
      url: `/carrier_accounts`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentDetails: async (data) => axios({
      url: `/shipments/${data}/details`,
      method: "get",
      headers: {
         // authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentCancel: async (data) => axios({
      url: `/shipments/${data}/cancel`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getItems: async () => axios({
      url: `/clients/plan_period_total_claims.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getPublicAccountDetails: async (data) => axios({
      url: `/client_account_options/${data}.json`,
      method: "get",
   }),
   getItemsClaims: async () => axios({
      url: `/clients/plan_period_total_items_history.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateClientDetails: async (data) => axios({
      url: `/clients/update_contact_info`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   userDetails: async (data) => axios({
      url: `/repoadmin/users/${data}/edit.json`,
      data,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   searchEmail: async (data) => axios({
      url: `/repoadmin/users/search?email=${data}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteLegacyRecords: async (data) => axios({
      url: `/claims/delete_legacy_records.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   busNameSearch: async (data) => axios({
      url: `/repoadmin/clients/search.json?business_name=${data}`,
      data,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   emailSearch: async (data) => axios({
      url: `/repoadmin/users/search?email=${data}`,
      data,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   enableClient: async (data) => axios({
      url: `/repoadmin/clients/${data}/enable.json`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   disableClient: async (data) => axios({
      url: `/repoadmin/clients/${data}/disable.json`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteClient: async (data) => axios({
      url: `/repoadmin/clients/${data}/delete_account.json`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateClient: async (data) => axios({
      url: `/repoadmin/clients/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateSub: async (data) => axios({
      url: `/repoadmin/clients/${data.id}/update_subscription`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateUseremail: async (data) => axios({
      url: `/repoadmin/users/${data.id}/update_email.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addTemplate: async (data) => axios({
      url: `/templates.json`,
      data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getTemplate: async (data) => axios({
      url: `/templates.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getTemplateDetails: async (data) => axios({
      url: `/templates/${data}.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateTemplateDetails: async (data) => axios({
      url: `/templates/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   removeTemplate: async (data) => axios({
      url: `/templates/${data}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   sendEmailUsingTemplate: async (data) => axios({
      url: `/emails`,
      method: "post",
      data: data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addUser: async (data) => axios({
      url: `/users.json`,
      data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   usersList: async () => axios({
      url: `/users.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   usersDetails: async (id) => axios({
      url: `/users/${id}.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   UpdateUserDetails: async (data) => axios({
      url: `/users/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   DeleteUser: async (data) => axios({
      url: `/users/${data}.json`,

      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   disableUsersAccount: async (data) => axios({
      url: `/users/${data}/disable.json`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   activeUsersAccount: async (data) => axios({
      url: `/users/${data}/enable.json`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   ActiveUser: async (data) => axios({
      url: `/users/${data.id}/activate_update.json`,
      data: data.data,
      method: "patch",
   }),
   addDispositions: async (data) => axios({
      url: `/dispositions.json`,
      data: data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addCategories: async (data) => axios({
      url: `/categories.json`,
      data: data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addVenues: async (data) => axios({
      url: `/venues.json`,
      data: data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addContainer: async (data) => axios({
      url: `/containers.json`,
      data: data,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getDispositions: async (data) => axios({
      url: `/dispositions.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   clientAuditOption: async (data) => axios({
      url: `/client_audit_options.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   clientAccountOptions: async (data) => axios({
      url: `/client_account_options`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   UpdateClientAccountOptions: async (data) => axios({
      url: `/client_account_options/update_weboptions`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   plans: async (data) => axios({
      url: `/invoice/plans.json`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   emailInvoice: async (data) => axios({
      url: `/invoice.json`,
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateClientAuditOption: async (data) => axios({
      url: `/client_audit_options.json`,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getCategories: async (data) => axios({
      url: `/categories.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getCategoriesPublic: async (data) => axios({
      url: `/categories/${data}.json`,
      method: "get",

   }),
   getVenues: async (data) => axios({
      url: `/venues.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteClaims: async (data) => axios({
      url: `/claims/${data}/inactivate.json`,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getVenuesPublic: async (data) => axios({
      url: `/venues/${data}.json`,
      method: "get",
   }),
   claimsTopAvailable: async (data) => axios({
      url: `/claims/top_available.json?limit=50`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getContainers: async (data) => axios({
      url: `/containers.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   downloadAccounts: async (data) => axios({
      url: `/repoadmin/clients.xls`,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getHiddenDispositions: async (data) => axios({
      url: `/dispositions.json?hidden=true`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getHiddenFileData: async (data) => axios({
      url: `/client_account_options`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getHiddenCategories: async (data) => axios({
      url: `/categories.json?hidden=true`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getHiddenVenues: async (data) => axios({
      url: `/venues.json?hidden=true`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteDispositions: async (data) => axios({
      url: `/dispositions/${data}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteCategories: async (data) => axios({
      url: `/categories/${data}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteVenues: async (data) => axios({
      url: `/venues/${data}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteContainers: async (data) => axios({
      url: `/containers/${data}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   hideOrUnHide: async (data) => axios({
      url: `/dispositions/${data}/hide_unhide_disposition.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   hideOrUnHideCategories: async (data) => axios({
      url: `/categories/${data}/hide_unhide_category.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   hideOrUnHideVenues: async (data) => axios({
      url: `/venues/${data}/hide_unhide_venue.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   setOrderDispositions: async (data) => axios({
      url: `/dispositions/set_sort_order`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   setOrderCategories: async (data) => axios({
      url: `/categories/set_sort_order`,
      data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateDispositions: async (data) => axios({
      url: `/dispositions/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateCategories: async (data) => axios({
      url: `/categories/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   move_items: async (data) => axios({
      url: `/items/move_items.json`,
      data: data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateVenues: async (data) => axios({
      url: `/venues/${data.id}.json`,
      data: data.data,
      method: "patch",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getCommission: async (data) => axios({
      url: `/repoadmin/settings`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   UpdateCommission: async (data) => axios({
      url: `/repoadmin/settings`,
      method: "put",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateShipmentFromAddress: async (data) => axios({
      url: `/client_from_address.json`,
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateShipmentFromAddressById: async (data, id) => axios({
      url: `/client_from_address/${id}.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   setDefault: async (data, id) => axios({
      url: `/client_from_address/default/${id}.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   deleteAddress: async (id) => axios({
      url: `/client_from_address/${id}.json`,
      method: "delete",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getShipmentFromAddress: async (data) => axios({
      url: `/shipment_settings.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   publicItems: async (data) => axios({
      url: `/public_items/${data}.json`,
      method: "get",
   }),
   publicClaimsItems: async (data) => axios({
      url: `/public_claim_list/${data}.json`,
      method: "get",
   }),
   publicClaims: async (data) => axios({
      url: `/public_claims/${data.id}.json`,
      data: data.data,
      method: "post",
   }),
   hs_tariff_details: async () => axios({
      url: `/hs_tariff_numbers.json`,
      method: "get"
   }),
   getStates: async (data) => axios({
      url: `/states/${data}.json`,
      method: "get",
   }),
   getCities: async (data) => axios({
      url: `/cities/${data}.json`,
      method: "get",
   }),


}
export default AppApi

