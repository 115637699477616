import axios from "../Utils/axios";

let AuthApi = {
   monthlyReportsClaimsActivityReports: async (data) => axios({
      url: `/reports/monthly_activity_report.xlsx?venue_id=${data.venue_id}&venue_date_range="${data.venue_date_range}"`,
      data: data.data,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   containerCounterReport: async (data) => axios({
      url: `/reports/container_count_report.xlsx?type=${data.type}&from=${data.from}&to=${data.to}`,
      data: data.data,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsAvailableReport: async (data) => axios({
      url: `/reports/items_available_report.xls?item_create_from_date=${data.item_create_to_date}&item_create_to_date=${data.item_create_to_date}`,
      data: data.data,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsReleasedReport: async (data) => axios({
      url: `/reports/items_released_report.xls?item_release_from_date=${data.item_release_from_date}&item_release_to_date=${data.item_release_to_date}&include_bulk_released_items=${data.include_bulk_released_items}`,
      data: data.data,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   claimsAvailableReport: async (data) => axios({
      url: `/reports/claims_available_report.xls?claim_create_from_date=${data.claim_create_from_date}&claim_create_to_date=${data.claim_create_to_date}&include_deleted_claims=${data.include_deleted_claims}`,
      data: data.data,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsDeletedReports: async () => axios({
      url: `/reports/items_deleted_report.xls`,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   dispositionCountReport: async () => axios({
      url: `/reports/disposition_count_report.xls`,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   categoryCountReport: async () => axios({
      url: `/reports/category_count_report.xls`,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsAvailableReport: async (data) => axios({
      url: `/reports/items_available_report.xls?item_create_from_date=${data.item_create_from_date}&item_create_to_date=${data.item_create_to_date}`,
      method: "get",
      responseType: 'arraybuffer',
      headers: {
         'Content-Type': 'blob',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),

   // ***************************report pages api****************************
   itemsDeletedReportJson: async (data) => axios({
      url: `/reports/items_deleted_report.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   DispositionCountReportJson: async (data) => axios({
      url: `/reports/disposition_count_report.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   CategoryCountReportJson: async (data) => axios({
      url: `/reports/category_count_report.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   containerCountReportJson: async (data) => axios({
      url: `/reports/container_count_report.json?type=${data.type}&from=${data.from}&to=${data.to}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsCreatedMonthlyReport: async (data) => axios({
      url: `/reports/items_created_monthly_report.json?venue_id=${data.venue_id}&venue_date_range=${data.venue_date_range}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   claimsCreatedMonthlyReport: async (data) => axios({
      url: `/reports/claims_created_monthly_report.json?venue_id=${data.venue_id}&venue_date_range=${data.venue_date_range}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   itemsReleaseCreatedMonthlyReport: async (data) => axios({
      url: `/reports/item_releases_monthly_report.json?venue_id=${data.venue_id}&venue_date_range=${data.venue_date_range}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),

}
export default AuthApi

