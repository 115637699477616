import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import ShipmentApi from "../Api/ShipmentApi";

export const useGetShipmentCurrencies = () => {
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        (async ()=> {
            try {
                const resp = await ShipmentApi.currencies();
                resp.status === 200 && setCurrencies( resp?.data?.shipment_currencies ?? [] )

            } catch (error) {
                toast.error('Server error.', {
                    autoClose: 5000,
                });
                setCurrencies([])
            }
        })();
    }, [])
    return currencies
}