import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
let LineChart = (props) => {
    useEffect(() => {
    })
    let apiData = props.apiData
    const labels = apiData.map(data => data.key)
    const values = apiData.map(data => data.value)
    const data = {
        labels,
        datasets: [
            {
                label: props.label,
                data: values,
                borderColor: '#97bbcd',
                backgroundColor: '#97bbcd',
            }
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
            },
        },
    };
    return (
        <>
            <Line options={options} data={data} />
        </>
    )
}

export default LineChart