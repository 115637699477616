import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { withNavigation, withParams } from '../../Utils/Navigater';
import ShipmentApi from '../../Api/ShipmentApi';
import { Formik, Form, Field } from 'formik';

const Index = () => {
    const [shipment, setShipment] = useState({});
    const [apiLoading, setApiLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        tracking_url: '',
        commercial_invoice: null,
        label: null,
        send_email_to_item_owner: false,
        send_email_to_customer: false,
    });
    const { id } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formValues);
        const formData = new FormData();
        formData.append('shipment[tracking_url]', formValues.tracking_url);
        formData.append('shipment[send_email_to_item_owner]', formValues.send_email_to_item_owner);
        formData.append('shipment[send_email_to_customer]', formValues.send_email_to_customer);

        if (formValues.commercial_invoice) {
            formData.append('shipment[commercial_invoice]', formValues.commercial_invoice);
        }

        if (formValues.label) {
            formData.append('shipment[label]', formValues.label);
        }

        try {
            setApiLoading(true);
            const response = await ShipmentApi.shipmentPublicUpdate(id, formData);
            setShipment(response.data.data);
            setFormValues({
                tracking_url: response.data.data.tracking_url,
                commercial_invoice: null,
                label: null,
                send_email_to_item_owner: false,
                send_email_to_customer: false,
            });
            setApiLoading(false);
            toast.success('Shipment has been updated.', {
                autoClose: 5000,
            });
        } catch (error) {
            setApiLoading(false);
            toast.error('There was an error updating shipment.', {
                autoClose: 5000,
            });
        }
    };

    useEffect(() => {
        async function fetchShipmentDetails() {
            try {
                setApiLoading(true);
                const response  = await ShipmentApi.shipmentPaidDetails(id);
                setShipment(response.data.data.shipment);
                setFormValues({
                    tracking_url: response.data.data.shipment.tracking_url,
                    commercial_invoice: null,
                    label: null,
                    send_email_to_item_owner: false,
                    send_email_to_customer: false,
                });
                setApiLoading(false);
            } catch (error) {
                setApiLoading(false);
            }
        }
        
        fetchShipmentDetails();
    }, []);

    return (
        <>
            {apiLoading ? (
                <div className="page-center-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div>
                    <h2>Edit Shipment</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='tracking_url'>Tracking URL</label>
                            <input
                                type="text"
                                id="tracking_url"
                                name="tracking_url"
                                defaultValue={shipment.tracking_url}
                                size="70"
                                onChange={(event) => {
                                    setFormValues({ ...formValues, tracking_url: event.target.value });
                                }}
                            />
                        </div>
                        {shipment.commercial_invoice_url && (
                            <div>
                                <a href={shipment.commercial_invoice_url} target='_blank'>View Attached Commercial Invoice</a>
                            </div>    
                        )}
                        <div>
                            <label htmlFor='commercial_invoice'>Commercial Invoice</label>
                            <input
                                type="file"
                                id="commercial_invoice"
                                name="commercial_invoice"
                                accept=".pdf"
                                onChange={(event) => {
                                    setFormValues({ ...formValues, commercial_invoice: event.target.files[0] });
                                }}
                            />
                        </div>
                        {shipment.label_url && (
                            <div>
                                <a href={shipment.label_url} target='_blank'>View Attached Label</a>
                            </div>    
                        )}
                        <div>
                            <label htmlFor='label'>Label</label>
                            <input
                                type="file"
                                id="label"
                                name="label"
                                accept=".pdf"
                                onChange={(event) => {
                                    setFormValues({ ...formValues, label: event.target.files[0] });
                                }}
                            />
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="send_email_to_item_owner"
                                name="send_email_to_item_owner"
                                checked={formValues.send_email_to_item_owner}
                                onChange={(event) => {
                                    setFormValues({ ...formValues, send_email_to_item_owner: event.target.checked });
                                }}
                            />
                            <label htmlFor='send_email_to_item_owner'>Send Email to Item Owner</label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="send_email_to_customer"
                                name="send_email_to_customer"
                                checked={formValues.send_email_to_customer}
                                onChange={(event) => {
                                    setFormValues({ ...formValues, send_email_to_customer: event.target.checked });
                                }}
                            />
                            <label htmlFor='send_email_to_customer'>Send Email to Customer</label>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            )}

        </>
    )
};

export default withParams(withNavigation(Index));
