import React, { useEffect, useState } from 'react';
import { withNavigation, withParams } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi';
import qs from 'qs';
import { Link } from 'react-router-dom'
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import AuthFooter from '../../Components/footers/LoginFooter';
const SignupSchema = Yup.object().shape({
   password: Yup.string().required('Password is required'),
   password_confirmation: Yup.string().required('Confirm password is required.')
      .test('passwords-match', 'Passwords must match', function (value) {
         return this.parent.password === value
      }),
});
let ForgetPassword = (props) => {
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 roboto-light'>Set Your New Password</h1></div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           password: "",
                           password_confirmation: ""
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                           setSubmitting(true)
                           try {
                              var data = qs.stringify({
                                 'user[password]': values.password,
                                 'user[password_confirmation]': values.password_confirmation
                              });
                              let { id } = props.params;
                              let objects = {
                                 data: data,
                                 id
                              }
                              AuthApi.updateResetPassword(objects).then(result => {
                                 if (result && result.status == 200 && result.data) {
                                    toast.success(result.data.errors[0], {
                                       autoClose: 5000,
                                    });
                                    props.navigate(Url.Login);
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                                 console.log(result)
                              }).catch(error => {
                                 try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                                 console.log(error.message)
                              })

                           } catch (error) {
                              setSubmitting(false);
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting }) => (
                           <div className="panel border rounded p-3 panel-default">
                              <div className="panel-body">
                                 <Form className="form" id="login" >
                                    <div className="form-group">
                                       <label>Password</label>
                                       <Field className="form-control" placeholder="" type="password" name="password" />
                                       <ErrorMessage component="small" className='text-danger' name="password" />
                                    </div>
                                    <small>(7 or more characters: 1+ upper case, 1+ lower case letter, and 1+ number. Strongly consider including special character(s))</small>
                                    <div className="form-group">
                                       <label>Re-type Password</label>
                                       <Field className="form-control" placeholder="" type="password" name="password_confirmation" />
                                       <ErrorMessage component="small" className='text-danger' name="password_confirmation" />
                                    </div>
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading...." : (<><i className="fa fa-lock text-white"></i>  Change Password</>)}
                                    </button>
                                 </Form>
                              </div>
                           </div>
                        )}
                     </Formik>
                     <div className="alert alert-info mt-4 ">
                        <i className="fa fa-info-circle"></i>  Be sure to also check your SPAM/Junk Mail folder for a Password Reset email. Please allow up to 3 minutes for receipt of the email.
                     </div>
                     <div className="alert alert-secondary mt-4">
                        <i className="fa fa-question-circle text-info1"></i> <Link to={Url.Login}>Login?</Link>
                     </div>
                  </div>
               </div>

            </div>
         </div>
         <AuthFooter />
      </>
   )
}

export default withParams(withNavigation(ForgetPassword))