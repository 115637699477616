const Url = {
    Landing: '/',
    ForgetPassword: "/forget-password",
    ResetPassword: '/reset-password',
    VerifyEmail: '/verify',
    Login: '/login',
    resetPassword: (id) => id ? `/password_reset/${id}` : "/password_reset/:id",
    SignUp: '/signup',
    Home: '',
    Terms: '/terms',
    PrivacyPolicy: '/privacy',
    signin: "/signin",
    Dashboard: '/repoadmin',
    FreeTrial: "/repoadmin/free-Trial",
    UsersEmailSearch: (id) => id ? `/repoadmin/users/${id}` : "/repoadmin/users/:search",
    editeUser: "/repoadmin/users/:id/edit",
    UserDetails: (id) => id ? `/repoadmin/users/${id}/edit` : "/repoadmin/users/:id/edit",
    ClientDetails: (id) => id ? `/repoadmin/clients/${id}/edit` : "/repoadmin/clients/:id/edit",
    AppURL: `${window.location.origin}`,
    RepoAppDashboard: "/repoapp",
    ChangePassword: "/change-password",
    ContactUs: "/contact",
    Help: "/help",
    DataPurge: "/data-purge",
    DefaultOptions: "/default-options",
    WebOptions: "/web-options",
    AuditOption: "/audit_option",
    Venues: "/venues",
    Dispositions: "/dispositions",
    Templates: "/templates",
    Categories: "/categories",
    Client: "/client",
    Users: "/users",
    AddUser: "/user/new",
    EditUsers: (id) => id ? `/repoapp/user/${id}/edit` : "/repoapp/user/:id/edit",
    ActiveUser: (id) => id ? `${window.location.origin}/users/${id}/activate` : `/users/:id/activate`,
    Reports: "/reports",
    Containers: "/containers",
    ItemBulkRelease: "/item-bulk-release",
    Claims: "/claims",

    Invoice: "/invoice",
    ScanItem: "/scan-item",
    Commission: "/commission",

    AddNewItems: "/repoapp/items/new",
    AddNewClaim: "/claims/new",
    EditNewItems: (id) => id ? `/repoapp/items/edit/${id}` : "/repoapp/items/edit/:id",
    EditClaim: (id) => id ? `/claims/edit/${id}` : "/claims/edit/:id",
    ItemsHistory: (id) => id ? `/repoapp/items/history/${id}` : "/repoapp/items/history/:id",
    ItemsPhoto: (id) => id ? `/repoapp//photo/edit/${id}` : "/repoapp//photo/edit/:id",
    ItemsRelease: (id) => id ? `/repoapp/items/release/${id}` : "/repoapp/items/release/:id",
    AddTemplates: "/repoapp/templates/new",
    EditTemplates: (id) => id ? `/repoapp/templates/${id}/edit` : "/repoapp/templates/:id/edit",
    ShipmentsDetails: "/shipments-details",
    ShipmentsCustomerDetails: (id) => id ? `/shipments/${id}/details` : "/shipments/:id/details",
    EditShipment: (id) => id ? `/shipments/${id}/edit` : "/shipments/:id/edit",
    StripeSuccess: (id) => id ? `/shipment/${id}/payment/success` : "/shipment/:id/payment/success",
    StripeFailed: (id) => id ? `/shipment/${id}/payment/failed` : "/shipment/:id/payment/failed",

    AdminShipments: `/shipment-list`,
    ItemsDeletedReport: `/items_deleted_report`,
    DispositionCountReport: `/disposition_count_report`,
    CategoryCountReport: `/category_count_report`,
    ContainerCountReport: (type, from, to) => type ? `/container_count_report/${type}/${from == "" ? null : from}/${to == "" ? null : to}` : "/container_count_report/:type/:from/:to",
    MonthlyActivityReport: (venue_id, venue_date_range) => venue_id ? `/monthly_activity_report/${venue_id}/${venue_date_range}` : "/monthly_activity_report/:venue_id/:venue_date_range",

    PublicClaims: (id) => id ? `/public_claims/${id}` : "/public_claims/:id",
    PublicItems: (id) => id ? `/public_items/${id}` : "/public_items/:id",
    PublicClaimItems: (id) => id ? `/public_claim_list/${id}` : "/public_claim_list/:id",
};

export default Url;
