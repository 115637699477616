import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput'

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation = Yup.object().shape({
   end_date: Yup.string().required('End date is required.'),
});
let DataPurge = (props) => {

   const UserPermissions = useSelector((state) => state.UserPermissions);

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <div className='max-w960 px-3 py-2'>
                  <h5>Purge Old Records</h5>
                  <p className='text-muted'>Does your organization have a data retention policy, or do you wish to declutter your data from past years? Use the below feature to purge item and claim records from past years.</p>
                  <div class="alert alert-danger">
                     <p className='mb-0'>
                        <i class="fa fa-exclamation-triangle"></i> <strong>Purged records cannot be recovered.</strong>
                     </p>
                  </div>
                  <div class="panel border panel-default">
                     <div class="panel-heading">
                        <div class="panel-title">Purge Item and Claim Records</div>
                     </div>
                     <Formik
                        initialValues={{
                           end_date: "",
                        }}
                        validationSchema={FormValidation}
                        onSubmit={(values, { setSubmitting, setFieldValue }) => {
                           setSubmitting(true);
                           try {
                              AppApi.deleteLegacyRecords(values).then(result => {
                                 if (result && result.status == 200) {
                                    toast.success('Deleted successfully.', {
                                       autoClose: 5000,
                                    });
                                    setFieldValue("end_date", "")
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                              }).catch(error => {
                                 setSubmitting(false)
                                 try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    
                                 }
                              })
                           } catch (error) {
                              console.log("🚀 ~ file: Index.js ~ line 133 ~ Index ~ error", error)
                              setSubmitting(false)
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });

                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                           <Form >
                              <div class=" ">
                                 <div class="panel-body   px-4">
                                    <div className="row mt-2">
                                       <div className="col-12">
                                          <p class="alert alert-warning">If a qualifying claim record is referenced by an item record that isn't in scope to be deleted, then that claim record won't be deleted. For example, if you're deleting records before May 15, 2013, qualifying claim records that are referenced in "item releases" after May 15, 2013, won't be deleted (since item records created after May 15, 2013, aren't in scope to be deleted). Therefore, a small number of claims may go undeleted.</p>
                                          <p>Delete item and claim records created prior to the below date (must be at least 1 year ago). This process could take up to 1 hour to complete.</p>
                                          <div class="form-group">
                                             <label class="control-label" for="end_date">Created Date</label> (YYYY-MM-DD)
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="end_date"
                                                value={values.end_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="end_date" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="panel-footer">
                                    <div>
                                       <span >
                                          <button class="btn btn-success me-1" disabled={isSubmitting} type="submit "   > {isSubmitting ? "Loading..." : (<><i class="fa fa-check"></i> Delete Item and Claim Records </>)} </button>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>
                  </div>
               </div >
            </>)}


      </>
   )
}

export default withNavigation(DataPurge)