import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Url from '../../Utils/Routes';


let AuthHeader = () => {
   let [login, setLogin] = useState(false);
   useEffect(() => {
      let token = localStorage.getItem('token');
      if (!token) {
         setLogin(false);
      } else {
         setLogin(true);
      }
   }, [])

   return (
      <div className="border-bottom">
         <div className="container authHeader_wrapper">
            <div className="row">
               <div className="col-12">
                  <div className="authHeaderContainer">
                     <Navbar expand="lg">
                        <Container fluid>
                           <Navbar.Brand href="#">
                              <Link to={localStorage.getItem("token") ? Url.RepoAppDashboard : Url.Login}> <img src="/assets/images/main-logo.png" className='applogo' alt="" /></Link>
                           </Navbar.Brand>
                           <Navbar.Toggle aria-controls="navbarScroll" />
                           <Navbar.Collapse id="navbarScroll">
                              <Nav
                                 className="me-auto my-2 my-lg-0"
                                 navbarScroll
                              >
                                 <a target="_blank" href="https://www.repoapp.com/" className='nav-link active' rel="noreferrer">HOME</a>
                                 <a target="_blank" href="https://www.repoapp.com/features/" className='nav-link active' rel="noreferrer">FEATURES</a>
                                 <a target="_blank" href="https://www.repoapp.com/faqs/" className='nav-link active' rel="noreferrer">FAQs</a>
                                 <a target="_blank" href="https://www.repoapp.com/pricing/" className='nav-link active' rel="noreferrer">PRICING</a>
                                 <a target="_blank" href="https://www.repoapp.com/blog/" className='nav-link active' rel="noreferrer">BLOG</a>
                              </Nav>
                              {login ? (
                                 <div className='py-1 text-center'>
                                    <p className='mb-0'>{localStorage.getItem("token") && <i className="fa fa-user" style={{ color: "#FFA500" }}></i>} {localStorage.getItem("token") && localStorage.getItem("email")}</p>
                                    <div >
                                       <Link to="/login"
                                          onClick={() => {
                                             localStorage.removeItem("token");
                                          }}
                                          className='btn btn-danger text-white me-1 btn-sm'>
                                          <i className="fa fa-sign-out text-white  me-1"></i>
                                          Sign out</Link>
                                       <Link to={Url.RepoAppDashboard} className='btn btn-success text-white text-decoration-none btn-sm'>
                                          <i className="fa fa-rocket text-white  me-1"></i>
                                          Launch</Link>
                                    </div>
                                 </div>
                              ) : (
                                 <>
                                    <Link to="/#" className='btn btn-outline-info me-1 btn-sm'>
                                       <i className="fa fa-lock text-info1  me-1"></i>
                                       Sign in</Link>
                                    <Link to="/signup" className='btn btn-danger text-white text-decoration-none btn-sm'>Free Trial</Link>
                                 </>
                              )}
                           </Navbar.Collapse>
                        </Container>
                     </Navbar>
                  </div>

               </div>
            </div >
         </div >
      </div >
   )
}


export default AuthHeader