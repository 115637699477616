import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import Container from '../../Components/Forms/Dispositions/Container';

import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation1 = Yup.object().shape({
   disposition_description: Yup.string().required('Container  is required.'),
});

const FormValidation2 = Yup.object().shape({
   disposition_description: Yup.string().required('Container is required.'),
   name: Yup.string().required('Updated  is required.'),
});
const FormValidation3 = Yup.object().shape({
   item_container_name_from: Yup.string().required('Container  is required.'),
   item_container_name_to: Yup.string().required('Container  is required.'),
});


let Venues = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.getContainers().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)
      }
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.containers?.container_view ? <UnAuthorized /> : (
            <>
               <div className="container-fluid mt-3">
                  <div className="row">
                     <div className="col-md-5">
                        <div class="panel  panel-default panel-info">
                           <div class="panel-body p-2">
                              <div class="alert alert-info rounded-0">
                                 <i class="fa fa-info-circle me-1 "></i>
                                 Freely add/delete item container pick list/dropdown values. These appear in the "Container" pick list/dropdown when adding or editing items.
                              </div>
                              <Formik
                                 initialValues={{
                                    disposition_description: "",
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, setFieldValue, resetForm }) => {
                                    setSubmitting(true);
                                    try {
                                       let dataObject = {
                                          container_name: values.disposition_description
                                       }
                                       AppApi.addContainer(dataObject).then(result => {
                                          if (result && result.status == 201) {

                                             toast.success('Created successfully.', {
                                                autoClose: 5000,
                                             });
                                             setData([result.data, ...data]);
                                             // setFieldValue("disposition_description", "")
                                             resetForm()
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false);

                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form >

                                       <div className="form-group">
                                          <label  >Add Container</label>
                                          <Field className="form-control  " placeholder="Container name" type="text" name="disposition_description" />
                                          <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                       </div>
                                       {isSubmitting ? "Loading..." : (
                                          <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                             <button class="btn btn-success btn-sm"  ><i class="fa fa-check me-1"></i> Save</button>
                                          </span>
                                       )}
                                    </Form>
                                 )}
                              </Formik>
                           </div>
                        </div>
                        <div class="panel panel-default panel-info">
                           <div class="panel-heading">
                              <div class="panel-title">Delete Container</div>
                           </div>
                           <div class="panel-body p-2 ">
                              <Formik
                                 initialValues={{
                                    disposition_description: "",
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    try {
                                       AppApi.deleteContainers(values.disposition_description).then(result => {
                                          if (result && result.status == 200) {

                                             toast.success('Deleted successfully.', {
                                                autoClose: 5000,
                                             });
                                             setData(data.filter(data => data.id != values.disposition_description));
                                             resetForm()
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });

                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <div className="form-group">
                                          <select class="form-control "
                                             value={values.disposition_description}
                                             onChange={(event) => {
                                                setFieldValue("disposition_description", event.target.value);
                                             }}>
                                             <option value="" selected="selected">-- Pick A Container --</option>
                                             {data.map((data, index) => {
                                                return (
                                                   <option key={data.id} value={data.id}>{data.container_name}</option>
                                                )
                                             })}
                                          </select>
                                          <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                       </div>
                                       {isSubmitting ? "Loading..." : (
                                          <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                             <button class="btn btn-danger btn-sm"><i class="fa fa-trash me-1"></i> Delete</button>
                                          </span>
                                       )}
                                    </Form>
                                 )}
                              </Formik>
                              <form class="ng-pristine ng-invalid ng-invalid-required">
                                 <div class="form-group">

                                 </div>
                              </form>

                           </div>
                        </div>

                        {/* ********************************************************* */}
                        {data.length > 0 && (
                           <div className="border p-2">
                              <div class="alert alert-warning">
                                 <i class="fa fa-exclamation-triangle me-1"></i>
                                 moving items from one container to another will update all item records associated with the selected "From" container.
                              </div>
                              <div class="panel panel-default panel-info">
                                 <div class="panel-heading">
                                    <div class="panel-title">Move Items from One Container To Another</div>
                                 </div>
                                 <div class="panel-body p-2">
                                    <Formik
                                       initialValues={{
                                          item_container_name_from: "",
                                          item_container_name_to: ""
                                       }}
                                       validationSchema={FormValidation3}
                                       onSubmit={(values, { setSubmitting, setFieldValue, resetForm }) => {
                                          setSubmitting(true);
                                          try {
                                             AppApi.move_items(values).then(result => {
                                                if (result && result.status == 200) {
                                                   toast.success(`${result.data.moved_items} items moved.`, {
                                                      autoClose: 5000,
                                                   });
                                                   resetForm()
                                                } else {
                                                   toast.error('Server error.', {
                                                      autoClose: 5000,
                                                   });
                                                }
                                                setSubmitting(false)
                                             }).catch(error => {
                                                setSubmitting(false)
                                                try {
                                                   let message = error.response.data.errors[0]
                                                   toast.error(message ? message : 'Server Error!', {
                                                      autoClose: 5000,
                                                   });
                                                } catch (error) {
                                                   
                                                }
                                             })
                                          } catch (error) {
                                             setSubmitting(false)
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                             console.log("server error", error.message)
                                          }

                                       }}
                                    >
                                       {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                          <Form>
                                             <div className="form-group">
                                                <label>From</label>
                                                <select class="form-control "
                                                   value={values.item_container_name_from}
                                                   onChange={(event) => {
                                                      setFieldValue("item_container_name_from", event.target.value);
                                                      setFieldValue("item_container_name_to", "");
                                                   }}>
                                                   <option value="" selected="selected">-- Pick A Container --</option>
                                                   {data.map((data, index) => {
                                                      return (
                                                         <option key={index} value={data.container_name}>{data.container_name}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="item_container_name_from" />
                                             </div>
                                             <div className="form-group">
                                                <label>To</label>
                                                <select class="form-control "
                                                   value={values.item_container_name_to}
                                                   onChange={(event) => {
                                                      setFieldValue("item_container_name_to", event.target.value);
                                                   }}>
                                                   <option value="" selected="selected">-- Pick A Container --</option>
                                                   {data.map((data, index) => {
                                                      if (values.item_container_name_from == data.container_name) {
                                                         return ""
                                                      }
                                                      return (
                                                         <option key={index} value={data.container_name}>{data.container_name}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="item_container_name_to" />
                                             </div>
                                             {isSubmitting ? "Loading..." : (
                                                <span >
                                                   <button class="btn btn-success btn-sm"><i class="fa fa-check me-1"></i> Save</button>
                                                </span>
                                             )}
                                          </Form>
                                       )}
                                    </Formik>

                                 </div>
                              </div>
                           </div>

                        )}
                        {/* ********************************************************* */}
                     </div>
                     <div class="offset-md-2 col-md-5 ng-scope">
                        <Container data={data} />
                     </div>
                  </div>
               </div>
            </>)}

         <br></br>
         <br></br>
         <br></br>
         <br></br>
         <br></br>
      </>
   )
}

export default withNavigation(Venues)