import React, { useEffect } from 'react';
import AuthApi from "../../Api/AuthApi";
import Url from '../../Utils/Routes'
import { withNavigation } from '../../Utils/Navigater';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserPermission } from '../../store/reducers/userPermissions'
import { checkShipmentStatus } from '../../store/reducers/shipmentPermissions'

let LoginAuth = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        checkSession();
    }, [])
    let checkSession = () => {
        AuthApi.userSession().then(res => {
            if (res.status == 200) {
                let role_code = res.data.role_code
                let client_id = res.data.client_id
                dispatch(updateUserPermission(role_code))
                dispatch(checkShipmentStatus(client_id))
                // if (!res.data.is_admin) {
                //     props.navigate(Url.RepoAppDashboard);
                // }
                // else {
                //     props.navigate(Url.Dashboard);
                // }
            }
        }).catch(error => {
            localStorage.clear()
            props.navigate(Url.Login);
        })
    }
    return (
        <></>
    )
}

export default withNavigation(LoginAuth)