import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation = Yup.object().shape({
   // default_claim_expire_after: Yup.string().typeError("Invalid data").max(2, "Value can not grater then 99."),
   // default_item_expire_after: Yup.string().typeError("Invalid data").max(2, "Value can not grater then 99."),
});
let DefaultOptions = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   let [container, setContainer] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               setData(res.data)
               getContainers();
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });

         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });

      }
   }

   let getContainers = async () => {
      try {
         AppApi.getContainers().then(res => {
            if (res.status == 200) {
               setContainer(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)
      }
   }
   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className='max-w960 px-3 py-2 mt-3'>
                     <div class="panel border panel-default">
                        <div class="panel-heading">
                           <div class="panel-title">Manage Default Values</div>
                        </div>
                        <Formik
                           initialValues={{
                              auto_search_day_spread: data.auto_search_day_spread,
                              default_claim_expire_after: data.default_claim_expire_after,
                              default_item_expire_after: data.default_item_expire_after,
                              default_container_name: data.default_container_name,
                              storage_date_enabled: data.storage_date_enabled,
                              require_asset_id: data.require_asset_id,
                              require_venue: data.require_venue,
                              require_category: data.require_category,
                              require_container: data.require_container,
                              require_signature: data.require_signature,
                              require_phone_number: data.require_phone_number,
                              date_format: data.date_format,
                           }}
                           validationSchema={FormValidation}
                           onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(true);
                              try {
                                 AppApi.UpdateClientAccountOptions(values).then(result => {
                                    if (result && result.status == 200) {
                                       if (values.date_format) {
                                          localStorage.setItem("date_format", values.date_format)
                                       }
                                       toast.success("Updated successfully.", {
                                          autoClose: 5000,
                                       });
                                    } else {
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });

                                    }
                                    setSubmitting(false)
                                 }).catch(error => {
                                    setSubmitting(false)
                                    try {
                                       let message = error.response.data.errors[0]
                                       toast.error(message ? message : 'Server Error!', {
                                          autoClose: 5000,
                                       });
                                    } catch (error) {
                                       
                                    }
                                 })
                              } catch (error) {
                                 setSubmitting(false)
                                 toast.error('Server error.', {
                                    autoClose: 5000,
                                 });

                                 console.log("server error", error.message)
                              }

                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form >
                                 <div class="panel-body p-3">
                                    <form name="OptionForm" role="form" novalidate="" class="ng-pristine ng-valid ng-valid-maxlength">
                                       <div class="form-group">
                                          <label for="auto_search_day_spread">Auto Matching Day Spread</label>
                                          <select class="form-control  "
                                             onChange={(event) => setFieldValue("auto_search_day_spread", event.target.value)} value={values.auto_search_day_spread}   >
                                             <option value="1">1 day</option>
                                             <option value="2">2 days</option>
                                             <option value="3">3 days</option>
                                             <option value="4">4 days</option>
                                             <option value="5">5 days</option>
                                             <option value="6">6 days</option>
                                             <option value="7">7 days</option>
                                             <option value="8">8 days</option>
                                             <option value="9">9 days</option>
                                             <option value="10">10 days</option>
                                             <option value="11">11 days</option>
                                             <option value="12">12 days</option>
                                          </select>
                                          <span class="help-block">Increase the number of days to enable matching of items and claims over a wider period of time. For example, 2 days will match items and claims up to 4 days apart (between item found date and claim lost date). A high number may result in many false matches. </span>
                                       </div>
                                       <div class="form-group">
                                          <label class="control-label" >Default "Expire After" days for Claims <br /><small>(same value will be used for web claims if feature enabled)</small></label>
                                          <Field className="form-control  " placeholder="" type="number" name="default_claim_expire_after" />
                                          <span class="help-block">Enter a range between 1 - 90 days. Common defaults are 30, 60, or 90 days.</span>
                                          <ErrorMessage component="small" className='text-danger d-block' name="default_claim_expire_after" />
                                       </div>
                                       <div class="form-group">
                                          <label class="control-label"  >Default "Expire After" days for Items <br /></label>
                                          <Field className="form-control  " placeholder="" type="number" name="default_item_expire_after" />
                                          <span class="help-block">Enter a range between 1 - 90 days. Common defaults are 30, 60, or 90 days.</span>
                                          <ErrorMessage component="small" className='text-danger d-block' name="default_item_expire_after" />
                                       </div>
                                       <div class="form-group">
                                          <label for="control-label">Default​ ​“Container”​ ​for​ ​Items</label>
                                          <select class="form-control"
                                             onChange={(event) => setFieldValue("default_container_name", event.target.value)} value={values.default_container_name}
                                          >
                                             <option value="" class="">- Pick Container -</option>
                                             {container.map((data, index) => {
                                                return (
                                                   <option key={data.container_name} value={data.container_name}>{data.container_name}</option>
                                                )
                                             })}
                                          </select>
                                       </div>
                                       <div class="form-group">
                                          <Field type="checkbox" name="storage_date_enabled" checked={values.storage_date_enabled} /> Hide "Storage Date" for Items
                                       </div>
                                       <div class="form-group">
                                          <Field type="checkbox" name="require_asset_id" checked={values.require_asset_id} />  Make "Asset / Badge ID" Required for Items
                                       </div>
                                       <div class="form-group">
                                          <Field type="checkbox" name="require_venue" checked={values.require_venue} />  Make “Venue" Required for Items
                                       </div>
                                       {/* <div class="form-group">
                                          <Field type="checkbox" name="require_category" checked={values.require_category} />  Make “Category" Required for Items
                                       </div> */}
                                       <div class="form-group">
                                          <Field type="checkbox" name="require_container" checked={values.require_container} />  Make “Container” Required for Items
                                       </div>
                                       <div class="form-group">
                                          <Field type="checkbox" name="require_signature" checked={values.require_signature} />
                                          Make "Signature" Required for Released items
                                       </div>
                                       <div class="form-group">
                                          <Field type="checkbox" name="require_phone_number" checked={values.require_phone_number} />  Make “Phone" Required for Claims
                                       </div>
                                       <div class="form-group">
                                          <label for="auto_search_day_spread">Date Format</label>
                                          <select class="form-control "
                                             onChange={(event) => setFieldValue("date_format", event.target.value)} value={values.date_format}
                                          >
                                             <option value="yyyy-MM-dd">YYYY-MM-DD</option>

                                             <option value="MM-dd-yyyy">MM-DD-YYYY</option>

                                             <option value="dd-MM-yyyy">DD-MM-YYYY</option>

                                          </select>
                                       </div>
                                    </form>
                                 </div>
                                 <div class="panel-footer ">
                                    <span>
                                       <button class="btn btn-success" disabled={isSubmitting}><i class="fa fa-check"></i> {isSubmitting ? "Loading..." : "Save"}</button>
                                    </span>
                                 </div>
                              </Form>
                           )}
                        </Formik>

                     </div>
                  </div>

               )}
            </>)}


      </>
   )
}

export default withNavigation(DefaultOptions)