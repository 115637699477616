import { createSlice } from '@reduxjs/toolkit'
let accessList = []
if (process.env.REACT_APP_ENV == "local") {
  accessList = [500, 501, 502, 503, 504]
  if (localStorage.getItem("add_client_id")) {
    accessList.push(parseInt(localStorage.getItem("add_client_id")))
  }
}
if (process.env.REACT_APP_ENV == "dev") {
  accessList = [561, 500, 501, 502, 503, 504];
  if (localStorage.getItem("add_client_id")) {
    accessList.push(localStorage.getItem("add_client_id"))
  }
}
if (process.env.REACT_APP_ENV == "prod") {
  accessList = [60, 911, 600, 569]
  if (localStorage.getItem("add_client_id")) {
    accessList.push(parseInt(localStorage.getItem("add_client_id")))
  }
}
const initialState = {
  status: true,
}

export const checkShipmentStatusSlice = createSlice({
  name: 'UserPermissions',
  initialState,
  reducers: {
    checkShipmentStatus: (state, action) => {
      if (accessList.includes(action.payload)) {
        return true
      } else {
        return true
      }
    },
  },
})

export const { checkShipmentStatus } = checkShipmentStatusSlice.actions

export default checkShipmentStatusSlice.reducer

