import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


let ToolTipContainer = (props) => {
    if (props.des == "") {
        return ""
    }
    return (
        <>
            <OverlayTrigger
                trigger="hover"
                key={props.index}
                placement={props.placement}
                overlay={
                    <Popover  >
                        <Popover.Header as="h3">
                            {props.title && (
                                <span>{props.title}</span>
                            )}
                        </Popover.Header>
                        <Popover.Body>
                            <p className='no-wrap mb-0'>{props.des}</p>
                        </Popover.Body>
                    </Popover>
                }
            >
                {props.view != "" ? <span>{props.view} </span> : (
                    <i class={`fa fa-question-circle  mx-1 ${props.icon} d-inline pointer`}></i>
                )}
            </OverlayTrigger>
        </>
    )
}

export default ToolTipContainer

