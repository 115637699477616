import React from 'react';
import { withNavigation } from './../../../Utils/Navigater';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Url from '../../../Utils/Routes'
import AppApi from '../../../Api/AppApi';
const FormValidation = Yup.object().shape({
    first_name: Yup.string().min(4, 'Too Short!').required('First name is required.'),
    last_name: Yup.string().min(4, 'Too Short!').required('Last name is required.'),
    email: Yup.string().required('Email is required.').email("Email is not valid."),
    role_code: Yup.string().required('Account role is required.'),
});
let AddTemplate = (props) => {
    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                role_code: '',
            }}
            validationSchema={FormValidation}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                try {
                    AppApi.addUser(values).then(result => {
                        if (result && result.status == 201) {

                            toast.success('Created successfully.', {
                                autoClose: 5000,
                            });
                            props.navigate(Url.EditUsers(result.data.id));
                        } else {
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                        }
                        setSubmitting(false)
                    }).catch(error => {
                        setSubmitting(false)
                        try {
                            let message = error.response.data.errors
                            toast.error(message ? message : 'Server Error!', {
                                autoClose: 5000,
                            });
                        } catch (error) {
                            
                        }
                    })
                } catch (error) {
                    setSubmitting(false)

                    toast.error('Server error.', {
                        autoClose: 5000,
                    });
                    console.log("server error", error.message)
                }

            }}
        >
            {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                <Form >
                    <div className="panel rounded-0    panel-default border">
                        <div className="panel-heading">
                            <h3 className="panel-title">User Details</h3>
                        </div>
                        <div className="panel-body p-2">
                            <div className="form-group">
                                <label  >Email</label>
                                <Field className="form-control rounded-0 " placeholder="" type="text" name="email" />
                                <ErrorMessage component="small" className='text-danger' name="email" />
                            </div>
                            <div className="form-group">
                                <label  >First Name</label>
                                <Field className="form-control rounded-0 " placeholder="" type="text" name="first_name" />
                                <ErrorMessage component="small" className='text-danger' name="first_name" />
                            </div>
                            <div className="form-group">
                                <label  >Last Name</label>
                                <Field className="form-control rounded-0 " placeholder="" type="text" name="last_name" />
                                <ErrorMessage component="small" className='text-danger' name="last_name" />
                            </div>
                            <div className="form-group">
                                <label  >Role</label>
                                <select class="form-control rounded-0 "
                                    value={values.role_code}
                                    onChange={(event) => setFieldValue("role_code", event.target.value)}
                                >
                                    <option value="" selected="selected">-- Pick A Role --</option>
                                    <option value="A">Admin</option>
                                    <option value="I">Items/Claims</option>
                                    <option value="C">Claims Only</option>
                                    <option value="R">ReadOnly</option>
                                </select>
                                <ErrorMessage component="small" className='text-danger' name="role_code" />
                            </div>

                        </div>
                        <div className="panel-footer">
                            {isSubmitting ? "Loading..." : (
                                <>
                                    <span>
                                        <button type='submit' className="btn btn-success rounded-0  me-1" ><i className="fa fa-check"></i> Save </button>
                                    </span>
                                    <Link className="btn btn-default" to={Url.Users}>Cancel</Link>
                                </>
                            )}

                        </div>
                    </div>
                </Form>
            )}
        </Formik>


    )
}

export default withNavigation(AddTemplate)