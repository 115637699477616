import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import Accordion from 'react-bootstrap/Accordion';
import bulkReleaseApi from '../../Api/bulkReleaseApi';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import CustomDatePickerInput from '../../Components/Forms/Inputs/CustomDatePickerInput';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [data, setData] = useState([]);
   let [bulk_release_ids, set_bulk_release_ids] = useState([]);
   let [releaseDate, setReleaseDate] = useState("");
   let [ids, setIds] = useState("");
   let [containerId, setContainerId] = useState("");
   let [item_storage_date_start, setItem_storage_date_start] = useState("");
   let [item_storage_date_end, setItem_storage_date_end] = useState("");
   let [releaseNotes, setReleaseNotes] = useState("");
   let [containerData, setContainerData] = useState([]);
   let [apiLoading, setApiLoading] = useState(true);
   useEffect(() => {
      getBulkReleaseIds();
      getContainers();
   }, [])

   let getContainers = async () => {
      try {
         bulkReleaseApi.getItemsContainers().then(res => {
            if (res.status == 200) {
               setContainerData(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getItemsList = async () => {
      if (ids) {
         try {
            setApiLoading(true);
            let apiObject = {
               id_list: ids
            }
            bulkReleaseApi.get_items_list(apiObject).then(res => {
               if (res.status == 200) {
                  setData(res.data);
                  toast.info(`${res.data.length} Records updated successfully.`, {
                     autoClose: 5000,
                  });
               }

               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false);
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            })
         } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Items id is required.', {
            autoClose: 5000,
         });
      }

   }
   let getBulkReleaseContainer = async () => {
      if (containerId) {
         try {
            setApiLoading(true);
            bulkReleaseApi.get_bulk_release_container(containerId).then(res => {
               if (res.status == 200) {
                  setData(res.data);
               }
               toast.info('Records updated successfully.', {
                  autoClose: 5000,
               });
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false);
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            })
         } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Container is required.', {
            autoClose: 5000,
         });
      }

   }
   let bulkReleaseContainer = async () => {
      if (containerId) {
         if (releaseNotes) {
            Swal.fire({
               title: 'Release all items in SAFEROOM? All items in this container will be released.',
               showDenyButton: true,
               showCancelButton: false,
               confirmButtonText: 'Yes',
               denyButtonText: `Cancel`,
            }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                  try {
                     setApiLoading(true);
                     let apiObject = {
                        item_container_name: containerId,
                        item_release_notes: releaseNotes,
                     }
                     bulkReleaseApi.bulk_release_container(apiObject).then(res => {
                        if (res.status == 200) {
                           setData([]);
                           getContainers()
                           getBulkReleaseIds();
                           toast.info(`${res.data.released_items_count} Record${res.data.released_items_count > 1 ? "s" : ""} updated successfully.`, {
                              autoClose: 5000,
                           });
                           setReleaseNotes("")
                           setContainerId("")
                        }
                        setApiLoading(false)
                     }).catch(error => {
                        setApiLoading(false)
                        toast.error('Server error.', {
                           autoClose: 5000,
                        });
                     })
                  } catch (error) {
                     setApiLoading(false);
                     let message = error.response.data.errors[0]
                     toast.error(message ? message : 'Server Error!', {
                        autoClose: 5000,
                     });
                  }
               } else if (result.isDenied) {
               }
            })
         } else {
            toast.error('Release note required.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Container is required.', {
            autoClose: 5000,
         });
      }

   }
   let bulkReleaseItemIds = async () => {
      if (ids) {
         // if (releaseNotes) {
         try {
            setApiLoading(true);
            let apiObject = {
               id_list: ids,
               item_release_notes: releaseNotes,
            }
            bulkReleaseApi.bulk_release_item_ids(apiObject).then(res => {
               if (res.status == 200) {
                  setData([]);
                  getContainers()
                  getBulkReleaseIds();
                  toast.info(`${res.data.released_items_count} Record${res.data.released_items_count > 1 ? "s" : ""} updated successfully.`, {
                     autoClose: 5000,
                  });
                  setReleaseNotes("")
                  setContainerId("")
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false);
               let message = error.response.data.errors[0]

               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            })
         } catch (error) {
            setApiLoading(false);
            let message = error.response.data.errors[0]
            toast.error(message ? message : 'Server Error!', {
               autoClose: 5000,
            });
         }
         // } else {
         //    toast.error('Release note required.', {
         //       autoClose: 5000,
         //    });
         // }
      } else {
         toast.error('Ids are required.', {
            autoClose: 5000,
         });
      }

   }
   let bulkReleaseExpired = async (id) => {

      try {
         setApiLoading(true);
         let apiObject = {
            item_release_notes: releaseNotes
         }
         bulkReleaseApi.bulk_release_expired(apiObject).then(res => {
            if (res.status == 200) {
               setData([]);
               getBulkReleaseIds();
               toast.info(`${res.data.released_items_count} Record${res.data.released_items_count > 1 ? "s" : ""} updated successfully.`, {
                  autoClose: 5000,
               });
               setReleaseNotes("")
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false);
            let message = error.response.data.errors[0]
            toast.error(message ? message : 'Server Error!', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }


   }
   let getBulkReleaseExpired = async (id) => {
      try {
         setApiLoading(true);
         bulkReleaseApi.get_bulk_release_expired(id).then(res => {
            if (res.status == 200) {
               setData(res.data.items_count)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getBulkReleaseStorageDate = async (id) => {
      if (item_storage_date_start) {
         if (item_storage_date_end) {
            try {
               setApiLoading(true);
               console.log(localStorage.getItem("date_format"))
               console.log(localStorage.getItem("date_format").replaceAll("d", "D").replaceAll("y", "Y"))
               let apiData = {
                  item_storage_date_start: moment(item_storage_date_start).local().format(localStorage.getItem("date_format").replaceAll("d", "D").replaceAll("y", "Y")),
                  item_storage_date_end: moment(item_storage_date_end).local().format(localStorage.getItem("date_format").replaceAll("d", "D").replaceAll("y", "Y")),
               }
               bulkReleaseApi.get_bulk_release_storage_date(apiData).then(res => {
                  if (res.status == 200) {
                     setData(res.data)
                     toast.info(`${res.data.length} record found.`, {
                        autoClose: 5000,
                     });
                  }
                  setApiLoading(false)
               }).catch(error => {
                  setApiLoading(false)
                  toast.error('Server error.', {
                     autoClose: 5000,
                  });
               })
            } catch (error) {
               setApiLoading(false);
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            }
         } else {
            toast.error('End date is required.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Start date is required.', {
            autoClose: 5000,
         });
      }

   }
   let bulkReleaseStorageDate = async (id) => {
      if (item_storage_date_start) {
         if (item_storage_date_end) {
            // if (releaseNotes) {
            try {
               setApiLoading(true);
               let apiData = {
                  item_storage_date_start, item_storage_date_end,
                  item_release_notes: releaseNotes
               }
               bulkReleaseApi.bulk_release_storage_date(apiData).then(res => {
                  if (res.status == 200) {
                     setData([]);
                     getBulkReleaseIds();
                     toast.info(`${res.data.released_items_count} Record${res.data.released_items_count > 1 ? "s" : ""} updated successfully.`, {
                        autoClose: 5000,
                     });
                     setReleaseNotes("");
                  }
                  setApiLoading(false)
               }).catch(error => {
                  setApiLoading(false)
                  toast.error('Server error.', {
                     autoClose: 5000,
                  });
               })
            } catch (error) {
               setApiLoading(false)
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
            // } else {
            //    toast.error('Release notes is required.', {
            //       autoClose: 5000,
            //    });
            // }

         } else {
            toast.error('End date is required.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Start date is required.', {
            autoClose: 5000,
         });
      }

   }
   let getBulkReleaseIds = async (id) => {
      try {
         setApiLoading(true);
         bulkReleaseApi.get_bulk_release_ids(id).then(res => {
            if (res.status == 200) {
               set_bulk_release_ids(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let removeBulkRelease = async () => {
      if (releaseDate) {
         Swal.fire({
            title: 'Undo bulk release (i.e., return items to Available status)?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: `Cancel`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
               try {
                  setApiLoading(true);
                  let apiObject = {
                     item_bulk_release_id: releaseDate
                  }
                  bulkReleaseApi.remove_bulk_release(apiObject).then(res => {
                     if (res.status == 200) {
                        setData([]);
                        let filterData = bulk_release_ids.filter(data => data != releaseDate);
                        set_bulk_release_ids(filterData)
                        setReleaseDate("");
                        toast.success('Records updated successfully. Items returned to Available status. Note that photos of items cannot be restored.', {
                           autoClose: 5000,
                        });
                     }
                     setApiLoading(false)
                  }).catch(error => {
                     setApiLoading(false);
                     let message = error.response.data.errors[0]

                     toast.error(message ? message : 'Server Error!', {
                        autoClose: 5000,
                     });
                  })
               } catch (error) {
                  setApiLoading(false)
                  toast.error('Server error.', {
                     autoClose: 5000,
                  });
               }
            } else if (result.isDenied) {
               // Swal.fire('Changes are not saved', '', 'info')
            }
         })
      } else {
         toast.error('Bulk releases id', {
            autoClose: 5000,
         });
      }

   }
   let getItemsByItemBulkReleaseId = async () => {
      if (releaseDate) {
         try {
            setApiLoading(true);
            let apiObject = {
               item_bulk_release_id: releaseDate
            }
            bulkReleaseApi.get_items_by_item_bulk_release_id(apiObject).then(res => {
               if (res.status == 200) {
                  setData(res.data)
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false);
               let message = error.response.data.errors[0]

               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            })
         } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Bulk releases id', {
            autoClose: 5000,
         });
      }

   }

   let downloadExcelFile = (data) => {
      console.log("before table data:", data)
      if (data && data.length > 0) {
         var html = document.querySelector("table").outerHTML;
         var data = [];
         var rows = document.querySelectorAll("table tr");
         for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");

            for (var j = 0; j < cols.length; j++) {
               row.push(cols[j].innerText);
            }
            data.push(row.join(","));
         }
         var csv_file, download_link;
         csv_file = new Blob([data.join("\n")], { type: "text/csv" });
         download_link = document.createElement("a");
         download_link.download = "data-file";
         download_link.href = window.URL.createObjectURL(csv_file);
         download_link.style.display = "none";
         document.body.appendChild(download_link);
         download_link.click();
      } else {
         toast.info('No record to download.', {
            autoClose: 5000,
         });
      }

   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.bulk_release?.bulk_release_view ? <UnAuthorized /> : (
            <>
               <div className="container-fluid  ">
                  <div className="row">
                     <div className="col-12 mt-1">
                        <div className="alert alert-danger">
                           <small>  <i className="fa fa-exclamation-triangle fa-lg me-1"></i>
                              <strong>Bulk Releasing performs the mass releasing of items</strong>. This feature is intented for the releasing of multiple items no longer in possession (e.g., releasing 100 items to a charitable organization). Choose an option below to get started.
                           </small></div>
                     </div>
                  </div>
                  <div className="row mt-3">
                     <div className="col-md-6">
                        <div className="accordion_1">
                           <Accordion>
                              <Accordion.Item eventKey="0">
                                 <Accordion.Header><i className="fa fa-plus-circle me-2"></i> Bulk Release Expired Items</Accordion.Header>
                                 <Accordion.Body>
                                    <div className="panel-body ">
                                       {/* <form name="BulkReleaseExpire" role="form" novalidate="" className="ng-pristine ng-valid ng-valid-maxlength ng-submitted"  > */}
                                       <button className="btn btn-info btn-sm"
                                          onClick={() => getBulkReleaseExpired()}
                                       > Step 1 - View Items</button>

                                       {UserPermissions?.permissions?.pages?.bulk_release?.update_bulk_release && (
                                          <>
                                             <div className="form-group">
                                                <label className="control-label" >Release Notes</label>
                                                <input className="form-control " type="text" autocomplete="off"
                                                   value={releaseNotes}
                                                   onChange={(event) => { setReleaseNotes(event.target.value) }}
                                                   placeholder="Optional - release notes" maxlength="50" />
                                             </div>
                                             <button className="btn btn-success btn-sm"
                                                onClick={() => {
                                                   bulkReleaseExpired()
                                                }}
                                             > Step 2 - Bulk Release Items</button>
                                          </>
                                       )}
                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                 <Accordion.Header><i className="fa fa-plus-circle me-2"></i>  Bulk Release by Container ID</Accordion.Header>
                                 <Accordion.Body>
                                    <div className="panel-body">
                                       <div className="form-group">
                                          <label>Container</label>
                                          <select className="form-control "
                                             value={containerId}
                                             onChange={(event) => {
                                                setContainerId(event.target.value)
                                             }}
                                          >
                                             <option value="" className="">-- Pick Container --</option>
                                             {containerData.map((data, index) => {
                                                return (
                                                   <option key={data} value={data}>{data}</option>
                                                )
                                             })}
                                          </select>
                                       </div>
                                       <button className="btn btn-info btn-sm"
                                          onClick={() => getBulkReleaseContainer()}
                                       > Step 1 - View Items</button>
                                       {UserPermissions?.permissions?.pages?.bulk_release?.update_bulk_release && (
                                          <>
                                             {containerId && (
                                                <>
                                                   <div className="form-group">
                                                      <label className="control-label" for="item_release_notes_container">Release Notes</label>
                                                      <input className="form-control " type="text"
                                                         value={releaseNotes}
                                                         onChange={(event) => { setReleaseNotes(event.target.value) }}
                                                      />
                                                   </div>
                                                   <span >
                                                      <button className="btn btn-success btn-sm"
                                                         onClick={() => bulkReleaseContainer()}
                                                      > Step 2 - Bulk Release Items</button>
                                                   </span>
                                                </>
                                             )}
                                          </>)}


                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                 <Accordion.Header><i className="fa fa-plus-circle me-2"></i>   Bulk Release by Storage Date Range</Accordion.Header>
                                 <Accordion.Body>
                                    <div class="panel-body">
                                       <div class="form-group">
                                          <label class="control-label" >Storage Start Date</label>
                                          {/* <input type="date" name="" className='form-control'
                                             onChange={(event) => {
                                                setItem_storage_date_start(event.target.value);
                                             }}
                                          /> */}
                                          <CustomDatePickerInput
                                             onChange={setItem_storage_date_start}
                                             name="setItem_storage_date_start"
                                             value={item_storage_date_start}
                                          />
                                       </div>
                                       <div class="form-group">
                                          <label class="control-label" >Storage End Date</label>
                                          <CustomDatePickerInput
                                             onChange={setItem_storage_date_end}
                                             name="setItem_storage_date_end"
                                             value={item_storage_date_end}
                                          />
                                       </div>
                                       <div class="form-group">
                                          <button
                                             onClick={() => getBulkReleaseStorageDate()}
                                             class="btn btn-info btn-sm"> Step 1 - View Items</button>
                                       </div>
                                       {UserPermissions?.permissions?.pages?.bulk_release?.update_bulk_release && (
                                          <>
                                             <div class="form-group">
                                                <label class="control-label">Release Notes</label>
                                                <input class="form-control" type="text"
                                                   value={releaseNotes}
                                                   onChange={(event) => setReleaseNotes(event.target.value)}
                                                   placeholder="Optional - release notes" maxlength="50"
                                                />
                                             </div>
                                             <button class="btn btn-success btn-sm"
                                                onClick={() => {
                                                   bulkReleaseStorageDate();
                                                }}
                                             > Step 2 - Bulk Release Items</button>
                                          </>)}


                                       <br /><br />
                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="3">
                                 <Accordion.Header><i className="fa fa-plus-circle me-2"></i>    Bulk Release by Item IDs</Accordion.Header>
                                 <Accordion.Body>
                                    <div class="panel-body">
                                       <div class="form-group">
                                          <div class="alert alert-warning">
                                             <i class="fa fa-info-circle"></i> List up to 50 item IDs with a <u>blank space</u> separating each ID. It's recommended to create this list offline, then paste it into the below field.
                                          </div>
                                          <label for="id_list">Item IDs</label>
                                          <textarea class="form-control" rows="2" type="text" id="id_list" autocomplete="off" placeholder=" Item IDs each separated by a space" required="required"
                                             onChange={(event) => {
                                                setIds(event.target.value)
                                             }}
                                             value={ids}
                                          ></textarea>
                                       </div>
                                       <button
                                          onClick={() => getItemsList()}
                                          class="btn btn-info btn-sm"> Step 1 - View Items</button>

                                       {UserPermissions?.permissions?.pages?.bulk_release?.update_bulk_release && (
                                          <>
                                             <div class="form-group">
                                                <label class="control-label" for="item_release_notes_ids">Release Notes</label>
                                                <input class="form-control " type="text"
                                                   value={releaseNotes}
                                                   onChange={(event) => setReleaseNotes(event.target.value)}
                                                   placeholder="Optional - release notes" maxlength="50"
                                                />
                                             </div>
                                             <button class="btn btn-success btn-sm"
                                                onClick={() => {
                                                   bulkReleaseItemIds()
                                                }}
                                             > Step 2 - Bulk Release Items</button>
                                          </>)}

                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="panel border panel-default">
                           <div className="panel-heading">
                              <div className="panel-title">Past Bulk Releases <div className="badge badge-info ng-binding">{bulk_release_ids.length}</div></div>
                           </div>
                           <div className="panel-body p-3">
                              <select className="form-control " required=""
                                 value={releaseDate}
                                 onChange={(event) => { setReleaseDate(event.target.value) }}
                              >
                                 <option value="" selected="selected">-- Pick Bulk Release ID --</option>
                                 {bulk_release_ids.map((data, index) => {
                                    return (
                                       <option value={data}>{data}</option>
                                    )
                                 })}
                              </select>
                              <br />
                              <div className="well well-sm">
                                 <div className="form-group">
                                    <label>Selected Bulk Release Transaction</label>
                                    <input className="form-control" value={releaseDate} type="text" disabled />
                                 </div>
                                 <a href="#" className="btn btn-info btn-sm text-white" disabled="disabled"
                                    onClick={() => getItemsByItemBulkReleaseId()}
                                 ><i className="fa fa-info-circle"></i> View Items</a>
                                 <a href="#" className="btn btn-info btn-sm text-white m-1 "
                                    onClick={() => downloadExcelFile(data)}
                                 ><i className="fa fa-cloud-download"></i> Download </a>
                                 {UserPermissions?.permissions?.pages?.bulk_release?.update_bulk_release && (
                                    <>
                                       <a href="#" className="btn btn-danger btn-sm text-white m-1 "
                                          onClick={() => removeBulkRelease()}
                                       ><i className="fa fa-undo"></i> Undo Bulk Release</a>
                                    </>)}

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div class="col-sm-12 mt-3">
                        <div class="panel border panel-default">
                           <div class="panel-heading">
                              <strong>Items</strong>
                              <div class="badge badge-info ng-binding mx-1">{data.length}</div>
                           </div>
                           <div class="table-responsive">
                              <table class="table table-condensed table-hover table-striped">
                                 <thead>
                                    <tr>
                                       <th > Item ID</th>
                                       <th>Description</th>
                                       <th> Container</th>
                                       <th>Expire After</th>
                                       <th> Storage Date</th>
                                       <th>Found Date</th>
                                       <th>Venue</th>
                                       <th >Release Notes</th>
                                       <th >Notes</th>
                                       <th>Created At</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {apiLoading && (
                                       <tr>
                                          <th className='text-center text-info' colSpan={10}>Loading...</th>
                                       </tr>
                                    )}
                                    {!apiLoading && data.length == 0 && (
                                       <tr>
                                          <th className='text-center' colSpan={10}>No record found.</th>
                                       </tr>
                                    )}
                                    {!apiLoading && data.map((data, index) => {
                                       return (
                                          <tr>
                                             <td><Link to={Url.EditNewItems(data.id)}>{data.id}</Link></td>
                                             <td>
                                                <ToolTipContainer
                                                   index={index}
                                                   view={data?.item_description?.substring(0, 20)}
                                                   placement="right"
                                                   icon="text-info"
                                                   title=""
                                                   des={data.item_description}
                                                />
                                             </td>
                                             <td>{data.item_container_name}</td>
                                             <td>{data.item_expiration_date}</td>
                                             <td>{data.item_storage_date}</td>
                                             <td>{data.item_found_date}</td>
                                             <td>{data?.venue?.venue_name}</td>
                                             <td style={{ maxWidth: "150px" }}>{data.item_release_notes} </td>
                                             <td style={{ maxWidth: "150px" }}>{data.item_notes} </td>
                                             <td>{moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase())} </td>
                                          </tr>
                                       )
                                    })}
                                    {/* <!-- ngRepeat: item in items --> */}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>)}


         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withNavigation(Index)