import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AppApi from '../../Api/AppApi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePickerInput from '../Forms/Inputs/DatePickerInput';
const SignupSchema = Yup.object().shape({
    plan_trial_start_date: Yup.string().required('Plan start date is required.'),
    plan_trial_end_date: Yup.string().required('Plan end date is required.'),
});
let UpdateSubscription = (props) => {
    let [apiLoading, setApiLoading] = useState(false);
    return (
        <>
            <div class="panel panel-default border">
                <div class="panel-heading">
                    <h3 class="panel-title">Update Subscription</h3>
                </div>
                <div class="panel-body p-2">
                    <small>Select Premium if Web Integration feature if active.</small>
                    <span class="label label-primary ms-2">Search: {JSON.stringify(props?.client_account_options?.search_page_enabled)}</span>
                    <span class="label label-primary ms-2">Claim: {JSON.stringify(props?.client_account_options?.claim_page_enabled)}</span>
                    <span class="label label-primary ms-2">Item: {JSON.stringify(props?.client_account_options?.item_page_enabled)}</span>
                    <br />
                    <br />
                    {/* ********************************************************************** */}
                    <Formik
                        initialValues={{
                            plan_name: props.client.plan_name,
                            plan_user_limit: props.client.plan_user_limit,
                            plan_start_date: props.client.plan_start_date,
                            plan_end_date: props.client.plan_end_date,
                            plan_trial_start_date: props.client.plan_trial_start_date,
                            plan_trial_end_date: props.client.plan_trial_end_date,
                            plan_item_limit: props.client.plan_item_limit,
                            plan_claim_limit: props.client.plan_claim_limit,
                            plan_photo_limit: props.client.plan_photo_limit,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            let objects = { ...values }
                            // delete objects.client_plan_end_date
                            let data = {
                                id: props.client.id,
                                data: { client: objects },
                            }
                            try {
                                AppApi.updateSub(data).then(res => {
                                    console.log(res.data)
                                    if (res && res.status == 200) {
                                        toast.success('Subscription updated.', {
                                            autoClose: 5000,
                                        });
                                    } else {

                                        toast.error('Subscription not updated.', {
                                            autoClose: 5000,
                                        });
                                    }
                                    setSubmitting(false);
                                }).catch(error => {
                                    setSubmitting(false);
                                    try {
                                        let message = error.response.data.errors[0] + "."
                                        toast.error(message ? message : 'Subscription not updated.', {
                                            autoClose: 5000,
                                        });
                                    } catch (error) {
                                        
                                        toast.error('Server error.', {
                                            autoClose: 5000,
                                        });
                                    }
                                })
                            } catch (error) {
                                console.log("found error on Subscription updating:",)
                                toast.error('Network Error!', {
                                    autoClose: 5000,
                                });
                            }
                        }}
                    >
                        {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                            <Form class="form-horizontal  formEl" id="edit_client_10" autocomplete="off" >
                                <div class="form-group d-flex justify-content-center ">
                                    <div>
                                        <label class="  control-label required" for="client_plan_name">Subscripton Plan</label>
                                    </div>
                                    <div class=" ms-3 ">
                                        <select value={values.plan_name}
                                            onChange={(event) => {
                                                setFieldValue("plan_name", event.target.value)
                                            }}
                                        >
                                            <option value="">Select Plan</option>
                                            <option value="BASIC">BASIC</option>
                                            <option value="PLUS">PLUS</option>
                                            <option selected="selected" value="PREMIUM">PREMIUM</option>
                                        </select>
                                        <br />Current: {props.client.plan_name}
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="plan_user_limit">Plan User Limit</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field type="number" name="plan_user_limit" id="plan_user_limit" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="plan_start_date">Plan Start Date</label>
                                    </div>
                                    <div class=" ms-3">
                                        <DatePickerInput
                                            onChange={setFieldValue}
                                            name="plan_start_date"
                                            value={values.plan_start_date}
                                        />
                                    </div>
                                    <ErrorMessage component="small" className='text-danger d-block' name="plan_start_date" />
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="plan_end_date">Plan End Date</label>
                                    </div>
                                    <div class=" ms-3">
                                        <DatePickerInput
                                            onChange={setFieldValue}
                                            name="plan_end_date"
                                            value={values.plan_end_date}
                                        />
                                        <ErrorMessage component="small" className='text-danger d-block' name="plan_end_date" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="plan_trial_start_date">Trial Start Date</label>
                                    </div>
                                    <div class=" ms-3">
                                        <DatePickerInput
                                            onChange={setFieldValue}
                                            name="plan_trial_start_date"
                                            value={values.plan_trial_start_date}
                                        />
                                        <ErrorMessage component="small" className='text-danger d-block' name="plan_trial_start_date" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="client_plan_trial_end_date">Trial End Date</label>
                                    </div>
                                    <div class=" ms-3">
                                        <DatePickerInput
                                            onChange={setFieldValue}
                                            name="plan_trial_end_date"
                                            value={values.plan_trial_end_date}
                                        />
                                        <ErrorMessage component="small" className='text-danger d-block' name="plan_trial_end_date" />
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <div>
                                        <label className=" control-label required" for="plan_item_limit">Plan Item Limit</label>
                                    </div>
                                    <div className=" ms-3">
                                        <Field className="" type="text" name="plan_item_limit" />
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="plan_claim_limit">Plan Claim Limit</label>
                                    </div>
                                    <div class="ms-3">
                                        <Field type="text" name="plan_claim_limit" id="plan_claim_limit" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="plan_photo_limit">Plan Photo Limit</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field class="" type="text" name="plan_photo_limit" />
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" class="btn btn-primary" >
                                        {isSubmitting ? "Loading...." : "Update Subscription"}
                                    </button>
                                </div>

                            </Form>

                        )}
                    </Formik>
                    {/* ********************************************************************** */}

                </div>
            </div>
        </>
    )
}

export default UpdateSubscription

