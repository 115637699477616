import AppApi from "../../Api/AppApi";
import {toast} from "react-toastify";
import React, {useEffect, useMemo, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import EmailTemplateActionModal from "../modals/EmailTemplateActionModal";

const SelectEmailTemplateDropdown = ({setApiLoading, sendToEmail, contentPopulator}) => {
    const [templates, setTemplates] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        getTemplates();
    }, [])

    const getTemplates = async () => {
        try {
            AppApi.getTemplate().then(res => {
                if (res.status == 200) {
                    setTemplates(res.data)
                }
            }).catch(error => {
                setApiLoading(false)
            })
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }

    const reset = () => {
        setSelectedTemplate(null);
        setShowEmailModal(false)
    }

    return (
        <>
            <Dropdown className='dashboardDropDown '>
                <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                    <i class="fa fa-envelope"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {templates.map((data, index) =>
                        (
                            <li
                                key={data.id}
                                onClick={() => {
                                    setShowEmailModal(true);
                                    const content = contentPopulator(data.content);
                                    setSelectedTemplate({...data, content});
                                }}
                            >
                                    {data.label}
                            </li>
                        )
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <EmailTemplateActionModal templateData={selectedTemplate} sendToEmail={sendToEmail} show={showEmailModal} reset={reset} closeModal={()=> setShowEmailModal(false)}/>
        </>
    )
}
export default SelectEmailTemplateDropdown;