import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Link } from 'react-router-dom'
import AuthApi from '../../Api/AuthApi';
import Url from '../../Utils/Routes'
import AuthFooter from '../../Components/footers/LoginFooter';
const SignupSchema = Yup.object().shape({
   old_password: Yup.string().required('Old password is required'),
   password: Yup.string().required('Password is required'),
   password_confirmation: Yup.string().required('Confirm password is required.')
      .test('passwords-match', 'Passwords must match', function (value) {
         return this.parent.password === value
      }),
});
let ChangePassword = (props) => {
   let [apiError, setApiError] = useState(false);
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 roboto-light'>Change Password</h1></div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           old_password: '',
                           password: '',
                           password_confirmation: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                           setSubmitting(true)
                           try {
                              let apiObject = {
                                 user: values
                              }
                              AuthApi.updatePassword(apiObject).then(result => {
                                 if (result && result.status == 200) {
                                    toast.success('Updated successfully.', {
                                       autoClose: 5000,
                                    });
                                    props.navigate(Url.RepoAppDashboard);
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                              }).catch(error => {
                                 setSubmitting(false)
                                 try {
                                    let message = error.response.data.message
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    
                                 }
                              })
                           } catch (error) {
                              setSubmitting(false)
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting }) => (
                           <div className="panel border rounded p-3 panel-default">
                              <div className="panel-body">
                                 <Form className="form" id="login" >
                                    <div className="form-group">
                                       <label htmlFor="session_email">Current Password</label>
                                       <Field className="form-control" placeholder="Password" type="password" name="old_password" />
                                       <ErrorMessage component="small" className='text-danger' name="old_password" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">New Password</label>
                                       <Field className="form-control" placeholder="New Password" type="password" name="password" />
                                       <ErrorMessage component="small" className='text-danger' name="password" />
                                    </div>
                                    <small className='text-muted'>(7 or more characters: 1+ upper case, 1+ lower case letter, and 1+ number. Strongly consider including special character(s))</small>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Confirm New Password</label>
                                       <Field className="form-control" placeholder="Confirm New Password" type="password" name="password_confirmation" />
                                       <ErrorMessage component="small" className='text-danger' name="password_confirmation" />
                                    </div>
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading...." : (<><i className="fa fa-lock"></i>   Change Password</>)}
                                    </button>
                                 </Form>
                              </div>
                           </div>
                        )}
                     </Formik>
                     {/* <div className="alert alert-secondary mt-4"><Link to="/">Back</Link>
                     </div> */}
                  </div>
               </div>

            </div>
         </div>

         <AuthFooter />
      </>
   )
}

export default withNavigation(ChangePassword)