import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import DashboardHeader from '../../Components/Headers/AdminHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
// import CONSTANTS from '../../Utils/Constants';
import { toast } from 'react-toastify';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const SignupSchema = Yup.object().shape({
   email: Yup.string()
      .required('Email is required.').email("Email is not valid."),
});


let ClientDetails = (props) => {
   let { id } = props.params;
   console.log("props", props.location)
   let [user, setUser] = useState({});
   let [email, setEmail] = useState("");
   let [apiLoader, setApiLoader] = useState(false);
   useEffect(() => {
      getClintlistDetails(id);
   }, [])
   let getClintlistDetails = (id) => {
      setApiLoader(true);
      try {
         AppApi.userDetails(id).then(result => {
            console.log(result)
            if (result && result.status == 200) {
               setUser(result.data.data)
            }
            setApiLoader(false);
         }).catch(error => {
            console.log("api error:", error)
            setApiLoader(false);
         })
      } catch (error) {
         console.log("Client list api error :", error)
      }
   }



   return (
      <>
         <DashboardHeader showFilters={false} />
         <div className="p-2 px-4">
            <div className="container-fluid ">
               <div className="row">
                  {apiLoader && (
                     <div className="col-12 py-5 mt-5 text-center">
                        <div className="spinner-border text-secondary" role="status">
                           <span className="visually-hidden">Loading...</span>
                        </div>
                     </div>
                  )}
               </div>
               {!apiLoader && (
                  <div className="row mt-3">
                     <div className="col-md-12">
                        <p className="lead">
                           <Link to={Url.ClientDetails(user.client_id)}>Back </Link>
                           {/* <a href={Url.ClientDetails(user.client_id)}>RepoApp Country Club</a>  */}
                           <span className='ms-5'>Account</span> : {user.email}</p>
                     </div>
                     <div className="col-md-6">
                        <div className="panel panel-default border-info panel-info rounded">
                           <div className="panel-heading bg-info text-white">
                              <h3 className="panel-title">Details </h3>
                           </div>
                           <div className="panel-body p-2">
                              <table className="table table-condensed table-bordered">
                                 <tbody><tr><td>Email:</td><td> {email ? email : user.email} </td></tr>
                                    <tr><td>First Name:</td><td> {user.first_name} </td></tr>
                                    <tr><td>Last Name:</td><td> {user.last_name} </td></tr>
                                    <tr><td>Phone:</td><td>{user.phone} </td></tr>
                                    <tr><td>Password Reset Sent At:</td><td>{user.password_reset_sent_at} </td></tr>
                                    <tr><td>Last Sign-in IP:</td><td> {user.last_signin_ip} </td></tr>
                                    <tr><td>Total Sign-ins:</td><td> {user.sign_in_count} </td></tr>
                                    <tr><td>Last Login:</td><td> {user.last_signin_datetime} </td></tr>
                                    <tr><td>Status Code:</td><td>{user.status_code} </td></tr>
                                    <tr><td>Role Code:</td><td> {user.role_code}</td></tr>
                                    <tr><td>GUI ID:</td><td> {user.gui_id} </td></tr>
                                    <tr><td>Added By:</td><td>{user.created_by_user_id}
                                    </td></tr>
                                    <tr><td>Client ID:</td><td> {user.client_id} </td></tr>
                                    <tr><td>Created At:</td><td> {user.created_at} </td></tr>
                                    <tr><td>Updated At:</td><td> {user.updated_at} </td></tr>
                                 </tbody></table>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="panel panel-default border-info panel-info">
                           <div class="panel-heading bg-info">
                              <h3 class="panel-title text-white">Update Email (reno.castrogiovanni@whitelodging.com)</h3>
                           </div>
                           <div class="panel-body p-3">
                              <Formik
                                 initialValues={{
                                    email: '',
                                 }}
                                 validationSchema={SignupSchema}
                                 onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true)

                                    try {
                                       let data = {
                                          id: user.id,
                                          data: { new_email: values.email },
                                       }
                                       AppApi.updateUseremail(data).then(result => {
                                          if (result && result.status == 200) {
                                             setEmail(values.email)
                                             toast.success('Successfully updated!', {
                                                autoClose: 5000,
                                             });
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                          console.log(result)
                                       }).catch(error => {

                                          setSubmitting(false)
                                          console.log(error.message)
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting }) => (
                                    <Form className="form" id="login" >
                                       <div className="form-group">
                                          <Field placeholder="Email" class="me-4" type="text" name="email" />
                                          <button type={isSubmitting ? "button" : "submit"} id="submit"  >
                                             {isSubmitting ? "Loading...." : "Update"}
                                          </button>
                                       </div>
                                       <ErrorMessage component="small" className='text-danger' name="email" />
                                    </Form>
                                 )}
                              </Formik>
                           </div>
                        </div>
                     </div>
                  </div>
               )
               }
            </div >
         </div >

      </>
   )
}

export default withParams(withNavigation(ClientDetails));