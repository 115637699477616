import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import ExistingDisposition from '../../Components/Forms/Dispositions/ExistingDisposition';
import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation1 = Yup.object().shape({
   disposition_description: Yup.string().min(2, 'Too Short!').required('Disposition description is required.'),
});
const FormValidation2 = Yup.object().shape({
   disposition_description: Yup.string().min(2, 'Too Short!').required('Disposition description is required.'),
   name: Yup.string().min(2, 'Too Short!').required('Updated name is required.'),
});
let Venues = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   let [dataHidden, setDataHidden] = useState([]);
   let [disposition_require_enabled, setDisposition_require_enabled] = useState("");
   useEffect(() => {
      getData();
      getHiddenData();
      getHiddenFileData();
   }, [])
   let getData = async () => {
      try {
         AppApi.getDispositions().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getHiddenData = async () => {
      try {
         AppApi.getHiddenDispositions().then(res => {
            if (res.status == 200) {
               setDataHidden(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let getHiddenFileData = async () => {
      try {
         AppApi.getHiddenFileData().then(res => {
            // console.log("🚀 ~ file: Index.js ~ line 80 ~ AppApi.getHiddenFileData ~ res", res)
            if (res.status == 200) {
               setDisposition_require_enabled(res.data.disposition_require_enabled);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let getHiddenFileUpdate = async () => {
      try {
         setDisposition_require_enabled(!disposition_require_enabled);
         AppApi.UpdateClientAccountOptions({ disposition_require_enabled: !disposition_require_enabled }).then(res => {
            // console.log("🚀 ~ file: Index.js ~ line 80 ~ AppApi.getHiddenFileData ~ res", res)
            if (res.status == 200) {
               setDisposition_require_enabled(res.data.disposition_require_enabled);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }

   let updateOrder = (data) => {
      setData(data)
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className="container-fluid mt-3">
                     <div className="row">
                        <div className="col-md-5">
                           <div class="form-group">
                              <div class="checkbox checkbox-primary">
                                 <input type="checkbox" class="  me-1 " onClick={() => getHiddenFileUpdate()} checked={disposition_require_enabled} />
                                 <label for="disposition_require_enabled">Make Disposition Field Required</label>
                              </div>
                           </div>
                           <div class="panel  panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Add Disposition</div>
                              </div>
                              <div class="panel-body p-2">
                                 <Formik
                                    initialValues={{
                                       disposition_description: "",
                                    }}
                                    validationSchema={FormValidation1}
                                    onSubmit={(values, { setSubmitting }) => {
                                       setSubmitting(true);
                                       try {
                                          AppApi.addDispositions(values).then(result => {
                                             if (result && result.status == 201) {

                                                toast.success('Created successfully.', {
                                                   autoClose: 5000,
                                                });
                                                setData([...data, result.data])
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0]
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form >

                                          <div className="form-group">
                                             <label  >Disposition Name</label>
                                             <Field className="form-control  " placeholder="Disposition name" type="text" name="disposition_description" />
                                             <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                                <button class="btn btn-success btn-sm"  ><i class="fa fa-check me-1"></i> Save</button>
                                             </span>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>
                              </div>
                           </div>
                           <div class="panel panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Delete Disposition</div>
                              </div>
                              <div class="panel-body p-2 ">
                                 <div class="alert alert-warning">
                                    <i class="fa fa-exclamation-triangle"></i> Only dispositions that aren't referenced by item records can be deleted.
                                 </div>
                                 <Formik
                                    initialValues={{
                                       disposition_description: "",
                                    }}
                                    validationSchema={FormValidation1}
                                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                       setSubmitting(true);
                                       try {
                                          AppApi.deleteDispositions(values.disposition_description).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Deleted successfully.', {
                                                   autoClose: 5000,
                                                });
                                                setData(data.filter(data => data.id != values.disposition_description));
                                                setFieldValue("disposition_description", "")
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0]
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form>
                                          <div className="form-group">
                                             <select class="form-control "
                                                value={values.disposition_description}
                                                onChange={(event) => {
                                                   setFieldValue("disposition_description", event.target.value);
                                                }}>
                                                <option value="" selected="selected">-- Pick A Disposition --</option>
                                                {data.map((data, index) => {
                                                   return (
                                                      <option key={data.id} value={data.id}>{data.disposition_description}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                                <button class="btn btn-danger btn-sm"><i class="fa fa-trash me-1"></i> Delete</button>
                                             </span>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>
                                 <form class="ng-pristine ng-invalid ng-invalid-required">
                                    <div class="form-group">

                                    </div>
                                 </form>

                              </div>
                           </div>
                           <div class="panel panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Hide Disposition</div>
                              </div>
                              <div class="panel-body p-2">
                                 <div class="alert alert-warning">
                                    <i class="fa fa-exclamation-triangle"></i> If a Disposition is "Hidden" then it will no longer appear in the drop down for users to use.
                                 </div>
                                 <Formik
                                    initialValues={{
                                       disposition_description: "",
                                    }}
                                    validationSchema={FormValidation1}
                                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                       setSubmitting(true);
                                       try {
                                          AppApi.hideOrUnHide(values.disposition_description).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Updated successfully.', {
                                                   autoClose: 5000,
                                                });
                                                setData(data.filter(data => data.id != values.disposition_description));
                                                setDataHidden([...dataHidden, result.data]);
                                                setFieldValue("disposition_description", "")
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             
                                             setSubmitting(false)
                                             try {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form>
                                          <div className="form-group">
                                             <select class="form-control "
                                                value={values.disposition_description}
                                                onChange={(event) => {
                                                   setFieldValue("disposition_description", event.target.value);
                                                }}>
                                                <option value="" selected="selected">-- Pick A Disposition --</option>
                                                {data.map((data, index) => {
                                                   return (
                                                      <option key={data.id} value={data.id}>{data.disposition_description}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span >
                                                <button class="btn btn-danger btn-sm"><i class="fa fa-lock"></i> Hide</button>
                                             </span>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>
                              </div>
                           </div>
                           {/* *******************unhide ********** */}
                           {dataHidden.length > 0 && (
                              <div class="panel panel-default panel-info  "  >
                                 <div class="panel-heading">
                                    <div class="panel-title">Unhide Disposition</div>
                                 </div>
                                 <div class="panel-body p-2">
                                    <div class="alert alert-warning">
                                       <i class="fa fa-exclamation-triangle"></i> If we Unhide a Disposition it will appear again in the drop downs for users.
                                    </div>
                                    <Formik
                                       initialValues={{
                                          disposition_description: "",
                                       }}
                                       validationSchema={FormValidation1}
                                       onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                          setSubmitting(true);
                                          try {
                                             AppApi.hideOrUnHide(values.disposition_description).then(result => {
                                                if (result && result.status == 200) {
                                                   toast.success('Updated successfully.', {
                                                      autoClose: 5000,
                                                   });
                                                   setDataHidden(dataHidden.filter(data => data.id != values.disposition_description));
                                                   setData([...data, result.data]);
                                                   setFieldValue("disposition_description", "")
                                                } else {
                                                   toast.error('Server error.', {
                                                      autoClose: 5000,
                                                   });
                                                }
                                                setSubmitting(false)
                                             }).catch(error => {
                                                setSubmitting(false)
                                                try {
                                                   let message = error.response.data.errors[0]
                                                   toast.error(message ? message : 'Server Error!', {
                                                      autoClose: 5000,
                                                   });
                                                } catch (error) {
                                                   
                                                }
                                             })
                                          } catch (error) {
                                             setSubmitting(false)
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                             console.log("server error", error.message)
                                          }

                                       }}
                                    >
                                       {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                          <Form>
                                             <div className="form-group">
                                                <select class="form-control "
                                                   value={values.disposition_description}
                                                   onChange={(event) => {
                                                      setFieldValue("disposition_description", event.target.value);
                                                   }}>
                                                   <option value="" selected="selected">-- Pick A Disposition --</option>
                                                   {dataHidden.map((data, index) => {
                                                      return (
                                                         <option key={index} value={data.id}>{data.disposition_description}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                             </div>
                                             {isSubmitting ? "Loading..." : (
                                                <span >
                                                   <button class="btn btn-danger btn-sm"><i class="fa fa-unlock me-1"></i> Unhide</button>
                                                </span>
                                             )}
                                          </Form>
                                       )}
                                    </Formik>
                                 </div>
                              </div>
                           )}

                           {data.length > 0 && (
                              <div class="panel panel-default panel-info">
                                 <div class="panel-heading">
                                    <div class="panel-title">Rename Disposition</div>
                                 </div>
                                 <div class="panel-body p-2">
                                    <div class="alert alert-warning">
                                       <i class="fa fa-exclamation-triangle"></i> Updating a disposition's description will be reflected in <strong>all current item records</strong> that reference the updated disposition.
                                    </div>
                                    <Formik
                                       initialValues={{
                                          disposition_description: "",
                                          name: ""
                                       }}
                                       validationSchema={FormValidation2}
                                       onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                          setSubmitting(true);
                                          try {
                                             let object = {
                                                data: { disposition_description: values.name },
                                                id: values.disposition_description
                                             }
                                             AppApi.updateDispositions(object).then(result => {
                                                if (result && result.status == 200) {
                                                   toast.success('Updated successfully.', {
                                                      autoClose: 5000,
                                                   });
                                                   let index = data.findIndex(data => data.id == values.disposition_description);
                                                   let oldData = [...data]
                                                   oldData[index].disposition_description = values.name
                                                   setData(oldData);
                                                   setFieldValue("disposition_description", "")
                                                   setFieldValue("name", "")
                                                } else {
                                                   toast.error('Server error.', {
                                                      autoClose: 5000,
                                                   });
                                                }
                                                setSubmitting(false)
                                             }).catch(error => {
                                                setSubmitting(false)
                                                try {
                                                   let message = error.response.data.errors[0]
                                                   toast.error(message ? message : 'Server Error!', {
                                                      autoClose: 5000,
                                                   });
                                                } catch (error) {
                                                   
                                                }
                                             })
                                          } catch (error) {
                                             setSubmitting(false)
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                             console.log("server error", error.message)
                                          }

                                       }}
                                    >
                                       {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                          <Form>
                                             <div className="form-group">
                                                <select class="form-control "
                                                   value={values.disposition_description}
                                                   onChange={(event) => {
                                                      setFieldValue("disposition_description", event.target.value);
                                                   }}>
                                                   <option value="" selected="selected">-- Pick A Disposition --</option>
                                                   {data.map((data, index) => {
                                                      return (
                                                         <option key={index} value={data.id}>{data.disposition_description}</option>
                                                      )
                                                   })}
                                                </select>
                                                <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                             </div>
                                             <div className="form-group">
                                                <label>New Name</label>
                                                <Field className="form-control  rounded-0" placeholder="New disposition name" type="text" name="name" />
                                                <ErrorMessage component="small" className='text-danger' name="name" />
                                             </div>
                                             {isSubmitting ? "Loading..." : (
                                                <span >
                                                   <button class="btn btn-success btn-sm"><i class="fa fa-check me-1"></i> Save</button>
                                                </span>
                                             )}
                                          </Form>
                                       )}
                                    </Formik>

                                 </div>
                              </div>
                           )}
                        </div>
                        <div class="offset-md-2 col-md-5 ng-scope">
                           <ExistingDisposition data={data} updateOrder={updateOrder} />
                        </div>
                     </div>
                  </div>

               )}
            </>)}

         <br></br>
         <br></br>
         <br></br>
         <br></br>
         <br></br>
      </>
   )
}

export default withNavigation(Venues)