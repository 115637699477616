import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import AppApi from '../../Api/AppApi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const SignupSchema = Yup.object().shape({
    business_name: Yup.string().required('Business name is required.'),
    street1: Yup.string().required('Street is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    country_code: Yup.string().required('Country is required.'),
    phone: Yup.string().required('Phone is required.'),
    primary_contact_first_name: Yup.string().required('First name is required.'),
    primary_contact_last_name: Yup.string().required('Last name is required.'),
    primary_contact_email: Yup.string().required('Email is required.').email("Email is not valid."),
});
let UpdateAccount = (props) => {
    let [apiLoading, setApiLoading] = useState(false);
    return (
        <>
            <div class="panel panel-default border">
                <div class="panel-heading">
                    <h3 class="panel-title">Update Account</h3>
                </div>
                <div class="panel-body p-2">
                    {/* ********************************************************************** */}
                    <Formik
                        initialValues={{
                            business_name: props.client.business_name,
                            street1: props.client.street1,
                            street2: props.client.street2,
                            city: props.client.city,
                            state: props.client.state,
                            country_code: props.client.country_code,
                            phone: props.client.phone,
                            primary_contact_first_name: props.client.primary_contact_first_name,
                            primary_contact_last_name: props.client.primary_contact_last_name,
                            primary_contact_email: props.client.primary_contact_email,
                            notes: props.client.notes,
                            shipment_commission: props.client_account_options.shipment_commission,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            let { shipment_commission, ...client } = values;
                            let data = {
                                id: props.client.id,
                                data: { client: {...client, client_account_option_attributes: { shipment_commission: shipment_commission }}},
                            }
                            try {
                                AppApi.updateClient(data).then(res => {
                                    console.log(res.data)
                                    if (res && res.status == 200) {
                                        Swal.fire(
                                            'Success!',
                                            'Profile updated.',
                                            'success'
                                        )
                                    } else {
                                        Swal.fire(
                                            'Failed!',
                                            'Profile not updated.',
                                            'error'
                                        )
                                    }
                                    setSubmitting(false);
                                }).catch(error => {
                                    setSubmitting(false);

                                    try {
                                        let message = error.response.data.errors[0] + "."
                                        toast.error(message ? message : 'Server Error!', {
                                            autoClose: 5000,
                                        });
                                    } catch (error) {
                                        
                                        toast.error('Server error.', {
                                            autoClose: 5000,
                                        });
                                    }
                                    
                                })
                            } catch (error) {
                                console.log("found error on profile updating:",)
                                Swal.fire(
                                    'Network Error!',
                                    'Connection error.',
                                    'error'
                                )
                            }
                        }}
                    >
                        {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                            <Form class="form-horizontal formEl" id="edit_client_10" autocomplete="off" >

                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_user_limit">Business Name</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field class="" type="text" name="business_name" />
                                        <ErrorMessage component="small" className='text-danger d-block' name="business_name" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_start_date">Street1</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field type="text" name="street1" />
                                        <ErrorMessage component="small" className='text-danger d-block' name="street1" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="street2">Street2</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field type="text" name="street2" />
                                        <ErrorMessage component="small" className='text-danger d-block' name="street2" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="city">City</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field type="text" name="city" />
                                        <ErrorMessage component="small" className='text-danger d-block' name="city" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="city">State</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field type="text" name="state" />
                                        <ErrorMessage component="small" className='text-danger d-block' name="state" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label" for="client_plan_trial_end_date">Country</label>
                                    </div>
                                    <div class=" ms-3">
                                        <select
                                            onChange={(event) => {
                                                setFieldValue("country_code", event.target.value)
                                            }} className='p-1'>
                                            <option value="">Country code</option>
                                            <option value="US">UNITED STATES</option>
                                            <option value="CA">CANADA</option>
                                            <option value="PR">PUERTO RICO</option>
                                            <option value="VI">VIRGIN ISLANDS - U.S.</option>
                                            <option value="GB">UNITED KINGDOM</option>
                                            <option value="VG">VIRGIN ISLANDS - BRITISH</option>
                                            <option value="AU">AUSTRALIA</option>
                                            <option value="NO">NORWAY</option>
                                            <option value="NZ">NEW ZEALAND</option>
                                            <option value="TT">TRINIDAD AND TOBAGO</option>
                                            <option value="BM">BERMUDA</option>
                                            <option value="IE">IRELAND</option>
                                            <option value="UM">UNITED STATES MINOR OUTLYING ISLANDS</option>
                                            <option value="AW">ARUBA</option>
                                            <option value="BB">BARBADOS</option>
                                            <option value="CN">CHINA</option>
                                            <option value="SE">SWEDEN</option>
                                            <option value="TC">TURKS AND CAICOS</option>
                                        </select>
                                        <ErrorMessage component="small" className='text-danger' name="country_code" />
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <div>
                                        <label className=" control-label required" for="client_plan_item_limit">Phone</label>
                                    </div>
                                    <div className=" ms-3">
                                        <Field className="" type="text" name="phone" />
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_claim_limit">Primary Contact First Name</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field class="" type="text" name="primary_contact_first_name" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_photo_limit">Primary Contact Last Name</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field class="" type="text" name="primary_contact_last_name" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_photo_limit">Primary Contact Email</label>
                                    </div>
                                    <div class=" ms-3">
                                        <Field class="" type="text" name="primary_contact_email" />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class=" control-label required" for="client_plan_photo_limit">Notes</label>
                                    </div>
                                    <div class=" ms-3">
                                        <textarea class="" onChange={(event) => {
                                            setFieldValue("notes", event.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center">
                                    <div>
                                        <label class="control-label required" for="shipment_commission">Shipment Commission</label>
                                    </div>
                                    <div class="ms-3">
                                        <Field class="" type="number" name="shipment_commission" />
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" class="btn btn-primary" >
                                        {isSubmitting ? "Loading...." : "Update Account"}
                                    </button>
                                </div>

                            </Form>

                        )}
                    </Formik>
                    {/* ********************************************************************** */}

                </div>
            </div>
        </>
    )
}

export default UpdateAccount

