import React, { useState, useEffect } from 'react';
import ItemsApi from '../../Api/ItemsApi'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ToolTipContainer from './ToolTip';
import Swal from 'sweetalert2'
import AppApi from '../../Api/AppApi';
import ShipmentDetails from '../Shipment/ShipmentDetails';
const FormValidation = Yup.object().shape({
    claim_email: Yup.string().required('Email is required.').email("Email is not valid."),
    length: Yup.number().required('Length is required'),
    height: Yup.number().required('Height is required'),
    weight: Yup.number().required('Weight is required.').typeError("Please enter valid weight.").max(108, "Max weight allowed: 108 lbs.").min(0.0000001, "Weight is required."),
    width: Yup.number().required('Width is required').min(0.5, "Width must be greater than 0.5 inches."),
});
let Parcel = ({ id, email, updateEditMode, status }) => {
    let [data, setData] = useState({});
    let [shipment_status, setShipment_status] = useState("");
    let [form, setForm] = useState(true);
    let [disable, setDisable] = useState(false);
    let [addressData, setAddressData] = useState([])
    let [addressId, setAddressId] = useState(0)
    let [defaultAddressId, setDefaultAddressId] = useState(0);
    let [apiLoading, setApiLoading] = useState(true);
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        setForm(false)
        getShipmentFromAddress()
        setTimeout(() => {
            setForm(true)
        }, 1);
    }, [email])

    let getShipmentFromAddress = async (defaultOnSave) => {
        try {
            let data = await AppApi.getShipmentFromAddress().then(res => {
                if (res.status == 200 && res?.data?.from_address) {
                    setAddressData(res?.data?.from_address)
                    setDefaultAddressId(res?.data?.from_address.find(data => data.is_default)?.id);
                    return res?.data?.from_address
                } else {
                    return {}
                }
            }).catch(error => {
                ;
                return {}
            })
            return data
        } catch (error) {
            
            return {}
        }
    }

    let getData = async () => {
        try {
            ItemsApi.getItemParcelInformation(id).then(res => {
                if (res.status == 200) {
                    let data = res.data.data
                    if (data.shipment_status != null) {
                        setDisable(true)
                    }
                    if (data.shipment_status == "paid" || data.shipment_status == "unpaid") {
                        updateEditMode();
                    }
                    setShipment_status(data.shipment_status);
                    setData(data);
                    setAddressId(data?.from_address?.id)
                } else {
                    toast.error(res.data?.message, {
                        autoClose: 5000,
                    });
                }
                setApiLoading(false)
            }).catch(error => {
                setApiLoading(false)
                toast.error('Server error.', {
                    autoClose: 5000,
                });
            })
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }

    const handleSelectedAddress = (id) => {
        setAddressId(id)
    };

    return (
        <>
            {(status == "R" && shipment_status == null) ? (
                <div className="alert alert-info">
                    No shipment information against this item.
                </div>
            ) : (
                <>
                    {apiLoading ? (
                        <div className="page-center-loader">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>

                            {shipment_status != 'paid' && (
                                <>
                                    {form && (
                                        <Formik
                                            initialValues={{
                                                claim_email: email ? email : data?.claim_email ? data?.claim_email : "",
                                                weight: data?.parcel.weight ? parseFloat(data?.parcel.weight) : 0.0,
                                                width: data?.parcel.width ? parseFloat(data?.parcel.width) : 0.0,
                                                length: data?.parcel.length ? parseFloat(data?.parcel.length) : 0.0,
                                                height: data?.parcel.height ? parseFloat(data?.parcel.height) : 0.0,
                                            }}
                                            validationSchema={FormValidation}
                                            onSubmit={(values, { setSubmitting, setFieldError }) => {
                                                setSubmitting(false);
                                                if ((values.length + (2 * values.width) + (2 * values.height)) <= 108) {
                                                    Swal.fire({
                                                        title: 'Are you sure? ',
                                                        text: 'This will send an email to item owner, Continue?',
                                                        showDenyButton: false,
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Yes',
                                                    }).then((result) => {
                                                        /* Read more about isConfirmed, isDenied below */
                                                        if (result.isConfirmed) {
                                                            setSubmitting(true);
                                                            try {
                                                                let apiObjects = {
                                                                    id: id,
                                                                    data: {
                                                                        claim_email: values.claim_email,
                                                                        shipping_address_id: addressId || defaultAddressId,
                                                                        parcel: {
                                                                            weight: values.weight ? parseFloat(values.weight) : 0,
                                                                            width: values.width ? parseFloat(values.width) : 0,
                                                                            length: values.length ? parseFloat(values.length) : 0,
                                                                            height: values.height ? parseFloat(values.height) : 0,
                                                                        }
                                                                    }
                                                                }
                                                                ItemsApi.updateItemParcelInformation(apiObjects).then(result => {
                                                                    if (result && result.status == 200) {
                                                                        toast.success('Email sent to owner.', {
                                                                            autoClose: 5000,
                                                                        });
                                                                        setShipment_status("draft")
                                                                    } else {
                                                                        toast.error(result.data?.message, {
                                                                            autoClose: 5000,
                                                                        });
                                                                    }
                                                                    setSubmitting(false)
                                                                }).catch(error => {
                                                                    setSubmitting(false)
                                                                    try {
                                                                        let message = error?.response?.data?.message
                                                                        toast.error(message ? message : 'Server Error!', {
                                                                            autoClose: 5000,
                                                                        });
                                                                    } catch (error) {
                                                                        
                                                                    }
                                                                })
                                                            } catch (error) {
                                                                setSubmitting(false)
                                                                toast.error('Server error.', {
                                                                    autoClose: 5000,
                                                                });
                                                                console.log("server error", error)
                                                            }
                                                        } else if (result.isDenied) {
                                                            setSubmitting(false);
                                                        }
                                                    })
                                                } else {
                                                    toast.error('Dimensions exceed maximum limits. Please try again.', {
                                                        autoClose: 5000,
                                                    });
                                                }

                                            }}
                                        >
                                            {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                                <Form >
                                                    <div className="panel border panel-default">
                                                        <div className="panel-heading d-flex justify-content-between">
                                                            <div>
                                                                <h3 className="panel-title">Shipping Label
                                                                    <ToolTipContainer
                                                                        view=""
                                                                        index={1}
                                                                        placement="right"
                                                                        icon="text-info"
                                                                        title="Shipping Label"
                                                                        des="Please enter package details and request that the owner pay for a shipping label"
                                                                    /></h3>
                                                            </div>
                                                            <div>
                                                                {shipment_status && (
                                                                    <span class={`label ${shipment_status == "draft" && "label-warning"} ${shipment_status == "unpaid" && "label-danger"} ${data.shipment_status == "paid" && "label-success"}`}>Status: {shipment_status}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="panel-body p-2 px-4">
                                                            <div className="row mt-2">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="font-weight-bold  ">Email</label>
                                                                        <Field type="email" disabled={true} className='form-control rounded-0' name="claim_email" />
                                                                        <ErrorMessage component="small" className='text-danger' name="claim_email" />
                                                                    </div>

                                                                </div>
                                                                <div className="col-12">
                                                                    <div class="form-group">
                                                                        <label class="control-label" >From Address</label>
                                                                        <select name="" id="select-address" className='form-control' value={addressId || defaultAddressId}
                                                                            onChange={(e) => handleSelectedAddress(e.target.value)}
                                                                        >
                                                                            <option hidden disabled selected value>Select an Option</option>
                                                                            {addressData.map((data) => {
                                                                                return (
                                                                                    <option key={data.id} value={data.id}>
                                                                                        {`${data?.name} - ${data?.street1}${data.is_default ? ' (Default)' : ''}`}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <hr></hr>
                                                                    <h4>Package Details</h4>
                                                                    <label className='text-info'> L+2W+2H must be less than 108 inches</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="font-weight-bold required  ">Weight (lbs)</label>
                                                                        <Field type="number" className='form-control rounded-0' name="weight" />
                                                                        <ErrorMessage component="small" className='text-danger' name="weight" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="font-weight-bold  ">Length (inches)</label>
                                                                        <Field type="number" className='form-control rounded-0' name="length" />
                                                                        <ErrorMessage component="small" className='text-danger' name="length" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="font-weight-bold  ">Height (inches)</label>
                                                                        <Field type="number" className='form-control rounded-0' name="height" />
                                                                        <ErrorMessage component="small" className='text-danger' name="height" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="font-weight-bold">Width (inches)</label>
                                                                        <Field type="number" className='form-control rounded-0' name="width" />
                                                                        <ErrorMessage component="small" className='text-danger' name="width" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer d-flex justify-content-center">
                                                            {shipment_status == 'unpaid' ? (
                                                                <div className="alert alert-warning">
                                                                    Package is not editable anymore, as the customer has filled in their details.
                                                                </div>
                                                            ) : (
                                                                <button className="btn btn-success me-1" disabled={isSubmitting} type="submit"> {isSubmitting ? "Loading..." : "Request Shipping Label"} </button>
                                                            )}

                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>

                                    )}
                                </>
                            )}

                            {shipment_status == 'paid' && (
                                <div className="alert alert-success">
                                    Shipment label is paid for. Item ready to ship.
                                </div>
                            )}
                            {shipment_status == 'paid' && data?.parcel.shipment_id && (
                                <ShipmentDetails id={data?.parcel.shipment_id} />
                            )}

                        </>

                    )}
                </>
            )
            }


        </>
    )
}

export default Parcel

