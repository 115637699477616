import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi'
import { withNavigation } from './../../Utils/Navigater';
import Url from "../../Utils/Routes"
import { Link } from 'react-router-dom';
import AuthFooter from '../../Components/footers/LoginFooter';

const SignupSchema = Yup.object().shape({
   business_name: Yup.string().required('Business name is required.'),
   street1: Yup.string().required('Address is required.'),
   city: Yup.string().required('City is required.'),
   state: Yup.string().required('State is required.'),
   postal_code: Yup.string().required('Postal code is required.'),
   country_code: Yup.string().required('Country is required.'),
   phone: Yup.string().required('Phone number is required.'),
   // trial_code: Yup.string().required('Trial code is required.'),
   first_name: Yup.string().required('First name is required.'),
   last_name: Yup.string().required('Last is required.'),
   email: Yup.string().required('Email is required.').email("Email is not valid."),
   password: Yup.string().required('Password is required'),
   password_confirmation: Yup.string().required('Confirm password is required.')
      .test('passwords-match', 'Passwords must match', function (value) {
         return this.parent.password === value
      }),
   terms_of_service: Yup.bool().oneOf([true], "You must accept the terms and  conditions"),
});
let AuthSignup = (props) => {
   let [countries, setCountries] = useState([])
   useEffect(() => {
      countriesFun()
   }, [])
   let countriesFun = async () => {
      try {
         AuthApi.getCountries().then(res => {
            if (res.status == 200) {
               setCountries(res.data.data)
            }
         }).catch(error => {
            
         })
      } catch (error) {
         
      }
   }
   let [apiError, setApiError] = useState([]);
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 roboto-light'>Sign Up Your Organization</h1></div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           business_name: "",
                           street1: "",
                           street2: "",
                           city: "",
                           state: "",
                           postal_code: "",
                           country_code: "",
                           phone: "",
                           trial_code: "",
                           first_name: "",
                           last_name: "",
                           email: "",
                           password: "",
                           password_confirmation: "",
                           terms_of_service: false,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                           setSubmitting(true)
                           let data = { ...values }
                           console.log(data)
                           data.users_attributes = []
                           data.users_attributes.push({
                              first_name: values.first_name,
                              last_name: values.last_name,
                              email: values.email,
                              password: values.password,
                              password_confirmation: values.password_confirmation
                           })
                           delete data.first_name
                           delete data.last_name
                           delete data.email
                           delete data.password
                           delete data.password_confirmation
                           console.log(data);
                           let client = { client: data }
                           setApiError([])
                           try {
                              AuthApi.signup(client).then(result => {
                                 console.log(result)
                                 setSubmitting(false)
                                 if (result && result.status == 200 && result.data.token) {
                                    // localStorage.setItem("token", result.data.token);
                                    // localStorage.setItem("email", result.data.client.primary_contact_email);
                                    toast.success('Your account has been created.Please login to your account.', {
                                       autoClose: 3000,
                                    });
                                    props.navigate(Url.Login);
                                 }
                              }).catch(error => {
                                 setSubmitting(false)
                                 let message = error.response.data.errors[0];
                                 let objects = message.replace("Validation failed:", "").split(",")
                                 console.log('api error: objects', objects)
                                 setApiError(objects)
                              })
                           } catch (error) {
                              setSubmitting(false)
                              console.log("signup erros: ", error);
                              setApiError(["Server error."])
                           }

                        }}
                     >
                        {({ errors, touched, isSubmitting, setFieldValue }) => (
                           <Form className="form" id="login" >
                              <div className="panel border rounded p-3 panel-default">
                                 <div className="panel-body">
                                    <p><strong className="text-danger">Organization / Business Information</strong></p>
                                    <div className="form-group">
                                       <label className="required" htmlFor="business_name">Business name</label>
                                       <Field className="form-control" type="text" name="business_name" />
                                       <ErrorMessage component="small" className='text-danger' name="business_name" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="street1">Street1 </label>
                                       <Field className="form-control" type="text" name="street1" />
                                       <ErrorMessage component="small" className='text-danger' name="street1" />
                                    </div>
                                    <div className="form-group">
                                       <label className="" htmlFor="street2">Street2 </label>
                                       <Field className="form-control" type="text" name="street2" />
                                       <ErrorMessage component="small" className='text-danger' name="street2" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="city">City </label>
                                       <Field className="form-control" type="text" name="city" />
                                       <ErrorMessage component="small" className='text-danger' name="city" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="state">State </label>
                                       <Field className="form-control" type="text" name="state" />
                                       <ErrorMessage component="small" className='text-danger' name="state" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="postal_code">Postal code </label>
                                       <Field className="form-control" type="text" name="postal_code" />
                                       <ErrorMessage component="small" className='text-danger' name="postal_code" />
                                    </div>

                                    <div className="form-group">
                                       <label className="required" htmlFor="country_code">Country </label>
                                       <select
                                          onChange={(event) => {
                                             setFieldValue("country_code", event.target.value)
                                          }} className='form-control'>
                                          <option value="">Select Country </option>
                                          {countries.map((data, index) => {
                                             return (
                                                <option value={data.country_code}>{data.country_name}</option>
                                             )
                                          })}
                                       </select>
                                       <ErrorMessage component="small" className='text-danger' name="country_code" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="phone">Phone </label>
                                       <Field className="form-control" type="text" name="phone" />
                                       <ErrorMessage component="small" className='text-danger' name="phone" />
                                    </div>


                                 </div>
                              </div>
                              <div className="panel border rounded p-3 mt-4 panel-default">
                                 <div className="panel-body">
                                    <p><strong className="text-danger">Login information</strong></p>

                                    <div className="form-group">
                                       <label className="required" htmlFor="first_name">First name</label>
                                       <Field className="form-control" type="text" name="first_name" />
                                       <ErrorMessage component="small" className='text-danger' name="first_name" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="last_name">Last name </label>
                                       <Field className="form-control" type="text" name="last_name" />
                                       <ErrorMessage component="small" className='text-danger' name="last_name" />
                                    </div>
                                    <div className="form-group">
                                       <label className="" htmlFor="email">Email </label>
                                       <Field className="form-control" type="text" name="email" />
                                       <ErrorMessage component="small" className='text-danger' name="email" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="password">Password </label>
                                       <Field className="form-control" type="password" name="password" />
                                       <ErrorMessage component="small" className='text-danger' name="password" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="password_confirmation">Re-type Password </label>
                                       <Field className="form-control" type="password" name="password_confirmation" />
                                       <ErrorMessage component="small" className='text-danger' name="password_confirmation" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required" htmlFor="trial_code">Trial code </label>
                                       <Field className="form-control" type="text" name="trial_code" />
                                       <ErrorMessage component="small" className='text-danger' name="trial_code" />
                                    </div>
                                    <div className="form-group">
                                       <label className="required me-2 d-block" htmlFor="client_terms_of_service ">Accept Terms?</label>
                                       <Field type="checkbox" name="terms_of_service" /> I accept the <a href="/terms">Terms of Service</a>
                                       <ErrorMessage component="small" className='text-danger d-block' name="terms_of_service" />
                                    </div>
                                    {apiError && apiError.length > 0 && (
                                       <div className='alert alert-danger'>
                                          <ul className='text-danger'>
                                             {apiError && apiError.map((data, index) => {
                                                return (
                                                   <li>{data}</li>
                                                )
                                             })}
                                          </ul>
                                       </div>
                                    )}
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading..." : (<><i className="fa fa-lock text-white"></i> Sign up</>)}
                                    </button>

                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>

                  </div>
                  <div className="col-md-6">
                     <div className="alert alert-warning pt-4">
                        <h3 className='roboto-light text-danger2'>Please <Link to={Url.ContactUs}>contact us</Link> for a trial code.</h3>
                        <h5>Try RepoApp for 14 days, risk free.</h5>
                        <ul className="list-unstyled">
                           <li><i className="fa fa-check-circle"></i> Add other users</li>
                           <li><i className="fa fa-check-circle"></i> No credit card</li>
                           <li><i className="fa fa-check-circle"></i> Cancel anytime</li>
                        </ul>
                     </div>
                  </div>
               </div>

            </div>
         </div>
         <AuthFooter />

      </>
   )
}

export default withNavigation(AuthSignup)