import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from './../../Utils/Navigater';
import DashboardHeader from '../../Components/Headers/AdminHeader';
import AppApi from '../../Api/AppApi';
import ShipmentApi from '../../Api/ShipmentApi';
import Url from '../../Utils/Routes';
import moment from 'moment'
import { convertOuncesToPounds } from "Utils";
import Pagination from '../../Components/Pagination/Pagination';

let DashBoard = (props) => {
   let [clientList, setClientList] = useState([]);
   let [apiLoader, setApiLoader] = useState(false);
   let [filters, setFilters] = useState({});
   let [page, setPage] = useState(1);
   let [limit, setLimit] = useState(50);
   let [totalPages, setTotalPages] = useState(0);

   useEffect(() => {
      getClientList();
   }, [page])
   let getClientList = () => {
      setApiLoader(true);
      try {
         ShipmentApi.adminShipmentList(page,limit).then(result => {
            console.log(result)
            if (result && result.status == 200) {
               setTotalPages(result.data.total_pages);
               setClientList(result.data.data);
               setApiLoader(false);
            }
         }).catch(error => {
            console.log("api error:", error)
         })
      } catch (error) {
         console.log("Client list api error :", error)
      }
   }

   let updateFiltes = (data) => {
      setFilters(data)
   }
   let businessNameSearch = async (data) => {
      if (data.data) {
         setApiLoader(true);
         if (!data.email) {
            try {
               AppApi.busNameSearch(data.data).then(result => {
                  console.log(result)
                  if (result && result.status == 200) {
                     if (result.data.data) {
                        setClientList(result.data.data)
                     } else {
                        setClientList([])
                     }
                     setApiLoader(false);
                  }
               }).catch(error => {
                  setClientList([])
                  console.log("api error:", error)
               })
            } catch (error) {
               console.log("Client list api error :", error)
            }
         } else {
            AppApi.emailSearch(data.data).then(result => {
               console.log(result)
               if (result && result.status == 200) {
                  if (result.data.data) {
                     setClientList(result.data.data)
                  } else {
                     setClientList([])
                  }
                  setApiLoader(false);
               }
            }).catch(error => {
               setClientList([])
               setApiLoader(false);
               console.log("api error:", error)
            })
         }
      } else {
         setApiLoader(false);
         getClientList()
      }

   }

   return (
      <>
         <DashboardHeader updateFiltes={updateFiltes}
            businessNameSearch={businessNameSearch}
            showFilters={false} />
         <div className="p-2">
            <div className="container-fluid border">
               <div className="row">
                  <div className="col-12">
                     <div className="table-responsive">
                        <table className="table table-bordered " >
                           <thead>
                              <tr>
                                 <th>#</th>
                                 <th>ID</th>
                                 <th>Status</th>
                                 <th>Label</th>
                                 <th>Tracking  </th>
                                 <th>Parcel</th>
                                 <th>From Address</th>
                                 <th>To Address</th>
                                 <th stlye={{ minWidth: "150px"}}>Cost</th>
                                 <th>Date</th>
                              </tr>
                           </thead>
                           <tbody>
                              {apiLoader && (
                                 <tr>
                                    <td colSpan={11} className="text-center">Loading...</td>
                                 </tr>
                              )}
                              {!apiLoader && clientList.length == 0 && (
                                 <tr>
                                    <td colSpan={11} className="text-center">No record found.</td>
                                 </tr>
                              )}
                              {!apiLoader && clientList.length > 0 && clientList.map((data, index) => {
                                 return (
                                    <tr class={data.status == "paid" ? "alert-success" : data.status == "draft" ? "alert-warning" : data.status == "cancelled" ? "alert-disabled": "alert-danger"} key={index}>
                                       <td style= {{maxWidth: 30}}>{index + 1}</td>
                                       <td><nobr><a href={Url.StripeSuccess(data.id)} target="_blank" rel="noopener noreferrer">{data.id}</a></nobr></td>
                                       <td>
                                          {data.status && (
                                             <span className={`label ${data.status == "draft" && "label-warning"} ${data.status == "cancelled" && "label-disabled"} ${data.status == "unpaid" && "label-danger"} ${data.status == "paid" && "label-success"}`}> {data.status}</span>
                                          )}
                                       </td>
                                       <td>
                                          {data?.label_url ? (
                                             <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.label_url}>
                                                <i className='fas fa-link'></i>
                                             </a>) : "N/A"}
                                       </td>
                                       <td>
                                          {data?.tracking_url ? (
                                             <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.tracking_url}>
                                                <i className='fas fa-link'></i>
                                             </a>) : "N/A"}
                                       </td>

                                       <td>
                                          <label> <nobr> Weight: {convertOuncesToPounds(data?.parcel?.weight)} lbs </nobr> </label>
                                          <label> <nobr> Length: {data?.parcel?.length} inches </nobr> </label>
                                          <label> <nobr> Height: {data?.parcel?.height} inches </nobr> </label>
                                          <label> <nobr> Width: {data?.parcel?.width} inches </nobr> </label>
                                       </td>

                                       <td>
                                          Name:{data?.from_address?.name} <br />
                                          Email:{data?.from_address?.email} <br />
                                          Address:{data?.from_address?.street1},{data?.from_address?.street2},{data?.from_address?.city},{data?.from_address?.state},{data?.from_address?.zip},{data?.from_address?.country}
                                       </td>
                                       <td>
                                          {data?.status == "paid" ? (
                                             <>
                                                Name:{data?.to_name} <br />
                                                Email:{data?.to_email} <br />
                                                Phone:{data?.to_phone} <br />
                                                Address:{data?.to_street1},{data?.to_street2},{data?.to_city},{data?.to_state},{data?.to_zip},{data?.to_country}
                                             </>
                                          ) : "N/A"}

                                       </td>
                                       <td style={{ width: "60px" }}>
                                          {data?.status == "paid" ? (
                                             <>
                                                 <label><nobr> Total Cost:{parseFloat(data?.total_amount).toFixed(2)} </nobr></label>
                                                <label>  <nobr>Shipping Cost:{parseFloat(data?.shipping_cost).toFixed(2)}  </nobr>  </label>
                                                  <label> <nobr> Processing fee:{parseFloat(data?.processing_fee).toFixed(2)}  </nobr> </label>
                                                <label>  <nobr> Commission fee:{parseFloat(data?.commission).toFixed(2)}   </nobr> </label> 
                                             </>
                                          ) : "N/A"}

                                       </td>

                                       <td><nobr>  {moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</nobr></td>
                                    </tr>

                                 )
                              })}
                              <tr>
                                 <td colSpan={11} className="text-center">
                                    {
                                       <Pagination totalPages={totalPages} setPage={setPage}></Pagination>
                                    }
                                 </td>
                              </tr>
                              
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
}

export default withNavigation(DashBoard)