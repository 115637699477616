import React, { Component } from 'react'
import Url from '../../Utils/Routes';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
// import QrReader from 'react-qr-scanner'
import QrReader from 'modern-react-qr-reader'
import { toast } from 'react-toastify';
class Index extends Component {
   constructor(props) {
      super(props)
      this.state = {
         delay: 1000,
         apiLoading: false,
         camError: false,
         result: 'No result',
         videoDevices: [],
         selectedDevice: ""
      }

      this.handleScan = this.handleScan.bind(this)
   }

   async componentDidMount() {
      navigator.getUserMedia = navigator.getUserMedia ||
         navigator.webkitGetUserMedia ||
         navigator.mozGetUserMedia;
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      console.log("filted", videoDevices)
      console.log(videoDevices[0].deviceId);
      let fistDevice = videoDevices[0].deviceId;
      this.setState({
         videoDevices,
         selectedDevice: fistDevice
      })
      console.log("f", devices)
      if (navigator.getSources()) {
      } else {
         console.log("getUserMedia not supported");
      }
   }
   handleScan(data) {
      console.log(data)
      if (data) {
         this.setState({
            result: data,
         })
         try {
            let id = data;
            id = id.split("/").pop();
            this.getData(id);
         } catch (error) {
            
         }
      } else {
         console.log(data)
      }

   }
   handleError(err) {
      alert("can not access your camera.")
   }

   getData = async (id) => {
      try {
         this.setState({
            apiLoading: true,
         })
         ItemsApi.itemsDetails(id).then(res => {
            if (res.status == 200) {
               this.props.navigate(Url.EditNewItems(id));
            }
            this.setState({
               apiLoading: false,
            })
         }).catch(error => {
            this.setState({
               apiLoading: false,
            })
            try {
               let message = error.response.data.errors[0]
               
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               toast.error('No record found.', {
                  autoClose: 5000,
               });
            }
         })
      } catch (error) {
         this.setState({
            apiLoading: false,
         })
         toast.error('Net work error.', {
            autoClose: 5000,
         });
      }
   }
   render() {
      const previewStyle = {
         height: 240,
         width: 320,
      }
      return (
         <>
            <UserHeader />
            {this.state.camError ? (
               <div className="alert alert-danger mt-5">
                  Can not access your camera.
               </div>
            ) : (
               <>
                  {this.state.apiLoading ? (
                     <div className="page-center-loader">
                        <div className="spinner-border" role="status">
                           <span className="sr-only"></span>
                        </div>
                     </div>
                  ) : (
                     <div className="page-center-loader">
                        {/* {JSON.stringify(this.state.selectedDevice, null, 2)}
                        <select name="" id=""
                           onChange={(event) => {
                              this.setState({
                                 selectedDevice: ""
                              }, () => {
                                 setTimeout(() => {
                                    this.setState({
                                       selectedDevice: event.target.value
                                    })
                                 }, 2000);
                              })
                           }}
                        >
                           {this.state.videoDevices.map(videoDevice => {
                              return (<option value={videoDevice.deviceId}>{videoDevice.label}</option>)
                           })}
                        </select> */}
                        {/* {this.state.selectedDevice && ( */}
                        <QrReader
                           // facingMode={{
                           //    facingMode: 'rear',
                           // }}
                           // constraints={{
                           //    facingMode: 'user',
                           // }}
                           // constraints={{
                           //    facingMode: 'user'
                           // }}
                           facingMode={"environment"}
                           delay={this.state.delay}
                           style={previewStyle}
                           onError={this.handleError}
                           onScan={this.handleScan}
                        />
                        {/* )} */}
                     </div>
                  )}
               </>
            )}

         </>
      )
   }
}
export default withNavigation(Index)