import React, { useEffect, useState } from 'react';
import { withNavigation } from './../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi'
import { Link } from 'react-router-dom'
import Url from '../../Utils/Routes'
import AuthFooter from '../../Components/footers/LoginFooter';
import { useSelector, useDispatch } from 'react-redux'
import { updateUserPermission } from '../../store/reducers/userPermissions'
import { checkShipmentStatus } from '../../store/reducers/shipmentPermissions'
const SignupSchema = Yup.object().shape({
   email: Yup.string()
      .required('Email is required.').email("Email is not valid."),
   password: Yup.string()
      .min(4, 'Too Short!')
      .required('Password is required.'),
});
let AuthSignup = (props) => {
   const dispatch = useDispatch()
   let [apiError, setApiError] = useState(false);
   let [apiErrorMessage, setApiErrorMessage] = useState("Invalid email/password combination");

   useEffect(() => {
      let token = localStorage.getItem("token")
      if (token) {
         setTimeout(() => {
            props.navigate(Url.RepoAppDashboard);
         }, 1000);
      }
   })
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 roboto-light'>Sign in</h1></div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           email: '',
                           password: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                           setSubmitting(true)
                           setApiError(false)
                           values.email = values.email.toLowerCase();
                           try {
                              AuthApi.login(values).then(result => {
                                 if (result && result.status == 200 && result.data.token) {
                                    localStorage.setItem("token", result.data.token);
                                    localStorage.setItem("email", result.data.user.email);
                                    if (!localStorage.getItem("date_format")) {
                                       localStorage.setItem("date_format", "yyyy-MM-dd");
                                    }
                                    let role_code = result.data.user.role_code
                                    let client_id = result.data.user.client_id
                                    dispatch(updateUserPermission(role_code))
                                    dispatch(checkShipmentStatus(client_id))
                                    if (result.data.user.is_admin) {
                                       props.navigate(Url.Dashboard);
                                    } else {
                                       props.navigate(Url.RepoAppDashboard);
                                    }
                                 } else {
                                    setApiError(true);
                                 }
                                 setSubmitting(false)
                                 console.log(result)
                              }).catch(error => {
                                 const response = error.response;
                                 if (response.status = 401) {
                                    setApiErrorMessage(response.data.errors[0])
                                 }
                                 setApiError(true);
                                 setSubmitting(false)
                              })

                           } catch (error) {
                              setSubmitting(false)
                              setApiError(true)
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting }) => (
                           <div className="panel border rounded p-3 panel-default">
                              <div className="panel-body">
                                 <Form className="form" id="login" >
                                    <div className="form-group">
                                       <label htmlFor="session_email">Email</label>
                                       <Field className="form-control" placeholder="Email" type="text" name="email" />
                                       <ErrorMessage component="small" className='text-danger' name="email" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_password">Password</label>
                                       <Field className="form-control" placeholder="Password" type="password" name="password" />
                                       <ErrorMessage component="small" className='text-danger' name="password" />
                                    </div>
                                    {apiError && (
                                       <small className='text-danger d-block mb-3'>{apiErrorMessage}</small>
                                    )}
                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading...." : (<><i className="fa fa-lock text-white"></i> Sign in</>)}
                                    </button>
                                 </Form>
                              </div>
                           </div>
                        )}
                     </Formik>
                     <div className="mt-4">
                        <i className="fa fa-question-circle text-info1"></i> <Link to="/forget-password">Forgot your password?</Link>
                     </div>
                  </div>
               </div>

            </div>
         </div>

         <AuthFooter />
      </>
   )
}

export default withNavigation(AuthSignup)