import React, { useEffect, useState } from 'react';
import { withNavigation } from './../../Utils/Navigater';
import Url from './../../Utils/Routes';
import { NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
let UserAppNavigater = (props) => {


    return (
        <>
            <div className="d-md-block d-none">
                <div className="default_user_change_penal  mt-4">
                    <NavLink activeClass="active" to={Url.Users}>Users</NavLink>
                    <NavLink activeClass="active" to={Url.Templates}>Templates</NavLink>
                    <NavLink activeClass="active" to={Url.Client}>Account Details</NavLink>
                    <NavLink activeClass="active" to={Url.Dispositions}>Dispositions</NavLink>
                    <NavLink activeClass="active" to={Url.Categories}>Categories</NavLink>
                    <NavLink activeClass="active" to={Url.Venues}>Venues</NavLink>
                    <NavLink activeClass="active" to={Url.AuditOption}>Audit Status</NavLink>
                    <NavLink activeClass="active" to={Url.DefaultOptions}>Default Values</NavLink>
                    <NavLink activeClass="active" to={Url.WebOptions}>Web Integration</NavLink>
                </div>
            </div>
            <div className="d-flex justify-content-start px-3 mt-3">
                <Dropdown>
                    <Dropdown.Toggle className='setting-dropdown d-md-none d-block' id="dropdown-basic">
                        Users & Settings
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Users}>Users</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Templates}>Templates</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Client}>Account Details</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Dispositions}>Dispositions</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Categories}>Categories</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.Venues}>Venues</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.AuditOption}>Audit Status</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.DefaultOptions}>Default Values</NavLink>
                        <NavLink activeClass="active" className='dropdown-item' to={Url.WebOptions}>Web Integration</NavLink>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default withNavigation(UserAppNavigater)

