import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi'
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import AuthFooter from '../../Components/footers/LoginFooter';
const SignupSchema = Yup.object().shape({
   email: Yup.string().required('Email is required.').email("Enter Valid email."),
   name: Yup.string().required('Name is required.'),
   phone: Yup.string().required('Phone number is required.'),
   company: Yup.string().required('Company is required.'),
   topic: Yup.string().required('Topic is required.'),
   message: Yup.string().required('Message is required.'),
});
let Contact = (props) => {
   let [apiError, setApiError] = useState(false);
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 text-center roboto-light'>Contact Us</h1></div>
                  <br />
                  <h4 className='text-danger2 roboto-light '> Interested in a free trial?</h4>
                  <p>Discover what RepoApp has to offer, and how it will help your organization better manage lost and found items. Try RepoApp for 14 days. We'll send you a trial code to get started. Please complete the below form to get started.</p>
                  <h4 className='text-danger2 roboto-light '> Support or just have a question?</h4>
                  <p>Whether it's a question on a feature, a recommendation or a support question, we would like to hear from you. Please complete the form below.</p>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           name: "",
                           phone: "",
                           email: "",
                           company: "",
                           topic: "",
                           message: ""
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting, }) => {
                           setSubmitting(true)
                           try {
                              AuthApi.contactUs(values).then(result => {
                                 if (result && result.status == 200) {

                                    toast.success('Thank you for your message!', {
                                       autoClose: 5000,
                                    });

                                    props.navigate(Url.RepoAppDashboard);
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });

                                 }
                                 setSubmitting(false)
                              }).catch(error => {
                                 setSubmitting(false)
                                 try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    
                                 }
                              })
                           } catch (error) {
                              setSubmitting(false)
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                           <div className="panel border rounded p-3 panel-default">
                              <div className="panel-body">
                                 <Form className="form" id="login" >
                                    <div className="form-group">
                                       <label htmlFor="session_email">Name</label>
                                       <Field className="form-control" placeholder="Name" type="text" name="name" />
                                       <ErrorMessage component="small" className='text-danger' name="name" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Email</label>
                                       <Field className="form-control" placeholder="Email" type="text" name="email" />
                                       <ErrorMessage component="small" className='text-danger' name="email" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Phone</label>
                                       <Field className="form-control" placeholder="Phone" type="text" name="phone" />
                                       <ErrorMessage component="small" className='text-danger' name="phone" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Company</label>
                                       <Field className="form-control" placeholder="Company" type="text" name="company" />
                                       <ErrorMessage component="small" className='text-danger' name="company" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Topic</label>
                                       <select class="form-control"
                                          onChange={(event) => { setFieldValue("topic", event.target.value) }}
                                       >
                                          <option value="">--Select Topic--</option>
                                          <option value="Trial">Trial</option>
                                          <option value="Question">Question</option>
                                          <option value="Issue">Issue</option></select>
                                       <ErrorMessage component="small" className='text-danger' name="topic" />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="session_email">Message</label>
                                       <textarea
                                          onChange={(event) => { setFieldValue("message", event.target.value) }}
                                          name="" className='form-control' id="" cols="30" rows="3"></textarea>
                                       <ErrorMessage component="small" className='text-danger' name="message" />
                                    </div>

                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading...." : (<>Send Message</>)}
                                    </button>
                                 </Form>
                              </div>
                           </div>
                        )}
                     </Formik>

                     <h3 className='text-danger2 mt-4'>Contact us directly</h3>
                     <p><i class="fa fa-envelope-o"></i> <a href="mailto:info@repoapp.com">Feel free to email us directly.</a></p>
                  </div>
                  <div className="col-md-6">
                     <div className="alert alert-warning">
                        <h4 className='text-danger2'>Try RepoApp for 14 days. No commitments. Cancel anytime.</h4>
                     </div>
                  </div>
               </div>

            </div>
         </div>

         <AuthFooter />

      </>
   )
}

export default withNavigation(Contact)