import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ClientIframe from '../../Components/AppComponents/ClientIframe';
import { useSelector, useDispatch } from 'react-redux';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
const FormValidation = Yup.object().shape({
   default_claim_expire_after: Yup.number().typeError("Invalid data"),
   default_item_expire_after: Yup.number().typeError("Invalid data"),
});
let WebOptions = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <>
                     {data.id && (
                        <Formik
                           initialValues={{
                              search_page_enabled: data.search_page_enabled,
                              search_page_header_text: data.search_page_header_text,
                              search_page_logo_url: data.search_page_logo_url,
                              claim_page_enabled: data.claim_page_enabled,
                              item_page_enabled: data.item_page_enabled,
                              venue_enabled: data.venue_enabled,
                              claim_confirmation_email_enabled: data.claim_confirmation_email_enabled,
                              claim_page_header_text: data.claim_page_header_text,
                              item_confirmation_email_enabled: data.item_confirmation_email_enabled,
                              disposition_require_enabled: data.disposition_require_enabled,
                              item_page_header_text: data.item_page_header_text,
                              email_footer_text: data.email_footer_text,
                              bcc_email: data.bcc_email,
                              claim_page_logo_url: data.claim_page_logo_url,
                              item_email_footer_text: data.item_email_footer_text,
                              item_bcc_email: data.item_bcc_email,
                              item_page_logo_url: data.item_page_logo_url
                           }}
                           validationSchema={FormValidation}
                           onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(false);

                              Swal.fire({
                                 title: 'Do you want to save the changes?',
                                 showDenyButton: false,
                                 showCancelButton: true,
                                 confirmButtonText: 'Save',
                                 denyButtonText: `Don't save`,
                              }).then((result) => {
                                 /* Read more about isConfirmed, isDenied below */
                                 if (result.isConfirmed) {
                                    setSubmitting(true);
                                    try {
                                       AppApi.UpdateClientAccountOptions(values).then(result => {
                                          if (result && result.status == 200) {
                                             toast.success('Successfully updated!', {
                                                autoClose: 5000,
                                             });
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }
                                 } else if (result.isDenied) {
                                    setSubmitting(false)
                                 }
                              })
                           }}
                        >
                           {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                              <Form >
                                 <div className="container-fluid">
                                    <div className="row mt-3">
                                       <div className="col-md-6">
                                          <div class="panel border panel-default">
                                             <div class="panel-heading">
                                                <div class="panel-title">Public Item Search Form</div>
                                             </div>
                                             <div class="panel-body p-3">
                                                <form name="ItemWebForm" role="form" novalidate="" class="ng-pristine ng-valid">
                                                   <div class="form-group">
                                                      <Field type="checkbox" name="search_page_enabled" checked={values.search_page_enabled} />  Enable
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="About Search Integration"
                                                         des={"For PREMIUM plan accounts - if enabled, use the below link on a webpage on your website to allow customers to view non-expired, available items. The item's 'Web Description' will appear on this page."}
                                                      />
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-link"></i> <label class="control-label" for="web_search_url">Link to Public Item Search Form</label>
                                                      <br /><a href={`${window.location.origin}${data.search_page_url_path}`} target="_blank" id="web_search_url" class="ng-binding" rel="noreferrer">{window.location.origin}{data.search_page_url_path} </a>
                                                         <br /><a href={`${window.location.origin}${data.claim_list_page_url_path}`} target="_blank" id="web_search_url" class="ng-binding" rel="noreferrer">{window.location.origin}{data.claim_list_page_url_path} </a>
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-image-"></i> <label class="control-label" for="search_page_logo_url">Logo URL</label>
                                                      <Field className="form-control  " placeholder="Optional - add URL to logo" type="text" name="item_page_logo_url" />
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-pencil-square-o"></i> <label class="control-label" for="search_page_header_text">Page Header Text (HTML Format)</label>
                                                      <code><textarea class="form-control"
                                                         onChange={(event) => setFieldValue("item_page_header_text", event.target.value)}
                                                         name="description" rows="7" autocomplete="off" value={values.item_page_header_text}> </textarea></code>
                                                   </div>
                                                </form>
                                             </div>
                                          </div>
                                          <ClientIframe id={data.search_page_url_path} label="Search page embed code." />
                                          {/* <ClientIframe id={data.item_page_url_path} label="Items page embed code" /> */}
                                          <ClientIframe id={data.claim_page_url_path} label="Claim page embed code." />
                                          <ClientIframe id={data.claim_list_page_url_path} label="Claim items list page embed code." />
                                       </div>
                                       <div className="col-md-6">
                                          <div class="panel border panel-default">
                                             <div class="panel-heading">
                                                <div class="panel-title">Public Claim Form</div>
                                             </div>
                                             <div class="panel-body p-3">
                                                <form name="ClaimWebForm" role="form" novalidate="" class="ng-pristine ng-valid">
                                                   <div class="form-group">
                                                      <Field type="checkbox" name="claim_page_enabled" checked={values.claim_page_enabled} />  Enable
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="About Claim Integration"
                                                         des={"For PREMIUM plan accounts - if enabled, use the below link on a webpage on your website to allow customers to submit lost claims. Submitted claims will appear in the 'Claims' section with a follow-up flag and 'WEB CLAIM' in the Notes field. Claims will be created under an admin account."}
                                                      />
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-link"></i> <label class="control-label" for="web_claim_submit_url">Link to Public Claim Form</label><br />
                                                      <a href={`${window.location.origin}${data.claim_page_url_path}`} target="_blank" id="web_search_url" class="ng-binding" rel="noreferrer">{window.location.origin}{data.claim_page_url_path} </a>
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-file-image-o"></i> <label class="control-label" for="claim_page_logo_url">Logo URL </label>
                                                      <Field className="form-control  " placeholder="Optional - add URL to logo" type="text" name="claim_page_logo_url" />
                                                   </div>
                                                   <div class="form-group">
                                                      <i class="fa fa-pencil-square-o"></i> <label class="control-label" for="claim_page_header_text">Page Header Text (HTML Format)</label>
                                                      <code><textarea
                                                         onChange={(event) => setFieldValue("claim_page_header_text", event.target.value)}
                                                         class="form-control  " name="description" rows="7" autocomplete="off" value={values.claim_page_header_text}>
                                                      </textarea></code>
                                                   </div>
                                                   <div class="form-group">
                                                      <Field type="checkbox" name="venue_enabled" checked={values.venue_enabled} />    Add Venue to Web Claim Form
                                                   </div>
                                                   <div class="form-group  "  >
                                                      <Field type="checkbox" name="claim_confirmation_email_enabled" checked={values.claim_confirmation_email_enabled} />    Send Claim Confirmation Email
                                                   </div>
                                                   {values.claim_confirmation_email_enabled && (
                                                      <div ng-show="client_account_options.claim_confirmation_email_enabled == true" class="ng-hide">
                                                         <div class="form-group">
                                                            <i class="fa fa-file-image-o"></i> <label class="control-label" for="bcc_email">Bcc Email </label>
                                                            <Field className="form-control" placeholder="Optional - add Bcc Email" type="text" name="bcc_email" />
                                                         </div>
                                                         <div class="form-group">
                                                            <i class="fa fa-pencil-square-o"></i> <label class="control-label" for="email_footer_text">Email Footer Text (HTML Format)</label>
                                                            <code><textarea
                                                               onChange={(event) => setFieldValue("email_footer_text", event.target.value)}
                                                               class="form-control  " name="description" rows="7" autocomplete="off" value={values.email_footer_text}>
                                                            </textarea></code>
                                                         </div>
                                                      </div>
                                                   )}

                                                </form>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-12">
                                          <button class="btn btn-success btn-sm" disabled={isSubmitting}><i class="fa fa-check"></i> {isSubmitting ? "Loading..." : "Update"}</button>
                                          <br />
                                          <br />
                                          <br />
                                       </div>
                                    </div>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     )}

                  </>
               )}
            </>)}



      </>
   )
}

export default withNavigation(WebOptions)