import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AdminHeader from '../../Components/Headers/AdminHeader';
import { Link } from 'react-router-dom'
import Url from '../../Utils/Routes'
import AppApi from '../../Api/AppApi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const FormValidation = Yup.object().shape({
   repoapp_commission: Yup.number().required('Commission is required.').min(1, "Can not less than 1.").max(100, "Can not greater than 100.").typeError("Input is not valid commission.").test('two-decimal', 'Decimal places can not be more than two.', (value) => {
      if (value) {
         const pattern = /^\d+(\.\d{1,2})?$/;
         return pattern.test(value.toString());
      }
      return true;
   }),

   insurance_commission: Yup.number().required('Commission is required.').min(1, "Can not less than 1.").max(100, "Can not greater than 100.").typeError("Input is not valid commission.").test('two-decimal', 'Decimal places can not be more than two.', (value) => {
      if (value) {
         const pattern = /^\d+(\.\d{1,2})?$/;
         return pattern.test(value.toString());
      }
      return true;
   }),
});

let Index = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState({});
   let [insuranceData, setInsuranceData] = useState({})
   let [isInsuranceSubmitting, setInsuranceSubmitting] = useState(false)
   let [isShipmentSubmitting, setShipmentSubmitting] = useState(false)
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.getCommission().then(res => {
            if (res.status == 200) {
               const shipment_commission = res?.data?.repoapp_commission.filter((data) => data.setting_type == "shipment_commission");
               const insurance_commission = res?.data?.repoapp_commission.filter((data) => data.setting_type == "insurance_commission");
               const success = res?.data?.success;
               const shipmentObj = { shipment_commission, success }
               const insuranceObj = { insurance_commission, success }
               setData(shipmentObj);
               setInsuranceData(insuranceObj)
            }
            setApiLoading(false)
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
            setApiLoading(false)
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   return (
      <>
         <AdminHeader />
         <div className="container-fluid mt-3">

            <div className="row">
               {apiLoading ? (
                  <div className="col-12 py-5 mt-5 text-center">
                     <div class="spinner-border text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <>
                     {data?.success ? (
                        <div className="col-md-3">
                           <Formik
                              initialValues={{
                                 repoapp_commission: data.shipment_commission[0].repoapp_commission,
                                 insurance_commission: insuranceData.insurance_commission[0].repoapp_commission,
                                 setting_type: ""
                              }}
                              validationSchema={FormValidation}
                              onSubmit={(values, { setSubmitting, setFieldError }) => {
                                 setSubmitting(true);
                                 try {
                                    if (values.setting_type === "insurance_commission") {
                                       values.repoapp_commission = values.insurance_commission;
                                    }
                                    AppApi.UpdateCommission(values).then(res => {
                                       if (res && res.data.success) {
                                          toast.success('Settings has been updated successfully.', {
                                             autoClose: 5000,
                                          });
                                          props.navigate(Url.Dashboard);
                                       } else {
                                          toast.success(`${res.data.error}`, {
                                             autoClose: 5000,
                                          });
                                       }
                                       setSubmitting(false)
                                    }).catch(error => {
                                       setSubmitting(false)
                                       try {
                                          let message = error.response.data.errors[0]

                                          toast.error(message ? message : 'Server Error!', {
                                             autoClose: 5000,
                                          });
                                       } catch (error) {
                                          
                                       }
                                    })
                                 } catch (error) {
                                    setSubmitting(false)
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                    console.log("server error", error.message)
                                 }
                              }}
                           >
                              {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                 <Form >
                                    <div className="panel border panel-default">
                                       <div className="panel-heading d-flex justify-content-between">
                                          <h3 className="panel-title">Repoapp commission </h3>
                                       </div>
                                       <div className="panel-body p-2 px-4">
                                          <div className="row mt-2">
                                             <div className="col-12">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Shipping Commission</label>
                                                   <div className="d-flex align-items-center">
                                                      <div><h4 className='mb-0 me-1'>$</h4></div>
                                                      <div>
                                                         <Field type="number" className='form-control rounded-0' name="repoapp_commission" />
                                                      </div>
                                                   </div>
                                                   <ErrorMessage component="small" className='text-danger' name="repoapp_commission" />
                                                </div>
                                             </div>

                                             <div className="col-12">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Insurance Commission</label>
                                                   <div className="d-flex align-items-center">
                                                      <div><h4 className='mb-0 me-1'>$</h4></div>
                                                      <div>
                                                         <Field type="number" className='form-control rounded-0' name="insurance_commission" />
                                                      </div>
                                                   </div>
                                                   <ErrorMessage component="small" className='text-danger' name="insurance_commission" />
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                       <div className="panel-footer">
                                          {Object.keys(errors).length > 0 && (setShipmentSubmitting(false), setInsuranceSubmitting(false))}
                                          <button className="btn btn-success me-1 mb-2 mb-md-2" disabled={isSubmitting} onClick={() => { setFieldValue("setting_type", "shipment_commission"); setShipmentSubmitting(true) }} type="submit"> {isShipmentSubmitting ? "Loading..." : " Update Shipping"} </button>
                                          <button className="btn btn-success me-1 mb-2 mb-md-2" disabled={isSubmitting} onClick={() => { setFieldValue("setting_type", "insurance_commission"); setInsuranceSubmitting(true) }} type="submit"> {isInsuranceSubmitting ? "Loading..." : " Update Insurance"} </button>
                                          {!isSubmitting && (
                                             <Link to={Url.Dashboard} className="btn btn-default  ms-1"  >Cancel</Link>
                                          )}
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>
                        </div>
                     ) : (
                        <div className="col-md-
                        5">
                           <div className="alert">
                              You do not have commission to updated.
                           </div>
                        </div>
                     )}
                  </>
               )}
            </div>
         </div>
      </>
   )
}

export default withNavigation(Index)