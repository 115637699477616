import {Button, Modal} from "react-bootstrap";
import Mailto from "../AppComponents/MailTo";
import React, {useState} from "react";
import EmailPreviewModal from "./EmailPreviewModal";

const EmailTemplateActionModal = ({closeModal, show, templateData, sendToEmail, reset}) => {
    const [showTemplatePreview, setShowTemplatePreview] = useState(false);

    return (
       <>
           <Modal show={show} onHide={reset} animation={true} centered>
               <Modal.Body>
                   <Modal.Header closeButton className="border-0 text-center" />
                   <div className="d-flex justify-content-center pb-3" >
                       <h5>How would you like to send the email?</h5>
                   </div>
                   <div className="d-flex justify-content-center pb-3">
                       <Button variant="secondary" onClick={() => {
                           setShowTemplatePreview(true);
                           closeModal();
                       }}>
                           Via App (RepoApp)
                       </Button>
                       {
                           templateData &&
                           <Mailto email={sendToEmail} subject={templateData.subject} body={decodeURIComponent(templateData.content)} style='text-decoration-none text-white'>
                               <Button className="ml-5" variant="primary" onClick={reset}>
                                   Externally (Native Mail App)
                               </Button>
                           </Mailto>

                       }
                   </div>
               </Modal.Body>
            </Modal>
           <EmailPreviewModal
               handleClose={() =>{
                   reset();
                   setShowTemplatePreview(false)
               }}
               sendToEmail={sendToEmail}
               show={showTemplatePreview}
               templateData={templateData}
           />
       </>
   )
}

export default EmailTemplateActionModal;