import React, { useState, useEffect } from 'react'
import ItemsApi from '../../Api/ItemsApi';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import Url from '../../Utils/Routes';
import ToolTip from '../AppComponents/ToolTip';
let ItemsMatches = (props) => {
    let [data, setData] = useState([]);
    let [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        getData(false)
    }, [])
    let getData = async (notificationCheck = true) => {
        try {
            setApiLoading(true)
            ItemsApi.autoMatch(props.id).then(res => {
                if (res.status == 200) {
                    setData(res.data)
                }
                setApiLoading(false);
                if (res.data.length > 0) {
                    // toast.info('Records updated successfully.', {
                    //     autoClose: 5000,
                    // });
                } else {
                    if (notificationCheck) {
                        toast.info('No records returned.', {
                            autoClose: 5000,
                        });
                    }
                }
            }).catch(error => {
                setApiLoading(false)
                try {
                    let message = error.response.data.errors[0]
                    toast.error(message ? message : 'Server Error!', {
                        autoClose: 5000,
                    });
                } catch (error) {
                    
                }
            })
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }
    let getDataWide = async () => {
        try {
            setApiLoading(true)
            ItemsApi.WideAutoMatch(props.id).then(res => {
                if (res.status == 200) {
                    setData(res.data)
                }
                setApiLoading(false);
                if (res.data.length > 0) {
                    // toast.info('Records updated successfully.', {
                    //     autoClose: 5000,
                    // });
                } else {
                    toast.info('No records returned.', {
                        autoClose: 5000,
                    });
                }
            }).catch(error => {
                setApiLoading(false)
                try {
                    let message = error.response.data.errors[0]
                    toast.error(message ? message : 'Server Error!', {
                        autoClose: 5000,
                    });
                } catch (error) {
                    
                }
            })
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }



    return (
        <>
            <div class="panel border  panel-default" >
                <div class="panel-heading d-flex justify-content-between">
                    <h3 class="panel-title">Matching Claims
                        <ToolTip
                            view=""
                            index={1}
                            placement="bottom"
                            icon="text-info"
                            title=""
                            des="Shows top 20 matches. Too little matches or too many false matches? Consider changing the default setting in the Set Defaults section. By default, auto-matching search is performed against claims in the same category. However, to match against all categories, use 'Match (Wide)'."
                        />
                    </h3>
                    <div class="btn-group pull-right" n >
                        <a class="btn btn-default btn-sm" href='#' onClick={() => getData()}  >
                            <i class="fa fa-search"></i> Match
                        </a>
                        <a class="btn btn-default btn-sm" href='#' onClick={() => getDataWide()}   >
                            <i class="fa fa-search"></i> Match (Wide)
                        </a>
                    </div>
                </div>
                <div class="panel-body p-2">
                    {apiLoading && "Loading..."}
                    {!apiLoading && data.length == 0 ? "No record found." : ""}
                    {!apiLoading && data.map((data, index) => {
                        return (
                            <div>
                                <Link to={Url.EditClaim(data.id)}><strong  >{data.id}</strong>
                                    :  {data.claim_description}</Link><br />
                                <i class="fa fa-calendar"></i> Lost Date: {data.claim_lost_date}
                            </div>
                        )
                    })}

                </div>
            </div>
        </>
    )
}

export default ItemsMatches