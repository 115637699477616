import {ErrorMessage, Field, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import CurrencyInput from 'react-currency-input-field';
import {toast} from 'react-toastify';
import AppApi from '../../Api/AppApi';
import AuthApi from '../../Api/AuthApi';
import ShipmentApi from '../../Api/ShipmentApi';
import {blockInvalidChar} from '../../Utils/blockInvalidChar';
import Address from '../AppComponents/Address';
import {
    internationalShippingAddressValidation,
    shipmentAddressValidation,
    shippingServiceValidation
} from "../../Form Schema/ShipmentSchemas";
import {FormGroup, FormSelect} from "react-bootstrap";
import {useGetShipmentCurrencies} from "../../hooks/ShipmentHooks";
import { ITEM_MAX_PACKAGE_VALUE } from 'constants/index';

let TopShipment = (props) => {
    let [shipmentRates, setShipmentRates] = useState([]);
    let [hs_tarrif_data, seths_tarrif_data] = useState([]);
    let [apiLoading, setApiLoading] = useState(false);
    let [countries, setCountries] = useState([]);
    let [insure_it, setInsure_it] = useState(false);
    let [check_country, setCheck_country] = useState('')
    let [country_dropdown, setCountry_dropdown] = useState('')
    let [showTariff, setShowTariff] = useState(false)
    let [shipmentRateId, setShipmentRateId] = useState('');
    const [shipment, setShipment] = useState({});
    const [insurance_cost, setInsurance_cost] = useState(0);
    const [insuranceCommission, setInsuranceCommission] = useState(0);
    const [shipmentCurrency, setShipmentCurrency] = useState("");
    const [shipmentProvider, setShipmentProvider] = useState("");
    const [packageValue, setPackageValue] = useState(0);

    let shipmentId = props.id
    let showResidential = true;
    const currencies = useGetShipmentCurrencies();

    useEffect(() => {
        Promise.all([
            getShippingRates(shipmentId, shipmentCurrency, true),
            getHs_tariff_number(),
            countriesFun(),]).then((values) => {
                setCountries(values[1])
                setApiLoading(false)
            });
    }, [])

    const getShippingRates = async (id, currency, updateCurrency = false) => {
        try {
            setApiLoading(true);
            let data = await ShipmentApi.shipmentDetailsWithCurrency(id, currency).then(res => {
                if (res.status === 200) {
                    setShipment(res.data.data.shipment);
                    setShowTariff(res?.data?.data?.from_address?.country !== shipment?.to_country)
                    setPackageValue(res.data.data.shipment.package_value);
                    setCheck_country(res?.data?.data?.from_address?.country)
                    setInsurance_cost(res.data?.data?.total_insurance_cost);
                    setInsuranceCommission(res.data?.data?.insurance_commission);
                    updateCurrency && res.data?.data?.shipment_currency && setShipmentCurrency(res.data?.data?.shipment_currency);
                    if (res.data?.data?.shipment?.shipment_rate_id != null) {
                        setShipmentRateId(res.data?.data?.shipment?.shipment_rate_id)
                        setInsurance_cost(res.data?.data?.total_insurance_cost);
                        const matchingRate = res.data.data.rates.find(rate => rate.id === res.data.data.shipment.shipment_rate_id);
                        if (matchingRate) return setShipmentRates([{ ...matchingRate }]);
                    }
                    setShipmentRates(res.data.data.rates);
                    setShipmentProvider(res.data.data.shipment_provider);
                }
                setApiLoading(false);
                if (res.data.length > 0) {
                    toast.info('Records updated successfully.', {
                        autoClose: 5000,
                    });
                } else {
                    return []
                }
            }).catch(error => {
                setApiLoading(false)
                return []
            })
            return data
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
            return []
        }
    }
    let countriesFun = async () => {
        try {
            let data = await AuthApi.getCountries().then(res => {
                if (res.status == 200) {
                    return res.data.data
                }
            }).catch(error => {

            })
            return data
        } catch (error) {

        }
    }
    let getHs_tariff_number = async () => {
        try {
            setApiLoading(true);
            await AppApi.hs_tariff_details().then(res => {
                if (res.status == 200) {
                    seths_tarrif_data(res?.data?.data)
                    return res?.data?.data
                } else {
                    setApiLoading(false);
                    return []
                }

            }).catch(error => {
                setApiLoading(false)
                return []
            })
            return shipmentRates
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
            return []
        }
    }

    const getValidationSchema = () => {
        if (check_country != country_dropdown) {
            return internationalShippingAddressValidation;
        } else {
            return shipmentAddressValidation;
        }
    };
    const handleInsuranceFlag = (event) => {
        setInsure_it(event.target.checked);
    };

    const onCurrencyChange = async  (e) => {
        setShipmentCurrency(e.target.value)
        setApiLoading(true);
        await getShippingRates(shipmentId, e.target.value)
        setApiLoading(false);
    }

    const onEditShipmentClick = async () => {
        setApiLoading(true);
        const payload = { shipment: { status: 'draft', carrier_shipment_id: null } };
        await ShipmentApi.shipmentPublicUpdate(shipmentId, payload).then(res => {
            if (res.status === 200) {
                window.location.reload();
            }
        }).catch(error => {
            setApiLoading(false);
            toast.error('Server error.', {
                autoClose: 5000,
            });
        })
    }

    const calculateInsuranceCost = (value, commission) => {
        if (value === undefined) return 0;
        if (Number(value) === 0) return 0;

        const cost = Number(value) * 0.01;
        return cost + commission;
    }

    return (
        <>
            {apiLoading ? (
                <div className="page-center-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {shipmentRates.length === 0 ? (
                        <Formik
                            initialValues={{
                                name: shipment.to_name,
                                email: shipment.to_email,
                                street1: shipment.to_street1,
                                street2: shipment.to_street2,
                                city: shipment.to_city,
                                state: shipment.to_state,
                                zip: shipment.to_zip,
                                country: shipment.to_country,
                                phone: shipment.to_phone,
                                residential: shipment.is_to_address_residential,
                                package_value: shipment.package_value,
                                hs_tariff_number_id: shipment.hs_tariff_number
                            }}
                            validationSchema={getValidationSchema()}
                            onSubmit={(values, { setSubmitting }) => {
                                let currency = values.package_value;
                                const { package_value, ...newValues } = values;
                                setSubmitting(true);
                                try {
                                    let package_value = Number(currency)
                                    let hs_tariff_number_id = Number(values.hs_tariff_number_id);
                                    let apiData = {
                                        id: props.id,
                                        data: { to_address: newValues, package_value, hs_tariff_number_id, insure_it },
                                    };
                                    ShipmentApi.shipmentToAddress(apiData).then(res => {
                                        if (res && res.status == 200) {
                                            setShipment(res?.data?.data?.shipment);
                                            setPackageValue(res?.data?.data?.shipment?.package_value);
                                            setInsurance_cost(res?.data?.data?.total_insurance_cost)
                                            if (res.data.data.rates.length === 0) {
                                                toast.error("Unable to fetch rates please contact info@repoapp.com ", {
                                                    autoClose: 5000,
                                                });
                                            } else {
                                                setShipmentRates(res.data.data.rates);
                                            }
                                            setShipmentCurrency(res.data?.shipment_currency);
                                            setShipmentRates(res.data.data.rates);
                                        } else {
                                            toast.error('Server error.', {
                                                autoClose: 5000,
                                            });
                                        }
                                        setSubmitting(false)
                                    }).catch(error => {
                                        setSubmitting(false)
                                        try {
                                            let message = error.response.data.errors[0];
                                            ;
                                            // let message = "Our automated system needs accurate address information, we were unable to verify your address.Please recheck street, unit # and zip code"
                                            toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                            });
                                        } catch (error) {

                                        }
                                    })
                                } catch (error) {
                                    setSubmitting(false);
                                    toast.error('Server error.', {
                                        autoClose: 5000,
                                    });
                                    console.log("server error", error.message);
                                }
                            }}
                        >
                            {({ errors, touched, values, isSubmitting, setFieldValue, setFieldTouched, setSubmitting }) => (
                                <Form >
                                    <div className="panel border panel-default panel-info">
                                        <div className="panel-heading">
                                            <div className="panel-title">Item Shipment Destination</div>
                                        </div>
                                        <div className="panel-body p-2 ">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-info">
                                                        Please enter the delivery address for your item.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="control-label required" >Name</label>
                                                        <Field className="form-control" placeholder="" type="text" name="name" />
                                                        <ErrorMessage component="small" className='text-danger d-block' name="name" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="control-label required" >Email</label>
                                                        <Field className="form-control" placeholder="" type="text" name="email" />
                                                        <ErrorMessage component="small" className='text-danger d-block' name="email" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="control-label required" >Phone</label>
                                                        <Field className="form-control" placeholder="" type="text" name="phone" />
                                                        <ErrorMessage component="small" className='text-danger d-block' name="phone" />
                                                    </div>
                                                </div>
                                                <Address setFieldValue={setFieldValue} values={values} showResidential={showResidential} check_country={check_country} setShowTariff={setShowTariff} />
                                                {check_country == values.country && setShowTariff(false)}
                                                {setCountry_dropdown(values.country)}
                                                {showTariff ? (
                                                    <div className="col-12">
                                                        <div class="form-group">
                                                            <label className={`control-label required`}>Tariff Number </label>
                                                            <select
                                                                value={values.hsterrif}
                                                                onChange={(event) => {
                                                                    setFieldValue("hs_tariff_number_id", event.target.value);
                                                                }} className='form-control'>
                                                                <option value="">Select tariff number</option>
                                                                {hs_tarrif_data.map((data) => {
                                                                    return (
                                                                        <option value={data?.id} title={`${data?.hs_tariff_number}  ${data?.description}`}>
                                                                            {`${data?.hs_tariff_number}  ${data?.description}`}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="small" className='text-danger d-block' name="hs_tariff_number_id" />
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="control-label required">Estimated value (USD) of lost item</label>
                                                        <CurrencyInput className="form-control" placeholder="" prefix="$" decimalsLimit={2} name="package_value"
                                                            value={values.package_value}
                                                            onKeyDown={blockInvalidChar}
                                                            onValueChange={(value) => {
                                                                if (value <= ITEM_MAX_PACKAGE_VALUE) {
                                                                    setPackageValue(value);
                                                                    setFieldValue('package_value', value);
                                                                }
                                                                else if (value == undefined) {
                                                                    setPackageValue(0);
                                                                    setFieldValue('package_value', '');
                                                                }
                                                            }}
                                                            onBlur={() => setFieldTouched('package_value', true)}
                                                        />
                                                        <small>German Customs Requirement: Declaring a value of $1,000 or more for your lost item will be held up in Customs & require you to fill out an Export Declaration Form.</small>
                                                        <ErrorMessage component="small" touched={touched.package_value} className='text-danger d-block' name="package_value" />
                                                    </div>
                                                </div>
                                            </div>

                                            { shipmentProvider === 'shippo' ? (
                                            <div class="row">
                                                <div className="col-12" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    <Field
                                                      type="checkbox"
                                                      id="insureIt"
                                                      checked={insure_it}
                                                      onChange={handleInsuranceFlag}
                                                      style={{ marginRight: '10px', marginBottom: "8px" }}
                                                    />
                                                    <label htmlFor="insureIt">Insure my package for ${calculateInsuranceCost(packageValue, insuranceCommission)}. Details on insurance can be found <a href="https://goshippo.com/terms/insurance/" target="_blank" rel="noreferrer">here</a>.
                                                    </label>
                                                </div>
                                            </div>
                                            ) : null }
                                        </div>
                                        <div className="panel-footer d-flex justify-content-end ">
                                            <button className="btn btn-success" type='submit' disabled={isSubmitting}> {isSubmitting ? "Loading..." : "Next"}</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )
                    :(
                        <Formik
                            initialValues={{
                                id: shipmentRateId || "",
                            }}
                            validationSchema={shippingServiceValidation}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                try {
                                    let apiData = {
                                        id: props.id,
                                        data: {
                                            rate: {
                                                id: values.id
                                            },
                                            insure_it,
                                            currency: shipmentCurrency,
                                        },
                                    }
                                    ShipmentApi.shipmentPaymentLink(apiData).then(res => {
                                        if (res && res.status == 200) {
                                            toast.success("Updated successfully.", {
                                                autoClose: 5000,
                                            });
                                            window.location.replace(res.data.data);

                                        } else {
                                            toast.error('Server error.', {
                                                autoClose: 5000,
                                            });
                                        }
                                        setSubmitting(false)
                                    }).catch(error => {
                                        setSubmitting(false)
                                        try {
                                            let message = error.response.data.errors[0]
                                            toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                            });
                                        } catch (error) {

                                        }
                                    })
                                } catch (error) {
                                    setSubmitting(false);
                                    toast.error('Server error.', {
                                        autoClose: 5000,
                                    });
                                    console.log("server error", error.message);
                                }
                            }}
                        >
                            {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                <Form >
                                    <div className="panel border panel-default panel-info pb-0">

                                        <div className="panel-heading">
                                            <div className="panel-title">Shipment Options</div>
                                            { shipment.status === 'unpaid' &&
                                              <div className="panel-title"><a href="#" onClick={onEditShipmentClick}>Edit Shipment Address</a></div>
                                            }
                                        </div>

                                        <div className="panel-body pt-3">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <h5>Carrier Name: {shipmentRates && shipmentRates[0]?.carrier}</h5>
                                                    <label className="control-label" >Select currency & Shipping speed:</label>
                                                    <br />
                                                        <FormGroup className="pb-2">
                                                            <FormSelect  onChange={onCurrencyChange} defaultValue={shipmentCurrency}>
                                                                {currencies.map((curr) => <option key={curr.code} value={curr.code}>{curr.name}</option>)}
                                                            </FormSelect>
                                                        </FormGroup>
                                                    <table className='print-table'>
                                                        <tbody>
                                                            <tr>
                                                                <td className='text-center'>Option</td>
                                                                <td>Shipping Service</td>
                                                                <td>Est. Delivery Time</td>
                                                                <td>Cost</td>
                                                                { (shipment.is_insured && shipmentProvider === 'shippo') ?  (
                                                                  <td>Insurance Fees</td>
                                                                ) : null }
                                                            </tr>
                                                            {shipmentRates.map((shipmentService) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='text-center' style={{ width: "50px" }}>
                                                                            <Field type="radio" name="id" checked={values.id == shipmentService.id} value={shipmentService.id} />
                                                                        </td>
                                                                        <td>{shipmentService.service}</td>
                                                                        <td>{shipmentService.est_delivery_days ? `${shipmentService.est_delivery_days} Day(s)` : "N/A"} </td>
                                                                        <td>{Number(shipmentService.rate)?.toFixed(2)} {shipmentCurrency}</td>
                                                                        { (shipment.is_insured && shipmentProvider === 'shippo') ? (
                                                                          <td>{Number(insurance_cost)?.toFixed(2)} {shipmentCurrency}</td>
                                                                        ) : null }
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <ErrorMessage component="small" className='text-danger d-block' name="id" />
                                                </div>
                                                { shipmentProvider === 'easy_post' ? (
                                                    <div className="col-12" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        <Field
                                                            type="checkbox"
                                                            id="insureIt"
                                                            checked={insure_it}
                                                            onChange={handleInsuranceFlag}
                                                            style={{ marginRight: '10px', marginBottom: "10px" }}
                                                        />
                                                        <label htmlFor="insureIt">Insure my package for ${insurance_cost.toFixed(2)}. Details on insurance can be found <a href="https://support.easypost.com/hc/en-us/articles/360048066032-EasyPost-Insurance-Overview#h_01EG99JK1P6G0HQGXSTZ5YQHN2" target="_blank" rel="noreferrer">here</a>.
                                                        </label>
                                                    </div>                                                   
                                                ) : null }
                                                <div className="col-12" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    <p>*Estimated delivery days are approximations and may occasionally vary.</p>
                                                </div>
                                            </div>
                                            <div className="panel-footer d-flex justify-content-end ">
                                                {shipment.status !== 'paid' && (
                                                    <button className="btn btn-success" disabled={isSubmitting} type="submit">  {isSubmitting ? "Loading..." : "Pay"}</button>
                                                )}
                                                {shipment.status === 'paid' && (
                                                    <button className="btn btn-success" disabled={true} type="submit">Already paid</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    )}
                </>
            )
            }

        </>
    )
}

export default TopShipment