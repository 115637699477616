import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader';
import Url from '../../Utils/Routes';

let FreeTrile = (props) => {

   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1 className='text-danger2 roboto-light'>Welcome to RepoApp!</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <p>We've added the following defaults to your account:</p>
                     <ul className="list-unstyled">
                        <li><i className="fa fa-check-circle"></i> A Venue location called "MAIN"</li>
                        <li><i className="fa fa-check-circle"></i> Default Categories (approximately 16)</li>
                        <li><i className="fa fa-check-circle"></i> 3 Containers</li>
                     </ul>
                     <p>Before entering "live" lost &amp; found data, we strongly recommend finalizing your venues and categories.</p>
                     <p>We recommend that you enable the Item Auditing feature to track changes to item records.</p>
                     <p>During the trial, feel free to add other users.</p>
                     <p><Link id="launch-repoapp" className="btn btn-info btn-lg text-white" to={Url.RepoAppDashboard}><i className="fa fa-play text-white"></i> Launch RepoApp</Link></p>
                  </div>
                  <div className='col-md-6'>
                     <img src="/assets/images/screenshot.jpg" alt="Picture not found" />
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default withNavigation(FreeTrile);