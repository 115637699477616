const createItemEmailBody = (content,id,description) => {
   let replacedItemBody = content.replaceAll('[item_id]',`${id}`);
   let emailBody = replacedItemBody.replaceAll('[item_description]',description);
   return emailBody;
}

const createClaimEmailBody = (content,id,description) => {
   let replacedItemBody = content.replaceAll('[claim_id]',`${id}`);
   let emailBody = replacedItemBody.replaceAll('[claim_description]',description);
   return emailBody;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {createItemEmailBody, createClaimEmailBody};