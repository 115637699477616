import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { withNavigation, withParams } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ReportsApi from '../../Api/ReportsApi';
import Url from '../../Utils/Routes'
import LineChart from '../../Components/Charts/LineChart';
import { toast } from 'react-toastify';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';

let Index = (props) => {
   let { venue_id, venue_date_range } = props.params;
   let [apiLoading, setApiLoading] = useState(true);
   let [itemsData, setItemsData] = useState([]);
   let [itemsReleaseData, setItemsReleaseData] = useState([]);
   let [claimsData, setClaimsData] = useState([]);
   let [data, setData] = useState([]);
   const componentRef = useRef();
   useEffect(() => {
      getItemsData(venue_id, venue_date_range)
      getClaimData(venue_id, venue_date_range)
      getItemsReleaseData(venue_id, venue_date_range)
   }, [venue_id, venue_date_range])
   let getItemsReleaseData = async (venue_id, venue_date_range) => {
      try {
         ReportsApi.itemsReleaseCreatedMonthlyReport({ venue_id, venue_date_range }).then(res => {
            if (res.status == 200) {
               let objects = res.data
               let list = []
               Object.entries(objects).forEach(entry => {
                  const [key, value] = entry;
                  list.push({ key, value })
               })
               setItemsReleaseData(list)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getClaimData = async (venue_id, venue_date_range) => {
      try {
         ReportsApi.claimsCreatedMonthlyReport({ venue_id, venue_date_range }).then(res => {
            if (res.status == 200) {
               let objects = res.data
               let list = []
               Object.entries(objects).forEach(entry => {
                  const [key, value] = entry;
                  list.push({ key, value })
               })
               setClaimsData(list)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getItemsData = async (venue_id, venue_date_range) => {
      try {
         ReportsApi.itemsCreatedMonthlyReport({ venue_id, venue_date_range }).then(res => {
            if (res.status == 200) {
               let objects = res.data
               let list = []
               Object.entries(objects).forEach(entry => {
                  const [key, value] = entry;
                  list.push({ key, value })
               })
               setItemsData(list)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }


   let containerCounterReport = async (type, from, to) => {
      try {
         let apiData = { type, from, to }
         ReportsApi.containerCounterReport(apiData).then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xlsx`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.errors[0]
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }
   let downloadExcelFile = (data) => {
      console.log("before table data:", data)
      if (data && data.length > 0) {
         var html = document.querySelector("table").outerHTML;
         var data = [];
         var rows = document.querySelectorAll("table tr");
         for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");

            for (var j = 0; j < cols.length; j++) {
               row.push(cols[j].innerText);
            }
            data.push(row.join(","));
         }
         var csv_file, download_link;
         csv_file = new Blob([data.join("\n")], { type: "text/csv" });
         download_link = document.createElement("a");
         download_link.download = "data-file";
         download_link.href = window.URL.createObjectURL(csv_file);
         download_link.style.display = "none";
         document.body.appendChild(download_link);
         download_link.click();
      } else {
         toast.info('No record to download.', {
            autoClose: 5000,
         });
      }

   }


   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   });
   return (
      <>
         <UserHeader />
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid mt-3" >
               <div className="row">
                  <div className="col-md-6 d-flex  ">
                     <button className="btn-bordered rounded-0 text-black"
                        onClick={() => {
                           props.navigate(Url.Reports)
                        }}
                     >   <i className="fa fa-reply"></i> Back   </button>

                     <button className="btn btn-info rounded-0 text-white ms-2"
                        onClick={() => downloadExcelFile(itemsData)}
                     ><i className="fa fa-cloud-download"></i> Download</button>
                     <button className="btn btn-info rounded-0 text-white ms-2"
                        onClick={() => handlePrint()}
                     ><i className="fa fa-cloud-download"></i>  Download As Pdf</button>
                  </div>
               </div>
               <div className="row mt-3" ref={componentRef}>
                  <div className="col-md-4">
                     <h4>Items Created</h4>
                     <LineChart apiData={itemsData} label="Items" />
                     <table class="table table-striped mt-4 printTable">
                        <tbody>
                           {itemsData.map((data, index) => {
                              return (
                                 <tr>
                                    <td >{data.key}</td>
                                    <td >{data.value}</td>
                                 </tr>
                              )
                           })}
                        </tbody></table>
                  </div >
                  <div className="col-md-4">
                     <h4>Claims Created</h4>
                     <LineChart apiData={claimsData} label="Claims" />
                     <table class="table table-striped mt-4">
                        <tbody>
                           {claimsData.map((data, index) => {
                              return (
                                 <tr>
                                    <td >{data.key}</td>
                                    <td >{data.value}</td>
                                 </tr>
                              )
                           })}
                        </tbody></table>
                  </div >
                  <div className="col-md-4">
                     <h4>Items Released
                        <ToolTipContainer
                           placement="right"
                           view=""
                           icon="text-info"
                           title="Items Released"
                           des="Report reflects monthly count of individually released items (does not include bulk released items)."
                        />

                     </h4>
                     <LineChart apiData={itemsReleaseData} label="Claims" />
                     <table class="table table-striped mt-4">
                        <tbody>
                           {itemsReleaseData.map((data, index) => {
                              return (
                                 <tr>
                                    <td >{data.key}</td>
                                    <td >{data.value}</td>
                                 </tr>
                              )
                           })}
                        </tbody></table>
                  </div >

               </div>
            </div>
         )
         }

      </>
   )
}

export default withParams(withNavigation(Index))