import React from 'react'
import { Link } from 'react-router-dom'

let AuthFooter = () => {
    return (
        <div id="footer">
            <div className='container'>
                <div className='row'>
                    <div className="container text-center">
                        <p><Link className="" to="/contact">Contact Us</Link> | <a className="" target="_blank" href="https://www.repoapp.com/about-us/" rel="noreferrer">About Us</a> </p>
                        <p>
                        <Link className="" to="/terms">Terms of Use</Link> | 
                        <Link className='ms-1' to="/privacy">Privacy Policy</Link>
                        </p>
                        <p>© 2022 All Rights Reserved. RepoApp<small>®</small> is a registered trademark of RepoApp LLC.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthFooter