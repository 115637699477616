import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true)
   let [data, setData] = useState([])
   let [totalPlan, setTotalPlans] = useState(0);
   useEffect(() => {
      getData();
      totalPlans();
   }, [])
   let getData = () => {
      try {
         AppApi.usersList().then(res => {
            if (res.status == 200) {
               setData(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
         })
      } catch (error) {
         
      }
   }

   let totalPlans = async () => {
      try {
         AppApi.clientDetail().then(res => {
            if (res.status == 200) {
               setTotalPlans(res.data.plan_user_limit)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let getCreatedBy = (id) => {
      let filterRecord = data.filter(data => data.id == id);
      return filterRecord[0]?.email
   }
   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               <div className="container-fluid">
                  <div className="row mt-2">
                     <div className="col-12">
                        <div class="panel  border panel-default">
                           <div class="panel-heading">
                              <Link class="btn btn-info text-white rounded-0" to={Url.AddUser}>
                                 <i class="fa fa-user"></i> Add User »
                              </Link>
                           </div>
                           <div class="panel-body p-3">
                              <p class="text-muted">
                                 <i class="fa fa-info-circle"></i> Maximum users permitted under your plan: <span class="badge ng-binding">{totalPlan}</span> (Active and Pending users)
                              </p>
                              <p class="text-muted">
                                 <i class="fa fa-info-circle"></i> Pending users have been sent an activation email but have not completed the activation process.
                              </p>
                           </div>
                           <div class="table-responsive">
                              <table class="table table-hover table-striped">
                                 <thead>
                                    <tr>
                                       <th>Email</th>
                                       <th>First Name</th>
                                       <th>Last Name</th>
                                       <th>Role</th>
                                       <th>Status</th>
                                       <th>Created At</th>
                                       <th>Created By</th>
                                       <th>Last Login Date</th>
                                       <th>No. of Logins Last 12 Months</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {apiLoading ? (
                                       <tr>
                                          <td className='text-center' colSpan="9">Loading...</td>
                                       </tr>
                                    ) : data.length == 0 ? (
                                       <tr>
                                          <td colSpan={3} className="text-center text-info">No Record found.</td>
                                       </tr>
                                    ) : data.map((data, index) => {
                                       return (<tr key={index}>
                                          <td><Link to={Url.EditUsers(data.id)}>{data.email}</Link></td>
                                          <td>{data.first_name}</td>
                                          <td>{data.last_name}</td>
                                          <td>
                                             {data.role_code == "A" && "Admin"}
                                             {data.role_code == "I" && "Items/Claims"}
                                             {data.role_code == "C" && "Claims Only"}
                                             {data.role_code == "R" && "ReadOnly"}
                                          </td>
                                          <td>
                                             {data.status_code == "A" && (
                                                <span class="label label-default ng-binding label-success">Active</span>
                                             )}

                                             {data.status_code == "P" && (
                                                <span class="label label-default ng-binding label-warning" >Pending</span>
                                             )}
                                             {data.status_code == "D" && (
                                                <span class="label label-default ng-binding label-danger" >Disabled</span>
                                             )}
                                          </td>
                                          <td>{moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase())} </td>
                                          <td>{getCreatedBy(data.created_by_user_id)}</td>
                                          <td>{data.last_signin_datetime && moment(data.last_signin_datetime).local().format(localStorage.getItem("date_format").toString().toUpperCase())}</td>
                                          <td>{data.login_count.last_year_login_count_hash['2022']}</td>
                                       </tr>)
                                    })}

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>)}

      </>
   )
}

export default withNavigation(Index)