import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import ItemsApi from "Api/ItemsApi";

const UpdateStatusForm = ({ show, handleClose, handleSuccess, itemData }) => {
	const [status, setStatus] = useState(itemData?.item_status?.item_status_code);

	const updateStatus = () => {
		const apiData = {
			id: itemData.id,
			data: { item_status_code: status },
		}
		ItemsApi.updateItems(apiData).then(res => {
			handleSuccess(res.data);
			handleClose();
			if (res.status === 200) {
				toast.success(`Status updated successfully`, { autoClose: 5000 });
			};
		}).catch(err => {
			const errorMessages = err.response.data.errors
			errorMessages.forEach((error) => {
				toast.error(error, { autoClose: 5000 });
			});
		});
	};

	return (
		<Modal show={show} onHide={handleClose} animation={false} centered>
			<Modal.Header closeButton className="border-0 text-center">
				<h4 className="mt-auto mb-auto">Update Status</h4>
			</Modal.Header>
			<Modal.Body>
				<div className="form-group">
					<label htmlFor="status">Status</label>
					<select
						className="form-control"
						id="status"
						value={status}
						onChange={(e) => setStatus(e.target.value)}
					>
						<option value="A">Available</option>
						<option value="P">Pending</option>
					</select>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-primary" onClick={updateStatus}>
					Update
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default UpdateStatusForm;
