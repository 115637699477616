import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import AuthFooter from '../../Components/footers/LoginFooter'
import { Link } from 'react-router-dom'

let Terms = (props) => {
   let [apiError, setApiError] = useState(false);
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
         <div className="container">
    <h1>Terms Of Service</h1>
    <br />
    <p>This is an agreement between you or the legal entity that you represent as an agent or authorized employee (“You” or “your”) and RepoApp LLC (“RepoApp”, “our”, or “we”) governing your access and use of repoapp.com (“Website”) and the RepoApp online software service (“RepoApp” or “Service”).</p>

    <p>By using our Website or Service, you are agreeing to be bound by the following terms and conditions ("Terms of Service"). If you do not agree to be bound by these Terms of Service, do not proceed any further and do not access or use this Website or the Service.</p>

    <h3>Account Terms</h3>
    <ol>
        <li>Your account registration information for access to the Service (such as Your name, legal business name, address, email, telephone number) is accurate, truthful and current.</li>
        <li>Your use of the Service is at your sole risk. The Service is provided on an "as is" and "as available" basis.</li>
        <li>Technical support is only provided via email to customers who have paid for a subscription to the Service and only during the period of the subscription.</li>
        <li>Your Service account is used for a single, distinct property (building, facility, campus).</li>
        <li>You are responsible for the data that you post to the Service or permit to be posted to your account by the Service. We do not warrant that the access, transmission, processing, security, or storage of your data in the Service complies with any law or regulations that governs your data</li>
        <li>The Service does not pre-screen your data; we reserve the right (but not the obligation), at our sole discretion, for any reason or no reason and at any time, to limit or remove any part or whole of your data stored within the Service.</li>
        <li>We may backup your data from time to time. However it is recommended that you perform adequate backups of your data. RepoApp is not responsible for any loss or corruption of your data.</li>
    </ol>
    <h3>Disclaimer of Warranties</h3>
    <p>RepoApp does not warrant that the Service will meet your specific requirements, the Service will be uninterrupted, timely, secure, or error-free, the results that may be obtained from the use of the Service will be accurate or reliable, the quality of any products, services, information, or other material purchased or obtained by you through the Service will meet your expectations, and any errors in the Service will be corrected.</p>

    <h3>Limitation of Liability</h3>
    <p>You expressly understand and agree that RepoApp shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if RepoApp has been advised of the possibility of such damages), resulting from: the use or the inability to use the Service; the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Service; unauthorized access to or alteration of your transmissions or data; statements or conduct of any third party on the Service; or any other matter relating to the Service.</p>

    <h3>Intellectual Property &amp; Confidentiality </h3>
    <p>You may not modify, resell, redistribute, translate, decompile, disassemble, reverse engineer or otherwise manipulate any components of the Service. You may not lend, rent, lease or sublicense the Service to others for any purpose. You agree to use all reasonable efforts to protect the Service from unauthorized use, modification, reproduction, distribution or publication.</p>

    <p>The features and functionality of the Service (including look, layout, and data relationships) are owned by RepoApp and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. You agree to not copy, modify, publicly display, publicly perform, republish, create derivative works of or advise others in the creation of derivative works of our copyrighted material.
    </p>

    <h3>Cancellation, Refunds and Termination</h3>
    <ol>
        <li>You can cancel your account at any time by notifying us.</li>
        <li>All subscription purchases of Services are nonrefundable.</li>
        <li>If you fail to pay for the subscription of the Service, your account may become frozen and inaccessible (until payment is made), or your account data may be deleted.</li>
        <li>You agree that RepoApp, in its sole discretion, may terminate your account (or any part thereof) or use of the Service, and remove and discard any or all your data within the Service, for any reason or no reason, including, without limitation, for lack of use or if RepoApp believes that you have violated or acted inconsistently with the letter or spirit of this Agreement. RepoApp may also in its sole discretion, for any reason or no reason and at any time discontinue providing the Service, or any part thereof, with or without notice.</li>
    </ol>
    <h3>Modifications to Service and Prices</h3>
    <ol>
        <li>RepoApp reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice at any time. You agree that RepoApp shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service or any part of the Service.</li>
        <li>New features that may be added to the Service shall be subject to these Terms of Service. Should you continue to use the Service after any such modifications have been made, this shall constitute your agreement to such modifications.</li>
        <li>RepoApp reserves the right to increase the subscription rates for its Service at any time. Changes to subscription rates of the Service will be posted at repoapp.com.</li>
    </ol>
    <h3>Publicity</h3>
    <p>
        You agree that RepoApp may use your business or organization’s name and logo in presentations, marketing materials and Website listings of customers. To exclude your business or organization's name and logo from marketing materials, please notify us.</p>

        <h3>Privacy</h3>
        <p>Please see our <Link className='ms-1' to="/privacy">Privacy Policy</Link></p>

        <h3>Indemnity</h3>
        <p>You agree to hold harmless and indemnify RepoApp, and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any third party claim arising from or in any way related to (a) your breach of the Terms, (b) your use of the Service, or (c) your violation of applicable laws, rules or regulations in connection with the Service, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature.</p>

        <h3>General </h3>
        <ol>
            <li>These Terms of Service shall be governed by and construed in accordance with the laws of the United States of America and the State of New Jersey. You expressly agree the exclusive jurisdiction for any claim or action arising out of or relating to these Terms of Service or your use of this site or Service shall be in the state or federal courts located in the State of New Jersey, and you further agree and submit to the exercise of personal jurisdiction of those courts for the purpose of litigating any such claim or action.</li>
            <li>Any part of these Terms of Service found to be invalid or unenforceable by a court of competent jurisdiction will be replaced with language reflecting the original purpose in a valid and enforceable manner.</li>
            <li>We reserve the right to change these Terms of Service at any by posting revised Terms of Service on this Website, without notice to you. Your subsequent use of this Website or the Service will be governed by the new Terms of Service. Any questions about the Terms of Service should be directed to info@repoapp.com. These Terms of Service were last updated on June 30, 2015.
            </li>
            </ol>
            </div>
            {/* <script>
            `${window.intercomSettings = {
               app_id: "h9c0cmg8"
            }}`
            </script>

            <script> 
            `${(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/h9c0cmg8';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()}`
            </script> */}

         </div>

         <AuthFooter />

      </>
   )
}

export default withNavigation(Terms)