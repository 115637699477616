import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";

const withNavigation = (Component) => {
   return props => <Component {...props} navigate={useNavigate()} />;
}

const withParams = (Component) => {
   return props => <Component {...props} params={useParams()} />;
}
export { withNavigation, withParams }
