import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ReportsApi from '../../Api/ReportsApi';
import Url from '../../Utils/Routes'
import { toast } from 'react-toastify';
let Index = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData()
   }, [])
   let getData = async () => {
      try {
         ReportsApi.CategoryCountReportJson().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let categoryCountReport = async () => {
      try {
         ReportsApi.categoryCountReport().then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xls`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }
   return (
      <>
         <UserHeader />
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid">
               <div className="row mt-3">
                  <div className="col-12">
                     <h4>Category Count Report</h4>
                  </div >
               </div >
               <div className="row">
                  <div className="col-md-6 d-flex justify-content-between">
                     <button className="btn-bordered rounded-0 text-black"
                        onClick={() => {
                           props.navigate(Url.Reports)
                        }}
                     >   <i className="fa fa-reply"></i> Back   </button>

                     <button className="btn btn-info rounded-0 text-white ms-1"
                        onClick={() => categoryCountReport()}
                     ><i className="fa fa-cloud-download"></i> Download</button>
                  </div>
               </div>
               <div className="row mt-5">
                  <div className="col-md-6">
                     <span class="badge ng-binding me-1"> {data.length}  </span>Records

                     <table class="table table-striped">
                        <thead>
                           <tr>
                              <th>Category	 	 </th>
                              <th> 	Total</th>
                           </tr>
                        </thead>
                        <tbody>
                           {data.map((data, index) => {
                              return (
                                 <tr key={index}>
                                    <td>{data.category_description}</td>
                                    <td>{data.items_count}</td>
                                 </tr>
                              )
                           })}

                        </tbody></table>
                  </div>
               </div>
            </div >
         )
         }

      </>
   )
}

export default withNavigation(Index)