import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import { toast } from 'react-toastify';
import bulkReleaseApi from '../../Api/bulkReleaseApi'; 
import AuthFooter from '../../Components/footers/LoginFooter';
var qs = require('qs');
let Index = (props) => {
   let [data, setData] = useState({});
   let [plans, setPlans] = useState([]);
   let [SelectedPlans, setSelectedPlans] = useState("");
   let [apiLoading, setApiLoading] = useState(true);
   useEffect(() => {
      plansApi();
      getData()
   }, [])

   let plansApi = async () => {
      try {
         AppApi.plans().then(res => {
            if (res.status == 200) {
               setPlans(res.data.plans)

            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getData = async (plans) => {
      try {
         AppApi.clientDetail().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let emailInvoice = async () => {
      if (SelectedPlans) {
         try {
            var data = qs.stringify({
               'plan[id]': SelectedPlans,
               'commit': 'Email Invoice'
            });
            AppApi.emailInvoice(data).then(res => {
               console.log("🚀 ~ file: Index.js ~ line 49 ~ AppApi.emailInvoice ~ res", res)
               if (res.status == 200) {
                  toast.success('Email sent successfully7!', {
                     autoClose: 5000,
                  });
               }
            }).catch(error => {
               try {
                  let message = error.response.data.errors[0]
                  toast.error(message ? message : 'Server Error!', {
                     autoClose: 5000,
                  });
               } catch (error) {
                  
               }
            })
         } catch (error) {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         }
      } else {
         toast.error('Please select Plan.', {
            autoClose: 5000,
         });
      }

   }

   return (
      <>
         <AuthHeader />
         {/* <pre>  {JSON.stringify(data, null, 2)}</pre> */}

         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container  ">
               <div className="row mt-4">
                  <div class="col-md-12">
                     <div class="panel panel-info">
                        <div class="panel-heading">
                           <h3 class="panel-title">Subscription Details</h3>
                        </div>
                        <div class="panel-body p-2">
                           Plan Period: {data.plan_start_date} - {data.plan_end_date} <br />
                           Current Plan: <strong><span class="label label-success">{data.plan_name}</span></strong>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div class="col-md-6">
                     <div class="panel panel-info">
                        <div class="panel-heading">
                           <h3 class="panel-title">Option 1 - Purchase Order (Check) Payment</h3>
                        </div>
                        <div class="panel-body p-2">
                           <div class="form-group" >
                              <b>Name </b><br />
                              {data.business_name}
                           </div>
                           <div class="form-group">
                              <b>Invoice Address</b> <br />
                              {data.street1}<br />
                              {data.street2}<br />
                              {data.city} {data.state}  {data.postal_code} {data.country_code} <br />
                              {data.phone}

                              <br />
                           </div>
                           <div class="form-group">
                              <b>Email :</b> {data.primary_contact_email}
                           </div>
                           <div class="form-group">
                              <b>Current Plan</b>
                              <strong><span class="label label-success ms-1">{data.plan_name}</span></strong>
                           </div>
                           <div class="form-group">
                              <b>Confirm Plan  </b>
                              <select class="form-control"
                                 onChange={(event) => setSelectedPlans(event.target.value)}

                              >
                                 <option value="">Select Plan</option>
                                 {plans.map((data, index) => {
                                    return (
                                       <option value={data.id}>{data.plan_name}:{data.plan_description}: ${data.plan_price}</option>
                                    )
                                 })}
                              </select>
                           </div>
                           <input type="submit" name="commit" value="Email Invoice" class="btn btn-info"
                              onClick={() => {
                                 emailInvoice();
                              }}
                           />
                        </div>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="panel panel-info">
                        <div class="panel-heading">
                           <h3 class="panel-title">Option 2 - Credit Card Payment</h3>
                        </div>
                        <div class="panel-body p-2">
                           <iframe
                              id="JotFormIFrame-203446235127046"
                              title="RepoApp Payment Collection"
                              onload="window.parent.scrollTo(0,0)"
                              allowtransparency="true"
                              allowfullscreen="true"
                              allow="geolocation; microphone; camera"
                              src="https://form.jotform.com/203446235127046"
                              frameborder="0"
                              style={{
                                 minWidth: "100%",
                                 height: "539px",
                                 border: "none"
                              }}
                              scrolling="no"
                           >
                           </iframe>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mt-5">
                  <div class="col-md-12">
                     <h1 class="text-center text-danger">Yearly Subscription Plans</h1>
                     <table width="100%" cellspacing="0" cellpadding="0" border="0" class="plan_table table table-striped">
                        <tbody>
                           <tr   >
                              <th >&nbsp;</th>
                              <th >
                                 <div class="text-center">
                                    <h4 class="plan-name" style={{ color: "olive" }}> <b> Basic</b></h4>
                                    <h3 className='text-danger'>$999.99/year</h3>
                                    <h5>(~$67/month)</h5>
                                 </div>
                              </th>
                              <th align="center">
                                 <div class="text-center">
                                    <h4 class="plan-name" style={{ color: "orange" }}> <b> Plus</b></h4>
                                    <h3 className='text-danger'>$1399.99/year</h3>
                                    <h5>(~$100/month)</h5>
                                 </div>
                              </th>
                              <th >
                                 <div class="text-center">
                                    <h4 class="plan-name" style={{ color: "purple" }}> <b> Premium</b></h4>
                                    <h3 className='text-danger'>$1999.99/year</h3>
                                    <h5>(~$142/month)</h5>
                                 </div>
                              </th>
                           </tr>
                           <tr  >
                              <td class="subtitle_left">Example customers</td>
                              <td align="center" class="right">small to medium hotels <nobr>or malls</nobr></td>
                              <td align="center" class="right">large hotels, convention centers, <nobr>or theme parks</nobr></td>
                              <td align="center" class="right">transit companies (bus, train), airports, <nobr>or cruise lines</nobr></td>
                           </tr>
                           <tr className='old' >
                              <td class="subtitle_left">Max records &amp; users</td>
                              <td align="center" class="right">
                                 <p>20 users</p>
                                 <p><strong>6,000</strong> item records</p>
                                 <p><strong>6,000</strong> claim records</p>
                              </td>
                              <td align="center" class="right">
                                 <p>20 users</p>
                                 <p><strong>10,000</strong> item records</p>
                                 <p><strong>10,000</strong> claim records</p>
                              </td>
                              <td align="center" class="right">
                                 <p>20 users</p>
                                 <p><strong>18,000</strong> item records</p>
                                 <p><strong>18,000</strong> claim records</p>
                              </td>
                           </tr>
                           <tr  >
                              <td class="subtitle_left">Manage lost &amp; found items, customer claims</td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr className='old' >
                              <td class="subtitle_left">View and Download Reports</td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr  >
                              <td class="subtitle_left">Customize venues &amp; categories</td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr className='old' >
                              <td class="subtitle_left">Enable Item Auditing<br /><small>(history of changes)</small></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr >
                              <td class="subtitle_left">Access from Mobile Device<br /><small></small></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr className='old'>
                              <td class="subtitle_left">Capture photos<br /><small>(using your webcam)</small></td>
                              <td align="center" class="right"></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr>
                              <td class="subtitle_left">Search Item Form<br /> <small>(allow website visitors to view list of items)</small> </td>
                              <td align="center" class="right"></td>
                              <td align="center" class="right"></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                           <tr className='old'>
                              <td class="subtitle_left">Lost Claim Form<br /> <small>(allow website visitors to submit a claim)</small></td>
                              <td align="center" class="right"></td>
                              <td align="center" class="right"></td>
                              <td align="center" class="right"><i class="fa fa-check-circle"></i></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         )}


         <AuthFooter />

      </>
   )
}

export default withNavigation(Index)