import React from "react";

const Mailto = ({ email, subject = '', body = '', children, style }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a target="_blank" href={`mailto:${email}${params}`} className={style}>{children}</a>;
};

export default Mailto;