import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import EditTemplate from '../../Components/Forms/Users/Edit'
let Index = (props) => {





   return (
      <>
         <UserHeader />
         <div className="container-fluid mt-3">
            <div className="row">
               <div class="col-sm-1  ">
                  <p><Link class="btn btn-default border text-black font-14" to={Url.Users}><i class="fa fa-reply"></i> Back</Link></p>
               </div>
               <div class="col-md-6  ">
                  <EditTemplate />
               </div>
               <div className="col-md-5">
                  <div className="alert alert-info">
                     <h5><i className="fa fa-info-circle"></i> Important Notes</h5>
                     <small>
                        <ul>
                           <li>Email address cannot be updated. Please contact us to update an email address.</li>
                           <li>Only 'Pending' or 'Active' accounts count towards the subscription quota.</li>
                           <li>Users who have past record activity cannot be deleted. Instead, disable these accounts. Old user accounts are required for viewing/accessing historical records.</li>
                        </ul>
                     </small>
                  </div>
               </div>
            </div>

         </div>
      </>
   )
}

export default withNavigation(Index)