import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { withNavigation } from '../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { useSelector, useDispatch } from 'react-redux';
let UserHeader = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   const shipmentPermissions = useSelector((state) => state.shipmentPermissions);
   let [nav, setNav] = useState(false);

   return (

      <>
         <div className="user_top_wrapper sticky-top">
            <div >
               <Link to={Url.RepoAppDashboard}>  <img src="/assets/images/main-logo.png" alt="" /></Link>
            </div>
            <div>
               {nav ? (
                  <i
                     onClick={() => setNav(!nav)}
                     class="fas fa-times"></i>
               ) : (
                  <i
                     onClick={() => setNav(!nav)}
                     class="fas fa-bars"></i>
               )}
            </div>
         </div>

         <div className={`user_dashboard_wrapper pt-1 pt-lg-0 ${nav ? "active" : ""} sticky-top `}>
            <div>
               <NavLink activeClass="active" to={Url.RepoAppDashboard}>< i className='fa fa-tag fa-lg'> </i> Items</NavLink>
               <NavLink activeClass="active" to={Url.Claims}>< i className='fa fa-folder-open fa-lg'> </i>  Claims</NavLink>
               {UserPermissions?.permissions?.pages?.containers?.container_view && (
                  <NavLink activeClass="active" to={Url.Containers}>< i className='fa fa-box fa-lg'> </i>  Containers</NavLink>
               )}
               <NavLink activeClass="active" to={Url.ItemBulkRelease}>< i className='fa fa-shopping-cart fa-lg'> </i>  Bulk Release</NavLink>
               <NavLink activeClass="active" to={Url.ScanItem}>< i className='fas fa-barcode-read fa-lg'> </i>  Scan Items</NavLink>
            </div>
            <div >
               <NavLink activeClass="active" to={Url.Reports}>  <i className="fas fa-chart-bar"></i>  Reports</NavLink>
               {UserPermissions?.permissions?.pages?.user_settings?.user_setting_view && (
                  <Dropdown className='dashboardDropDown user_dropdown'>
                     <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <i className="fa fa-cogs fa-lg"></i>  Users & Settings
                     </Dropdown.Toggle>

                     <Dropdown.Menu>
                        <NavLink to={Url.Users} className='dropdown-item'> <i className="fa fa-users"></i>  Manage Users</NavLink>
                        <Dropdown.Divider />
                        <small className='px-3 setting-font' >SETTINGS</small>
                        <NavLink to={Url.Client} className='dropdown-item'>Account & Subscription Details</NavLink>
                        {shipmentPermissions && (
                           <NavLink to={Url.ShipmentsDetails} className='dropdown-item'>Shipment Settings</NavLink>
                        )}
                        <NavLink to={Url.Categories} className='dropdown-item'>Manage Categories</NavLink>
                        <NavLink to={Url.Templates} className='dropdown-item'>Manage Templates</NavLink>
                        <NavLink to={Url.Venues} className='dropdown-item'>Manage Venues</NavLink>
                        <NavLink to={Url.Dispositions} className='dropdown-item'>Manage Dispositions</NavLink>
                        <NavLink to={Url.AuditOption} className='dropdown-item'>Manage Item Audit Status</NavLink>
                        <NavLink to={Url.WebOptions} className='dropdown-item'>Manage Web Integration</NavLink>
                        <NavLink to={Url.DefaultOptions} className='dropdown-item'>Set Defaults</NavLink>

                        <Dropdown.Divider />
                        <NavLink to={Url.DataPurge} className='dropdown-item'> <i className="fa fa-trash"></i>  Purge Records (Data Retention)</NavLink>
                     </Dropdown.Menu>
                  </Dropdown>
               )}

               <Dropdown className='dashboardDropDown user_dropdown'>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                     <i className="fa fa-user"></i>  {localStorage.getItem("email")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                     <NavLink to={Url.Help} className='dropdown-item'> <i className="fa fa-question-circle"></i>  Help</NavLink>
                     <NavLink to={Url.ContactUs} className='dropdown-item'> <i className="fa fa-comments"></i>  Contact Us</NavLink>
                     <NavLink to={Url.ChangePassword} className='dropdown-item'>  <i className="fa fa-lock"></i>  Change Password</NavLink>
                     <NavLink to={Url.Invoice} className='dropdown-item'>  <i className="fa fa-file ms-1"></i>  Invoice </NavLink>
                     <Dropdown.Divider />
                     <NavLink to={Url.Login}
                        onClick={() => {
                           localStorage.removeItem("token");
                        }}
                        className='dropdown-item'>  <i className="fa fa-sign-out"></i>   Sign out</NavLink>
                  </Dropdown.Menu>
               </Dropdown>

            </div>
         </div>

      </>
   )
}


export default withNavigation(UserHeader)