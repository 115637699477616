import React, { useState } from 'react';
import { useEffect } from 'react';
import AppApi from '../../../Api/AppApi';
import Swal from 'sweetalert2';
let ExistingDisposition = (props) => {
    let [items, setItems] = useState([])
    useEffect(() => {
        setItems(props.data)
    }, [props.data])



    return (
        <div class="panel border panel-default">
            <div class="panel-heading">
                <div class="panel-title">Current Containers
                    <div class="badge badge-info ng-binding ms-1">{items.length}</div>
                </div>
            </div>
            <div class="panel-body p-3">

                {items.map((data, index) => {
                    return ( 
                        <p class="ng-binding ng-scope"  >
                            <i class="fa fa-box fa-1x me-1"></i> {data.container_name}
                        </p>
                    );
                })}

            </div>
        </div>
    )
}

export default ExistingDisposition