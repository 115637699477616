import React, { useEffect, useRef, useState } from 'react';
import { json, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthApi from '../../Api/AuthApi'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withNavigation, withParams } from '../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import PublicHeader from '../../Components/Headers/PublicHeader';
import AuthFooter from '../../Components/footers/LoginFooter';
import TopShipment from '../../Components/Shipment/TopShipment';
import ItemShipment from '../../Components/Shipment/ItemShipment';
const FormValidation = Yup.object().shape({
   company: Yup.string().required('Company name is required.'),
   street1: Yup.string().required('Address is required.'),
   // street2: Yup.string().required('Address is required.'),
   city: Yup.string().required('City is required.'),
   state: Yup.string().required('State is required.').min(2, "State code is not valid.").max(2, "State code is not vail"),
   zip: Yup.string().required('Zip is required.'),
   phone: Yup.string().required('Phone is required.'),
   country: Yup.string().required('Country is required.'),
});
let Index = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [countries, setCountries] = useState([]);
   let [data, setData] = useState({});
   let [shipmentStatus, setShipmentStatus] = useState(false)
   let [form, setForm] = useState(1);
   const topShipmentRef = useRef();
   let { id } = props.params
   useEffect(() => {
      Promise.all([
         getData(id),
         countriesFun(),]).then((values) => {
            console.log(values);
            setData(values[0])
            setCountries(values[1])
            setApiLoading(false)
         });
      if (data?.shipment_status === "cancelled") {
         topShipmentRef.current.removeChild(topShipmentRef.current.firstChild);
      }
   }, [shipmentStatus])

   let getData = async (id) => {
      try {
         let data = await AppApi.shipmentDetails(id).then(res => {
            if (res.status == 200) {
               return res.data.data
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
      }
   }
   let countriesFun = async () => {
      try {
         let data = await AuthApi.getCountries().then(res => {
            if (res.status == 200) {
               return res.data.data
            }
         }).catch(error => {
            
         })
         return data
      } catch (error) {
         
      }
   }

   let getCountryName = (countries, id) => {
      let list = [...countries];
      console.log(list)
      let filterData = list.filter(data => data.id == id);
      if (filterData[0]) {
         return filterData[0].country_name
      } else {
         return id
      }
   }

   return (
      <>
         <PublicHeader />

         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <>

               {data?.item?.id ? (
                  <div className="container" style={{ minHeight: "70vh" }}>
                     <div className="row mt-3">
                        <div className="col-md-6">
                           <ItemShipment id={id} setShipmentStatus={setShipmentStatus} shipmentStatus={shipmentStatus} />
                        </div>
                        <div className="col-md-6" ref={topShipmentRef}>
                           {data?.shipment_status != "paid" || data?.shipment_status != "cancelled" && <TopShipment id={id} />}
                           {data?.shipment_status == "draft" && <TopShipment id={id} setShipmentStatus={setShipmentStatus} shipmentStatus={shipmentStatus} />}
                           {data?.shipment_status !== 'draft' && data?.shipment_status === 'unpaid' && data?.shipment?.shipment_rate_id == null && (
                              <TopShipment id={id} setShipmentStatus={setShipmentStatus} shipmentStatus={shipmentStatus} />
                           )}
                           {data?.shipment_status === 'unpaid' && data?.shipment?.shipment_rate_id !== null && (<TopShipment id={id} setShipmentStatus={setShipmentStatus} shipmentStatus={shipmentStatus} />)}
                           {data?.shipment_status == "cancelled" && (
                              <div className="alert alert-danger">
                                 Shipment has been cancelled by the user
                              </div>
                           )}
                           {data?.shipment_status == "paid" && (
                              <div className="alert alert-success">
                                 Your shipment has been paid.
                              </div>
                           )}

                        </div>
                     </div>
                  </div>
               ) : (
                  <div className="container">
                     <div className="row">
                        <div className="col-12">
                           <div className="alert alert-danger mt-5" style={{ marginBottom: "500px" }}>
                              No shipment found with this record.
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </>
         )
         }

         {/* ********************************************** */}
         <AuthFooter />
      </>
   )
}

export default withParams(withNavigation(Index))