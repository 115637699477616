import { configureStore } from '@reduxjs/toolkit';
import UserPermissions from './reducers/userPermissions.js';
import shipmentPermissions from './reducers/shipmentPermissions';
import items from './reducers/items';

export const store = configureStore({
  reducer: {
    UserPermissions,
    shipmentPermissions,
    items,
  },
})