import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AppApi from '../../Api/AppApi';
let EnableClient = (props) => {
    let [profileStatus, setProfileStatus] = useState("");
    let [apiLoading, setApiLoading] = useState(false);
    useEffect(() => {
        setProfileStatus(props.client.status_code);
    }, [])
    let updateStatus = async () => {
        if (profileStatus == "D") {
            try {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to enable this account!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, enable it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setApiLoading(true)
                        AppApi.enableClient(props.client.id).then(res => {
                            toast.success('Account has been updated.', {
                                autoClose: 5000,
                            });
                            setProfileStatus("A")
                            setApiLoading(false)
                        }).catch(error => {
                            setApiLoading(false)
                            
                            try {
                                let message = error.response.data.errors[0] + "."
                                toast.error(message ? message : 'Subscription not updated.', {
                                    autoClose: 5000,
                                });
                            } catch (error) {
                                
                                toast.error('Server error.', {
                                    autoClose: 5000,
                                });
                            }
                        })
                    }
                })
            } catch (error) {
                setApiLoading(false)
                toast.error('Make sure you have internet connection.', {
                    autoClose: 5000,
                });
            }

        } else {
            try {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to disable this account!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, enable it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setApiLoading(true)
                        AppApi.disableClient(props.client.id).then(res => {
                            toast.success('Account has been updated.', {
                                autoClose: 5000,
                            });
                            setProfileStatus("D")
                            setApiLoading(false)
                        }).catch(error => {
                            setApiLoading(false)
                            
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                        })
                    }
                })
            } catch (error) {
                setApiLoading(false)
                toast.error('Make sure you have internet connection.', {
                    autoClose: 5000,
                });
            }

        }
    }
    return (
        <div class="panel panel-default panel-danger border">
            <div class="panel-heading">
                <h3 class="panel-title text-danger">Enable/Disable Account</h3>
            </div>
            <div class="panel-body p-3">
                Current Status:{profileStatus}
                <br />
                {apiLoading ? "Upading...." : (
                    <>
                        {profileStatus == "D" ? (
                            <input type="submit"
                                onClick={() => {
                                    updateStatus();
                                }}
                                name="commit" value="Enable" class="btn btn-primary" data-disable-with="Enable" />
                        ) : (
                            <input
                                onClick={() => {
                                    updateStatus();
                                }}
                                type="submit" name="commit" value="Disable" class="btn btn-primary" data-disable-with="Enable" />
                        )}
                    </>
                )}

            </div>
        </div>
    )
}

export default EnableClient

