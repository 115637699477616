import React, { useEffect, useState } from 'react';
import { withNavigation, withParams } from './../../Utils/Navigater';
import Url from './../../Utils/Routes';
import { Link, NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
let ItmesAppNavigater = (props) => {
    let { id } = props.params;
    return (
        <>
            <div className="d-md-block d-none">
                <div className="default_user_change_penal  mt-4">
                    <NavLink activeClass="active" to={Url.EditNewItems(id)}><i className="fa fa-info-circle me-1"></i> Details</NavLink>
                    <NavLink activeClass="active" to={Url.ItemsRelease(id)}><i className="fa fa-chevron-circle-right me-1"></i> Release</NavLink>
                    <NavLink activeClass="active" to={Url.ItemsPhoto(id)}><i className="fa fa-camera me-1"></i>  Photo</NavLink>
                    <NavLink activeClass="active" to={Url.ItemsHistory(id)}><i className="fa fa-copy me-1"></i> History</NavLink>
                </div>
            </div>
            <div className="d-flex justify-content-end pb-2 mt-3">
                <Dropdown>
                    <Dropdown.Toggle className='setting-dropdown w-100 d-md-none d-block' id="dropdown-basic">
                        Item Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Link className='dropdown-item' to={Url.EditNewItems(id)}><i className="fa fa-info-circle me-1"></i> Details</Link>
                        <Link className='dropdown-item' to={Url.ItemsRelease(id)}><i className="fa fa-chevron-circle-right me-1"></i> Release</Link>
                        <Link className='dropdown-item' to={Url.ItemsPhoto(id)}><i className="fa fa-camera me-1"></i>  Photo</Link>
                        <Link className='dropdown-item' to={Url.ItemsHistory(id)}><i className="fa fa-copy me-1"></i> History</Link>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default withParams(withNavigation(ItmesAppNavigater))

