import React from 'react';
import { withNavigation, withParams } from './../../../Utils/Navigater';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Url from '../../../Utils/Routes'
import AppApi from '../../../Api/AppApi';

import { toast } from 'react-toastify';
const FormValidation = Yup.object().shape({
    password: Yup.string().required('Password is required').min(5, 'Too Short!'),
    password_confirmation: Yup.string().min(5, 'Too Short!').required('Confirm password is required.')
        .test('passwords-match', 'Passwords must match.', function (value) {
            return this.parent.password === value
        }),
});
let VerifyAccount = (props) => {
    let { id } = props.params;
    return (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
            validationSchema={FormValidation}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                try {

                    let apiObject = {
                        id: id,
                        data: {
                            user: {
                                password: values.password,
                                password_confirmation: values.password_confirmation
                            }
                        }
                    }
                    AppApi.ActiveUser(apiObject).then(result => {
                        if (result && result.status == 200) {
                            toast.success(result.data.message ? result.data.message : 'Account verified successfully.', {
                                autoClose: 5000,
                            });
                            localStorage.setItem("email", result.data.user.email)
                            props.navigate(Url.FreeTrial);
                        } else {
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                        }
                        setSubmitting(false)
                    }).catch(error => {
                        
                        setSubmitting(false)
                        try {
                            let message = error.response.data.errors[0]
                            toast.error(message ? message : 'Server Error!', {
                                autoClose: 5000,
                            });
                        } catch (error) {
                            
                        }
                    })
                } catch (error) {
                    setSubmitting(false)
                    toast.error('Server error.', {
                        autoClose: 5000,
                    });
                    console.log("server error", error.message)
                }

            }}
        >
            {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                <Form >
                    <div className="panel rounded-0    panel-default border">
                        <div className="panel-heading">
                            <h3 className="panel-title">Set Account Password</h3>
                        </div>
                        <div className="panel-body p-2">
                            <div className="form-group">
                                <label  >Password</label>
                                <Field className="form-control rounded-0 border-danger " placeholder="" type="password" name="password" />
                                <ErrorMessage component="small" className='text-danger' name="password" />
                            </div>
                            <div className="form-group">
                                <label  >Confirm New Password</label>
                                <Field className="form-control rounded-0 border-danger " placeholder="" type="password" name="password_confirmation" />
                                <ErrorMessage component="small" className='text-danger' name="password_confirmation" />
                            </div>

                        </div>
                        <div className="panel-footer">
                            {isSubmitting ? "Loading..." : (
                                <>
                                    <span>
                                        <button type='submit' className="btn btn-success rounded-0  me-1" ><i className="fa fa-check"></i> Save </button>
                                    </span>
                                </>
                            )}

                        </div>
                    </div>
                </Form>
            )}
        </Formik >


    )
}

export default withParams(withNavigation(VerifyAccount))