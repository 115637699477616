import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import AuthFooter from '../../Components/footers/LoginFooter'

let Privacy = (props) => {
   let [apiError, setApiError] = useState(false);
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div class="container">
               <h1>Privacy Policy</h1>
               <p>RepoApp LLC (“we”, “us”, or “RepoApp”) recognizes that your privacy is very important and takes it seriously. RepoApp is the owner and operator of repoapp.com (“our Website”) and RepoApp, a user sign-in, account-based service ("RepoApp" or "our Service").</p>

               <p>RepoApp is used by businesses or organizations (“Account Owner”) interested in managing lost and found items and by people who view and fill out a Lost Item Form that has been made available online by the Account Owner.</p>

               <p>This Privacy Policy is split into three sections: <strong>General Policy</strong>, <strong>Privacy For Account Owners</strong>, and <strong>Privacy for Lost Item Form Respondents</strong>. The “General Policy” section applies to “Privacy For Account Owners” and the “Privacy for Lost Item Form Respondents” section. </p>

               <h2>General Policy</h2>

               <h3>Cookies</h3>
               <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive.</p>

               <p>Like many websites, our Website uses "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.</p>

               <h3>Log Data</h3>
               <p>Like many other websites, our Website collects information that your browser sends whenever you visit our Website ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Website that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

               <h3>Your Consent</h3>
               <p>By using our Website or our Service, you consent to the collection and use of this information by us, our Website, and our Service.</p>

               <h3>Changes to This Privacy Policy</h3>
               <p>We reserve the right to change this Privacy Policy as we deem necessary or appropriate because of legal compliance requirements or changes in our business practices. If this Privacy Policy changes, the revised policy will be posted on this site. Please check back periodically. We will endeavor to notify Account Owners by email of any material change to how we will collect, use, or disclose your personally identifiable information.</p>

               <p>This Privacy Policy was last updated on June 30, 2015. If you have any questions or feedback related to our Privacy Policy, please email us at info@repoapp.com</p>

               <h2>Privacy For Account Owners</h2>

               <h3>Information collection and use</h3>
               <p>You need to sign up for an account in order to access or use RepoApp. When signing up for an account, we need to know your name, business name, business contact information (address and phone number), email address, and password. This allows us to create your account and billing profile. RepoApp permits you to create other sign-in user accounts that are part of your account. When you create these additional sign-in user accounts, we collect their names and email addresses.</p>

               <h3>Third Party Service Providers</h3>
               <p>We may share your Personal Information with third party service providers to: enable our Service to complete your request (such as receive automated emails from RepoApp); to provide technical support; and/or to provide other services to RepoApp. The third party service providers that we use are required not to use 
                  your Personal Information other than to provide the services requested by us.</p>

                  <h3>Security</h3>
                  <p>RepoApp has implemented reasonable security measures in order to protect both personal and non-personal information from loss, misuse and unauthorized access, disclosure, alteration or destruction. The security of your personal and non-personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                  <h3>Cookies</h3>
                  <p>RepoApp uses cookies to authenticate your identity, such as confirming whether you are currently logged into RepoApp. If you set up your Web browser (for example, Internet Explorer or Firefox) so that cookies are not allowed, you will not be able to access or use our Service. </p>

                  <h3>Disclosure of information</h3>
                  <p>RepoApp does not sell, trade, or rent your personal information to others. RepoApp may release part or whole of your account information and data when we believe, in good faith, that such release is reasonably necessary to (i) comply with law, (ii) enforce or apply the terms of any of our user agreements or (iii) protect the rights, property or safety of RepoApp, our users, or others.</p>

                  <p>RepoApp may use your business or organization’s name and logo in presentations, marketing materials, and website listings of customers, but this information will not include personally identifying information (such as your name, e-mail, or phone number). To exclude your business or organization's name or logo from marketing materials, please notify us.</p>

                  <h3>Your Privacy Responsibilities</h3>
                  <p>To help protect your privacy and RepoApp account data, be sure:</p>
                  <ul>
                     <li>not to share your sign-in ID or password with anyone else;</li>
                     <li>change your password from time to time;</li>
                     <li>to sign-out of RepoApp when you are finished;</li>
                     <li>to take customary precautions to guard against “malware” (viruses, Trojan horses, bots, etc.), such as installing and updating suitable anti-virus software.</li>
                  </ul>

                  <h3>Notice to European Union Account Owners</h3>
                  <p>RepoApp and RepoApp operations are located primarily in the United States. If you provide information to us, the information will be transferred out of the European Union (EU) to the United States. By providing personal information to us, you are consenting to its storage and use as described herein.</p>

                  <h2>Privacy for Lost Item Form Respondents</h2>
                  <p>RepoApp makes available to Account Owners the capability to publish a form that captures information on lost item(s). Account Owners may make this form available from their website.</p>
                  <p>RepoApp collects and stores the responses that you submit to the Lost Item Form. The Lost Item Form collects personally identifiable information (such as your name, email, and phone number). This allows the Account Owner of the Lost Item Form to receive your lost item(s) claim.</p>
                  <p>The Account Owner of the Lost Item Form is the owner of this data and is responsible for how it is managed. Please see our “Disclosure of information” section above for how we disclose data managed by Account Owners.</p>

            </div>
         </div>
         <AuthFooter />

      </>
         )
}

         export default withNavigation(Privacy)