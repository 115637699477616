import { createSlice } from '@reduxjs/toolkit';

const initialState = []

export const updateItemsListSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    updateItemsList: (state, action) => {
      return action.payload
    },
  },
})

export const { updateItemsList } = updateItemsListSlice.actions

export default updateItemsListSlice.reducer

