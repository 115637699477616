import {Button, Modal} from "react-bootstrap";
import AppApi from "../../Api/AppApi";
import {toast} from "react-toastify";
import {isApiSuccess} from "../../Utils/commonUtils"
import React, {useEffect, useState} from "react";

const EmailPreviewModal = ({handleClose, show, templateData, sendToEmail}) => {
    const [isEditMode, setIsEditMode] = useState(false)
    const [content, setContent] = useState("");
    const [tempContent, setTempContent] = useState("");
    const [shippingInfo, setShippingInfo] = useState("");
    const convertLineBreaks = (str) => {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    };
    const getShipmentFromAddress = async () => {
        try {
            AppApi.getShipmentFromAddress().then(res => {
                if (res?.data?.from_address && res.data.from_address[0]) {
                    setShippingInfo(res.data.from_address[0]);
                }
            })
        } catch (e) {

        }
    }

    useEffect(() => {
        if(templateData) {
            setContent(templateData.content);
            setTempContent(templateData.content);
            getShipmentFromAddress();
        }
    }, [templateData]);

    const sendEmailWithGivenTemplate = async () => {
        const data  = {
            email: {
                to: sendToEmail,
                subject: decodeURIComponent(templateData.subject),
                html: convertLineBreaks(decodeURIComponent(content)),
                from: `${shippingInfo?.company} <${shippingInfo?.email}>`,
            }
        }
        const resp = await AppApi.sendEmailUsingTemplate(data)
        if (isApiSuccess(resp)) {
            toast.success('Email sent.', {
                autoClose: 5000,
            });
        }
        handleClose();
    }

    return (
        <Modal className="p-2" show={show} onHide={ () => {
            handleClose()
            setIsEditMode(false);
        }} animation={false} centered size={isEditMode ? "lg" :"md"}>
            <Modal.Header closeButton className="border-0 text-center" >
                <h4 className="mt-auto mb-auto">{ isEditMode ? "Edit Template" : "Template Preview"}</h4>
            </Modal.Header>
            {templateData &&
                <Modal.Body>
                    {!isEditMode ?
                        <>
                            <div dangerouslySetInnerHTML={{__html: convertLineBreaks(decodeURIComponent(content))}} />
                        </>
                        :
                        <>
                            <textarea
                                value={tempContent}
                                onChange={(event) => setTempContent(event.target.value)}
                                className="form-control mb-2" autoComplete="off" required rows="8">
                            </textarea>
                            <p className="text-danger font-weight-bold pl-1 mb-0">Note: Any modifications you make will be saved exclusively for this particular email and will not affect the template permanently.</p>
                        </>
                    }
                </Modal.Body>
            }
            <Modal.Footer className="d-flex justify-content-between">
                {
                    isEditMode ?
                        <>
                            <Button variant="primary"
                                onClick={() =>{
                                    setContent(tempContent)
                                    setIsEditMode(false)
                                }}
                                    disabled={!tempContent}
                            >
                                Save Changes
                            </Button>
                            <Button variant="secondary" onClick={() => {
                                setTempContent(content)
                                setIsEditMode(false);
                            }}>
                                Cancel
                            </Button>
                        </>
                        : <>
                            <Button variant="primary" onClick={sendEmailWithGivenTemplate}>
                                Send
                            </Button>
                            <Button variant="secondary" onClick={() => {
                                setIsEditMode(true)
                                setTempContent(tempContent || templateData.content);
                            }}>
                                Edit
                            </Button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}
export default EmailPreviewModal;