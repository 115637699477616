import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Url from '../../Utils/Routes';


let PublicHeader = () => {


   return (
      <>
         <div className="border-bottom">
            <div className="container authHeader_wrapper">
               <div className="row">
                  <div className="col-12">
                     <div className="authHeaderContainer">
                        <Navbar expand="lg">
                           <Container fluid>
                              <Navbar.Brand href="#">
                                 <a href="#"> <img src="/assets/images/main-logo.png" className='applogo' alt="" /></a>
                              </Navbar.Brand>
                              <Navbar.Toggle aria-controls="navbarScroll" />
                              <Navbar.Collapse id="navbarScroll">
                                 <Nav
                                    className="ms-auto my-2 my-lg-0"
                                    navbarScroll
                                 >
                                    {/* <a target="_blank" href="https://www.repoapp.com/" className='nav-link active'>HOME</a>
                                 <a target="_blank" href="https://www.repoapp.com/features/" className='nav-link active'>FEATURES</a>
                                 <a target="_blank" href="https://www.repoapp.com/faqs/" className='nav-link active'>FAQs</a>
                              <a target="_blank" href="https://www.repoapp.com/blog/" className='nav-link active'>BLOG</a> */}
                                    <Link to={Url.ContactUs} className='nav-link active'>Contact Us</Link>
                                 </Nav>
                              </Navbar.Collapse>
                           </Container>
                        </Navbar>
                     </div>

                  </div>
               </div >
            </div >
         </div >
         
      </>
   )
}


export default PublicHeader