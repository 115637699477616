import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import EditTemplate from '../../Components/Forms/Templates/EditTemplate'
let Index = (props) => {





   return (
      <>
         <UserHeader />
         <UserAppNavigater />
         <div className="container-fluid mt-3">
            <div className="row">
               <div class="col-sm-1  ">
                  <p><Link class="btn btn-default border text-black font-14" to={Url.Templates}><i class="fa fa-reply"></i> Back</Link></p>
               </div>
               <div class="col-sm-11  ">
                  <EditTemplate />
               </div>
            </div>

         </div>
      </>
   )
}

export default withNavigation(Index)