import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized'
let Index = (props) => {
   let [data, setData] = useState([]);
   let [claim_page_enabled, setClaim_page_enabled] = useState(false);
   let [apiLoading, setApiLoading] = useState(true);
   let [account, setAccount] = useState({});
   let { id } = props.params;
   useEffect(() => {
      getApis(id);
   }, [id])

   let getApis = (id) => {
      getData(id);
      getAccountDetails(id);
   }

   let getAccountDetails = async (id) => {
      try {
         AppApi.getPublicAccountDetails(id).then(res => {
            if (res.status == 200) {
               setAccount(res.data)
               setClaim_page_enabled(res.data.search_page_enabled)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)
      }
   }
   let getData = async (id) => {
      try {
         AppApi.publicClaimsItems(id).then(res => {
            if (res.status == 200) {
               setData(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let filter = () => {
      var input, filter, table, tr, td, cell, i, j;
      input = document.getElementById("filterInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("filterTable");
      tr = table.getElementsByTagName("tr");
      for (i = 1; i < tr.length; i++) {
         // Hide the row initially.
         tr[i].style.display = "none";

         td = tr[i].getElementsByTagName("td");
         for (var j = 0; j < td.length; j++) {
            cell = tr[i].getElementsByTagName("td")[j];
            if (cell) {
               if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
                  tr[i].style.display = "";
                  break;
               }
            }
         }
      }
   }

   return (
      <>
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <>
               {claim_page_enabled ? (
                  <div className="container-fluid mt-5">
                     <div className="row">
                        <div className="col-md-5 text-right">
                           <div class="dataTables_filter"><label>Search:<input
                              onKeyUp={() => filter()}
                              type="text" class="p-0" id="filterInput" placeholder="" /></label></div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-5">
                           <div class="table-responsive">
                              <table class="table table-striped print-table" id='filterTable' >
                                 <thead>
                                    <tr >
                                       <th className='px-1' >Claim ID</th>
                                       <th className='px-1' >First Name</th>
                                       <th className='px-1' >Last Name</th>
                                       <th className='px-1' >Venue</th>
                                       <th className='px-1' style={{ minWidth: "100px" }}>Description</th>
                                       <th className='px-1' style={{ minWidth: "100px" }}>Location</th>
                                       <th className='px-1' style={{ minWidth: "100px" }}> Lost Date</th>
                                       <th className='px-1' style={{ minWidth: "100px" }}>Phone</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {apiLoading && (
                                       <tr >
                                          <td colSpan={6} className='text-center '>Loading...</td>
                                       </tr>
                                    )}
                                    {!apiLoading && data.length == 0 && (
                                       <tr>
                                          <td colSpan={6} className='text-center'>No Record</td>
                                       </tr>
                                    )}
                                    {!apiLoading && data.map((data, index) => {
                                       return (
                                          <tr >
                                             <td className='px-1'>{data.id} </td>
                                             <td className='px-1' >{data.claim_first_name} </td>
                                             <td className='px-1'>{data.claim_last_name} </td>
                                             <td className='px-1'>{data?.venue?.venue_name} </td>
                                             <td className='px-1'>{data.claim_description} </td>
                                             <td className='px-1'>{data.claim_lost_location} </td>
                                             <td className='px-1'>{data.claim_lost_date} </td>
                                             <td className='px-1'>{data.claim_phone} </td>
                                          </tr>
                                       )
                                    })}
                                 </tbody></table></div>
                           <img src="/assets/images/repoapp_powered_by.png" style={{ width: "100px" }} alt="" />
                           <a href="https://www.repoapp.com/privacy" target="_blank" rel="noreferrer" ><sup><small>Privacy</small></sup></a>
                        </div>
                     </div>
                  </div>
               ) : (<UnAuthorized />)}
            </>

         )}
      </>

   )
}

export default withParams(withNavigation(Index))