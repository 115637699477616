import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import Dropdown from 'react-bootstrap/Dropdown';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ItmesAppNavigater from '../../Components/AppComponents/ItmesAppNavigater';

let Index = (props) => {
   let { id } = props.params;
   let [data, setData] = useState({});
   let [dataHistory, setDataHistory] = useState([]);
   let [apiLoading, setApiLoading] = useState(true);
   useEffect(() => {
      getData()
      history()
   }, [])
   let history = async () => {
      try {
         ItemsApi.itemsHistory(id).then(res => {
            if (res.status == 200) {
               setDataHistory(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getData = async () => {
      try {
         console.log("props", props)

         ItemsApi.itemsDetails(id).then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }





   return (
      <>
         <UserHeader />
         <ItmesAppNavigater />



         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid mt-3">
               <div className="row">
                  <div className="col-12">
                     <p class="text-muted">
                        <i class="fa fa-info-circle"></i>
                        Item history records will show for updates to the item record while the
                        <Link className='link px-1' to={Url.AuditOption}>Audit Status</Link> feature is set to Enabled.
                     </p>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div class="panel border panel-default">
                        <div class="panel-heading">
                           <div class="panel-title">Current Record - ID: <span id="item_id">{data.id}</span></div>
                        </div>
                        <div class="panel-body p-2">
                           <div class="alert alert-warning  ">
                              <strong>Last Updated By:</strong>
                              <i class="fa fa-user"></i> {data?.update_user?.email} - <i class="fa fa-calendar"></i> {data.updated_at}
                           </div>
                           <div className="row">
                              <div class="col-md-6">
                                 <table class="table" id="current_item">
                                    <thead>
                                       <tr>
                                          <th><b>Field</b></th>
                                          <th><b>Value</b></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr><td>Description</td><td>{data.item_original_description}</td></tr>
                                       <tr><td>Web Description</td><td>{data.item_web_description}</td></tr>
                                       <tr><td>Venue</td><td>{data?.venue?.venue_name}</td></tr>
                                       <tr><td>Category</td><td>{data?.category?.category_description}
                                       </td></tr>
                                       <tr><td>Container</td><td>{data.item_container_name}</td></tr>
                                       <tr><td>Expire After</td><td>{data.item_expire_after}</td></tr>
                                       <tr><td>Found Date</td><td>{data.item_found_date}</td></tr>
                                       <tr><td>Storage Date</td><td>{data.item_storage_date}</td></tr>
                                       <tr><td>Asset / Badge ID</td><td>{data.item_asset_id}</td></tr>
                                       <tr><td>Suspected Owner</td><td>{data.item_suspected_owner_email}</td></tr>
                                       <tr><td>Found Location</td><td>{data.item_found_location}</td></tr>
                                       <tr><td>Found By</td><td>{data.item_found_by}</td></tr>
                                       <tr><td>Notes</td><td>{data.item_notes}</td></tr>
                                       <tr><td>Photo</td>
                                          <td>
                                             {data?.item_image_url ? (
                                                <a href={data.item_image_url} target="_blank" rel="noopener noreferrer"><i className="fas fa-image"></i></a>
                                             ) : ""}
                                          </td></tr>
                                       <tr><td>Status</td><td><strong>{data?.item_status?.item_status_description}</strong></td></tr>
                                       <tr><td>Updated By</td><td>{data?.update_user?.email}<br /> {data.updated_at}updated_at</td></tr>
                                       <tr><td>Created By (initial)</td><td>{data?.create_user?.email}<br /> {data.created_at}</td></tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-md-6">
                                 <table class="table"  >
                                    <thead>
                                       <tr>
                                          <th width="120px">Field</th>
                                          <th width="300px">Value</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr><td>Release Date</td><td >{data.item_release_datetime}
                                          <br />
                                          <br />
                                          {data.item_bulk_release_id && (
                                             <span>(Part of a Bulk Release Event)</span>
                                          )}
                                       </td></tr>
                                       <tr><td>Claim</td><td >{data.claim_id}</td></tr>
                                       <tr><td>Claim First Name</td><td >{data.item_claim_first_name}</td></tr>
                                       <tr><td>Claim Last Name</td><td >{data.item_claim_last_name}</td></tr>
                                       <tr><td>Claim Phone</td><td >{data.item_claim_phone}</td></tr>
                                       <tr><td>Claim Email</td><td >{data.item_claim_email}</td></tr>
                                       <tr><td>Claim Address</td><td >{data.item_release_address}</td></tr>
                                       <tr><td>Claim Notes</td><td >{data.item_release_notes}</td></tr>
                                    </tbody>
                                 </table>
                                 {data.item_release_signature && (
                                    <img src={data.item_release_signature} className="w-100" style={{ maxWidth: "200px" }} alt="" />
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {dataHistory.map((data, index) => {
                  return (
                     <div className="row">
                        <div className="col-12">
                           <div class="panel border rounded panel-danger ng-scope"    >
                              <div class="panel-heading">
                                 <h3 class="panel-title">History Record</h3>
                              </div>
                              <div class="panel-body p-2">
                                 <div class="alert alert-warning  ">
                                    Updated on <i class="fa fa-calendar"></i> {data.item_hist_created_at} - by
                                    <i class="fa fa-user"></i> {data.item_hist_created_at_user_email}
                                 </div>
                                 <div className="row">
                                    <div class="col-md-6">
                                       <table class="table" id="history_item_1">
                                          <thead>
                                             <tr>
                                                <th>Field</th>
                                                <th>Value</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr><td>Description</td><td >{data.item_hist_description}</td></tr>
                                             <tr><td>Web Description</td><td >{data.item_hist_web_description}</td></tr>
                                             <tr><td>Venue</td><td >{data.item_hist_venue_name}</td></tr>
                                             <tr><td>Category</td><td >{data.item_hist_category_description}</td></tr>
                                             <tr><td>Container</td><td >{data.item_hist_container_name}</td></tr>
                                             <tr><td>Expire After</td><td >{data.item_hist_expire_after}</td></tr>
                                             <tr><td>Found Date</td><td >{data.item_hist_found_date}</td></tr>
                                             <tr><td>Storage Date</td><td >{data.item_hist_storage_date}</td></tr>
                                             <tr><td>Asset / Badge ID</td><td >{data.item_hist_asset_id}</td></tr>
                                             <tr><td>Suspected Owner</td><td >{data.item_hist_suspected_owner}</td></tr>
                                             <tr><td>Found Location</td><td >{data.item_hist_found_location}</td></tr>
                                             <tr><td>Found By</td><td >{data.item_hist_found_by}</td></tr>
                                             <tr><td>Notes</td><td >{data.item_hist_notes}</td></tr>
                                             <tr>
                                                <td>Photo</td><td>

                                                   {data?.item_image_url ? (
                                                      <a href={data.item_image_url} target="_blank" rel="noopener noreferrer"><i className="fas fa-image"></i></a>
                                                   ) : ""}
                                                </td>
                                             </tr>
                                             <tr><td>Status</td><td><strong >{data.item_hist_status_description}</strong></td></tr>
                                             <tr><td>Updated</td><td >{data.item_hist_updated_at_user_email} <br /> {data.item_hist_updated_at}</td></tr>
                                             <tr><td>Created By (initial)</td><td >{data.item_hist_created_at_user_email} <br /> {data.item_hist_created_at}</td></tr>
                                          </tbody>
                                       </table>
                                    </div>
                                    <div class="col-md-6">
                                       <table class="table"  >
                                          <thead>
                                             <tr>
                                                <th width="120px">Field</th>
                                                <th width="300px">Value</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr><td>Release Date</td>
                                                <td >{data.item_hist_bulk_release_id}
                                                   <br />    {data.item_bulk_release_id && (
                                                      <span>(Part of a Bulk Release Event)</span>
                                                   )}
                                                </td>
                                             </tr>
                                             <tr><td>Claim</td><td >{data.item_hist_claim_id}</td></tr>
                                             <tr><td>Claim First Name</td><td >{data.item_hist_claim_first_name}</td></tr>
                                             <tr><td>Claim Last Name</td><td >{data.item_hist_claim_last_name}</td></tr>
                                             <tr><td>Claim Phone</td><td >{data.item_hist_claim_phone}</td></tr>
                                             <tr><td>Claim Email</td><td >{data.item_hist_claim_email}</td></tr>
                                             <tr><td>Claim Address</td><td >{data.item_hist_release_address}</td></tr>
                                             <tr><td>Claim Notes</td><td >{data.item_hist_release_notes}</td></tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               })}

            </div>
         )}

         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withParams(withNavigation(Index))