import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import { useSelector, useDispatch } from 'react-redux';
import Url from '../../Utils/Routes';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation = Yup.object().shape({
   primary_contact_first_name: Yup.string().min(4, 'Too Short!').required('First name is required.'),
   primary_contact_last_name: Yup.string().required('Last name is required.'),
   primary_contact_email: Yup.string().required('Email is required.').email("Email is not valid."),
});
let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState({});
   let [data1, setData1] = useState(0);
   let [data2, setData2] = useState(0);
   useEffect(() => {
      getData();
      getItems();
      getItems2();
   }, []) 
   let getData = async () => {
      try {
         AppApi.clientDetail().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getItems = async () => {
      try {
         AppApi.getItems().then(res => {
            if (res.status == 200) {
               setData1(res.data.plan_period_total_claims)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getItems2 = async () => {
      try {
         AppApi.getItemsClaims().then(res => {
            if (res.status == 200) {
               setData1(res.data.plan_period_total_items_history);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className="container-fluid">
                     <div className="row mt-3">
                        <div className="col-md-4">
                           <div class="panel border panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Account Information</div>
                              </div>
                              <div class="panel-body p-3">
                                 <form class="ng-pristine ng-valid">
                                    <div class="form-group">
                                       <label>Business Name</label>
                                       <input class="form-control " type="text" value={data.business_name} disabled />
                                       <label>Street 1</label>
                                       <input class="form-control " type="text" value={data.street1} disabled />
                                       <label>Apartment #/ Unit #</label>
                                       <input class="form-control " type="text" value={data.street2} disabled />
                                       <label>City</label>
                                       <input class="form-control " type="text" value={data.city} disabled />
                                       <label>State</label>
                                       <input class="form-control " type="text" value={data.state} disabled />
                                       <label>Postal Code</label>
                                       <input class="form-control " type="text" value={data.postal_code} disabled />
                                       <hr />
                                       <label>Phone</label>
                                       <input class="form-control " type="text" value={data.phone} disabled />
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div >
                        <div className="col-md-4">
                           <div class="panel border panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Primary Contact</div>
                              </div>
                              <div class="panel-body p-3">
                                 <div class="alert alert-warning">
                                    <i class="fa fa-info-circle"></i> For subscription renewal alerts, ensure the below contact is kept current.
                                 </div>
                                 <Formik
                                    initialValues={{
                                       primary_contact_first_name: data.primary_contact_first_name,
                                       primary_contact_last_name: data.primary_contact_last_name,
                                       primary_contact_email: data.primary_contact_email,
                                    }}
                                    validationSchema={FormValidation}
                                    onSubmit={(values, { setSubmitting }) => {
                                       setSubmitting(true);
                                       try {
                                          AppApi.updateClientDetails(values).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Updated successfully.', {
                                                   autoClose: 5000,
                                                });
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0]
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form >
                                          <div className="form-group">
                                             <label  >First Name</label>
                                             <Field className="form-control  " placeholder="" type="text" name="primary_contact_first_name" />
                                             <ErrorMessage component="small" className='text-danger' name="primary_contact_first_name" />
                                          </div>
                                          <div className="form-group">
                                             <label  >Last Name</label>
                                             <Field className="form-control  " placeholder="" type="text" name="primary_contact_last_name" />
                                             <ErrorMessage component="small" className='text-danger' name="primary_contact_last_name" />
                                          </div>
                                          <div className="form-group">
                                             <label  >Email</label>
                                             <Field className="form-control  " placeholder="" type="text" name="primary_contact_email" />
                                             <ErrorMessage component="small" className='text-danger' name="primary_contact_email" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <button class="btn btn-primary"   >Update Contact</button>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>

                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div class="panel border panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Subscription &amp; Usage</div>
                              </div>
                              <div class="panel-body p-3">
                                 <form class="ng-pristine ng-valid">
                                    <div class="form-group">
                                       <label for="plan_name">Current Plan</label>
                                       <input type="text" class="form-control " id="plan_name" value={data.plan_name} disabled />
                                       <label for="plan_start_date">Subscription Start</label>
                                       <input type="text" class="form-control " id="plan_start_date" value={data.plan_start_date} disabled />
                                       <label for="plan_end_date">Subscription End</label>
                                       <input type="text" class="form-control " id="plan_end_date" value={data.plan_end_date} disabled />
                                       <label for="plan_item_limit">Plan Max Items</label>
                                       <input type="text" class="form-control " id="plan_item_limit" value={data.plan_item_limit} disabled />
                                       <label for="plan_claim_limit">Plan Max Claims</label>
                                       <input type="text" class="form-control " id="plan_claim_limit" value={data.plan_claim_limit} disabled />
                                       <label for="plan_user_limit">Plan Max Users</label>
                                       <input type="text" class="form-control " id="plan_user_limit" value={data.plan_user_limit} disabled />
                                       <label for="plan_period_total_items">Current Items</label>
                                       <input type="text" class="form-control" id="plan_period_total_items" value={data1} disabled />
                                       <label for="plan_period_total_claims">Current Claims</label>
                                       <input type="text" class="form-control " value={data2} disabled />
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div >
                  </div >
               )
               }
            </>)}


      </>
   )
}

export default withNavigation(Index)