import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from './../../Utils/Navigater';
import DashboardHeader from '../../Components/Headers/AdminHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import moment from 'moment'
let DashBoard = (props) => {
   let [clientList, setClientList] = useState([]);
   let [apiLoader, setApiLoader] = useState(false);
   let [filters, setFilters] = useState({});

   useEffect(() => {
      getClintlist();
   }, [filters])
   let getClintlist = () => {
      setApiLoader(true);
      try {
         AppApi.clientList(filters).then(result => {
            console.log(result)
            if (result && result.status == 200) {
               setClientList(result.data.data)
               setApiLoader(false);
            }
         }).catch(error => {
            console.log("api error:", error)
         })
      } catch (error) {
         console.log("Client list api error :", error)
      }
   }

   let updateFiltes = (data) => {
      setFilters(data)
   }
   let businessNameSearch = async (data) => {
      if (data.data) {
         setApiLoader(true);
         if (!data.email) {
            try {
               AppApi.busNameSearch(data.data).then(result => {
                  console.log(result)
                  if (result && result.status == 200) {
                     if (result.data.data) {
                        setClientList(result.data.data)
                     } else {
                        setClientList([])
                     }
                     setApiLoader(false);
                  }
               }).catch(error => {
                  setClientList([])
                  console.log("api error:", error)
               })
            } catch (error) {
               console.log("Client list api error :", error)
            }
         } else {
            AppApi.emailSearch(data.data).then(result => {
               console.log(result)
               if (result && result.status == 200) {
                  if (result.data.data) {
                     setClientList(result.data.data)
                  } else {
                     setClientList([])
                  }
                  setApiLoader(false);
               }
            }).catch(error => {
               setClientList([])
               setApiLoader(false);
               console.log("api error:", error)
            })
         }
      } else {
         setApiLoader(false);
         getClintlist()
      }

   }

   return (
      <>
         <DashboardHeader updateFiltes={updateFiltes}
            businessNameSearch={businessNameSearch}
            showFilters={true} />
         <div className="p-2">
            <div className="container-fluid border">
               <div className="row">
                  <div className="col-12">
                     <div className="table-responsive">
                        <table className="table table-bordered " >
                           <thead>
                              <tr>
                                 <th>ID</th>
                                 <th>Business Name</th>
                                 <th>Trial Period</th>
                                 <th>Plan Start</th>
                                 <th>Plan End</th>
                                 <th>Plan Users</th>
                                 <th>Plan Limits</th>
                                 <th>Plan</th>
                                 <th>Status</th>
                                 <th>Primary Contact</th>
                                 <th>Last User Login</th>
                                 <th>Created</th>
                              </tr>
                           </thead>
                           <tbody>
                              {apiLoader && (
                                 <tr>
                                    <td colSpan={11} className="text-center">Loading...</td>
                                 </tr>
                              )}
                              {!apiLoader && clientList.length == 0 && (
                                 <tr>
                                    <td colSpan={11} className="text-center">No record found.</td>
                                 </tr>
                              )}
                              {!apiLoader && clientList.length > 0 && clientList.map((data, index) => {
                                 return (
                                    <tr class={data.status_code == "A" ? "success_row" : "danger_row"} key={index}>
                                       <td><Link to={Url.ClientDetails(data.id)}>{data.id}</Link></td>
                                       <td><strong><Link to={Url.ClientDetails(data.id)}>{data.business_name}</Link></strong> <br />
                                          {data.street1} <br /> {data.city}, {data.state}, {data.postal_code}</td>
                                       <td><nobr> {data.plan_start_date} </nobr> <nobr></nobr></td>
                                       <td><nobr>{data.plan_trial_start_date}</nobr></td>
                                       <td><nobr>{data.plan_trial_end_date}</nobr></td>
                                       <td>{data.plan_user_limit}</td>
                                       <td>Items: {data.plan_item_limit}<br /> Claims: {data.plan_claim_limit}<br /> Photos: {data.plan_photo_limit}</td>
                                       <td>{data.plan_name}</td>
                                       <td>{data.status_code}</td>
                                       <td>{data.primary_contact_first_name} {data.primary_contact_last_name}<br />{data.primary_contact_email}</td>
                                       <td>
                                          {data.latest_user_login ? moment(data.latest_user_login).local().format("MM/DD/Y HH:mm A") : '—'}
                                          {data.latest_user_email && (
                                             <>
                                                <br />
                                                {data.latest_user_email}
                                             </>
                                          )}
                                       </td>
                                       <td><nobr>  {moment(data.created_at).local().format("MM/DD/Y HH:mm A")}</nobr></td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
}

export default withNavigation(DashBoard)