import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ClaimItemsApi from '../../Api/ClaimItemsApi';
import Url from '../../Utils/Routes';
import ItemsApi from '../../Api/ItemsApi';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AppApi from '../../Api/AppApi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment'
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import { useSelector, useDispatch } from 'react-redux';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import { Tooltip } from 'react-bootstrap';
const FormValidation = Yup.object().shape({
});

let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [keyWord, setkeyWord] = useState("");
   let [apiLoading, setApiLoading] = useState(true);
   let [advanceFilter, setAdvanceFilter] = useState(false);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async (limit = 300) => {
      try {
         setApiLoading(true)
         ClaimItemsApi.index(limit).then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   const customStyles = {
      rows: {
          style: {
              minHeight: '45px', // override the row height
              fontSize: '14px'
          },
      },
      headCells: {
          style: {
              paddingLeft: '2px', // override the cell padding for head cells
              paddingRight: '0px',
              minHeight: '30px',
              fontWeight: 500,
          },
      },
      cells: {
          style: {
              paddingLeft: '2px', // override the cell padding for data cells
              paddingRight: '2px',
              maxWidth: "200px"
          },
      },
      highlightOnHoverStyle: {
			backgroundColor: "#f3f3f3 !important",

		},
   };
   const customSort = (rows, selector, direction) => {
      return rows.sort((rowA, rowB) => {
       // use the selector function to resolve your field names by passing the sort comparitors
       let aField, bField = '';
       try {
         aField = selector(rowA)?.toString()?.toLowerCase();
         bField = selector(rowB)?.toString()?.toLowerCase();
      } catch (exception){};
       let comparison = 0;
     
       if (aField > bField) {
        comparison = 1;
       } else if (aField < bField) {
        comparison = -1;
       }
     
       return direction === 'desc' ? comparison * -1 : comparison;
      });
     };

   let followUp = async () => {
      try {
         setApiLoading(true)
         ClaimItemsApi.followUp().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let handleKeyDown = event => {
      if (event.key === 'Enter') {
         searchItemsWithKeyWords()
      }
   };

   let searchItemsWithKeyWords = async (status = false) => {
      try {

         if (keyWord) {
            setApiLoading(true);
            
            ClaimItemsApi.searchWithKeyWords(keyWord, status).then(res => {
               if (res.status == 200) {
                  setData(res.data)
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false)
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            })
         } else {
            document.getElementById("keywordInput").focus();
            // toast.error('No record found.', {
            //    autoClose: 5000,
            // });
            // getData()
         }
      } catch (error) {
         setApiLoading(false)
         // toast.error('No record found.', {
         //    autoClose: 5000,
         // });
      }
   }

   let searchById = async () => {
      try {
         if (keyWord) {
            setApiLoading(true);
            ClaimItemsApi.getClaim(keyWord).then(res => {
               if (res.status == 200) {
                  setData([res.data])
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false)
               toast.error('No record found.', {
                  autoClose: 5000,
               });
               setData([])
            })
         } else {
            toast.error('Id is required.', {
               autoClose: 5000,
            });
         }
      } catch (error) {
         setData([])
         setApiLoading(false)
         toast.error('No record found.', {
            autoClose: 5000,
         });
      }
   }

   let [CategoriesData, setDataCategories] = useState([]);
   let [ContainersData, setDataContainers] = useState([]);
   let [venuesData, setDataVenues] = useState([]);
   useEffect(() => {
      getCategories();
      getVenues();
   }, [])
   let getCategories = async () => {
      try {
         AppApi.getCategories().then(res => {
            if (res.status == 200) {
               setDataCategories(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let getVenues = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setDataVenues(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   const columns = [
      {
         name: <div>Claim ID</div>,
         selector: row => row.id,
         sortable: true,
         width: "100px",
         cell: (data) => {
            return (
               <Dropdown className={`  success dashboardDropDown itemsTableDropDown`}>
                  <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                     {data.id}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                     <Link to={Url.EditClaim(data.id)} className='dropdown-item'>View/Edit Claim Details</Link>
                  </Dropdown.Menu>
               </Dropdown>
            )
         }
      },
      {
         name: <div>First Name</div>,
         selector: row => row.claim_first_name,
         sortable: true,
         width: "100px",
         cell: (data) => {
            return (
               <div>
                  {data?.claim_first_name}
               </div>
            )
         },
      },
      {
         name: <div>Last Name</div>,
         selector: row => row.claim_last_name,
         sortable: true,
         width: "100px",
         cell: (data) => {
            return (
               <div>
                  {data?.claim_last_name}
               </div>
            )
         },
      },
      {
         name: <div>Description</div>,
         selector: row => row.claim_description,
         sortable: true,
         width: "200px",
         cell: (data, index) => {
            return (
               <Link to={Url.EditClaim(data.id)} className=''>
                  <ToolTipContainer
                     index={index}
                     view={data?.claim_description?.substring(0, 170)}
                     placement="right"
                     icon="text-info"
                     title=""
                     des={data.claim_description}
                  />
               </Link>
            )
         }
      },
      {
         name: <div>Follow Up</div>,
         selector: row => row.claim_follow_up,
         sortable: true,
         width: "60px",
         cell: (data) => {
           return (
            <>
            {data.claim_follow_up ? (
               <i class="fa fa-flag fa-lg" ></i>
            ) : ""}
         </>
           )
         }
      },
      {
         name: <div>Lost Date</div>,
         selector: row => row.claim_lost_date,
         sortable: true,
         width: "100px",
         cell: (data) => {
            return (
               <div>
                  {data?.claim_lost_date}
               </div>
            )
         },
         
      },
      {
         name: <div>Lost Location</div>,
         selector: row => row.claim_lost_location,
         sortable: true,
         cell: (data) => {
            return (
               <div>
                  {data?.claim_lost_location}
               </div>
            )
         },
      },
      {
         name: <div>Venue</div>,
         selector: row => row?.venue?.venue_name,
         sortable: true,
         cell: (data) => {
            return (
               <div>
                  {data?.venue?.venue_name}
               </div>
            )
         },
      },
      {
         name: <div>Phone</div>,
         selector: row => row.claim_phone,
         sortable: true,
         width: "130px",
         cell: (data) => {
            return (
               <div>
                  {data?.claim_phone}
               </div>
            )
         },
      },
      {
         name: <div>Notes</div>,
         selector: row => row.claim_notes,
         sortable: true,
         width: "70px",
         cell: (data) => {
            return (
               <ToolTipContainer
                  view=""
                  index={1}
                  placement="left"
                  icon="text-info"
                  title=""
                  des={data.claim_notes}
               />
            )
         }
      },
      {
         name: <div>Created At</div>,
         width: "100px",
         selector: row => row.created_at,
         sortable: true,
         cell: (data) => {
            return (
               <>{moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase())}  </>
            )
         }
      },


   ];
   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.claims?.claims_view ? <UnAuthorized /> : (
            <>  <div className="container-fluid mt-2  ">
               <div className="row">
                  <div className="col-12">
                     <div className="addItemsContainers py-2">
                        <div className="">
                           <h4>Claims</h4>
                        </div>
                        <div className=" px-0 px-md-5">
                           <Link to={Url.AddNewClaim} className='btn btn-info rounded-0 me-2 '>Add Claim <i class="fa fa-angle-double-right"></i></Link>
                           <Dropdown as={ButtonGroup} className='itemsBtnDropDown'>
                              <Button variant="success" className='rounded-0'
                                 onClick={() => getData()}
                              ><i class="fas fa-sync-alt"></i> Refresh  </Button>

                              <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                              <Dropdown.Menu className='dropDownList'>
                                 <Dropdown.Item href="#"
                                    onClick={() => getData(100)}
                                 >Last 100 Non-expired Claims</Dropdown.Item>
                                 <Dropdown.Item href="#"
                                    onClick={() => getData(200)}
                                 >Last 200 Non-expired Claims</Dropdown.Item>
                                 <Dropdown.Divider />
                                 <Dropdown.Item href="#"
                                    onClick={() => followUp()}
                                 >Flagged Claims (last 300 in last year)</Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </div>
                        <div className="flex-grow-1 text-center">
                           <div className="itemsSearchContainer">
                              <div><input type="text" id='keywordInput' placeholder='Keyword(s)' value={keyWord}
                                 onChange={(event) => { setkeyWord(event.target.value) }}
                                 onKeyDown={handleKeyDown}
                              /></div>
                              <div>
                                 <Dropdown as={ButtonGroup} className='itemsBtnDropDown'>
                                    <Button
                                       onClick={() => {
                                          searchItemsWithKeyWords()
                                       }} variant="success" className='rounded-0'><i class="fa fa-search me-1"></i> Search  </Button>
                                    <Dropdown.Toggle split variant="success" id="fasdf" />

                                    <Dropdown.Menu className='dropDownList'>
                                       <Dropdown.Item href="#"
                                          onClick={() => searchItemsWithKeyWords(true)}
                                       >Search Expired Claims</Dropdown.Item>
                                       <Dropdown.Item href="#"
                                          onClick={() => searchById()}
                                       >Search by Claim ID</Dropdown.Item>
                                    </Dropdown.Menu>
                                 </Dropdown>
                              </div>
                           </div>
                        </div>
                        <div className="">
                           <button className={`advanceFilter ${advanceFilter ? "active" : ""}  btn-bordered btn rounded-0`}
                              onClick={() => setAdvanceFilter(!advanceFilter)}><i class="fa fa-search-plus me-1"></i> Advanced Search</button>
                        </div>
                     </div>
                  </div>
               </div>

               {advanceFilter && (
                  <Formik
                     initialValues={{
                        category_id: "",
                        claim_lost_date_end: "",
                        claim_lost_date_start: "",
                        claim_status: "",
                        keyword: "",
                        venue_id: "",
                     }}
                     validationSchema={FormValidation}
                     onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                           setApiLoading(true)
                           let data = { ...values }
                           if (data.claim_lost_date_end) {
                              data.claim_lost_date_end = moment(data.claim_lost_date_end).local().format(localStorage.getItem("date_format").toString().toUpperCase())
                           }
                           if (data.claim_lost_date_start) {
                              data.claim_lost_date_start = moment(data.claim_lost_date_start).local().format(localStorage.getItem("date_format").toString().toUpperCase())
                           }
                           ClaimItemsApi.advanceSearch(data, "").then(res => {
                              setApiLoading(false);
                              if (res && res.status == 200) {
                                 setData(res.data);
                                 if (res.data.length == 0) {
                                    toast.warn('No record found.', {
                                       autoClose: 5000,
                                    });
                                 } else {
                                    toast.info(`${res.data.length} records found.`, {
                                       autoClose: 5000,
                                    });
                                 }
                              } else {
                                 toast.error('Server error.', {
                                    autoClose: 5000,
                                 });
                              }
                              setSubmitting(false)
                           }).catch(error => {
                              setApiLoading(false);
                              setSubmitting(false)
                              try {
                                 let message = error.response.data.errors[0]
                                 toast.error(message ? message : 'Server Error!', {
                                    autoClose: 5000,
                                 });
                              } catch (error) {
                                 
                              }
                           })
                        } catch (error) {
                           setApiLoading(false);
                           setSubmitting(false)
                           toast.error('Server error.', {
                              autoClose: 5000,
                           });
                           console.log("server error", error.message)
                        }

                     }}
                  >
                     {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting, resetForm }) => (
                        <Form >
                           <div className="row">
                              <div className="col-sm-12">
                                 <div className="panel panel-warning "  >
                                    <div className="panel-heading p-0">
                                       <div className="panel-heading d-flex justify-content-between">
                                          <h3 className="panel-title">Advanced Search   </h3>
                                          <ToolTipContainer
                                             placement="left"
                                             view=""
                                             icon="text-warning"
                                             title="Advanced Search"
                                             des="Use any combination of the below fields along with an item status to search for items "
                                          />
                                       </div>
                                    </div>
                                    <div className="panel-body p-2">
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Category</label>
                                                <select className='form-control'
                                                   value={values.category_id}
                                                   onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                >
                                                   <option value="">-Pick Category -</option>
                                                   {CategoriesData.map((data, index) => {
                                                      return (
                                                         <option key={data.id} value={data.id}>{data.category_description}</option>
                                                      )
                                                   })}
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Venue</label>
                                                <select name="" id="" className='form-control'
                                                   onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                   value={values.venue_id}
                                                >
                                                   <option value="">-Pick Venue -</option>
                                                   {venuesData.map((data, index) => {
                                                      return (
                                                         <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                      )
                                                   })}
                                                </select>
                                             </div>
                                          </div>

                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Lost on or After</label>
                                                <DatePickerInput
                                                   onChange={setFieldValue}
                                                   name="claim_lost_date_start"                                                   
                                                   value={values.claim_lost_date_start}      
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Lost on or Before</label>
                                                <DatePickerInput
                                                   onChange={setFieldValue}
                                                   name="claim_lost_date_end"
                                                   value={values.claim_lost_date_end}
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Status</label>
                                                <select name="" id="" className='form-control'
                                                   onChange={(event) => setFieldValue("claim_status", event.target.value)}
                                                   value={values.claim_status}
                                                >
                                                   <option value="" selected="selected">--Either--</option>
                                                   <option value="match_released">Matched/Released</option>
                                                   <option value="active">Active</option>
                                                </select>

                                                <ErrorMessage component="small" className='text-danger' name="claim_status" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="control-label" for="search_page_logo_url">Keyword</label>
                                                <Field className="form-control  " type="text" name="keyword" />
                                                <ErrorMessage component="small" className='text-danger' name="keyword" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">

                                          </div>
                                          <div className="col-md-6 d-flex align-items-center">
                                             <div className="input-group">
                                                <button type='submit' className="btn btn-default"><i className="fa fa-search"></i> Search </button>
                                                <button className="btn btn-default" type='button' onClick={() => {
                                                   getData();
                                                   resetForm();
                                                   // setAdvanceFilter(!advanceFilter)
                                                }} >   Reset</button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Form>
                     )}
                  </Formik>
               )}
               <div className="row">
                  <div className="col-sm-12">
                     <div className="panel border panel-default">
                        <div className="panel-heading">
                           <strong>Claims</strong>
                           <div className="badge badge-info ms-2 ">
                              {data.length}
                           </div>
                        </div>
                        <DataTable
                           columns={columns}
                           data={data}
                           
                           customStyles={customStyles}
                           sortFunction={customSort}
                           fixedHeader
                           progressPending={apiLoading}
                        />

                     </div>
                  </div>
               </div>
            </div>
            </>
         )}
         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withNavigation(Index)