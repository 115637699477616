import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi'
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import AuthFooter from '../../Components/footers/LoginFooter';
const SignupSchema = Yup.object().shape({
   email: Yup.string().required('Email is required.').email("Email is not valid."),
});
let ForgetPassword = (props) => {
   return (
      <>
         <AuthHeader />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div className="row">
                  <div className="col-12"><h1 className='text-danger2 roboto-light'>Reset Password</h1></div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <Formik
                        initialValues={{
                           email: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                           setSubmitting(true)
                           try {
                              AuthApi.forgetPasswordEmail(values).then(result => {
                                 if (result && result.status == 200 && result.data) {
                                    toast.success(result.data.message, {
                                       autoClose: 5000,
                                    });
                                 } else {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                                 console.log(result)
                              }).catch(error => {
                                 try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                       autoClose: 5000,
                                    });
                                 } catch (error) {
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                 }
                                 setSubmitting(false)
                                 console.log(error.message)
                              })

                           } catch (error) {
                              setSubmitting(false)
                              toast.error('Server error.', {
                                 autoClose: 5000,
                              });
                              console.log("server error", error.message)
                           }

                        }}
                     >
                        {({ errors, touched, values, isSubmitting }) => (
                           <div className="panel border rounded p-3 panel-default">
                              <div className="panel-body">
                                 <Form className="form" id="login" >
                                    <div className="form-group">
                                       <label htmlFor="session_email">Email</label>
                                       <Field className="form-control" placeholder="Email" type="text" name="email" />
                                       <ErrorMessage component="small" className='text-danger' name="email" />
                                    </div>

                                    <button type={isSubmitting ? "button" : "submit"} id="submit" className="btn btn-primary">
                                       {isSubmitting ? "Loading...." : (<><i className="fa fa-check text-white"></i>  Reset Password</>)}
                                    </button>
                                 </Form>
                              </div>
                           </div>
                        )}
                     </Formik>
                     <div className="alert alert-secondary mt-4">
                        <i className="fa fa-question-circle text-info1"></i> <Link to={Url.Login}>Login?</Link>
                     </div>
                     <div className="alert alert-info mt-4 ">
                        <i className="fa fa-info-circle"></i>  Be sure to also check your SPAM/Junk Mail folder for a Password Reset email. Please allow up to 3 minutes for receipt of the email.
                     </div>
                  </div>
               </div>

            </div>
         </div>
         <AuthFooter />
      </>
   )
}

export default withNavigation(ForgetPassword)