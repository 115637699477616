import React, { useEffect, useState } from 'react';
import { withNavigation, withParams } from './../../../Utils/Navigater';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Url from '../../../Utils/Routes'
import AppApi from '../../../Api/AppApi';
const FormValidation = Yup.object().shape({
    first_name: Yup.string().min(4, 'Too Short!').required('First name is required.'),
    last_name: Yup.string().min(4, 'Too Short!').required('Last name is required.'),
    email: Yup.string().required('Email is required.').email("Email is not valid."),
    role_code: Yup.string().required('Account role is required.'),
});
let EditTemplate = (props) => {
    let [apiLoading, setApiLoading] = useState(true);
    let [data, setData] = useState({});
    useEffect(() => {
        getTemplateDetails()
    }, [])
    let getTemplateDetails = async () => {
        let { id } = props.params;
        try {
            AppApi.usersDetails(id).then(res => {
                if (res.status == 200) {
                    setData(res.data)
                }
                setApiLoading(false)
            }).catch(error => {
                setApiLoading(false)
                toast.error('Server error.', {
                    autoClose: 5000,
                });
            })
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }
    return (
        <>

            {apiLoading ? (
                <div className="page-center-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        phone: data.phone,
                        role_code: data.role_code,
                    }}
                    validationSchema={FormValidation}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                            let objects = {
                                data: values,
                                id: data.id
                            }
                            AppApi.UpdateUserDetails(objects).then(result => {
                                if (result && result.status == 200) {
                                    toast.success('Updated successfully.', {
                                        autoClose: 5000,
                                    });
                                    props.navigate(Url.Users);
                                } else {
                                    toast.error('Server error.', {
                                        autoClose: 5000,
                                    });
                                }
                                setSubmitting(false)
                            }).catch(error => {
                                setSubmitting(false)
                                try {
                                    let message = error.response.data.errors[0]
                                    toast.error(message ? message : 'Server Error!', {
                                        autoClose: 5000,
                                    });

                                } catch (error) {
                                    
                                }
                            })
                        } catch (error) {
                            setSubmitting(false)
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                            console.log("server error", error.message)
                        }

                    }}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                        <Form >

                            <div className="panel rounded-0 panel-default border">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Edit User Details</h3>
                                </div>
                                <div className="panel-body p-2">
                                    <div className="form-group">
                                        <label  >Email</label>
                                        <Field className="form-control  " placeholder="" type="text" name="email" />
                                        <ErrorMessage component="small" className='text-danger' name="email" />
                                    </div>
                                    <div className="form-group">
                                        <label  >First Name</label>
                                        <Field className="form-control  " placeholder="" type="text" name="first_name" />
                                        <ErrorMessage component="small" className='text-danger' name="first_name" />
                                    </div>
                                    <div className="form-group">
                                        <label  >Last Name</label>
                                        <Field className="form-control  " placeholder="" type="text" name="last_name" />
                                        <ErrorMessage component="small" className='text-danger' name="last_name" />
                                    </div>
                                    <div className="form-group">
                                        <label  >Role</label>
                                        <select class="form-control  "
                                            value={values.role_code}
                                            onChange={(event) => setFieldValue("role_code", event.target.value)}
                                        >
                                            <option value="" selected="selected">-- Pick A Role --</option>
                                            <option value="A">Admin</option>
                                            <option value="I">Items/Claims</option>
                                            <option value="C">Claims Only</option>
                                            <option value="R">ReadOnly</option>
                                        </select>
                                        <ErrorMessage component="small" className='text-danger' name="role_code" />
                                    </div>
                                    <div className="form-group">
                                        <label  >Status</label>
                                        <input
                                            value={`${data.status_code == "A" ? "Active" : "Pending"}`}
                                            className="form-control" placeholder="" type="text" disabled />
                                    </div>
                                    <div className="form-group">
                                        <label  >Activation Link</label>
                                        <input className="form-control"
                                            value={Url.ActiveUser(data.gui_id)}
                                            placeholder="" type="text" name="last_name" disabled />
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    {isSubmitting ? "Loading..." : (
                                        <>
                                            <span>
                                                <button type='submit' className="btn btn-success rounded-0 me-1" ><i className="fa fa-check"></i> Update </button>
                                                <button className="btn btn-danger rounded-0 disable me-1" type='button' onClick={() => {
                                                    setSubmitting(true)
                                                    try {
                                                        AppApi.DeleteUser(data.id).then(res => {
                                                            if (res.status == 200) {
                                                                toast.success('Deleted successfully!', {
                                                                    autoClose: 5000,
                                                                });

                                                                props.navigate(Url.Users);
                                                            } else {
                                                                toast.error('Server error.', {
                                                                    autoClose: 5000,
                                                                });

                                                            }
                                                        }).catch(error => {
                                                            setSubmitting(false);
                                                            try {
                                                                let message = error.response.data.errors[0]

                                                                toast.error(message ? message : 'Server Error!', {
                                                                    autoClose: 5000,
                                                                });

                                                            } catch (error) {
                                                                toast.error('Server error.', {
                                                                    autoClose: 5000,
                                                                });

                                                            }
                                                        })
                                                    } catch (error) {
                                                        setSubmitting(false)
                                                        toast.error('Server error.', {
                                                            autoClose: 5000,
                                                        });

                                                        console.log("server error", error.message)
                                                    }

                                                }}><i className="fa fa-trash"></i> Delete </button>
                                                {data.status_code == "A" ? (
                                                    <button type='submit' className="btn btn-warning rounded-0 me-1 ms-1"
                                                        onClick={() => {
                                                            setSubmitting(true)
                                                            try {
                                                                AppApi.disableUsersAccount(data.id).then(res => {
                                                                    if (res.status == 200) {
                                                                        toast.success('User Disabled!', {
                                                                            autoClose: 5000,
                                                                        });
                                                                        props.navigate(Url.Users);
                                                                    }
                                                                }).catch(error => {
                                                                    setSubmitting(false);
                                                                    try {
                                                                        let message = error.response.data.errors[0]
                                                                        toast.error(message ? message : 'Server Error!', {
                                                                            autoClose: 5000,
                                                                        });
                                                                    } catch (error) {
                                                                        toast.error('Server error.', {
                                                                            autoClose: 5000,
                                                                        });
                                                                    }
                                                                })
                                                            } catch (error) {
                                                                setSubmitting(false)
                                                                toast.error('Server error.', {
                                                                    autoClose: 5000,
                                                                });
                                                                console.log("server error", error.message)
                                                            }

                                                        }}
                                                    ><i className="fa fa-times"></i> Disable </button>
                                                ) : ""}
                                                {data.status_code == "D" ? (
                                                    <button type='submit' className="btn btn-warning rounded-0 me-1 ms-1 text-white"
                                                        onClick={() => {
                                                            setSubmitting(true)
                                                            try {
                                                                AppApi.activeUsersAccount(data.id).then(res => {
                                                                    if (res.status == 200) {
                                                                        toast.success('User Active successfully!', {
                                                                            autoClose: 5000,
                                                                        });
                                                                        props.navigate(Url.Users);
                                                                    }
                                                                }).catch(error => {
                                                                    setSubmitting(false);
                                                                    try {
                                                                        let message = error.response.data.errors[0]
                                                                        toast.error(message ? message : 'Server Error!', {
                                                                            autoClose: 5000,
                                                                        });
                                                                    } catch (error) {
                                                                        toast.error('Server error.', {
                                                                            autoClose: 5000,
                                                                        });
                                                                    }
                                                                })
                                                            } catch (error) {
                                                                setSubmitting(false)
                                                                toast.error('Server error.', {
                                                                    autoClose: 5000,
                                                                });
                                                                console.log("server error", error.message)
                                                            }

                                                        }}
                                                    ><i className="fa fa-check "></i> Activate </button>
                                                ) : ""}
                                            </span>
                                            <Link className="btn btn-default" to={Url.Users}>Cancel</Link>
                                        </>
                                    )}

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}

        </>



    )
}

export default withParams(withNavigation(EditTemplate))