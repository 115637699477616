import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import ReportsApi from '../../Api/ReportsApi';
import { toast } from 'react-toastify';
import Url from '../../Utils/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
const FormValidation1 = Yup.object().shape({
   venue_id: Yup.string().required('Venue is required.'),
   venue_date_range: Yup.string().required('Date is required.'),
});
const FormValidation2 = Yup.object().shape({
   item_create_from_date: Yup.string().required('Item created date is required.'),
   item_create_to_date: Yup.string().required('Item created to date is required.'),
});
const FormValidation4 = Yup.object().shape({
   item_create_from_date: Yup.string().required('Start date is required.'),
   item_create_to_date: Yup.string().required('End  date is required.'),
});
const FormValidation5 = Yup.object().shape({
   item_release_from_date: Yup.string().required('Start date is required.'),
   item_release_to_date: Yup.string().required('End  date is required.'),
});
const FormValidation6 = Yup.object().shape({
   claim_create_from_date: Yup.string().required('Start date is required.'),
   claim_create_to_date: Yup.string().required('End  date is required.'),
});
const FormValidation3 = Yup.object().shape({
   // type: Yup.string().required('Type is required.'),
   // from: Yup.string().test('validation-test-type', 'Start date is required.', function (value) {
   //    if (this.parent.type == "custom") {
   //       if (this.parent.form == "") {
   //          return true
   //       } else {
   //          return false
   //       }
   //    } else {
   //       return false
   //    }
   // }),
});
let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [apiLoading, setApiLoading] = useState(true);
   let [venueData, setVenueData] = useState([]);
   useEffect(() => {
      getVenueData();
   }, [])
   let getVenueData = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setVenueData(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let itemsDeletedReports = async () => {
      try {
         ReportsApi.itemsDeletedReports().then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xls`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.errors[0]
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }
   let dispositionCountReport = async () => {
      try {
         ReportsApi.dispositionCountReport().then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xls`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.errors[0]

               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }
   let categoryCountReport = async () => {
      try {
         ReportsApi.categoryCountReport().then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xls`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.reports?.report_view ? <UnAuthorized /> : (
            <>
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className="container-fluid mt-3">
                     <div className="row">
                        <di className="col-lg-5">
                           <ul className="list-group">
                              <Formik
                                 initialValues={{
                                    venue_id: "",
                                    venue_date_range: ""
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       ReportsApi.monthlyReportsClaimsActivityReports(values).then(res => {
                                          if (res && res.status == 200) {
                                             const outputFilename = `${Date.now()}.xlsx`;
                                             const url = URL.createObjectURL(new Blob([res.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', outputFilename);
                                             document.body.appendChild(link);
                                             link.click();
                                             setFieldValue("venue", "")
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]

                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <li className="list-group-item">
                                          <p className="lead">Items and Claims Activity Report</p>
                                          <p className="text-muted">Report contains count of items and claims created per month and count of items released per month for the past year.</p>
                                          <div className="form-group">
                                             <label for="control-label">Venue</label>
                                             <select class="form-control "
                                                value={values.venue}
                                                onChange={(event) => {
                                                   setFieldValue("venue_id", event.target.value);
                                                }}>
                                                <option value="" selected="selected">-- Pick A Venue --</option>
                                                <option value="all">All</option>
                                                {venueData.map((data, index) => {
                                                   return (
                                                      <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="venue_id" />
                                          </div>
                                          <div className="form-group">
                                             <label for="control-label">Date Range</label>
                                             <select className="form-control  "
                                                onChange={(event) => {
                                                   setFieldValue("venue_date_range", event.target.value);
                                                }}
                                             >
                                                <option value="" selected="selected">- Pick Date Range -</option>
                                                <option value="ttm">Trailing 12 Months (TTM)</option>
                                                <option value="2023" className="ng-binding">2023</option>
                                                <option value="2022" className="ng-binding">2022</option>
                                                <option value="2021" className="ng-binding">2021</option>
                                                <option value="2020" className="ng-binding">2020</option>
                                                <option value="2019" className="ng-binding">2019</option>
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="venue_date_range" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                                <button class="btn btn-info rounded-0 text-white me-1" type='button'
                                                   onClick={() => {
                                                      props.navigate(Url.MonthlyActivityReport(values.venue_id, values.venue_date_range));

                                                   }}
                                                   disabled={(values.venue_id !== "" && values.venue_date_range !== "") ? false : true}
                                                ><i className="fas fa-chart-bar"></i> View</button>
                                                <button class="btn btn-info rounded-0 text-white" type='submit'><i className="fa fa-cloud-download"></i> Download</button>
                                             </span>
                                          )}
                                       </li>
                                    </Form>
                                 )}
                              </Formik>
                              <Formik
                                 initialValues={{
                                    type: "",
                                    from: "",
                                    to: ""
                                 }}
                                 validationSchema={FormValidation3}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       ReportsApi.containerCounterReport(values).then(res => {
                                          if (res && res.status == 200) {
                                             const outputFilename = `${Date.now()}.xlsx`;
                                             const url = URL.createObjectURL(new Blob([res.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', outputFilename);
                                             document.body.appendChild(link);
                                             link.click();
                                             setFieldValue("type", "")
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <li className="list-group-item">
                                          <p className="lead">Container Report</p>
                                          <p className="text-muted">Report shows count of available items by container</p>
                                          <div className="form-group">
                                             <label for="control-label">Date Range</label>
                                             <select class="form-control "
                                                value={values.venue}
                                                onChange={(event) => {
                                                   setFieldValue("type", event.target.value);
                                                   if (event.target.value != "custom") {
                                                      setFieldValue("from", "");
                                                      setFieldValue("to", "");
                                                   }
                                                }}>
                                                <option value="" selected="selected">- Pick Date Range -</option>
                                                <option value="today">Today</option>
                                                <option value="yesterday">Yesterday</option>
                                                <option value="this_week">This Week</option>
                                                <option value="last_week">Last Week</option>
                                                <option value="this_month">This Month</option>
                                                <option value="last_month">Last Month</option>
                                                <option value="custom">Custom</option>
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="type" />
                                          </div>
                                          {values.type == "custom" && (
                                             <>
                                                <div className="form-group">
                                                   <label class="control-label" >From</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="from"
                                                      value={values.from}
                                                   />
                                                   <ErrorMessage component="small" className='text-danger' name="from" />
                                                </div>
                                                <div className="form-group">
                                                   <label class="control-label"  >To</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="to"
                                                      value={values.to}
                                                   />
                                                   <ErrorMessage component="small" className='text-danger' name="to" />
                                                </div>
                                             </>
                                          )}
                                          {isSubmitting ? "Loading..." : (
                                             <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                                <button class="btn btn-info rounded-0 text-white me-1" type='button'
                                                   disabled={values.type == "" ? true : values.type !== "custom" ? false : (values.from !== "" && values.to !== "") ? false : true}
                                                   onClick={() => {
                                                      props.navigate(Url.ContainerCountReport(values.type, values.from, values.to));
                                                   }}><i className="fas fa-chart-bar"></i> View</button>
                                                <button class="btn btn-info rounded-0 text-white" type='submit'><i className="fa fa-cloud-download"></i> Download</button>
                                             </span>
                                          )}
                                       </li>
                                    </Form>
                                 )}
                              </Formik>

                              <li className="list-group-item">
                                 <p className="lead">Category Report</p>
                                 <p className="text-muted">Report shows count of available items by categories</p>
                                 <p>
                                    <button
                                       onClick={() => {
                                          props.navigate(Url.CategoryCountReport);
                                       }} className="btn btn-info rounded-0 text-white"  ><i className="fa fa-file"></i> View</button>
                                    <button className="btn btn-info rounded-0 text-white ms-1"
                                       onClick={() => categoryCountReport()}
                                    ><i className="fa fa-cloud-download"></i> Download</button>
                                 </p>
                              </li>
                              <li className="list-group-item">
                                 <p className="lead">Disposition Report</p>
                                 <p className="text-muted">Report shows count of released items by dispositions</p>
                                 <p>
                                    <button className="btn btn-info rounded-0 text-white me-1"
                                       onClick={() => {
                                          props.navigate(Url.DispositionCountReport);
                                       }}
                                    ><i className="fa fa-file"></i> View</button>
                                    <button onClick={() => dispositionCountReport()} className="btn btn-info rounded-0 text-white"><i className="fa fa-cloud-download"></i> Download</button>
                                 </p>
                              </li>
                              <li className="list-group-item">
                                 <p className="lead">Deleted Items Report</p>
                                 <p className="text-muted">Report contains items that have been flagged as deleted</p>
                                 <p>
                                    <button className="btn btn-info rounded-0 text-white"
                                       onClick={() => {
                                          props.navigate(Url.ItemsDeletedReport)
                                       }}
                                    >  <i className="fa fa-file"></i> View</button>
                                    <button className="btn btn-info rounded-0 text-white ms-1"
                                       onClick={() => itemsDeletedReports()}
                                    ><i className="fa fa-cloud-download"></i> Download</button>
                                 </p>
                              </li>
                           </ul>
                        </di>
                        <div class="col-lg-5  offset-lg-2">
                           <ul class="list-group">


                              <Formik
                                 initialValues={{
                                    item_create_from_date: "",
                                    item_create_to_date: "",
                                 }}
                                 validationSchema={FormValidation4}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       ReportsApi.itemsAvailableReport(values).then(res => {
                                          if (res && res.status == 200) {
                                             const outputFilename = `${Date.now()}.xls`;
                                             const url = URL.createObjectURL(new Blob([res.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', outputFilename);
                                             document.body.appendChild(link);
                                             link.click();
                                             setFieldValue("type", "")
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]

                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <li className="list-group-item">
                                          <p class="lead">All Available Items</p>
                                          <p class="text-muted">Report includes all items for provided date range.</p>
                                          <div class="form-group">
                                             <label class="control-label" for="item_create_from_date">From</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="item_create_from_date"
                                                value={values.item_create_from_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="item_create_from_date" />
                                          </div>
                                          <div className="form-group">
                                             <label class="control-label"  >To</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="item_create_to_date"
                                                value={values.item_create_to_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="item_create_to_date" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span>
                                                <button class="btn btn-info rounded-0 text-white" type='submit'><i className="fa fa-cloud-download"></i> Download</button>
                                             </span>
                                          )}
                                       </li>
                                    </Form>
                                 )}
                              </Formik>

                              <Formik
                                 initialValues={{
                                    item_release_from_date: "",
                                    item_release_to_date: "",
                                    include_bulk_released_items: false,
                                 }}
                                 validationSchema={FormValidation5}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       ReportsApi.itemsReleasedReport(values).then(res => {
                                          if (res && res.status == 200) {
                                             const outputFilename = `${Date.now()}.xls`;
                                             const url = URL.createObjectURL(new Blob([res.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', outputFilename);
                                             document.body.appendChild(link);
                                             link.click();
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]

                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <li className="list-group-item">
                                          <p class="lead">Released Items</p>
                                          <p class="text-muted">Report includes released items (non-bulk) for provided release date range.</p>
                                          <div class="form-group">
                                             <label class="control-label"  >From</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="item_release_from_date"
                                                value={values.item_release_from_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="item_release_from_date" />
                                          </div>
                                          <div className="form-group">
                                             <label class="control-label"  >To</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="item_release_to_date"
                                                value={values.item_release_to_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="item_release_to_date" />
                                          </div>
                                          <div class="form-group">
                                             <Field type="checkbox" name="include_bulk_released_items" /> Include bulk released Items
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span>
                                                <button class="btn btn-info rounded-0 text-white" type='submit'><i className="fa fa-cloud-download"></i> Download</button>
                                             </span>
                                          )}
                                       </li>
                                    </Form>
                                 )}
                              </Formik>

                              <Formik
                                 initialValues={{
                                    claim_create_from_date: "",
                                    claim_create_to_date: "",
                                    include_deleted_claims: false,
                                 }}
                                 validationSchema={FormValidation6}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       ReportsApi.claimsAvailableReport(values).then(res => {
                                          if (res && res.status == 200) {
                                             const outputFilename = `${Date.now()}.xls`;
                                             const url = URL.createObjectURL(new Blob([res.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', outputFilename);
                                             document.body.appendChild(link);
                                             link.click();
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <li className="list-group-item">
                                          <p class="lead">All Claims</p>
                                          <p class="text-muted">Report includes available claims (non-deleted) created during provided date range.</p>
                                          <div class="form-group">
                                             <label class="control-label"  >From</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="claim_create_from_date"
                                                value={values.claim_create_from_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="claim_create_from_date" />
                                          </div>
                                          <div className="form-group">
                                             <label class="control-label"  >To</label>
                                             <DatePickerInput
                                                onChange={setFieldValue}
                                                name="claim_create_to_date"
                                                value={values.claim_create_to_date}
                                             />
                                             <ErrorMessage component="small" className='text-danger' name="claim_create_to_date" />
                                          </div>
                                          <div class="form-group">
                                             <Field type="checkbox" name="include_deleted_claims" /> Include matched claims
                                          </div>

                                          {isSubmitting ? "Loading..." : (
                                             <span>
                                                <button class="btn btn-info rounded-0 text-white" type='submit'><i className="fa fa-cloud-download"></i> Download</button>
                                             </span>
                                          )}
                                       </li>
                                    </Form>
                                 )}
                              </Formik>


                           </ul>
                        </div>
                     </div>
                  </div>
               )
               }
            </>)}



      </>
   )
}

export default withNavigation(Index)