import axios from "../Utils/axios";

// authorization: "Bearer " + localStorage.getItem("token"),
let AuthApi = {
   login: async (data) => axios({
      url: "/auth/login.json",
      data,
      method: "post",
   }),
   forgetPasswordEmail: async (data) => axios({
      url: "/password_resets.json",
      data,
      method: "post",
   }),
   updateResetPassword: async (data) => axios({
      url: `/password_resets/${data.id}/`,
      data: data.data,
      method: "patch",
      headers: {
         'Content-Type': 'application/x-www-form-urlencoded'
      },
   }),
   signup: async (data) => axios({
      url: "/clients.json",
      data,
      method: "post",
   }),
   userSession: async () => axios({
      url: "/sessions/session_user.json",
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   contactUs: async (data) => axios({
      url: "/contact.json",
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updatePassword: async (data) => axios({
      url: "/change_password.json",
      method: "put",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getCountries: async (data) => axios({
      url: "/countries.json",
      method: "get", 
   }),
}
export default AuthApi

