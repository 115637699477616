import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  role: "",
  permissions: {
    pages: {
      items: {
        items_view: true,
        add_item: true,
        edit_item: true,
      },
      claims: {
        claims_view: true,
        add_claim: true,
        edit_claim: true,
      },
      containers: {
        container_view: true,
      },
      bulk_release: {
        bulk_release_view: true,
        update_bulk_release: true,
      },
      reports: {
        report_view: true,
        download_reports: true,
        view_reports: true,
      },
      user_settings: {
        user_setting_view: true,

      }
    }
  }
}

export const counterSlice = createSlice({
  name: 'UserPermissions',
  initialState,
  reducers: {
    updateUserPermission: (state, action) => {
      if (action.payload == "A") {
        return {
          role: "Admin",
          permissions: {
            pages: {
              items: {
                items_view: true,
                add_item: true,
                edit_item: true,
              },
              claims: {
                claims_view: true,
                add_claim: true,
                edit_claim: true,
              },
              containers: {
                container_view: true,
              },
              bulk_release: {
                bulk_release_view: true,
                update_bulk_release: true,
              },
              reports: {
                report_view: true,
                download_reports: true,
                view_reports: true,
              },
              user_settings: {
                user_setting_view: true,
              }
            }
          }
        }

      }
      if (action.payload == "R") {
        return {
          role: "Read Only",
          permissions: {
            pages: {
              items: {
                items_view: true,
                add_item: false,
                edit_item: false,
              },
              claims: {
                claims_view: true,
                add_claim: false,
                edit_claim: false,
              },
              containers: {
                container_view: false,
              },
              bulk_release: {
                bulk_release_view: true,
                update_bulk_release: false,
              },
              reports: {
                report_view: true,
                download_reports: true,
                view_reports: true,
              },
              user_settings: {
                user_setting_view: false,
              }
            }
          }
        }

      }
      if (action.payload == "I") {
        return {
          role: "item/claims",
          permissions: {
            pages: {
              items: {
                items_view: true,
                add_item: true,
                edit_item: true,
              },
              claims: {
                claims_view: true,
                add_claim: true,
                edit_claim: true,
              },
              containers: {
                container_view: false,
              },
              bulk_release: {
                bulk_release_view: true,
                update_bulk_release: true,
              },
              reports: {
                report_view: true,
                download_reports: true,
                view_reports: true,
              },
              user_settings: {
                user_setting_view: false,
              }
            }
          }
        }

      }
      if (action.payload == "C") {
        return {
          role: "claim Only ",
          permissions: {
            pages: {
              items: {
                items_view: true,
                add_item: false,
                edit_item: false,
              },
              claims: {
                claims_view: true,
                add_claim: true,
                edit_claim: true,
              },
              containers: {
                container_view: false,
              },
              bulk_release: {
                bulk_release_view: true,
                update_bulk_release: false,
              },
              reports: {
                report_view: true,
                download_reports: true,
                view_reports: true,
              },
              user_settings: {
                user_setting_view: false,
              }
            }
          }
        }

      }
    },
  },
})

export const { updateUserPermission } = counterSlice.actions

export default counterSlice.reducer

