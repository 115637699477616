import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthApi from '../../Api/AuthApi'
import Url from '../../Utils/Routes'
import AuthFooter from '../../Components/footers/LoginFooter';
const SignupSchema = Yup.object().shape({
   email: Yup.string()
      .required('Password is required.'),
});
let Help = (props) => {
   let [apiError, setApiError] = useState(false);
   return (
      <>
         <AuthHeader />
         <div className="container">
            <div className="row mt-5">
               <div className="col-12">
                  <h1 className="text-danger2 text-center">RepoApp Help</h1>
                  <p className='text-danger2 text-center'>Learn about features, definitions, and best practices.</p>
               </div>
            </div>
         </div>
         <hr />
         <div className='max-w960 px-3 py-5'>
            <div className="container">
               <div class="row">

                  <div class="col-md-3" id="leftCol">
                     <ul class="nav nav-stacked help_navigation_bar" id="sidebar">
                        <li className='p-2 '><a className='d-block' href="#sec0">Welcome and Getting Started</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec1">Definitions</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec2">Found Items</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec3">Claims</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec4">Containers</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec5">Venues</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec6">Categories</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec7">Item Auditing</a></li>
                        <li className='p-2 '><a className='d-block' href="#sec8">Managing Users</a></li>
                     </ul>
                  </div>

                  <div class="col-md-9 help_section">
                     <h2 id="sec0" className='mt-3'> Welcome and Getting Started</h2>
                     <p>Welcome to RepoApp. Check here for updates, tips, and more. Our goal is to keep RepoApp so simple that hopefully you never have to refer to this help section.</p>
                     <h4><i class="fa fa-info-circle"></i> Browser requirements</h4>
                     Because RepoApp is a interactive, web-based program that utilizes the latest web frameworks, it requires a modern browser. The following browsers are supported:
                     <ul>
                        <li>Chrome</li>
                        <li>Firefox</li>
                        <li>Internet Explorer 9+ (though IE9 is supported, IE10 or higher is recommended for a better, more reliable browsing experience)</li>
                     </ul>
                     <h4><i class="fa fa-info-circle"></i> Screen resolution</h4>
                     It is recommended that your monitor/screen resolution be set to a width of <strong>1280 or higher</strong> (e.g., 1280x1024, 1366x768, 1920x1080 and so on). A higher resolution width 1280 or higher (with the browser taking up the full screen) enhances viewing especially for data table records. Visit <a href="http://www.whatismyscreenresolution.com" target="_blank" rel="noreferrer">http://www.whatismyscreenresolution.com</a> to view your current screen resolution.
                     <br />
                     <br />
                     <h4><i class="fa fa-file-video-o"></i> Video tutorial</h4>
                     <a href="http://repoapp.wistia.com/medias/3wkul4wiec" target="_blank" rel="noreferrer">Watch a 25-minute video on using RepoApp.</a>
                     <br />
                     <hr id="sec1" />
                     <h3><i class="fa fa-book"></i> Definitions</h3>
                     <div>While using RepoApp, you'll come across these common terms:</div>
                     <div>
                        <ul>
                           <li><b>Found Item</b> - this represents one or more items found together that you believe is part of a single lost &amp; found incident. So, if a set of keys and cellphone are found together, then ideally create one found item record that includes a description of both items.</li>
                           <li><b>Claim</b> - this represents an incident where a person has lost one or more items.</li>
                           <li><b>Release (non-bulk)</b> - this action represents releasing an item to a customer or claimant.</li>
                           <li><b>Bulk Release</b> - if part of your operations is to release multiple stored items after a certain period (e.g., everyday, or at the end of the month), then this action is referred to as "bulk releasing".</li>
                           <li><b>Container</b> - a container represents a storage unit that's used to store found items. It may be a box, shelf, or room (e.g., Security). You can add, update, and delete containers to coincide with how your group manages storage of items.</li>
                           <li><b>Venue</b> - a venue is a distinct zone/area of the property. For example, if you're an airport, you may have a venue for each terminal (e.g., TERM-A, TERM-B, and <nobr>TERM-C</nobr>)</li>
                           <li><b>Expire After Days (for Items)</b> - use to denote the number of days you plan on keeping the item before releasing it. "Expired" items will appear under the expired, Bulk Release section. Even though an item record may appear as expired, its status won't change until further action has been taken on the record (such as released to a customer or bulk released). </li>
                           <li><b>Expire After Days (for Claims)</b> - use to denote the number of days you consider the customer's claim to still be "resolvable" (possibility that item(s) can still be found). Even if customer claim records expire, you can always search and access these records.</li>
                        </ul>
                     </div>
                     <br />
                     <hr id="sec2" />
                     <h3><i class="fa fa-tag"></i> Found Items</h3>
                     <p>To add a new item, click the Add Item button under the Items section.</p>
                     <span class="label label-info">TIPS</span>
                     <ul>
                        <li>Use a succint description when describing a found item. This will help with auto-matching of claims and reduce false-positives.</li>
                        <li>Expire After is always based on the Storage Date.</li>
                        <li>Found Date and Storage Date should generally be the same unless you have a unique process where Storage Date is after the found date.</li>
                        <li>If using the Search Item Form feature (that allows available items to be displayed on your website), add a less descriptive version of the item in Web Description. This is the description that will appear on the Public Item Search Form.</li>
                        <li>You may release item records in an Available status. Under the Release tab, you may link the item to an existing claim or enter the claimant's information.</li>
                        <li>If Item Auditing is enabled, under the History tab you can find a history of updates to the record.</li>
                        <li>Delete items that are truly a mistake (such as a duplicate record). Deleted item records can be accessed under the Reports section.</li>
                     </ul>
                     <br />
                     <hr id="sec3" />
                     <h3><i class="fa fa-folder-open"></i> Claims</h3>
                     <p>To add a new claim, click the "Add Claim" button under the Claims section.</p>
                     <span class="label label-info">TIPS</span>
                     <ul>
                        <li>Use a succint description when describing a lost item. This will help with auto-matching of items and reduce false-positives. </li>
                        <li>Use the Follow-up flag to signify a claim requires additional actions by your group. For claims submitted via the Public Claim Form (via the Claim integration feature), this field will be checked. Uncheck this field to acknowledge receipt of the claim and your attempt to find the missing item(s).</li>
                        <li>The Expire After field should be set to the number of days that your organization has established as a reasonable period for the claim to still be "resolvable" (chance of still finding lost items). To set a default value for this field, go to Set Defaults under the Settings section.</li>
                     </ul>
                     <br />
                     <hr id="sec4" />
                     <h3><i class="fa fa-dropbox fa-lg"></i> Containers</h3>
                     <p>A container is where you store found items. It may be a room (e.g., ROOM3, SECURITY), a bin (e.g., BIN-A, BIN-1) or other naming preferences. </p>
                     <p>Container "pick list" or "drop-down" values should be managed under the Containers section. Listed values under "Current Containers" will be available in the drop-down Container list when adding an item.</p>
                     <br />
                     <h4><i class="fa fa-info-circle"></i> Moving items from one container to another</h4>
                     <p>Although this feature is under the Containers section, moving items from one container to another will update all item records associated with the "From" container. Therefore, multiple item records may be updated by this action.</p>
                     <br />
                     <hr id="sec5" />
                     <h3><i class="fa fa-building-o"></i> Venues</h3>
                     <p>A venue is a distinct area of a property. In the case of an airport, for instance, it may be a terminal (e.g., TERM-A, TERM-B). In the case of a theme park, it may be the ride or entertainment area. </p>
                     <span class="label label-info">TIPS</span>
                     <ul>
                        <li>Don't add too many venues. For example, if you're in a hotel setting with 500 rooms, it's strongly recommended not to create a venue for each room (500 venues). Instead, create blocks of rooms (e.g., "RM 300-310")</li>
                        <li>Keep venue names short (preferably 10 characters or less)</li>
                        <li>Establish the final list of venues before entering live data. This is important, since updating venues in the future will be difficult without impacting past records logged under those venues.</li>
                     </ul>
                     <br />
                     <hr id="sec6" />
                     <h3><i class="fa fa-th-list"></i> Categories</h3>
                     <p>A category is how you wish to classify an item. By default new accounts are created with 16 categories. You may create additional categories to align with categories of items your group most commonly finds. </p>
                     <span class="label label-info">TIPS</span>
                     <ul>
                        <li>Try to limit the number of categories to a manageable amount. Our recommendation is not to exceed 32 categories.</li>
                        <li>Establish the final list of categories before entering live data. This is important, since updating categories in the future will be difficult without impacting records logged under those categories.</li>
                     </ul>
                     <br />
                     <hr id="sec7" />
                     <h3><i class="fa fa-files-o"></i> Item Auditing</h3>
                     <p>If enabled, Item Auditing captures a history of updates made to the item record.</p>
                     <p>These updates can be found under the History tab when viewing the detailed item record.</p>
                     <br />
                     <hr id="sec8" />
                     <h3><i class="fa fa-users"></i> Managing Users</h3>
                     <p>By default, all plans permit up to 20 active user accounts. Active user accounts are defined as those in an Active as well as Pending status.</p>
                     <span class="label label-info">TIPS</span>
                     <ul>
                        <li>Only use a valid email address when adding user accounts. Without a valid email address, the account user will not be able to complete a password reset process.</li>
                        <li>Encourage users to practice safe account access: 1) always logout when complete with their session 2) change passwords every 2-3 months. 3) do not share passwords.</li>
                     </ul>
                     <br />
                     <br />
                  </div>
               </div>
            </div>
         </div>

         <AuthFooter />
      </>
   )
}

export default withNavigation(Help)