import React from 'react';
import { withNavigation } from './../../../Utils/Navigater';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; 
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Url from '../../../Utils/Routes'
import AppApi from '../../../Api/AppApi';
const FormValidation = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    subject: Yup.string().required('Subject is required.'),
    content: Yup.string().min(4, 'Too Short!').required('Content is required.'),
});
let AddTemplate = (props) => {
    return (
        <Formik
            initialValues={{
                title: '',
                subject: '',
                content: '',
            }}
            validationSchema={FormValidation}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                try {
                    AppApi.addTemplate(values).then(result => {
                        if (result && result.status == 201) {
                            toast.success('Created successfully.', {
                                autoClose: 5000,
                            });
                            props.navigate(Url.Templates);
                        } else {
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                        }
                        setSubmitting(false)
                    }).catch(error => {
                        setSubmitting(false)
                        try {
                            let message = error.response.data.errors[0]
                            toast.error(message ? message : 'Server Error!', {
                                autoClose: 5000,
                            });
                        } catch (error) {
                            
                        }
                    })
                } catch (error) {
                    setSubmitting(false)
                    toast.error('Server error.', {
                        autoClose: 5000,
                    });
                    console.log("server error", error.message)
                }

            }}
        >
            {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                <Form >
                    <div className="panel rounded-0 panel-default border">
                        <div className="panel-heading">
                            <h3 className="panel-title">Template Details</h3>
                        </div>
                        <div className="panel-body p-2">
                            <div className="form-group">
                                <label  >Name</label>
                                <Field className="form-control" placeholder="" type="text" name="title" />
                                <ErrorMessage component="small" className='text-danger' name="title" />
                            </div>
                            <div className="form-group">
                                <label  >Email Subject</label>
                                <Field className="form-control" placeholder="" type="text" name="subject" />
                                <ErrorMessage component="small" className='text-danger' name="subject" />
                            </div>
                            <div className="form-group  "  >
                                <label className="control-label"  >Body</label>
                                <textarea
                                    value={values.content}
                                    onChange={(event) => setFieldValue("content", event.target.value)}
                                    className="form-control" n autoComplete="off" required=""></textarea>
                                <ErrorMessage component="small" className='text-danger' name="content" />
                                <div className='text-info' name="content">
                                    Available tags to auto-populate information:
                                    <ul>
                                        <li>
                                             [item_description]
                                        </li>
                                        <li>
                                             [item_id]
                                        </li>
                                        <li>
                                             [claim_description]
                                        </li>
                                        <li>
                                             [claim_id]
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {isSubmitting ? "Loading..." : (
                                <>
                                    <span>
                                        <button type='submit' className="btn btn-success rounded-0 me-1" ><i className="fa fa-check"></i> Save </button>
                                        {/* <button className="btn btn-danger rounded-0 disable ng-hide" data-target=".confirmation-modal-sm"><i className="fa fa-trash-o"></i> Delete </button> */}
                                    </span>
                                    <Link className="btn btn-default" to={Url.Templates}>Cancel</Link>
                                </>
                            )}

                        </div>
                    </div>
                </Form>
            )}
        </Formik >


    )
}

export default withNavigation(AddTemplate)