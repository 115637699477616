import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ReportsApi from '../../Api/ReportsApi';
import Url from '../../Utils/Routes'

let Index = (props) => {
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData()
   }, [])
   let getData = async () => {
      try {
         ReportsApi.itemsDeletedReportJson().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let itemsDeletedReports = async () => {
      try {
         ReportsApi.itemsDeletedReports().then(res => {
            if (res && res.status == 200) {
               const outputFilename = `${Date.now()}.xls`;
               const url = URL.createObjectURL(new Blob([res.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', outputFilename);
               document.body.appendChild(link);
               link.click();
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         }).catch(error => {
            
            try {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message)
      }
   }
   return (
      <>
         <UserHeader />
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid">
               <div className="row mt-3">
                  <div className="col-12">
                     <h4>Deleted Items Reports(in last 3 years)</h4>
                  </div >
               </div >
               <div className="row">
                  <div className="col-12 d-flex justify-content-between">
                     <button className="btn-bordered rounded-0 text-black"
                        onClick={() => {
                           props.navigate(Url.Reports)
                        }}
                     >   <i className="fa fa-reply"></i> Back   </button>

                     <button className="btn btn-info rounded-0 text-white ms-1"
                        onClick={() => itemsDeletedReports()}
                     ><i className="fa fa-cloud-download"></i> Download</button>
                  </div>
               </div>
               <div className="row mt-5">
                  <div className="col-12">
                     <span class="badge ng-binding me-1"> {data.length}  </span>Records
                     <table class="table table-striped mt-1">
                        <thead>
                           <tr>
                              <th>Item ID</th>
                              <th>Description</th>
                              <th>Original Description</th>
                              <th>Expire After</th>
                              <th>Container</th>
                              <th>Venue</th>
                              <th>Found Date</th>
                              <th>Storage Date</th>
                              <th>Notes</th>
                              <th>Deleted By</th>
                           </tr>
                        </thead>
                        <tbody>
                           {data.map((data, index) => {
                              return (
                                 <tr key={index}>
                                    <td>{data.id}</td>
                                    <td>{data.item_description}</td>
                                    <td>{data.item_original_description}</td>
                                    <td>{data.item_expire_after}</td>
                                    <td>{data.item_container_name}</td>
                                    <td>{data?.venue?.venue_name}</td>
                                    <td>{data.item_found_date}</td>
                                    <td>{data.item_storage_date}</td>
                                    <td><i class="fa fa-info-circle fa-sm me-1"  ></i>{data.item_notes}</td>
                                    <td>{data.update_user?.email}</td>
                                 </tr>
                              )
                           })}

                        </tbody>
                     </table>
                  </div>
               </div>
            </div >
         )
         }

      </>
   )
}

export default withNavigation(Index)