import React, { useState } from 'react';
import { useEffect } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import AppApi from '../../../Api/AppApi';
import { toast } from 'react-toastify';
let ExistingDisposition = (props) => {
    let [items, setItems] = useState([])
    useEffect(() => {
        let data = props.data.map(data => {
            return {
                id: data.disposition_order_index,
                title: data.disposition_description
            }
        })
        setItems(props.data)
    }, [props.data])
    let handleRLDDChange = (newItems) => {
        setItems(newItems)

    }
    let [apiLoading, setApiLoading] = useState(false);

    let setOrder = async () => {
        try {
            setApiLoading(true)
            let data = {
                disposition: items
            }
            AppApi.setOrderDispositions(data).then(res => {
                if (res.status == 200) {
                    props.updateOrder(items);
                    toast.success('Record updated successfully.', {
                        autoClose: 5000,
                    });
                }
                setApiLoading(false);
            }).catch(error => {
                setApiLoading(false)
                toast.error('Server error.', {
                    autoClose: 5000,
                });
            })
        } catch (error) {
            
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
        }
    }
    return (
        <div class="panel border panel-default">
            <div class="panel-heading">
                <div class="panel-title">Existing Disposition
                    <div class="badge badge-info ng-binding ms-1">{items.length}</div>
                </div>
            </div>
            <div class="panel-body p-3">
                <ul id="disposition_sortable" class="list-group ng-pristine ng-untouched ng-valid ui-sortable ng-not-empty" >
                    <RLDD
                        items={items}
                        itemRenderer={(item, index) => {
                            return (
                                <li class="list-group-item ng-binding ng-scope ui-sortable-handle" key={index}>
                                    <span class="label label-primary me-2"><i class="fa fa-arrows fa-1x"></i></span>
                                    {index + 1} {item.disposition_description}
                                </li>
                            );
                        }}
                        onChange={handleRLDDChange}
                    />
                </ul>
                <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                    <p class="help-block mt-2"> To reorder dispositions, click and move record to desired position. </p>
                    {apiLoading ? "Loading..." : (
                        <button class="btn btn-success"
                            onClick={() => {
                                setOrder()
                            }}
                        ><i class="fa fa-check"></i> Save Order</button>
                    )}

                </span>
            </div>
        </div>
    )
}

export default ExistingDisposition