import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { withNavigation } from '../../Utils/Navigater';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import Swal from 'sweetalert2';
let DashboardHeader = (props) => {
   let [bussness, setBussness] = useState("");
   let [email, setEmail] = useState("");
   let getExcel = () => {
      AppApi.downloadAccounts().then(res => {
         if (res.status == 200) {
            const outputFilename = `${Date.now()}.xls`;
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
         }
      }).catch(error => {
         ;
      })
   }
   return (
      <>
         <div className="dashbaord_header sticky-top ">
            <div className="links_container flex-wrap justify-content-md-center">
               <div><NavLink to={Url.Dashboard} className="text-decoration-none"><h5 className='mb-0'
                  onClick={() => {
                     props.updateFiltes({ enabled: true })
                  }}
               > All Accounts</h5></NavLink></div>
               <div>
                  <NavLink to={Url.Dashboard} className="text-decoration-none"> <i class="fa fa-home"></i> Home</NavLink>
               </div>
               {props.showFilters && (
                  <div>
                     {!props.bussnessSearch && (
                        <DropdownButton className='dashboardDropDown' id="dropdown-basic-button" title="Filter Accounts ">
                           <Dropdown.Item href="#" className="dropdown-item" onClick={() => {
                              props.updateFiltes({ enabled: true })
                           }}>Enabled Accounts</Dropdown.Item>
                           <Dropdown.Item href="#" className="dropdown-item" onClick={() => {
                              props.updateFiltes({ disabled: true })
                           }}>Disabled Accounts</Dropdown.Item>
                           <Dropdown.Divider />
                           <Dropdown.Item href="#" className="dropdown-item"
                              onClick={() => {
                                 props.updateFiltes({ trial: true })
                              }}>In Trial</Dropdown.Item>
                           <Dropdown.Item href="#" className="dropdown-item"
                              onClick={() => {
                                 props.updateFiltes({ expired: true })
                              }}>Expired Subscriptions</Dropdown.Item>

                           <Dropdown.Item href="#" className="dropdown-item"
                              onClick={() => {
                                 props.updateFiltes({ expiring60: true })
                              }}>Expiring in 60 Days</Dropdown.Item>
                           <Dropdown.Divider />
                           <Dropdown.Item href="#" className="dropdown-item"
                              onClick={() => getExcel()}>Download Excel of Accounts         </Dropdown.Item>
                        </DropdownButton>
                     )}
                  </div>



               )}
               <div>
                  <DropdownButton className='dashboardDropDown' id="dropdown-basic-button" title="Settings ">


                     {/* <Dropdown.Divider /> */}



                     <Dropdown.Item href="#" className="dropdown-item"
                        onClick={() => props.navigate(Url.Commission)}>Commission         </Dropdown.Item>
                     <Dropdown.Item href="#" className="dropdown-item"
                        onClick={() => props.navigate(Url.AdminShipments)}>Shipments         </Dropdown.Item>
                  </DropdownButton>
               </div>

               {/* <div>
               <NavLink to="#" className="text-decoration-none"> <i class="fa fa-play"></i> Launch</NavLink>
            </div>
            <div>
               <NavLink to="#" className="text-decoration-none"> <i class="fas fa-cog"></i> DJ</NavLink>
            </div> */}
               <div >
                  <NavLink to="/login"
                     onClick={() => {
                        localStorage.removeItem("token");
                     }}
                     className="text-decoration-none"> <i class="fa fa-sign-out"></i>  Sign-out</NavLink>
               </div>
            </div>
            {props.showFilters && (
               <div className='d-flex flex-wrap justify-content-md-center'>
                  <div className='d-flex me-2 py-1'>
                     <input type="text" className='me-1'
                        onKeyUp={(event) => {
                           if (event.key === 'Enter' && email) {
                              // props.businessNameSearch({ email: true, data: email });
                              props.navigate(Url.UsersEmailSearch(email))
                           }
                        }}
                        onChange={(event) => {
                           setEmail(event.target.value);
                           setBussness("");
                        }}
                        value={email}
                        placeholder='Email search' />
                     <button className='btn btn-primary btn-sm '
                        onClick={() => {
                           setBussness("");
                           if (email) {
                              props.navigate(Url.UsersEmailSearch(email))
                           }
                           // props.businessNameSearch(email);
                        }}
                     >Search</button>
                  </div>
                  {!props.bussnessSearch && (
                     <div className='d-flex py-1'>
                        <input type="text" className='me-1 out-line-none' placeholder='Business name'
                           onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                 props.businessNameSearch({ email: false, data: bussness });
                              }
                           }}
                           onChange={(event) => {
                              setBussness(event.target.value);
                              setEmail("");
                           }}
                           value={bussness}
                        />
                        <button className='btn btn-primary btn-sm '
                           onClick={() => {
                              setEmail("");
                              props.businessNameSearch(bussness);
                           }}
                        >Search</button>
                     </div>
                  )}

               </div>
            )}

         </div>
      </>
   )
}


export default withNavigation(DashboardHeader)