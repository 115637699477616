/**
 * Convert ounces to pounds
 * @param   {String} ounces 
 * @returns {Number} pounds
 */
const convertOuncesToPounds = (ounces) => {
  return (ounces / 16.0).toFixed(2);
};

export default convertOuncesToPounds;
