

import React, { useState, useEffect } from 'react';
import ShipmentApi from '../../Api/ShipmentApi';
import { toast } from 'react-toastify';

let ShipmentDetails = (props) => {
    let [form, setForm] = useState(1);
    let [data, setData] = useState([]);
    let [apiLoading, setApiLoading] = useState(false);
    let [countries, setCountries] = useState([]);
    useEffect(() => {
        Promise.all([
            getData(props.id),
        ]).then((values) => {
            setData(values[0])

            setApiLoading(false)
        });
    }, [])
    let getData = async (id) => {
        try {
            setApiLoading(true);
            let data = await ShipmentApi.shipmentPaidDetails(id).then(res => {
                if (res.status == 200) {
                    return res.data.data.shipment
                }
                if (res.data.length > 0) {
                    toast.info('Records updated successfully.', {
                        autoClose: 5000,
                    });
                } else {
                    return []
                }
            }).catch(error => {
                setApiLoading(false)
                return []
            })
            return data
        } catch (error) {
            setApiLoading(false)
            toast.error('Server error.', {
                autoClose: 5000,
            });
            return []
        }
    }

    const printShippingLabelOnClick = () => {
        window.open(data?.label_url, '_blank');
    };

    return (
        <>
            {apiLoading ? (
                <div className="page-center-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="panel border panel-default panel-info">
                        <div className="panel-heading d-flex justify-content-between">
                            <div className="panel-title">Package Details</div>
                        </div>
                        <div className="panel-body p-2 ">
                            <table className='  print-table'>
                                <tbody>
                                    <tr>
                                        <td>Shipment ID</td>
                                        <td>{data?.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Track Shipment</td>
                                        <td>
                                            {data?.tracking_url ? (
                                                <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.tracking_url}>
                                                    Track Here
                                                </a>) : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Label Dimensions</td>
                                        <td>
                                            {data?.label_size ? (<>  {data?.label_size}  </>) : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Shipping Label</td>
                                        <td>
                                            {data?.label_url ? (
                                                <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.label_url}>
                                                    Preview Shipping Label
                                                </a>) : "N/A"}
                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Commercial Invoice </td>
                                        <td>
                                            {data?.label_url ? (
                                                <a target="_blank" rel="noreferrer" className='overflowWrap' href={data?.commercial_invoice_url}>
                                                    Download Invoice
                                                </a>) : "N/A"}
                                            <br />
                                        </td>
                                    </tr>       
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <button className='btn btn-success btn-sm' onClick={printShippingLabelOnClick}>Print Shipping Label</button>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}

export default ShipmentDetails