
import React from 'react';

const Pagination = (props) => {
  const {totalPages, setPage } = props; 

  const renderPages = () => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} class="page-item">
            <button onClick= {()=> {
                setPage(i);
                }} class="page-link"> 
                {i}   
            </button>   
        </li>
      );
    }

    return pages;
  };

  return (
    <div>
      <ul class="pagination">
        {renderPages()}
      </ul>
    </div>
  );
};

export default Pagination;