import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withNavigation, withParams } from '../../Utils/Navigater';
import DashboardHeader from '../../Components/Headers/AdminHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
// import CONSTANTS from '../../Utils/Constants'
let UsersSearch = (props) => {
   let { search } = props.params;
   let [user, setUser] = useState([]);
   let [apiLoader, setApiLoader] = useState(false);
   useEffect(() => {
      getClintlistDetails(search);
   }, [search])
   let getClintlistDetails = (search) => {
      setApiLoader(true);
      try {
         AppApi.searchEmail(search).then(result => {
            console.log(result)
            if (result && result.status == 200) {
               setUser(result.data.data)
            } else {
               setUser([])
            }
            setApiLoader(false);
         }).catch(error => {
            setUser([])
            console.log("api error:", error)
            setApiLoader(false);
         })
      } catch (error) {
         setUser([])
         console.log("Client list api error :", error)
      }
   }



   return (
      <>
         <DashboardHeader showFilters={true} bussnessSearch={true} />
         <div className="p-2 px-4">
            <div className="container ">
               <div className="row">
                  {apiLoader && (
                     <div className="col-12 py-5 mt-5 text-center">
                        <div className="spinner-border text-secondary" role="status">
                           <span className="visually-hidden">Loading...</span>
                        </div>
                     </div>
                  )}
               </div>
               {!apiLoader && (
                  <div className="row mt-3">
                     <div className="col-10 offset-1 text-center py-5">
                        <table className="table table-condensed table-bordered">
                           <thead>
                              <tr>
                                 <th>ID</th>
                                 <th>Email</th>
                                 {/* <th>Business</th> */}
                                 <th>First Name</th>
                                 <th>Last Name</th>
                                 <th>Role Code</th>
                                 <th>Status Code</th>
                              </tr>
                           </thead>
                           <tbody>
                              {user.length == 0 && (
                                 <tr>
                                    <td colSpan={6} className="text-center">No record found.</td>
                                 </tr>
                              )}
                              {user.map((data, index) => {
                                 return (
                                    <tr>
                                       <td>{data.id}</td>
                                       <td>
                                          <Link to={Url.UserDetails(data.id)}>{data.email}</Link>
                                       </td>
                                       {/* <td>{data.bus}</td> */}
                                       <td>{data.first_name}</td>
                                       <td>{data.last_name}</td>
                                       <td>{data.role_code}</td>
                                       <td>{data.status_code == "A" ? "Active" : "Deactive"}</td>
                                    </tr>
                                 )
                              })}


                           </tbody></table>
                     </div>
                  </div>
               )}
            </div>
         </div>

      </>
   )
}

export default withParams(withNavigation(UsersSearch));