import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import VenuesFile from '../../Components/Forms/Dispositions/Venues';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
const FormValidation1 = Yup.object().shape({
   disposition_description: Yup.string().required('Venue is required.'),
});
const FormValidation2 = Yup.object().shape({
   disposition_description: Yup.string().required('Venue is required.'),
   name: Yup.string().required('Updated name is required.'),
});
let Venues = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   let [dataHidden, setDataHidden] = useState([]);
   useEffect(() => {
      getData();
      getHiddenData();
   }, [])
   let getData = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getHiddenData = async () => {
      try {
         AppApi.getHiddenVenues().then(res => {
            if (res.status == 200) {
               setDataHidden(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <>
               <UserAppNavigater />
               <div className="container-fluid mt-3">
                  <div className="row">
                     <div className="col-md-5">
                        <div class="panel  panel-default panel-info">
                           <div class="panel-heading">
                              <div class="panel-title">Add Venue</div>
                           </div>
                           <div class="panel-body p-2">
                              <div class="alert alert-info">
                                 <i class="fa fa-info-circle"></i> Create venues for distinct property areas (e.g, TERM-A, TERM-B). Venues should not be used for locations/properties that are significantly apart in distance and also have a different team managing lost and found operations.
                              </div>
                              <Formik
                                 initialValues={{
                                    disposition_description: "",
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    try {
                                       let dataObject = {
                                          venue_name: values.disposition_description
                                       }
                                       AppApi.addVenues(dataObject).then(result => {
                                          if (result && result.status == 201) {

                                             toast.success('Created successfully!', {
                                                autoClose: 5000,
                                             });
                                             setData([...data, result.data]);
                                             // setFieldValue("disposition_description", "")
                                             resetForm()
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false);

                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form >

                                       <div className="form-group">
                                          <label  >Venue  Name</label>
                                          <Field className="form-control  " placeholder="Venue name" type="text" name="disposition_description" />
                                          <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                       </div>
                                       {isSubmitting ? "Loading..." : (
                                          <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                             <button class="btn btn-success btn-sm"  ><i class="fa fa-check me-1"></i> Save</button>
                                          </span>
                                       )}
                                    </Form>
                                 )}
                              </Formik>
                           </div>
                        </div>
                        <div class="panel panel-default panel-info">
                           <div class="panel-heading">
                              <div class="panel-title">Delete Venue</div>
                           </div>
                           <div class="panel-body p-2 ">
                              <div class="alert alert-warning">
                                 <i class="fa fa-exclamation-triangle"></i> Only venues that aren't referenced by item or claim records can be deleted.
                              </div>
                              <Formik
                                 initialValues={{
                                    disposition_description: "",
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       AppApi.deleteVenues(values.disposition_description).then(result => {
                                          if (result && result.status == 200) {

                                             toast.success('Created successfully!', {
                                                autoClose: 5000,
                                             });
                                             setData(data.filter(data => data.id != values.disposition_description));
                                             setFieldValue("disposition_description", "")
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          setSubmitting(false)
                                          try {
                                             let message = error.response.data.errors[0]
                                             toast.error(message ? message : 'Server Error!', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <div className="form-group">
                                          <select class="form-control "
                                             value={values.disposition_description}
                                             onChange={(event) => {
                                                setFieldValue("disposition_description", event.target.value);
                                             }}>
                                             <option value="" selected="selected">-- Pick A Venue --</option>
                                             {data.map((data, index) => {
                                                return (
                                                   <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                )
                                             })}
                                          </select>
                                          <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                       </div>
                                       {isSubmitting ? "Loading..." : (
                                          <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                             <button class="btn btn-danger btn-sm"><i class="fa fa-trash me-1"></i> Delete</button>
                                          </span>
                                       )}
                                    </Form>
                                 )}
                              </Formik>
                              <form class="ng-pristine ng-invalid ng-invalid-required">
                                 <div class="form-group">

                                 </div>
                              </form>

                           </div>
                        </div>
                        <div class="panel panel-default panel-info">
                           <div class="panel-heading">
                              <div class="panel-title">Hide Venue</div>
                           </div>
                           <div class="panel-body p-2">
                              <div class="alert alert-warning">
                                 <i class="fa fa-exclamation-triangle"></i> If a Venue is "Hidden" then it will no longer appear in the drop down for users to use.
                              </div>
                              <Formik
                                 initialValues={{
                                    disposition_description: "",
                                 }}
                                 validationSchema={FormValidation1}
                                 onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                    setSubmitting(true);
                                    try {
                                       AppApi.hideOrUnHideVenues(values.disposition_description).then(result => {
                                          if (result && result.status == 200) {
                                             toast.success('Successfully updated!', {
                                                autoClose: 5000,
                                             });
                                             setData(data.filter(data => data.id != values.disposition_description));
                                             setDataHidden([...dataHidden, result.data]);
                                             setFieldValue("disposition_description", "")
                                          } else {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          }
                                          setSubmitting(false)
                                       }).catch(error => {
                                          
                                          setSubmitting(false)
                                          try {
                                             toast.error('Server error.', {
                                                autoClose: 5000,
                                             });
                                          } catch (error) {
                                             
                                          }
                                       })
                                    } catch (error) {
                                       setSubmitting(false)
                                       toast.error('Server error.', {
                                          autoClose: 5000,
                                       });
                                       console.log("server error", error.message)
                                    }

                                 }}
                              >
                                 {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                    <Form>
                                       <div className="form-group">
                                          <select class="form-control "
                                             value={values.disposition_description}
                                             onChange={(event) => {
                                                setFieldValue("disposition_description", event.target.value);
                                             }}>
                                             <option value="" selected="selected">-- Pick A Venue --</option>
                                             {data.map((data, index) => {
                                                return (
                                                   <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                )
                                             })}
                                          </select>
                                          <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                       </div>
                                       {isSubmitting ? "Loading..." : (
                                          <span >
                                             <button class="btn btn-danger btn-sm"><i class="fa fa-lock"></i> Hide</button>
                                          </span>
                                       )}
                                    </Form>
                                 )}
                              </Formik>
                           </div>
                        </div>
                        {/* *******************unhide ********** */}
                        {dataHidden.length > 0 && (
                           <div class="panel panel-default panel-info  "  >
                              <div class="panel-heading">
                                 <div class="panel-title">Unhide Venue</div>
                              </div>
                              <div class="panel-body p-2">
                                 <div class="alert alert-warning">
                                    <i class="fa fa-exclamation-triangle"></i> If a Venue is "Hidden" then it will no longer appear in the drop down for users to use.
                                 </div>
                                 <Formik
                                    initialValues={{
                                       disposition_description: "",
                                    }}
                                    validationSchema={FormValidation1}
                                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                       setSubmitting(true);
                                       try {
                                          AppApi.hideOrUnHideVenues(values.disposition_description).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Successfully updated!', {
                                                   autoClose: 5000,
                                                });
                                                setDataHidden(dataHidden.filter(data => data.id != values.disposition_description));
                                                setData([...data, result.data]);
                                                setFieldValue("disposition_description", "")
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0]
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form>
                                          <div className="form-group">
                                             <select class="form-control "
                                                value={values.disposition_description}
                                                onChange={(event) => {
                                                   setFieldValue("disposition_description", event.target.value);
                                                }}>
                                                <option value="" selected="selected">-- Pick A Venue --</option>
                                                {dataHidden.map((data, index) => {
                                                   return (
                                                      <option key={index} value={data.id}>{data.venue_name}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span >
                                                <button class="btn btn-danger btn-sm"><i class="fa fa-unlock me-1"></i> Unhide</button>
                                             </span>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>
                              </div>
                           </div>
                        )}

                        {data.length > 0 && (
                           <div class="panel panel-default panel-info">
                              <div class="panel-heading">
                                 <div class="panel-title">Rename Venue</div>
                              </div>
                              <div class="panel-body p-2">
                                 <div class="alert alert-warning">
                                    <i class="fa fa-exclamation-triangle"></i>
                                    updating a venue's name will be reflected in <strong>all current item and claim records</strong> that reference the updated venue.
                                 </div>
                                 <Formik
                                    initialValues={{
                                       disposition_description: "",
                                       name: ""
                                    }}
                                    validationSchema={FormValidation2}
                                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                       setSubmitting(true);
                                       try {
                                          let object = {
                                             data: { venue_name: values.name },
                                             id: values.disposition_description
                                          }
                                          AppApi.updateVenues(object).then(result => {
                                             if (result && result.status == 200) {
                                                toast.success('Successfully updated!', {
                                                   autoClose: 5000,
                                                });
                                                let index = data.findIndex(data => data.id == values.disposition_description);
                                                let oldData = [...data]
                                                oldData[index].venue_name = values.name
                                                setData(oldData);
                                                setFieldValue("disposition_description", "")
                                                setFieldValue("name", "")
                                             } else {
                                                toast.error('Server error.', {
                                                   autoClose: 5000,
                                                });
                                             }
                                             setSubmitting(false)
                                          }).catch(error => {
                                             setSubmitting(false)
                                             try {
                                                let message = error.response.data.errors[0];
                                                toast.error(message ? message : 'Server Error!', {
                                                   autoClose: 5000,
                                                });
                                             } catch (error) {
                                                
                                             }
                                          })
                                       } catch (error) {
                                          setSubmitting(false)
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                          console.log("server error", error.message)
                                       }

                                    }}
                                 >
                                    {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting }) => (
                                       <Form>
                                          <div className="form-group">
                                             <select class="form-control "
                                                value={values.disposition_description}
                                                onChange={(event) => {
                                                   setFieldValue("disposition_description", event.target.value);
                                                }}>
                                                <option value="" selected="selected">-- Pick A Venue --</option>
                                                {data.map((data, index) => {
                                                   return (
                                                      <option key={index} value={data.id}>{data.venue_name}</option>
                                                   )
                                                })}
                                             </select>
                                             <ErrorMessage component="small" className='text-danger' name="disposition_description" />
                                          </div>
                                          <div className="form-group">
                                             <label>New Name</label>
                                             <Field className="form-control  rounded-0" placeholder="New venue name" type="text" name="name" />
                                             <ErrorMessage component="small" className='text-danger' name="name" />
                                          </div>
                                          {isSubmitting ? "Loading..." : (
                                             <span >
                                                <button class="btn btn-success btn-sm"><i class="fa fa-check me-1"></i> Save</button>
                                             </span>
                                          )}
                                       </Form>
                                    )}
                                 </Formik>

                              </div>
                           </div>
                        )}
                     </div>
                     <div class="offset-md-2 col-md-5 ng-scope">
                        <VenuesFile data={data} />
                     </div>
                  </div>
               </div>
            </>)}

         <br></br>
         <br></br>
         <br></br>
         <br></br>
         <br></br>
      </>
   )
}

export default withNavigation(Venues)