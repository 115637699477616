import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ClaimItemsApi from '../../Api/ClaimItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import DateHelper from '../../Utils/DateHelper';
const FormValidation = Yup.object().shape({
   claim_first_name: Yup.string().required('First name is required.'),
   claim_last_name: Yup.string().required('Last name is required.'),
   // claim_phone: Yup.string().required('Phone  is required.'),
   // claim_email: Yup.string().required('Email is required.').email("Email is not valid."),
   // venue_id: Yup.string().required('Venue id  is required.'),
   // claim_lost_location: Yup.string().required('Location is required.'),
   category_id: Yup.string().required('Category  is required.'),
   claim_lost_date: Yup.string().required('Lost Date  is required.'),
   claim_description: Yup.string().required('Description is required.'),
   // claim_notes: Yup.string().required('Notes is required.'),
   claim_expire_after: Yup.string().required('Expire after  is required.'),
});
let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [CategoriesData, setDataCategories] = useState([]);
   let [ContainersData, setDataContainers] = useState([]);
   let [venuesData, setDataVenues] = useState([]);


   // ***************************************************************
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {

         })
      } catch (error) {
         console.log("🚀 ~ file: Index.js ~ line 51 ~ getData ~ error", error)
      }
   }
   // ***************************************************************
   useEffect(() => {
      getCategories();
      getVenues();
   }, [])
   let getCategories = async () => {
      try {
         AppApi.getCategories().then(res => {
            if (res.status == 200) {
               setDataCategories(res.data)
            }
         }).catch(error => {

            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getVenues = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setDataVenues(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }


   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.claims?.add_claim ? <UnAuthorized /> : (
            <>
               <div className="container-fluid mt-3">
                  {apiLoading ? (
                     <div className="page-center-loader">
                        <div className="spinner-border" role="status">
                           <span className="sr-only">Loading...</span>
                        </div>
                     </div>
                  ) : (
                     <div className="row">
                        <div className="col-lg-8 co-12">
                           <Formik
                              initialValues={{
                                 claim_first_name: "",
                                 claim_last_name: "",
                                 claim_phone: "",
                                 claim_email: "",
                                 venue_id: "",
                                 claim_lost_location: "",
                                 category_id: "",
                                 claim_lost_date: "",
                                 claim_description: "",
                                 claim_notes: "",
                                 claim_expire_after: data.default_claim_expire_after,
                                 claim_follow_up: false
                              }}
                              validationSchema={FormValidation}
                              onSubmit={(values, { setSubmitting }) => {
                                 setSubmitting(true);
                                 try {
                                    values.claim_lost_date = DateHelper.convertLocalToUTCDate(values?.claim_lost_date);
                                    ClaimItemsApi.addClaim(values).then(result => {
                                       if (result && result.status == 201) {
                                          toast.success('Created successfully.', {
                                             autoClose: 5000,
                                          });
                                          props.navigate(Url.EditClaim(result.data.id));
                                       } else {
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                       }
                                       setSubmitting(false)
                                    }).catch(error => {
                                       setSubmitting(false)
                                       try {
                                          let message = error.response.data.errors[0]
                                          toast.error(message ? message : 'Server Error!', {
                                             autoClose: 5000,
                                          });
                                       } catch (error) {
                                          
                                       }
                                    })
                                 } catch (error) {
                                    console.log("🚀 ~ file: Index.js ~ line 133 ~ Index ~ error", error)
                                    setSubmitting(false)
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                    console.log("server error", error.message)
                                 }

                              }}
                           >
                              {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                                 <Form >
                                    <div class="panel border panel-default">
                                       <div class="panel-heading">
                                          <h3 class="panel-title">Claim Details
                                             <ToolTipContainer
                                                index={1}
                                                view={""}
                                                placement="right"
                                                icon="text-info"
                                                title="About Claims"
                                                des={"Use this form to capture lost item(s) reports by the public. Refrain from entering language that identifies reporter as a 'patient' if you're in a Healthcare setting or 'student' if you're in a University setting."}
                                             />
                                          </h3>
                                       </div>
                                       <div class="panel-body p-2 px-4">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">First Name</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_first_name" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_first_name" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">Last Name</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_last_name" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_last_name" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Phone</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_phone" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_phone" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold ">Email</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_email" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_email" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold">Venue</label>
                                                   <select className='form-control rounded-0'
                                                      onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                   >
                                                      <option value="">-Pick Venue -</option>
                                                      {venuesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Lost Location</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_lost_location" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_lost_location" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">Category</label>
                                                   <select className='form-control rounded-0' name=""
                                                      onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                   >
                                                      <option value="">-Pick Category -</option>
                                                      {CategoriesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.category_description}</option>
                                                         )
                                                      })}
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="category_id" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required ">Lost Date</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="claim_lost_date"
                                                      value={values.claim_lost_date}
                                                   />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_lost_date" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  required">Lost Items</label>
                                                   <textarea name="" className='form-control' rows="2"
                                                      onChange={(event) => setFieldValue("claim_description", event.target.value)}
                                                   ></textarea>
                                                   <ErrorMessage component="small" className='text-danger' name="claim_description" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Notes</label>
                                                   <textarea name="" className='form-control' rows="2"
                                                      onChange={(event) => setFieldValue("claim_notes", event.target.value)}
                                                   ></textarea>
                                                   <ErrorMessage component="small" className='text-danger' name="claim_notes" />
                                                </div>
                                             </div>
                                             <div className="col-md-4">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  required">Expire After
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="About Expire After"
                                                         des={"Select the number of days for which this Claim will be considered 'current' and resolvable. Claims beyond this date are still accessible but considered expired and won't appear by default in the recent list of claims. You can search, view, and edit expired Claims. Also note that under Settings section you may set the default Expire After value."}
                                                      />
                                                   </label>
                                                   <select class="form-control "
                                                      onChange={(event) => setFieldValue("claim_expire_after", event.target.value)}
                                                   >
                                                      <option value="" selected="selected">-- Pick Expire After --</option>
                                                      <option value="30">30 days</option>
                                                      <option value="60">60 days</option>
                                                      <option value="90">90 days</option>
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="claim_expire_after" />
                                                </div>
                                             </div>
                                             <div className="col-md-4">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required ">days or OTHER::</label>
                                                   <Field type="text" className='form-control rounded-0' name="claim_expire_after" />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_expire_after" />
                                                </div>
                                             </div>
                                             <div className="col-md-4">
                                                <div className="form-group">
                                                   <label className="font-weight-bold d-block ">Follow-Up
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="About Follow-up"
                                                         des={"Check to signify Claim requires follow-up action(s) by your organization. For Web Claims, un-check to signify acknowledging receipt and review of the Claim. Use Notes to capture the follow-up action(s)."}
                                                      />
                                                   </label>
                                                   <Field type="checkbox" name="claim_follow_up" checked={values.claim_follow_up} />
                                                   <ErrorMessage component="small" className='text-danger' name="claim_follow_up" />
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                       <div class="panel-footer">
                                          <div>
                                             <span >
                                                <button class="btn btn-success me-1" disabled={isSubmitting} type="submit "   > {isSubmitting ? "Loading..." : (<><i class="fa fa-check"></i> Save</>)} </button>
                                                {/* <button class="btn btn-danger ng-hide"> <i class="fa fa-trash-o"></i> Delete</button> */}
                                             </span>
                                             <Link to={Url.Claims} class="btn btn-default"  >Cancel</Link>
                                          </div>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>

                        </div>
                     </div>
                  )
                  }

               </div >

            </>)}

         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withNavigation(Index)