import React, { useEffect, useState } from 'react';
import { SHIPMENT_COUNTRIES } from 'constants/index';
import { Field, ErrorMessage } from 'formik';
import AppApi from '../../Api/AppApi';

let ShipmentAddress = ({ setFieldValue, values, showResidential, shouldBypassAddressValidation }) => {
    let [copied, setCopied] = useState(false);
    let [states, setStates] = useState([]);
    let [statesLoader, setStatesLoader] = useState(false);
    let [cities, setCities] = useState([]);
    let [citiesLoader, setCitiesLoader] = useState(false);
    let [residentail, setResidential] = useState(false);
    useEffect(() => {
        if (values.country) {
            getStates(values.country);
        }
    }, [])

    let getStates = (country) => {
        setStates([]);
        setCities([]);
        if (country) {
            try {
                setStatesLoader(true)
                AppApi.getStates(country).then(res => {
                    if (res.status == 200) {
                        let allData = res.data.data
                        let filtered = allData.filter(data => data.state_code.length == 2 || data.state_code.length == 1 || data.state_code.length == 3);
                        let removedFiltered = filtered.map(data => {
                            let name = data.name.replaceAll('\"', "");
                            return { ...data, name: name }
                        }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                        setStates(removedFiltered);
                    }
                    setStatesLoader(false)
                }).catch(error => {
                    setStatesLoader(false)
                    setStates([]);
                })
            } catch (error) {
                setStatesLoader(false)
                setStates([]);
            }
        } else {
            setStates([]);
        }

    }
    let getCities = (state) => {
        setCities([]);
        if (state) {
            try {
                setCitiesLoader(true)
                AppApi.getCities(state).then(res => {
                    if (res.status == 200) {
                        let filtered = res.data.data
                        let removedFiltered = filtered.map(data => {
                            let name = data.name.replaceAll('\"', "");
                            return { ...data, name: name }
                        });
                        setCities(removedFiltered);
                    }
                    setCitiesLoader(false)
                }).catch(error => {
                    setCitiesLoader(false)
                    setCities([]);
                })
            } catch (error) {
                setCitiesLoader(false)
                setCities([]);
            }
        } else {
            setCities([]);
        }

    }
    return (
        <>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >Company</label>
                    <Field className="form-control  " placeholder="" type="text" name="company" />
                    <ErrorMessage component="small" className='text-danger d-block' name="company" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >Name</label>
                    <Field className="form-control  " placeholder="" type="text" name="name" />
                    <ErrorMessage component="small" className='text-danger d-block' name="name" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >Email</label>
                    <Field className="form-control  " placeholder="" type="text" name="email" />
                    <ErrorMessage component="small" className='text-danger d-block' name="email" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >Phone</label>
                    <Field className="form-control  " placeholder="" type="string" name="phone" />
                    <ErrorMessage component="small" className='text-danger d-block' name="phone" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >Street 1</label>
                    <Field className="form-control  " placeholder="" type="text" name="street1" />
                    <ErrorMessage component="small" className='text-danger d-block' name="street1" />
                </div>
            </div>

            <div className="col-12">
                <div class="form-group">
                    <label class="control-label" >Apartment #/ Unit #</label>
                    <Field className="form-control  " placeholder="" type="text" name="street2" />
                    <ErrorMessage component="small" className='text-danger d-block' name="street2" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label class="control-label required" >City</label>
                    <Field className="form-control  " placeholder="" type="text" name="city" />
                    <ErrorMessage component="small" className='text-danger d-block' name="city" />
                </div>
            </div>
            <div className="col-12">
                <div class="form-group">
                    <label htmlFor="country_code required">Country </label>
                    <select
                        value={values.country}
                        onChange={(event) => {
                            setFieldValue("country", event.target.value);
                            setFieldValue("state", "");
                            getStates(event.target.value)
                        }} className='form-control'>
                        <option value="">Select Country</option>
                        {SHIPMENT_COUNTRIES.map((data, index) => {
                            return <option value={data.code}>{data.name}</option>
                        })}
                    </select>
                    <ErrorMessage component="small" className='text-danger d-block' name="country" />
                </div>
            </div>
            <div className="col-md-6">
                <div class="form-group">

                    <label class="control-label required" >State</label>
                    <select
                        value={values.state}
                        onChange={(event) => {
                            setFieldValue("state", event.target.value);
                            // setFieldValue("city", "");
                            getCities(event.target.value);
                        }} className='form-control'>
                        {statesLoader ? (
                            <option value="">Loading..</option>
                        ) : (
                            <option value="">Select State</option>
                        )}
                        {states.map((data, index) => {
                            return (
                                <option value={data.state_code}>{data.name.replace(/"/g, '')}</option>
                            )
                        })}
                    </select>
                    <ErrorMessage component="small" className='text-danger d-block' name="state" />
                </div>
            </div>


            <div className="col-md-6">
                <div class="form-group">
                    <label class="control-label required" >Postal Code</label>
                    <Field className="form-control  " placeholder="" type="text" name="zip" />
                    <ErrorMessage component="small" className='text-danger d-block' name="zip" />
                </div>
            </div>

            {shouldBypassAddressValidation && (
                <div className="col-12">
                    <div style={{color: 'red'}}>This address could not be validated in our database. Please confirm this address is correct.</div>
                    <div class="form-group">
                        <Field type="checkbox" name="skip_validation" id="skip_validation" style={{ marginRight: '10px', marginBottom: "10px" }} />
                        <label htmlFor="skip_validation">This address is correct</label>
                    </div>
                </div>
            )}
        </>
    )
}

export default ShipmentAddress

