import React from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment'

let CustomDatePickerInput = (props) => {
    return (
        <>
            <DatePicker
                selected={props.value ? new Date(props.value) : null}
                dateFormat={localStorage.getItem("date_format").replaceAll("D", "d").replaceAll("Y", "y")}
                placeholderText={localStorage.getItem("date_format").replaceAll("d", "D").replaceAll("y", "Y")}
                onChange={(date) => props.onChange(new Date(date))}
                customInput={<input type="text" className='form-control rounded-0' value={props.value} disabled name="claim_email" />} />
        </>
    )
}

export default CustomDatePickerInput