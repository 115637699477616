import React, { useEffect, useState } from 'react';
import { withNavigation, withParams } from '../../Utils/Navigater';
import PublicHeader from '../../Components/Headers/PublicHeader';
import AuthFooter from '../../Components/footers/LoginFooter';
import ItemShipment from '../../Components/Shipment/ItemShipment';

let Index = (props) => {
   let { id } = props.params
   return (
      <>
         <PublicHeader />
         <div className="container" style={{ minHeight: "70vh" }}>
            <div className="row mt-3">
               <div className="col-12">
                  {/* <div className="alert alert-success">
                     Your payment has been successfully done.Will get back to you shortly.
                  </div> */}
               </div>
            </div>
            <div className="row mt-3">
               <div className="col-12">
                  <ItemShipment id={id} stripeStatus={false} />
               </div>
            </div>
         </div>
         {/* ********************************************** */}
         <AuthFooter />
      </>
   )
}

export default withParams(withNavigation(Index))