import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import AppApi from '../../Api/AppApi';
import { withNavigation } from './../../Utils/Navigater';
import Url from './../../Utils/Routes';
import { toast } from 'react-toastify';

let DeletClientAccount = (props) => {
    let [apiLoading, setApiLoading] = useState(false);
    let deleteAcount = async () => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to delete this account!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setApiLoading(true)
                    AppApi.deleteClient(props.client.id).then(res => {
                        toast.success('Account has been deleted!', {
                            autoClose: 5000,
                        });
                        props.navigate(Url.Dashboard);
                        setApiLoading(false)
                    }).catch(error => {
                        try {
                            let message = error.response.data.errors[0]
                            setApiLoading(false)
                            
                            toast.error(message ? message : 'Server Error!', {
                                autoClose: 5000,
                            });
                        } catch (error) {
                            toast.error('Server error.', {
                                autoClose: 5000,
                            });
                        }
                    })
                }
            })
        } catch (error) {
            setApiLoading(false)
            Swal.fire(
                'NetWork Error!',
                'Make sure you have internet connection.',
                'warning'
            )
        }
    }
    return (
        <div class="panel panel-default panel-danger">
            <div class="panel-heading">
                <h3 class="panel-title text-danger">Delete Account (Migrated)</h3>
            </div>
            <div class="panel-body p-3">
                {props.client.business_name}<br /><br />
                {apiLoading ? "Deleting...." : (
                    <input type="submit"
                        onClick={deleteAcount}
                        name="commit" value="Delete Account" class="btn btn-danger" data-disable-with="Delete Account" />
                )}

            </div>
        </div>
    )
}

export default withNavigation(DeletClientAccount)

