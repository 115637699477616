import { Link, Route, Routes, Navigate, Redirect } from "react-router-dom";
import React, { Component, useEffect, useState } from 'react';
import Url from './Utils/Routes'
// ****************************utils**************************************
import { withNavigation } from './Utils/Navigater';
import AuthApi from "./Api/AuthApi";
import LoginAuth from "./Components/Auth/LoginAuth";
// *************************pages**************************************
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// *************************pages**************************************
// auth pages 
import ResetPassword from './Pages/ResetPassword/Index';
import AuthLogin from './Pages/Login/Index';
import AuthSignup from './Pages/signup/Index';
import DashBoard from './Pages/Dashboard/Index';
import FreeTrial from './Pages/FreeTrial/Index';
import ForgetPassword from './Pages/ForgetPassword/Index';
import ClientDetails from './Pages/ClientDetails/Index';
import UserDetails from './Pages/UserDetails/Index';
import UsersSearch from './Pages/UsersSearch/Index';
import Invoice from './Pages/Invoice/Index';

// dashboard pages 
import ChangePassword from './Pages/ChangePassword/Index';
import Contact from './Pages/Contact/Index';
import Terms from './Pages/Terms/Index';
import Privacy from './Pages/Privacy/Index';
import Help from './Pages/help/Index';
import UserDashboard from './Pages/UserDashboard/Index';
import DataPurge from './Pages/DataPurge/Index';
import DefaultOptions from './Pages/DefaultOptions/Index';
import WebOptions from './Pages/WebOptions/Index';
import AuditOption from './Pages/AuditOption/Index';
import Venues from './Pages/Venues/Index';
import Dispositions from './Pages/Dispositions/Index';
import Templates from './Pages/Templates/Index';
import Categories from './Pages/Categories/Index';
import Client from './Pages/Client/Index';
import Users from './Pages/Users/Index';
import Reports from './Pages/Reports/Index';
import Containers from './Pages/Containers/Index';
import ItemBulkRelease from './Pages/ItemBulkRelease/Index';
import Claims from './Pages/Claims/Index';

import AddTemplates from './Pages/AddTemplates/Index';
import EditTemplates from './Pages/EditTemplate/Index';
import AddUser from './Pages/AddUser/Index';
import EditUsers from './Pages/EditUsers/Index';
import ActiveUser from './Pages/ActiveUser/Index';

import AddNewItems from './Pages/AddNewItems/Index';
import EditNewItems from './Pages/EditNewItems/Index';
import ItemsHistory from './Pages/ItemsHistory/Index';
import ItemsPhoto from './Pages/ItemsPhoto/Index';
import ItemsRelease from './Pages/ItemsRelease/Index';

import AddNewClaim from './Pages/AddNewClaim/Index';
import EditClaim from './Pages/EditClaim/Index';
import AdminShipments from './Pages/AdminShipments/Index';


import ShipmentsCustomerDetails from './Pages/ShipmentsCustomerDetails/Index';
import EditShipment from "./Pages/EditShipment/index";

import ItemsDeletedReport from './Pages/ItemsDeletedReport/Index';
import ContainerCountReport from './Pages/ContainerCountReport/Index';
import DispositionCountReport from './Pages/DispositionCountReport/Index';
import CategoryCountReport from './Pages/CategoryCountReport/Index';
import MonthlyActivityReport from './Pages/MonthlyActivityReport/Index';

import PublicClaims from './Pages/PublicClaims/Index';
import PublicItems from './Pages/PublicItems/Index';
import PublicClaimItems from './Pages/PublicClaimItems/Index';
import ScanItem from './Pages/ScanItem/Index';
import Commission from './Pages/Commission/Index';
import ShipmentPaymentResult from './Pages/StripeComponents/Index'


import ShipmentsDetails from './Pages/ShipmentsDetails/Index'
import StripeSuccess from './Pages/StripeSuccess/Index'
import StripeFailed from './Pages/StripeFailed/Index'

let App = (props) => {
  useEffect(() => {
    if (!localStorage.getItem("date_format")) {
      localStorage.setItem("date_format", "yyyy-MM-dd");
    }
  }, [])
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      {/* <div className="page-center-loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div></div> */}
      <Routes>
        <Route exact={true} path={Url.Login} element={<><LoginAuth /><AuthLogin /></>} />
        <Route exact={true} path={Url.resetPassword()} element={<><ResetPassword /></>} />
        <Route exact={true} path={Url.SignUp} element={<><AuthSignup /></>} />
        <Route exact={true} path={Url.Dashboard} element={<><LoginAuth /><DashBoard /></>} />
        <Route exact={true} path={Url.FreeTrial} element={<><LoginAuth /><FreeTrial /></>} />
        <Route exact={true} path={Url.ForgetPassword} element={<><ForgetPassword /></>} />
        <Route exact={true} path={Url.ClientDetails()} element={<><LoginAuth /><ClientDetails /></>} />
        <Route exact={true} path={Url.UserDetails()} element={<><LoginAuth /><UserDetails /></>} />
        <Route exact={true} path={Url.UsersEmailSearch()} element={<><LoginAuth /><UsersSearch /></>} />

        <Route exact={true} path={Url.AddNewItems} element={<><LoginAuth /><AddNewItems /></>} />
        <Route exact={true} path={Url.AddNewClaim} element={<><LoginAuth /><AddNewClaim /></>} />
        <Route exact={true} path={Url.EditNewItems()} element={<><LoginAuth /><EditNewItems /></>} />
        <Route exact={true} path={Url.ItemsHistory()} element={<><LoginAuth /><ItemsHistory /></>} />
        <Route exact={true} path={Url.ItemsPhoto()} element={<><LoginAuth /><ItemsPhoto /></>} />
        <Route exact={true} path={Url.ItemsRelease()} element={<><LoginAuth /><ItemsRelease /></>} />
        <Route exact={true} path={Url.Commission} element={<><LoginAuth /><Commission /></>} />

        <Route exact={true} path={Url.PublicClaims()} element={<><PublicClaims /></>} />
        <Route exact={true} path={Url.PublicItems()} element={<><PublicItems /></>} />
        <Route exact={true} path={Url.PublicClaimItems()} element={<><PublicClaimItems /></>} />

        <Route exact={true} path={Url.ShipmentsDetails} element={<><LoginAuth /><ShipmentsDetails /></>} />
        <Route exact={true} path={Url.ShipmentsCustomerDetails()} element={<><ShipmentsCustomerDetails /></>} />
        <Route exact={true} path={Url.EditShipment()} element={<><LoginAuth /><EditShipment /></>} />

        <Route exact={true} path={Url.StripeSuccess()} element={<><StripeSuccess /></>} />
        <Route exact={true} path={Url.StripeFailed()} element={<><StripeFailed /></>} />

        <Route exact={true} path={Url.ItemsDeletedReport} element={<><LoginAuth /><ItemsDeletedReport /></>} />
        <Route exact={true} path={Url.AdminShipments} element={<><LoginAuth /><AdminShipments /></>} />
        <Route exact={true} path={Url.DispositionCountReport} element={<><LoginAuth /><DispositionCountReport /></>} />
        <Route exact={true} path={Url.CategoryCountReport} element={<><LoginAuth /><CategoryCountReport /></>} />
        <Route exact={true} path={Url.ContainerCountReport()} element={<><LoginAuth /><ContainerCountReport /></>} />
        <Route exact={true} path={Url.MonthlyActivityReport()} element={<><LoginAuth /><MonthlyActivityReport /></>} />

        <Route exact={true} path={Url.RepoAppDashboard} element={<><LoginAuth /><UserDashboard /></>} />
        <Route exact={true} path={Url.ChangePassword} element={<><LoginAuth /><ChangePassword /></>} />
        <Route exact={true} path={Url.ContactUs} element={<><Contact /></>} />
        <Route exact={true} path={Url.Help} element={<><LoginAuth /><Help /></>} />
        <Route exact={true} path={Url.DataPurge} element={<><LoginAuth /><DataPurge /></>} />
        <Route exact={true} path={Url.DefaultOptions} element={<><LoginAuth /><DefaultOptions /></>} />
        <Route exact={true} path={Url.WebOptions} element={<><LoginAuth /><WebOptions /></>} />
        <Route exact={true} path={Url.AuditOption} element={<><LoginAuth /><AuditOption /></>} />
        <Route exact={true} path={Url.Venues} element={<><LoginAuth /><Venues /></>} />
        <Route exact={true} path={Url.Dispositions} element={<><LoginAuth /><Dispositions /></>} />
        <Route exact={true} path={Url.Templates} element={<><LoginAuth /><Templates /></>} />
        <Route exact={true} path={Url.Categories} element={<><LoginAuth /><Categories /></>} />
        <Route exact={true} path={Url.Client} element={<><LoginAuth /><Client /></>} />
        <Route exact={true} path={Url.Users} element={<><LoginAuth /><Users /></>} />
        <Route exact={true} path={Url.Reports} element={<><LoginAuth /><Reports /></>} />
        <Route exact={true} path={Url.Containers} element={<><LoginAuth /><Containers /></>} />
        <Route exact={true} path={Url.ItemBulkRelease} element={<><LoginAuth /><ItemBulkRelease /></>} />
        <Route exact={true} path={Url.Claims} element={<><LoginAuth /><Claims /></>} />
        <Route exact={true} path={Url.EditClaim()} element={<><LoginAuth /><EditClaim /></>} />

        <Route exact={true} path={Url.AddTemplates} element={<><LoginAuth /><AddTemplates /></>} />
        <Route exact={true} path={Url.EditTemplates()} element={<><LoginAuth /><EditTemplates /></>} />
        <Route exact={true} path={Url.AddUser} element={<><LoginAuth /><AddUser /></>} />
        <Route exact={true} path={Url.EditUsers()} element={<><LoginAuth /><EditUsers /></>} />
        <Route exact={true} path={Url.ActiveUser()} element={<><ActiveUser /></>} />
        <Route exact={true} path={Url.Invoice} element={<><LoginAuth /><Invoice /></>} />
        <Route exact={true} path={Url.ScanItem} element={<><LoginAuth /><ScanItem /></>} />
        <Route exact={true} path={Url.ShipmentPaymentSuccess} element={<><LoginAuth /><ShipmentPaymentResult /></>} />
        <Route exact={true} path={Url.ShipmentPaymentFailed} element={<><ShipmentPaymentResult /></>} />
        <Route exact={true} path={Url.PrivacyPolicy} element={<><Privacy /></>} />
        <Route exact={true} path={Url.Terms} element={<><Terms /></>} />
        <Route
          path="*"
          element={<Navigate to={localStorage.getItem("token") ? Url.RepoAppDashboard : Url.Login} />}
        />
      </Routes>

    </>

  )

}


export default withNavigation(App)