import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import Swal from 'sweetalert2';
import UserAppNavigater from '../../Components/AppComponents/UserAppNavigater'
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { toast } from 'react-toastify';
let WebOptions = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions);
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.clientAuditOption().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }

   let updateAuditOption = async () => {
      Swal.fire({
         title: 'Do you want to save the changes?',
         showDenyButton: false,
         showCancelButton: true,
         confirmButtonText: 'Yes',
      }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
            try {
               AppApi.updateClientAuditOption().then(res => {
                  if (res.status == 201) {
                     setData([...data, res.data])
                     toast.success('Record updated successfully.', {
                        autoClose: 5000,
                     });
                  }
                  setApiLoading(false)
               }).catch(error => {
                  setApiLoading(false)
                  toast.error('Server error.', {
                     autoClose: 5000,
                  });
               })
            } catch (error) {
               setApiLoading(false)
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         } else if (result.isDenied) {
         }
      })

   }

   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.user_settings?.user_setting_view ? <UnAuthorized /> : (
            <> <UserAppNavigater />
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className="container-fluid mt-3">
                     <div className="row">
                        <div className="col-12">
                           <div class="panel  border panel-default">

                              <div class="panel-heading">
                                 <h3 class="panel-title">Item Audit Status</h3>
                              </div>
                              <div class="panel-body p-3">
                                 <span ng-show="CURRENT_USER_ROLE_CODE == 'A'">
                                    <button class="btn btn-warning"
                                       onClick={() => {
                                          updateAuditOption()
                                       }}
                                    ><i class="fa fa-warning"></i> Enable/Disable</button>
                                 </span>
                                 <br /><br />
                                 <span class="lead ng-binding">Current Status: ENABLED</span>
                                 <p class="help-block">Enabling Item Audit will capture updates to item records. For each update, a history record is created. </p>
                              </div>
                              <table class="table table-condensed table-striped">
                                 <thead>
                                    <tr>
                                       <th>Status</th>
                                       <th>Changed By</th>
                                       <th>Changed On</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {apiLoading ? (
                                       <tr>
                                          <td className='text-center' colSpan="3">Loading...</td>
                                       </tr>
                                    ) : data.map((data, index) => {
                                       return (<tr ng-repeat="a in client_audit_options" class="ng-scope" >
                                          <td><span class={`label label-${data.audit_status == "DISABLED" ? "danger" : "success"}`}>{data.audit_status}</span></td>
                                          <td class="ng-binding">{data.user.email}</td>
                                          <td class="ng-binding">{moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " " + "HH:mm A")} </td>
                                       </tr>)
                                    })}
                                    {!apiLoading && data.length == 0 && (
                                       <tr>
                                          <td colSpan={3} className="text-center text-info">No Record found.</td>
                                       </tr>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </>)}
      </>
   )
}

export default withNavigation(WebOptions)