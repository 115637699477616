import * as Yup from "yup";
import { ITEM_MAX_PACKAGE_VALUE } from "constants/index";

export const shippingServiceValidation = Yup.object().shape({
    id: Yup.string().required('Select Shipment Service.'),
});
export const internationalShippingAddressValidation = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().required('Email is required.').email("email is not valid."),
    street1: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
    country: Yup.string().required('Country is required.'),
    hs_tariff_number_id: Yup.string().required('Tariff number is required.'),
    phone: Yup.number().required('Phone is required.').typeError("Please enter valid number.").test('len', 'Phone number can not less than 10 character.', val => val?.toString().length >= 10),
    package_value: Yup.number().required('Value is required.').min(1, "Can not less than 1.").max(ITEM_MAX_PACKAGE_VALUE, `Can not be greater than ${ITEM_MAX_PACKAGE_VALUE}.`).test('two-decimal', 'Decimal places can not be more than two.', (value) => {
        if (value) {
            const pattern = /^\d+(\.\d{1,2})?$/;
            return pattern.test(value.toString());
        }
        return true;
    }),

});
export const shipmentAddressValidation = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().required('Email is required.').email("email is not valid."),
    street1: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
    country: Yup.string().required('Country is required.'),
    phone: Yup.number().required('Phone is required.').typeError("Please enter valid number.").test('len', 'Phone number can not less than 10 character.', val => val?.toString().length >= 10),
    package_value: Yup.number().required('Value is required.').min(1, "Can not less than 1.").max(ITEM_MAX_PACKAGE_VALUE, `Can not be greater than ${ITEM_MAX_PACKAGE_VALUE}.`).test('two-decimal', 'Decimal places can not be more than two.', (value) => {
        if (value) {
            const pattern = /^\d+(\.\d{1,2})?$/;
            return pattern.test(value.toString());
        }
        return true;
    }),

});