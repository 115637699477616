import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation, withParams } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes';
import moment from 'moment';
import { Camera } from "react-camera-pro";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ItmesAppNavigater from '../../Components/AppComponents/ItmesAppNavigater';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';

let Index = (props) => {
   let { id } = props.params;
   const camera = useRef("");
   const [image, setImage] = useState("");
   const [image2, setImage2] = useState("");
   const [data, setData] = useState({});
   let [apiLoading, setApiLoading] = useState(true);
   useEffect(() => {
      getData(id)
   }, [id])
   let getData = async (id) => {
      try {
         ItemsApi.itemsDetails(id).then(res => {
            if (res.status == 200) {
               setImage2(res.data.item_image_url);
               setData(res.data);
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let addPhoto = async () => {
      try {
         console.log("props", props)
         let apiData = {
            id: id,
            data: { image_data_uri: image }
         }
         ItemsApi.addItemsPhoto(apiData).then(res => {
            if (res.status == 200) {
               setImage2(res.data.item_image_url);
               toast.success('Successfully updated!', {
                  autoClose: 5000,
               });
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            try {
               let message = error.response.data.errors[0] + "."
               toast.error(message ? message : 'Record not updated.', {
                  autoClose: 5000,
               });
            } catch (error) {
               
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let deletePhoto = async () => {
      try {
         ItemsApi.deleteItemsPhoto(id).then(res => {
            if (res.status == 200) {
               setImage2(res.data.item_image_url);
               toast.error('Photo deleted successfully.', {
                  autoClose: 5000,
               });
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }




   return (
      <>
         <UserHeader />
         <ItmesAppNavigater />
         {apiLoading ? (
            <div className="page-center-loader">
               <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="container-fluid mt-3">
               {data?.item_status?.item_status_code == "R" ? (
                  <di className="row">
                     <div className="col-md-8">
                        <div class="alert alert-warning ng-binding">
                           <p class="lead">Item Released <Link to={Url.ItemsRelease(data.id)} className="btn btn-default btn-sm">View Details </Link></p>
                           <i class="fa fa-user"></i> {data?.release_user?.email}<br />
                           <i class="fa fa-calendar"></i> <span id="item_release_datetime" class="ng-binding">{moment(data?.item_release_datetime).local().format(localStorage.getItem("date_format").toString().toUpperCase() + " HH:mm A")}</span> <br />
                        </div>
                     </div>
                  </di>
               ) : (
                  <>
                     <div className="row">
                        <div className="col-md-6">
                           <div class="panel panel-default border">
                              <div class="panel-heading">
                                 <h3 class="panel-title">Your Camera
                                    <ToolTipContainer
                                       view=""
                                       index={1}
                                       placement="right"
                                       icon="text-info"
                                       title="Your Camera"
                                       des="When you first access this page, your browser will prompt you to allow this website to access your webcam. Select the 'Always Allow' or 'Share Selected Device' option or similar. Refresh this page if you accidentally selected No or unset this option in your browser. Optimal browsers to use with this feature are Chrome or Firefox. IE (depending on the version) may be problematic."
                                    />
                                 </h3>
                              </div>
                              <div class="panel-body p-2">
                                 <div class="select">
                                    <label for="videoSource">Video source: </label><select id="videoSource"><option value="">camera 1</option></select>
                                 </div>
                                 <div className='camWeb'>
                                    <Camera facingMode='environment' numberOfCamerasCallback={(event) => {
                                       console.log("event", event)
                                    }} ref={camera} />
                                 </div>

                              </div>
                              <div class="panel-footer"   >
                                 <button class="btn btn-primary btn-large rounded-0" onClick={() => setImage(camera.current.takePhoto())} ><i class="fa fa-camera"></i> Step 1 - Snap Photo</button>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-6">

                           <div class="panel panel-default border">
                              <div class="panel-heading">
                                 <h3 class="panel-title">Snapped Photo   </h3>
                              </div>
                              <div class="panel-body p-2">
                                 <div className='camWeb border' >
                                    {image && (
                                       <img src={image} alt='Taken photo' className="w-100" />
                                    )}
                                 </div>
                              </div>
                              <div class="panel-footer"  >
                                 <button class="btn btn-success btn-large rounded-0" disabled={image == "" ? true : false} onClick={() => addPhoto(image)} ><i class="fa fa-camera"></i>  Step 2 - Save Photo</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     {image2 && (
                        <div className="row">
                           <div className="col-12">
                              <div class="panel border panel-default">
                                 <div class="panel-heading">
                                    <h3 class="panel-title">Existing Photo</h3>
                                 </div>
                                 <div class="panel-body p-2">
                                    <div class=""  >
                                       <button class="btn btn-danger btn-sm"
                                          onClick={() => {
                                             deletePhoto()
                                             setImage2(null)
                                          }}
                                       >
                                          <i class="fa fa-trash"></i> Delete Photo
                                       </button>
                                       <br />
                                       <br />
                                       <a href="#" target="_blank" class=""  >
                                          <div className='camWeb' >
                                             {image2 && (
                                                <img src={image2} alt='Taken photo' className="w-100" />
                                             )}
                                          </div>

                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </>
               )}


            </div>
         )}


         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withParams(withNavigation(Index))