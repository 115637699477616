import React, { useEffect, useState } from 'react';
import { withNavigation } from '../../Utils/Navigater';
import AuthHeader from '../../Components/Headers/AuthHeader';
import VerifyAccount from '../../Components/Forms/Users/VerifyAccount'
let Index = (props) => {

   return (
      <>
         {/* <AuthHeader /> */}
         <div className="container-fluid mt-3">
            <div className="row">
               <div className="col-md-3"></div>
               <div class="col-md-6  ">
                  <VerifyAccount />
               </div>
            </div>
         </div>
      </>
   )
}

export default withNavigation(Index)