import React, { useEffect, useState } from 'react';

import { withNavigation, withParams } from '../../Utils/Navigater';
import DashboardHeader from '../../Components/Headers/AdminHeader';
import AppApi from '../../Api/AppApi';
import CONSTANTS from '../../Utils/Constants';
import Url from '../../Utils/Routes'
import { Link } from 'react-router-dom';
import EnableClient from '../../Components/ClientDetails/EnableClient';
import DeletClientAccount from '../../Components/ClientDetails/DeletClientAccount';
import UpdateSubscription from '../../Components/ClientDetails/UpdateSubscription';
import UpdateAccount from '../../Components/ClientDetails/UpdateAccount';


let ClientDetails = (props) => {
   let { id } = props.params;
   let [client, setClient] = useState({});
   let [otherData, setOtherData] = useState({});
   let [users, setUsers] = useState([]);
   let [client_account_options, setClient_account_options] = useState({});
   let [apiLoader, setApiLoader] = useState(false);
   useEffect(() => {
      getClintlistDetails(id);
   }, [])
   let getClintlistDetails = (id) => {
      setApiLoader(true);
      try {
         AppApi.clientDetails(id).then(result => {
            console.log(result)
            if (result && result.status == 200) {
               setClient(result.data.data.client);
               setClient_account_options(result.data.data.client_account_options);
               setUsers(result.data.data.users);
               setOtherData({
                  item_table_count: result.data.data.item_table_count,
                  claim_table_count: result.data.data.claim_table_count,
                  item_history_table_count: result.data.data.item_history_table_count,
                  new_item_records_count: result.data.data.new_item_records_count,
                  new_claim_records_count: result.data.data.new_claim_records_count,
                  plan_trial_start_date: result.data.data.client.plan_trial_start_date,
                  plan_trial_end_date: result.data.data.client.plan_trial_end_date,
                  status_code: result.data.data.client.status_code,
                  business_name: result.data.data.client.business_name,
               })
               setApiLoader(false);
            }
         }).catch(error => {
            console.log("api error:", error)
         })
      } catch (error) {
         console.log("Client list api error :", error)
      }
   }



   return (
      <>
         <DashboardHeader showFilters={false} />
         <div className="p-2 px-4">
            <div className="container-fluid ">
               <div className="row">
                  {apiLoader && (
                     <div className="col-12 py-5 mt-5 text-center">
                        <div class="spinner-border text-secondary" role="status">
                           <span class="visually-hidden">Loading...</span>
                        </div>
                     </div>
                  )}
               </div>
               {!apiLoader && (
                  <div className="row mt-3">
                     <div className="col-md-6">

                        <div className="panel panel-default border">
                           <div className="panel-heading">
                              <h3 className="panel-title">Account Details - <span className="label label-default">ID {id}</span> </h3>
                           </div>
                           <div className="panel-body p-3">
                              <table className="table table-condensed table-bordered">
                                 <tbody><tr><td>Bus. Name</td><td> <strong>{client.business_name}</strong> </td></tr>
                                    <tr><td>Street1</td><td> {client.street1}</td></tr>
                                    <tr><td>Street2</td><td> {client.street2}</td></tr>
                                    <tr><td>City</td><td> {client.city} </td></tr>
                                    <tr><td>State</td><td> {client.city} </td></tr>
                                    <tr><td>Country Code</td><td> {client.country_code} </td></tr>
                                    <tr><td>Plan Start Date</td><td> {client.plan_start_date} </td></tr>
                                    <tr><td>Plan End Date</td><td> {client.plan_end_date}</td></tr>
                                    <tr><td>Plan Item Limit</td><td> {client.plan_item_limit} </td></tr>
                                    <tr><td>Plan Claim Limit</td><td> {client.plan_claim_limit} </td></tr>
                                    <tr><td>Plan User Limit</td><td> {client.plan_user_limit} </td></tr>
                                    <tr><td>Plan Photo Limit</td><td> {client.plan_photo_limit} </td></tr>
                                    <tr><td>Plan Name</td><td> {client.plan_name} </td></tr>
                                    <tr><td>Trial Start Date</td><td>{client.plan_trial_start_date} </td></tr>
                                    <tr><td>Trial End Date</td><td>{client.plan_trial_end_date} </td></tr>
                                    <tr><td>Primary Contact Email</td><td> {client.primary_contact_email}</td></tr>
                                    <tr><td>First Name</td><td>{client.primary_contact_first_name} </td></tr>
                                    <tr><td>Last Name</td><td>{client.primary_contact_last_name} </td></tr>
                                    <tr><td>Phone</td><td>{client.phone} </td></tr>
                                    <tr><td>GUI ID</td><td>{client.gui_id} </td></tr>
                                    <tr><td>Web ID </td><td>{client.web_id} </td></tr>
                                    <tr><td>Status Code</td><td>{client.status_code} </td></tr>
                                    <tr><td>Created At</td><td> {client.created_at} </td></tr>
                                    <tr><td>Updated At</td><td>{client.updated_at}</td></tr>
                                    <tr><td>Notes</td><td> {client.notes}</td></tr>
                                 </tbody></table>
                           </div>
                        </div>


                        <div class="panel panel-default border">
                           <div class="panel-heading">
                              <h3 class="panel-title">
                                 Users <span class="badge mx-1">{users.length}</span>
                                 {users.filter(data => data.status_code == CONSTANTS.ACTIVE).length}  Active,
                                 {users.filter(data => data.status_code == CONSTANTS.PANDING).length}  Pending,
                                 {users.filter(data => data.status_code == CONSTANTS.DEACTIVE).length}  Disabled
                              </h3>
                           </div>
                           <div class="panel-body p-3">
                              <table class="table table-condensed table-bordered">
                                 <thead>
                                    <tr>
                                       <th>Email</th>
                                       <th>First Name</th>
                                       <th>Last Name</th>
                                       <th>Role</th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {users.map((data, index) => {
                                       return (
                                          <tr class={`${data.status_code == "A" ? "bg-success" : ""} ${data.status_code == "D" ? "bg-danger" : ""} ${data.status_code == "P" ? "bg-warning" : ""}`}>
                                             <td><Link to={Url.UserDetails(data.id)}>{data.email}</Link></td>
                                             <td>{data.first_name}</td>
                                             <td>{data.last_name}</td>
                                             <td>Admin</td>
                                             <td>{data.status_code == "A" ? "Active" : "Disabled"}</td>
                                          </tr>
                                       )
                                    })}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div class="panel panel-default border ">
                           <div class="panel-heading">
                              <h3 class="panel-title">Plan Period Usage Overview</h3>
                           </div>
                           <div class="panel-body p-3">
                              Items: {otherData.item_table_count} (In New RepoApp: {otherData.new_item_records_count}) <br />
                              Claims: {otherData.claim_table_count} (In New RepoApp: {otherData.new_claim_records_count}) <br /><br />
                              Item History: {otherData.item_history_table_count} <br />
                              Users: {users.length}
                              <hr />
                              Subscription Start: {client.plan_start_date}<br />
                              Subscription End: {client.plan_end_date}<br />
                           </div>
                        </div>
                        <EnableClient client={client} />
                        <DeletClientAccount client={client} />
                        <UpdateSubscription client={client} client_account_options={client_account_options} />
                        <UpdateAccount client={client} client_account_options={client_account_options} />

                     </div>
                  </div>
               )}
            </div>
         </div>

      </>
   )
}

export default withParams(withNavigation(ClientDetails));