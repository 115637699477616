import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import Url from '../../Utils/Routes';
import AppApi from '../../Api/AppApi';
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { updateItemsList } from "../../store/reducers/items"
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import ToolTip from '../../Components/AppComponents/ToolTip';
let FormValidation = Yup.object().shape({
});
let Index = (props) => {
   let dispatch = useDispatch()
   let UserPermissions = useSelector((state) => state.UserPermissions)
   // let items = useSelector((state) => state.items)
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   let [keyWord, setkeyWord] = useState("");

   let [advanceFilter, setAdvanceFilter] = useState(false);
   let [filters, setFilters] = useState({});

   useEffect(() => {
      getCategories();
      getContainers();
      getVenues();
      let filObjects = localStorage.getItem("filters");
      if (filObjects) {
         filObjects = JSON.parse(filObjects);
         setFilters(filObjects);
         setAdvanceFilter(true);
         filterApi(filObjects)
      } else {
         getData(false);
      }
   }, [])

   let customSort = (rows, selector, direction) => {
      return rows.sort((rowA, rowB) => {
         // use the selector function to resolve your field names by passing the sort comparitors
         let aField, bField = '';
         try {
            aField = selector(rowA)?.toString()?.toLowerCase();
            bField = selector(rowB)?.toString()?.toLowerCase();
         } catch (exception) {
         };

         let comparison = 0;

         if (aField > bField) {
            comparison = 1;
         } else if (aField < bField) {
            comparison = -1;
         }

         return direction === 'desc' ? comparison * -1 : comparison;
      });
   };

   let getData = async (notification = true) => {
      try {
         setApiLoading(true)
         ItemsApi.topAvailable().then(res => {
            if (res.status == 200) {
               setData(res.data);
               // dispatch(updateItemsList(res.data))
               if (notification && res.data.length <= 0) {
                  toast.info(`${res.data.length} item records found.}`, {
                     autoClose: 2000,
                  });
               }

            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })

      } catch (error) {
         setApiLoading(false)

      }
   }
   let todayItems = async () => {
      try {
         setApiLoading(true)
         ItemsApi.todayItems().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let topAvailableLimit = async (limit) => {
      try {
         setApiLoading(true)
         ItemsApi.topAvailableLimit(limit).then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let decoratedOnClick = useAccordionButton((id) =>
      console.log('totally custom!'),
   );
   let lastReleasedItems = async (limit) => {
      try {
         setApiLoading(true)
         ItemsApi.lastReleasedItems(limit).then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)

         })
      } catch (error) {
         setApiLoading(false)

      }
   }
   let searchItemsWithKeyWords = async (status = false) => {
      try {
         if (keyWord) {
            setApiLoading(true)
            ItemsApi.searchItemsWithKeyWords(keyWord, status).then(res => {
               if (res.status == 200) {
                  setData(res.data);
                  if (res.data.length > 0) {
                     toast.info(`${res.data.length} item records found.`, {
                        autoClose: 2000,
                     });
                  }
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false)

            })
         } else {
            document.getElementById("keywordInput").focus();
            // getData()
         }
      } catch (error) {
         setApiLoading(false)

      }
   }
   let searchById = async () => {
      try {
         if (keyWord) {
            setApiLoading(true)
            ItemsApi.itemsDetails(keyWord).then(res => {
               if (res.status == 200) {
                  setData([res.data])
               }
               setApiLoading(false)
            }).catch(error => {
               setApiLoading(false)
               toast.error('No record found.', {
                  autoClose: 5000,
               });
               setData([])
            })
         } else {
            toast.error('Id is required.', {
               autoClose: 5000,
            });
         }
      } catch (error) {
         setData([])
         setApiLoading(false)
         toast.error('No record found.', {
            autoClose: 5000,
         });
      }
   }
   let handleKeyDown = event => {
      if (event.key === 'Enter') {
         searchItemsWithKeyWords();
      }
   };
   let [CategoriesData, setDataCategories] = useState([]);
   let [ContainersData, setDataContainers] = useState([]);
   let [venuesData, setDataVenues] = useState([]);

   let getCategories = async () => {
      try {
         AppApi.getCategories().then(res => {
            if (res.status == 200) {
               setDataCategories(res.data)
            }
         }).catch(error => {

         })
      } catch (error) {

      }
   }
   let getContainers = async () => {
      try {
         AppApi.getContainers().then(res => {
            if (res.status == 200) {
               setDataContainers(res.data)
            }
         }).catch(error => {

         })
      } catch (error) {

      }
   }
   let getVenues = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setDataVenues(res.data)
            }
         }).catch(error => {

         })
      } catch (error) {

      }
   }

   let columns = [
      {
         name: <div>Item ID</div>,
         selector: row => row.id,
         width: "100px",
         sortable: true,
         cell: (data) => {
            return (
               <Dropdown className={` ${data?.item_status?.item_status_code == "R" ? "danger" : data.item_expired ? "yellow" : data?.item_status?.item_status_code == "A" ? "success" : ""}   dashboardDropDown itemsTableDropDown`}>
                  <Dropdown.Toggle size="sm" id="dropdown-basic">
                     {data.id}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Link to={Url.EditNewItems(data.id)} className='dropdown-item'>View/Edit Details</Link>
                     <Link to={Url.ItemsRelease(data.id)} className='dropdown-item'>Release Item</Link>
                     <Link to={Url.ItemsPhoto(data.id)} className='dropdown-item'>Add/Edit Photo</Link>
                     <Link to={Url.ItemsHistory(data.id)} className='dropdown-item'>Show History</Link>
                  </Dropdown.Menu>
               </Dropdown>
            )
         },

      },
      {
         name: <div>Item Title</div>,
         selector: row => row.id,
         sortable: true,
         width: "140px",
         cell: (data) => {
            return (
               <Link to={Url.EditNewItems(data.id)} className=''>
                  {data?.item_title?.substring(0, 170)}
               </Link>
            )
         }
      },
      {
         name: <div>Description</div>,
         selector: row => row.item_description,
         sortable: true,
         width: "190px",
         cell: (data) => {
            return (
               <Link to={Url.EditNewItems(data.id)}>
                  {data?.item_description?.substring(0, 170)}
               </Link>
            )
         },
      },
      {
         name: <div>Shipment URL</div>,
         selector: row => row.shipment_id,
         sortable: false,
         width: "150px",
         cell: (data) => {
            return (
               <div>
                  {data?.shipment_id ? (
                     <a href={Url.ShipmentsCustomerDetails(data.shipment_id)} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i> Shipment</a>
                  ) : ""}
               </div>
            )
         }
      },
      {
         name: <div>After Expire</div>,
         selector: row => row.item_expire_after,
         sortable: true,
         width: "70px",
      },
      {
         name: <div>Container</div>,
         selector: row => row.item_container_name,
         sortable: true,
         width: "110px",
         cell: (data) => {
            return (
               <div>
                  {data.item_container_name}
               </div>
            )
         },
      },
      {
         name: <div>Venue</div>,

         selector: row => row.venue?.venue_name,
         sortable: true,
         width: "120px",
         cell: (data) => {
            return (
               <div>
                  {data.venue?.venue_name}
               </div>
            )
         },
      },
      {
         name: <div>Category</div>,
         width: "250px",
         selector: row => row.category?.category_description,
         sortable: true,
         cell: (data) => {
            return (
               <div>
                  {data?.category.category_description}
               </div>
            )
         },
      },
      {
         name: <div>Asset / Badge ID</div>,
         selector: row => row.item_asset_id,
         sortable: true,
      },
      {
         name: <div>Found Loc</div>,
         selector: row => row.item_found_location,
         sortable: true,
         width: "150px",
         cell: (data, index) => {
            return (
               <ToolTip
                  index={index}
                  view={data?.item_found_location?.substring(0, 200)}
                  placement="bottom"
                  icon="text-info"
                  title=""
                  des={data?.item_found_location}
               />
            )
         }
      },
      {
         name: <div>Date Found</div>,
         selector: row => row.item_found_date,
         sortable: true,
      },
      {
         name: <div>Storage Date</div>,
         width: "120px",
         selector: row => row.item_storage_date,
         sortable: true,
         cell: (data) => {
            return (
               <div>
                  {data?.item_storage_date}
               </div>
            )
         },

      },
      {
         name: <div>Status</div>,
         selector: row => row.item_status,
         width: "70px",
         sortable: true,
         cell: (data) => {
            return (
               <div>{data?.item_status?.item_status_code == "R" ? "Release" : data.item_expired ? "Expired" : data?.item_status?.item_status_code == "A" ? "Available" : ""}</div>
            )
         }
      },
      {
         name: <div>Photo</div>,
         selector: row => row.item_image_url,
         width: "50px",
         cell: (data) => {
            return (
               <div> {data?.item_image_url ? (
                  <a href={data.item_image_url} target="_blank" rel="noopener noreferrer"><i className="fas fa-image"></i></a>
               ) : ""}</div>
            )
         }
      },
      {
         name: <div>Notes</div>,
         selector: row => row.item_notes,
         sortable: true,
         width: "70px",
         cell: (data, index) => {
            if (data?.item_notes?.length > 0)
               return (

                  <ToolTip
                     index={index}
                     view={""}
                     placement="left"
                     icon="text-info"
                     title=""
                     des={data.item_notes}
                  />
               )
            else
               return (<></>);
         }
      },
      {
         name: <div>Created At</div>,
         selector: row => row.created_at,
         sortable: true,
         cell: (data) => {
            return (
               <>{moment(data.created_at).local().format(localStorage.getItem("date_format").toString().toUpperCase())} </>
            )
         }
      },
   ];

   let customStyles = {
      rows: {
         style: {
            minHeight: '45px', // override the row height
            fontSize: '14px'
         },
      },
      headCells: {
         style: {
            paddingLeft: '2px', // override the cell padding for head cells
            paddingRight: '0px',
            minHeight: '30px',
            fontWeight: 900,
         },
      },
      cells: {
         style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',
            paddingTop: '2px',
            paddingBottom: '2px',
            maxWidth: "200px"
         },
      },
      highlightOnHoverStyle: {
         backgroundColor: "#f3f3f3 !important",

      },
   };

   let filterApi = async (data) => {
      localStorage.setItem("filters", JSON.stringify(data));
      try {
         setApiLoading(true);
         if (data.item_found_date_end) {
            data.item_found_date_end = moment(data.item_found_date_end).local().format(localStorage.getItem("date_format").toString().toUpperCase())
         }
         if (data.item_found_date_start) {
            data.item_found_date_start = moment(data.item_found_date_start).local().format(localStorage.getItem("date_format").toString().toUpperCase())
         }
         await ItemsApi.advanceSearch(data).then(res => {
            if (res && res.status == 200) {
               setApiLoading(false);
               setData(res.data);

               if (res.data.length == 0) {
                  toast.warn('No record found.', {
                     autoClose: 5000,
                  });
               }
            } else {
               toast.error('Server error.', {
                  autoClose: 5000,
               });
            }
            return true
         }).catch(error => {
            try {
               let message = error.response.data.errors[0]
               toast.error(message ? message : 'Server Error!', {
                  autoClose: 5000,
               });
            } catch (error) {
               
            }
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
         console.log("server error", error.message);
         return true
      }
   }
   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.items?.items_view ? (
            <>
               <UnAuthorized />
            </>
         ) : (
            <>
               <div className="container-fluid mt-2  ">
                  <div className="row">
                     <div className="col-12">
                        <div className="addItemsContainers py-2">
                           <div className="">
                              <h4>Items</h4>
                           </div>
                           <div className=" px-0 px-md-5">
                              <Link to={Url.AddNewItems} className='btn btn-info rounded-0 me-2 '>Add item <i className="fa fa-angle-double-right"></i></Link>
                              <Dropdown as={ButtonGroup} className='itemsBtnDropDown'>
                                 <Button variant="success" className='rounded-0'
                                    onClick={() => getData()}
                                 ><i className="fas fa-sync-alt"></i> Refresh  </Button>
                                 <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                                 <Dropdown.Menu className='dropDownList'>
                                    <Dropdown.Item href="#today-items"
                                       onClick={() => todayItems()}
                                    >Items Found Today</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="#"
                                       onClick={() => topAvailableLimit(200)}
                                    >Last 200 Available Items</Dropdown.Item>
                                    <Dropdown.Item href="#"
                                       onClick={() => topAvailableLimit(300)}
                                    >Last 300 Available Items</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="#/action-1"
                                       onClick={() => lastReleasedItems(100)}
                                    >Last 100 Released Items</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>

                           </div>
                           <div className="flex-grow-1   text-center">
                              <div className="itemsSearchContainer">
                                 <div><input type="text" id='keywordInput' placeholder='Keyword(s)' value={keyWord}
                                    onChange={(event) => { setkeyWord(event.target.value) }}
                                    onKeyDown={handleKeyDown}
                                 /></div>
                                 <div>
                                    <Dropdown as={ButtonGroup} className='itemsBtnDropDown'>
                                       <Button variant="success" className='rounded-0'
                                          onClick={() => {
                                             searchItemsWithKeyWords()
                                          }}
                                       ><i className="fa fa-search me-1"></i>Search</Button>
                                       <Dropdown.Toggle split variant="success" />
                                       <Dropdown.Menu className='dropDownList'>
                                          <Dropdown.Item href="#"
                                             onClick={() => searchById()}
                                          >Search by Item ID (all statuses)</Dropdown.Item>
                                          <Dropdown.Item href="#"
                                             onClick={() => searchItemsWithKeyWords(true)}
                                          >Released Items (created in last year)</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </div>
                              </div>
                           </div>
                           <div className="">
                              <button className={`advanceFilter ${advanceFilter ? "active" : ""}  btn-bordered btn rounded-0`}
                                 onClick={() => setAdvanceFilter(!advanceFilter)}
                              ><i className="fa fa-search-plus me-1"></i> Advanced Search</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  {advanceFilter && (
                     <Formik
                        initialValues={{
                           category_id: filters.category_id ? filters.category_id : "",
                           item_container_name: filters.item_container_name ? filters.item_container_name : "",
                           item_found_date_start: filters.item_found_date_start ? filters.item_found_date_start : "",
                           item_found_date_end: filters.item_found_date_end ? filters.item_found_date_end : "",
                           item_status: filters.item_status ? filters.item_status : "",
                           keyword: filters.keyword ? filters.keyword : "",
                           venue_id: filters.venue_id ? filters.venue_id : "",
                        }}
                        enableReinitialize={true}
                        validationSchema={FormValidation}
                        onSubmit={async (values, { setSubmitting }) => {
                           setSubmitting(true);
                           let data = { ...values }
                           let result = await filterApi(data)
                           setSubmitting(false)

                        }}
                     >
                        {({ errors, touched, values, isSubmitting, setFieldValue, setSubmitting, resetForm }) => (
                           <Form >
                              <div className="row">
                                 <div className="col-sm-12">
                                    <div className="panel panel-warning "  >
                                       <div className="panel-heading d-flex justify-content-between">
                                          <h3 className="panel-title">Advanced Search   </h3>
                                          <ToolTip
                                             placement="left"
                                             view=""
                                             icon="text-warning"
                                             title="Advanced Search"
                                             des="Use any combination of the below fields along with an item status to search for items "
                                          />
                                       </div>
                                       <div className="panel-body p-2">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label lassName="control-label" for="search_page_logo_url">Container</label>
                                                   <select className='form-control'
                                                      onChange={(event) => setFieldValue("item_container_name", event.target.value)}
                                                      value={values.item_container_name} Remove Release
                                                   >
                                                      <option value="">-Pick Container -</option>
                                                      {ContainersData.map((data, index) => {
                                                         return (
                                                            <option key={data.container_name} value={data.container_name}>{data.container_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Category</label>
                                                   <select className='form-control'
                                                      onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                      value={values.category_id}
                                                   >
                                                      <option value="">-Pick Category -</option>
                                                      {CategoriesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.category_description}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Found on or After</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="item_found_date_start"
                                                      value={values.item_found_date_start}
                                                   />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Found on or Before</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="item_found_date_end"
                                                      value={values.item_found_date_end}
                                                   />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Status</label>
                                                   <select name="" id="" className='form-control'
                                                      onChange={(event) => setFieldValue("item_status", event.target.value)}
                                                      value={values.item_status}
                                                   >
                                                      <option value="" selected="selected">--Either--</option>
                                                      <option value="A">Available</option>
                                                      <option value="R">Released</option>
                                                      <option value="P">Pending</option>
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="item_status" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Keyword</label>
                                                   <Field className="form-control  " type="text" name="keyword" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="control-label" for="search_page_logo_url">Venue</label>
                                                   <select name="" id="" className='form-control'
                                                      onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                      value={values.venue_id}
                                                   >
                                                      <option value="">-Pick Venue -</option>
                                                      {venuesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="col-md-6 d-flex align-items-center">
                                                <div className="input-group">
                                                   <button type='submit' className="btn btn-default"><i className="fa fa-search"></i> Search </button>
                                                   <button className="btn btn-default" type='button' onClick={() => {
                                                      getData();
                                                      resetForm();
                                                      setFilters({})
                                                      localStorage.removeItem("filters")
                                                   }} >   Reset</button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     </Formik>


                  )}
                  {/* *************************************************************** */}
                  <div className="row mt-3">
                     <div className="col-sm-12">
                        <div className="panel border panel-default">
                           <div className="panel-heading">
                              <strong>Items</strong>
                              <div className="badge badge-info ms-2 ng-binding">
                                 {data.length} 
                              </div>
                           </div>
                           <DataTable
                              striped={true}
                              customStyles={customStyles}
                              columns={columns}
                              data={data}
                              fixedHeader
                              progressPending={apiLoading}
                              sortFunction={customSort}
                           />

                        </div>
                     </div>
                  </div>
               </div>
            </>
         )}
         <br />
         <br />
         <br />
      </>
   )
}

export default withNavigation(Index)