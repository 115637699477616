import React, { useState } from 'react';
import QRCode from "react-qr-code";
import Url from '../../Utils/Routes';
let QrCode = ({ id }) => {
    let [copied, setCopied] = useState(false)
    return (
        
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%" }} className='text-center mt-2 h-10 w-10'>
            <QRCode 
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            viewBox={`0 0 256 256`}
            value={`${Url.AppURL}${Url.EditNewItems(id)}`} />
        </div>
    )
}

export default QrCode

