import React, { useState } from 'react';

let UnAuthorized = (props) => {
    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="unauthorized_container">
                            <div> <i className='fa fa-lock'></i>
                                <br />
                                <div className='alert alert-danger'> You are unauthorized to access this service.</div></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnAuthorized

