import axios from "../Utils/axios";

let AuthApi = {
   get_bulk_release_expired: async () => axios({
      url: `/items/get_bulk_release_expired.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   get_bulk_release_container: async (data) => axios({
      url: `/items/get_bulk_release_container?item_container_name=${data}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   get_bulk_release_storage_date: async (data) => axios({
      url: `/items/get_bulk_release_storage_date?item_storage_date_end=${data.item_storage_date_end}&item_storage_date_start=${data.item_storage_date_start}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   get_bulk_release_ids: async (data) => axios({
      url: `/items/get_bulk_release_ids.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   get_items_by_item_bulk_release_id: async (data) => axios({
      url: `/items/get_items_by_item_bulk_release_id.json?item_bulk_release_id=${data.item_bulk_release_id}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   remove_bulk_release: async (data) => axios({
      url: `/items/remove_bulk_release.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   bulk_release_container: async (data) => axios({
      url: `/items/bulk_release_container.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   bulk_release_expired: async (data) => axios({
      url: `/items/bulk_release_expired.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   bulk_release_storage_date: async (data) => axios({
      url: `/items/bulk_release_storage_date.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getItemsContainers: async (data) => axios({
      url: `/items/get_used_container_names.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   get_items_list: async (data) => axios({
      url: `/items/get_items_list.json`,
      method: "get",
      params: data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   bulk_release_item_ids: async (data) => axios({
      url: `/items/bulk_release_item_ids.json`,
      method: "patch",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),



}
export default AuthApi

