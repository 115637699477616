import axios from "../Utils/axios";

let ClaimItemsApi = {
   index: async (limit) => axios({
      url: `/claims/top_available.json?limit=${limit}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   followUp: async (limit) => axios({
      url: `/claims/show_follow_up.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   addClaim: async (data) => axios({
      url: "/claims.json",
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   updateClaim: async (data) => axios({
      url: `/claims/${data.id}.json`,
      method: "patch",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   getClaim: async (data) => axios({
      url: `/claims/${data}.json`,
      method: "get",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   autoMatch: async (data) => axios({
      url: `/claims/${data}/auto_match.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   WideAutoMatch: async (data) => axios({
      url: `/claims/${data}/auto_match_wide.json`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   advanceSearch: async (data, status) => axios({
      url: `/claims/advanced_search.json?category_id=${data.category_id}&claim_lost_date_end=${data.claim_lost_date_end}&claim_lost_date_start=${data.claim_lost_date_start}&claim_status=${data.claim_status}&keyword=${data.keyword}&venue_id=${data.venue_id}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   searchWithKeyWords: async (data, status) => axios({
      url: `/claims/keyword_search?keyword=${data}&&expired=${status}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),


}
export default ClaimItemsApi

