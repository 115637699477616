import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { withNavigation } from '../../Utils/Navigater';
import UserHeader from '../../Components/Headers/UserHeader';
import ItemsApi from '../../Api/ItemsApi';
import AppApi from '../../Api/AppApi';
import Url from '../../Utils/Routes'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UnAuthorized from '../../Components/AppComponents/UnAuthorized';
import DatePickerInput from '../../Components/Forms/Inputs/DatePickerInput';
import ToolTipContainer from '../../Components/AppComponents/ToolTip';
import DateHelper from '../../Utils/DateHelper';

const FormValidation = (data) => {
   let checks = {}
   if (!data?.storage_date_enabled) {
      checks.item_storage_date = Yup.string().required('Item storage date is required.');
   }
   if (data?.require_asset_id) {
      checks.item_asset_id = Yup.string().required('Asset ID is required.')
   }
   if (data?.require_venue) {
      checks.venue_id = Yup.string().required('Venue is required.')
   }
   // if (data?.require_category) {
   // }
   checks.category_id = Yup.string().required('Category name is required.')
   if (data?.require_container) {
      checks.item_container_name = Yup.string().required('Container name is required.')
   }
   checks.category_id = Yup.string().required('Category name is required.')
   checks.item_description = Yup.string().required('Description is required.')
   checks.item_expire_after = Yup.string().required('Expire date is required.')
   checks.item_found_date = Yup.string().required('Item found date is required.')

   return Yup.object().shape(checks)
};


let Index = (props) => {
   const UserPermissions = useSelector((state) => state.UserPermissions)
   let [apiLoading, setApiLoading] = useState(true);
   let [data, setData] = useState([]);
   useEffect(() => {
      getData();
   }, [])
   let getData = async () => {
      try {
         AppApi.clientAccountOptions().then(res => {
            if (res.status == 200) {
               setData(res.data)
            }
            setApiLoading(false)
         }).catch(error => {
            setApiLoading(false)
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         setApiLoading(false)
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let [CategoriesData, setDataCategories] = useState([]);
   let [ContainersData, setDataContainers] = useState([]);
   let [venuesData, setDataVenues] = useState([]);
   useEffect(() => {
      getCategories();
      getContainers();
      getVenues();
   }, [])
   let getCategories = async () => {
      try {
         AppApi.getCategories().then(res => {
            if (res.status == 200) {
               setDataCategories(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getContainers = async () => {
      try {
         AppApi.getContainers().then(res => {
            if (res.status == 200) {
               setDataContainers(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }
   let getVenues = async () => {
      try {
         AppApi.getVenues().then(res => {
            if (res.status == 200) {
               setDataVenues(res.data)
            }
         }).catch(error => {
            toast.error('Server error.', {
               autoClose: 5000,
            });
         })
      } catch (error) {
         toast.error('Server error.', {
            autoClose: 5000,
         });
      }
   }


   return (
      <>
         <UserHeader />
         {!UserPermissions?.permissions?.pages?.items?.add_item ? <UnAuthorized /> : (
            <>
               {apiLoading ? (
                  <div className="page-center-loader">
                     <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                     </div>
                  </div>
               ) : (
                  <div className="container-fluid mt-3">
                     <div className="row">
                        <div className="col-lg-8 co-12">
                           <Formik
                              initialValues={{
                                 category_id: "",
                                 item_asset_id: "",
                                 item_container_name: data.default_container_name,
                                 item_description: "",
                                 item_expire_after: data.default_item_expire_after,
                                 item_found_by: "",
                                 item_found_date: "",
                                 item_found_location: "",
                                 item_notes: "",
                                 item_storage_date: "",
                                 item_suspected_owner: "",
                                 item_suspected_owner_country_code: "",
                                 item_suspected_owner_email: "",
                                 item_suspected_owner_initial_country: "",
                                 item_suspected_owner_phone_number: "",
                                 item_title: "",
                                 item_web_description: "",
                                 venue_id: ""
                              }}
                              validationSchema={FormValidation(data)}
                              onSubmit={(values, { setSubmitting }) => {
                                 setSubmitting(true);
                                 try {
                                    let valuesToAdd = JSON.parse(JSON.stringify(values));
                                    if (data?.storage_date_enabled) {
                                       // MEANS HIDE STORAGE DATE
                                       valuesToAdd.item_storage_date = DateHelper.convertLocalToUTCDate(valuesToAdd?.item_found_date);
                                    }
                                    else {
                                       // MEANS GET STORAGE DATE FROM USER
                                       valuesToAdd.item_storage_date = DateHelper.convertLocalToUTCDate(valuesToAdd?.item_storage_date);
                                    }
                                    valuesToAdd.item_found_date = DateHelper.convertLocalToUTCDate(valuesToAdd?.item_found_date);
                                    ItemsApi.addItems(valuesToAdd).then(result => {
                                       if (result && result.status == 201) {
                                          toast.success('Created successfully.', {
                                             autoClose: 5000,
                                          });
                                          props.navigate(Url.EditNewItems(result.data.id));
                                       } else {
                                          toast.error('Server error.', {
                                             autoClose: 5000,
                                          });
                                       }
                                       setSubmitting(false)
                                    }).catch(error => {
                                       setSubmitting(false)
                                       try {
                                          let message = error.response.data.errors[0]
                                          toast.error(message ? message : 'Server Error!', {
                                             autoClose: 5000,
                                          });
                                       } catch (error) {
                                          
                                       }
                                    })
                                 } catch (error) {
                                    setSubmitting(false)
                                    toast.error('Server error.', {
                                       autoClose: 5000,
                                    });
                                    console.log("server error", error.message)
                                 }

                              }}
                           >
                              {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                                 <Form >
                                    <div class="panel border panel-default">
                                       <div class="panel-heading">
                                          <h3 class="panel-title">Item Details    </h3>
                                       </div>
                                       <div class="panel-body p-2 px-4">
                                          <div className="row">
                                             <div className="col-12">
                                                <div className="form-group">
                                                   <label className="font-weight-bold">Item Title</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_title" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_title" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">Description</label>
                                                   <textarea name="" className='form-control' rows="2"
                                                      onChange={(event) => setFieldValue("item_description", event.target.value)}
                                                      value={values.item_description}
                                                   ></textarea>
                                                   <ErrorMessage component="small" className='text-danger' name="item_description" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Web Description
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="Web Description"
                                                         des={"For accounts that currently use the Item Web Integration feature for displaying Items on public accessible page. Add less descriptive version of the Item. The Web Description will appear on the public Item search page. If no description is provided, the record will not be listed. Found Date and Found Location is also shown on the public Item search page. "}
                                                      />
                                                   </label>
                                                   <textarea name="" className='form-control' rows="2"
                                                      onChange={(event) => setFieldValue("item_web_description", event.target.value)}
                                                      value={values.item_web_description}
                                                   ></textarea>
                                                   <ErrorMessage component="small" className='text-danger' name="item_web_description" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold">Venue</label>
                                                   <select className='form-control rounded-0'
                                                      onChange={(event) => setFieldValue("venue_id", event.target.value)}
                                                   >
                                                      <option value="">-Pick Venue -</option>
                                                      {venuesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.venue_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="venue_id" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">Category</label>
                                                   <select className='form-control rounded-0' name=""
                                                      onChange={(event) => setFieldValue("category_id", event.target.value)}
                                                   >
                                                      <option value="">-Pick Category -</option>
                                                      {CategoriesData.map((data, index) => {
                                                         return (
                                                            <option key={data.id} value={data.id}>{data.category_description}</option>
                                                         )
                                                      })}
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="category_id" />
                                                </div>
                                             </div>
                                             <div className="col-md-3">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Container
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="Container"
                                                         des={"Pick a container where the item will be stored. You may add new container dropdown values under the Container section."}
                                                      />
                                                   </label>
                                                   <select className='form-control rounded-0'
                                                      value={values.item_container_name}
                                                      onChange={(event) => setFieldValue("item_container_name", event.target.value)}
                                                   >
                                                      <option value="">-Pick Container -</option>
                                                      {ContainersData.map((data, index) => {
                                                         return (
                                                            <option key={data.container_name} value={data.container_name}>{data.container_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                   <ErrorMessage component="small" className='text-danger' name="item_container_name" />
                                                </div>
                                             </div>
                                             <div className="col-md-3">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Expire After
                                                      <ToolTipContainer
                                                         index={1}
                                                         view={""}
                                                         placement="right"
                                                         icon="text-info"
                                                         title="Expire After"
                                                         des={"Select the number of days this Item will be kept in storage per your rentention policy. Expiration is based on Storage Date + Expire After days. Items beyond this date will still be 'Available' but will show up as 'Expired' (such as under the Bulk Release Expired Items function)."}
                                                      />
                                                   </label>
                                                   <select className='form-control rounded-0'
                                                      onChange={(event) => setFieldValue("item_expire_after", event.target.value)}
                                                      value={values.item_expire_after}
                                                   >
                                                      <option value="" >- Pick Expire After -</option>
                                                      <option value="30">30 days</option>
                                                      <option value="60">60 days</option>
                                                      <option value="90">90 days</option>
                                                      <option value="120">120 days</option>
                                                   </select>
                                                </div>
                                             </div>

                                             <div className="col-md-3">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">or other # of days</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_expire_after" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_expire_after" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div className="col-md-3">
                                                <div className="form-group">
                                                   <label className="font-weight-bold required">Found Date</label>
                                                   <DatePickerInput
                                                      onChange={setFieldValue}
                                                      name="item_found_date"
                                                      value={values.item_found_date}
                                                   />
                                                   <ErrorMessage component="small" className='text-danger' name="item_found_date" />
                                                </div>
                                             </div>
                                             {(!data?.storage_date_enabled || data?.storage_date_enabled == null) && (
                                                <div className="col-md-3">
                                                   <div className="form-group">
                                                      <label className="font-weight-bold required">Storage Date
                                                         <ToolTipContainer
                                                            index={1}
                                                            view={""}
                                                            placement="right"
                                                            icon="text-info"
                                                            title="Storage Date"
                                                            des={"Select the date when this item was added to storage. Usually, this should match the Found Date unless you have a different internal process. The expiration of this item is based on the Storage Date and Expire After value."}
                                                         />
                                                      </label>
                                                      <DatePickerInput
                                                         onChange={setFieldValue}
                                                         name="item_storage_date"
                                                         value={values.item_storage_date}
                                                      />
                                                      <ErrorMessage component="small" className='text-danger' name="item_storage_date" />
                                                   </div>
                                                </div>
                                             )}

                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold ">Asset / Badge ID</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_asset_id" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_asset_id" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Suspected Owner</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_suspected_owner" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_suspected_owner" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Suspected Owner Email</label>
                                                   <Field type="email" className='form-control rounded-0' name="item_suspected_owner_email" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_suspected_owner_email" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Suspected Owner Phone Number</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_suspected_owner_phone_number" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_suspected_owner_phone_number" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Found Location</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_found_location" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_found_location" />
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Found By</label>
                                                   <Field type="text" className='form-control rounded-0' name="item_found_by" />
                                                   <ErrorMessage component="small" className='text-danger' name="item_found_by" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div className="col-12">
                                                <div className="form-group">
                                                   <label className="font-weight-bold  ">Notes</label>
                                                   <textarea name="" className='form-control' rows="2"
                                                      onChange={(event) => setFieldValue("item_notes", event.target.value)}
                                                   ></textarea>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="panel-footer">
                                          <div>
                                             <span >
                                                <button class="btn btn-success me-1" disabled={isSubmitting} type="submit "   > {isSubmitting ? "Loading..." : (<><i class="fa fa-check"></i> Save</>)} </button>
                                                {/* <button class="btn btn-danger ng-hide"> <i class="fa fa-trash-o"></i> Delete</button> */}
                                             </span>
                                             <Link to={Url.RepoAppDashboard} class="btn btn-default"  >Cancel</Link>
                                          </div>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>

                        </div>
                     </div>
                  </div>

               )}
            </>
         )
         }


         <br />
         <br />
         <br />
         <br />
      </>
   )
}

export default withNavigation(Index)