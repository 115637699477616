import axios from "../Utils/axios";

let ShipmentApi = {
   shipmentDetails: async (data) => axios({
      url: `/shipments/${data}/address`,
      method: "post",
      data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentPaidDetails: async (data) => axios({
      url: `/shipments/${data}/details`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentDetailsWithCurrency: async (id, currency) => axios({
      url: `/shipments/${id}/details?currency=${currency}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentToAddress: async (data) => axios({
      url: `/shipments/${data.id}/address`,
      method: "post",
      data: data.data,
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   shipmentPaymentLink: async (data) => axios({
      url: `/shipments/${data.id}/payment_link.json`,
      method: "post",
      data: data.data,
      headers: {
         'Content-Type': 'application/json',
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   adminShipmentList: async (page,limit) => axios({
      url: `/repoadmin/shipments?page=${page}&per_page=${limit}`,
      method: "get",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      }
   }),
   resendShipmentRequest: async (shipmentId) => axios({
      url: `/shipments/${shipmentId}/resend_shipment_request`,
      method: "post",
      headers: {
         authorization: "Bearer " + localStorage.getItem("token"),
      },
   }),
   currencies: async () => axios({
      url: `/shipment_currencies`,
      method: "get",
   }),
   shipmentPublicUpdate: async (shipmentId, data) => axios({
      url: `/shipments/${shipmentId}/public_update`,
      method: "put",
      data: data
   }),
}
export default ShipmentApi

