import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let ClientIframe = (props) => {
    let [copied, setCopied] = useState(false)
    return (
        <>
            <div className="alert alert-info mt-3">
                {props.label}
                <CopyToClipboard text={`<iframe style="height: 100vh;width:100%" src="${window.location.origin + props.id}"></iframe>`}
                    onCopy={() => {
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, 2000);
                    }}>
                    {copied ? (
                        <span className='float-right pointer'>
                            <i className="fa fa-check text-success"></i></span>
                    ) : (
                        <span className='float-right pointer'
                        ><i className="fa fa-copy"></i></span>
                    )}
                </CopyToClipboard>
            </div>
        </>
    )
}

export default ClientIframe

