import axios from 'axios'

const instance = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL_NODE,
});

instance.interceptors.response.use(function (response) {   
   return response;
}, function (error) {
   if (error.response && error.response.status == 401) {
      // localStorage.removeItem('token')
      // window.location = "/login"
   }
   if (!error.response) {
      // localStorage.removeItem('token')
      // window.location = "/login"
   }
   return Promise.reject(error);
});

export default instance